import React from "react";
import "./loader2.css";

function Loader2() {
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="load-more">
            <button type="button">
              Loading Diamonds <span className="c-three-dots-loader "></span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loader2;
