import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CartItem from "./CartItem";
import { getCartSummary } from "../../redux/reducers/CheckoutSlice";
function CartSummary() {
  const dispatch = useDispatch();
  let cart = useSelector((state) => state.productReducer.cart.items);

  let cartSummary = useSelector(
    (state) => state.CheckoutReducer.checkout.cartSummary
  );
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);

  useEffect(() => {
    UserID &&
      dispatch(getCartSummary({ Customer_ID: UserID, BackEndClientId: 3 }));
  }, []);

  return (
    <div className="cart-summary-box">
      <div className="cart-title">
        <h5>Cart Summary</h5>
      </div>
      <div className="checkout-cart-summary cmn-scroll cmn-scroll2">
        {cart.length > 0 &&
          cart.map((product) => {
            return <CartItem product={product} key={product.Stock_ID} />;
          })}
      </div>
      <div className="cart-box">
        <ul>
          <li>
            <span>Subtotal</span>
            <span>${cartSummary.Total_WebOrder_Amt}</span>
          </li>
          <li>
            <span>Shipping fee*</span>
            <span>Free</span>
          </li>
          <li>
            <span>Tax</span>
            <span>${cartSummary.SumOfCharges}</span>
          </li>
          <li className="carttotal">
            <span>Total</span>
            <span>${cartSummary.Total_WebOrder_Amt_With_Charges}</span>
          </li>
        </ul>
        {/* <div className="cart-button">
          <a className="cart-btn" href="#">
            Pay Now
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default CartSummary;
