import React from "react";

function FixPart() {
  return (
    <div className="compare-fixedpart">
      <div className="compare-cmn-part compare-fixedpart-inner">
        {/*  this first ul used for design alignment purpose */}
        <ul className="fixed-ul">
          <li className="id-close-icon cmn-not-shown-item">
            {/*  this report id used for design alignment purpose */}
            <span> Dimond Properties</span>
            <span>
              {/* <img
                src="assets/images/stock/close.png"
                alt=""
                className="img-fluid"
              /> */}
            </span>
          </li>
          <li className="image-li cmn-not-shown-item">
            <span>
              <img
                src="assets/images/home/Logo.png"
                className="img-fluid"
                alt=""
              />
            </span>
          </li>
        </ul>
        <ul>
          <li className="title">
            <span> Crading</span>
          </li>
          <li>Diamond Shape</li>
          <li>Carat Size</li>
          <li>Color</li>
          <li>Clarity</li>
          <li>Cut</li>
          <li>Polish</li>
          <li className="title">
            <span>Advanced</span>
          </li>
          <li>Symmetry</li>
          <li>Flourescence</li>
          <li>Depth</li>
          <li>Table</li>
          <li>Lab</li>
          <li>Girdle%</li>
          <li>Crown Height</li>
          <li>Crown Angle</li>
          <li>Pavilion Height</li>
          <li>Pavilion Angle</li>
          <li> Culet</li>
          <li>Shade</li>
          <li>H&A</li>
          <li>LowerHalve</li>
          <li>Tinge</li>
          <li>Milky</li>
          <li>Eyeclean</li>
          <li>Key To Symbol</li>
          <li>Comment</li>
          <li className="title">
            <span>Inclusion</span>
          </li>
          <li>BIS</li>
          <li>BIC</li>
          <li>WIS</li>
          <li>WIC</li>
        </ul>
      </div>
    </div>
  );
}

export default FixPart;
