import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import CookiePolicy from "../CookiePolicy/CookiePolicy";

function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="Cookie Policy" />
        <CookiePolicy />
      </Layout>
    </>
  );
}

export default Index;
