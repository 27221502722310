import { useToasts } from "react-toast-notifications";
function useNotify() {
  const { addToast } = useToasts();

  const callAlert = (message, type) => {
    addToast(message, {
      appearance: type,
      autoDismiss: true,
      placement: "top-right",
    });
  };

  return callAlert;
}

export default useNotify;
