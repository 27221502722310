import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Domain, BackEndClientId } from "../../constant";

const initialState = {
  wishlist: {
    items: [],
    isItemAddedToWishlist: true,
    isGetItemFromWishlist: false,
    getItemWishError: "",
    isItemDeletedToWishlist: true,
    addItemWishSuccess: "",
    addItemWishError: "",
  },
};

export const addToWishlist = createAsyncThunk(
  "addtowishlist",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/add-to-watch-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
        BackEndClientId,
      }),
    });
    let data = await response.json();
    return data;
  }
);

export const getMyWishList = createAsyncThunk(
  "getmywishlist",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/get-stock-watchlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
        BackEndClientId,
      }),
    });
    let data = await response.json();
    return data.Result.rows;
  }
);

export const removeWishListItem = createAsyncThunk(
  "remove_wishlist_item",
  async (searchParam) => {
    const response = await fetch(
      `${Domain}/mobile-api/remove-from-watch-list`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...searchParam,
          BackEndClientId,
        }),
      }
    );
    let data = await response.json();
    return data.Result;
  }
);

export const WishlistSlice = createSlice({
  name: "wishlist_slice",
  initialState,
  reducers: {
    removeLocalWishList: (state, action) => {
      let olditem = state.wishlist.items;
      let filterItem = olditem.filter(
        (product) => product.Stock_ID !== action.payload
      );
      state.wishlist.items = filterItem;
    },
    resetaddItemWishSuccess: (state) => {
      state.wishlist.addItemWishSuccess = "";
    },
    resetaddItemWishError: (state) => {
      state.wishlist.addItemWishError = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToWishlist.pending, (state) => {
        state.wishlist.isItemAddedToWishlist = false;
        state.wishlist.addItemWishSuccess = "";
      })
      .addCase(addToWishlist.fulfilled, (state, action) => {
        if (action.payload.IsSuccess) {
          state.wishlist.isItemAddedToWishlist = true;
          state.wishlist.addItemWishError = "";
          state.wishlist.addItemWishSuccess = action.payload.Message;
        } else {
          state.wishlist.isItemAddedToWishlist = false;
          state.wishlist.addItemWishError = action.payload.Message;
        }
      })
      .addCase(addToWishlist.rejected, (state, action) => {
        state.wishlist.isItemAddedToWishlist = false;
        state.wishlist.addItemWishError =
          "Some thing went wrong! Internal server error";
        state.wishlist.addItemWishSuccess = "";
      });

    builder
      .addCase(getMyWishList.pending, (state) => {
        state.wishlist.items = [];
      })
      .addCase(getMyWishList.fulfilled, (state, action) => {
        state.wishlist.items = action.payload || [];
        state.getItemWishError =
          action.payload === null ? "Data not found!" : "";
        action.payload !== null &&
          localStorage.setItem("localwishlist", JSON.stringify(action.payload));
      })
      .addCase(getMyWishList.rejected, (state, action) => {
        state.wishlist.items = [];
        state.getItemWishError = "Some thing went wrong! Internal server error";
      });

    builder
      .addCase(removeWishListItem.pending, (state) => {
        state.isItemDeletedToWishlist = false;
      })
      .addCase(removeWishListItem.fulfilled, (state, action) => {
        state.isItemDeletedToWishlist = true;
      })
      .addCase(removeWishListItem.rejected, (state, action) => {
        state.isItemDeletedToWishlist = false;
      });
  },
});
export const {
  removeLocalWishList,
  resetaddItemWishError,
  resetaddItemWishSuccess,
} = WishlistSlice.actions;
export default WishlistSlice.reducer;
