import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import { getViewProduct } from "../../redux/reducers/CommonSlice";
import {
  addToCart,
  getMyCart,
  addToCartLocal,
  resetaddItemSuccess,
  resetaddItemError,
} from "../../redux/reducers/ProductSlice";
import {
  addToWishlist,
  getMyWishList,
  resetaddItemWishSuccess,
  resetaddItemWishError,
} from "../../redux/reducers/WishListSlice";
import { setSearchRemember } from "../../redux/reducers/StockFilterSlice";
import useNotify from "../../hooks/useNotify";
import { Domain, DomainWithOutHttp } from "../../constant";
import { getProductDetails } from "../../redux/reducers/CommonSlice";
import { isEmptyObj } from "../../helper/helperFunction";
import { resetorderPlaced } from "../../redux/reducers/CheckoutSlice";

function ProductDetail() {
  const { stonenos } = useParams();
  const [imageMode, setImageMode] = useState(true);
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [pageLinkShare, setPageLinkShare] = useState(false);
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  let { isItemAdded, addItemError, addItemSuccess } = useSelector(
    (state) => state.productReducer.cart
  );
  let { isItemAddedToWishlist, addItemWishError, addItemWishSuccess } =
    useSelector((state) => state.WishlistReducer.wishlist);
  // useEffect(() => {
  //   $(".popeover-btn").on("click", function () {
  //     $(".pop-content.pop-show ").removeClass("pop-show");
  //     $(".popeover-btn.button-actives").removeClass("button-actives");
  //     $(this).addClass("button-actives");
  //     $(this).next(".pop-content").toggleClass("pop-show");
  //   });
  // }, []);
  const product = useSelector((state) => state.commonReducer.viewProductDetail);

  useEffect(() => {
    if (!isEmptyObj(product)) {
      $("html, body").animate(
        {
          scrollTop: $("#productdetail_main").offset().top - 30,
        },
        "slow"
      );
    }
  }, []);
  useEffect(() => {
    dispatch(getViewProduct());
    dispatch(setSearchRemember());
    if (isEmptyObj(product)) {
      dispatch(getProductDetails({ StoneNos: stonenos }));
      setPageLinkShare(true);
    }
  }, []);

  useEffect(() => {
    addItemSuccess && Notify(addItemSuccess, "success");
    addItemSuccess && dispatch(resetaddItemSuccess());
    UserID && addItemSuccess && dispatch(getMyCart({ UserID }));
  }, [addItemSuccess]);

  // when any item added to cart after login then this function is called after adding item to cart
  useEffect(() => {
    addItemError && Notify(addItemError, "error");
    addItemError && dispatch(resetaddItemError());
  }, [addItemError]);

  useEffect(() => {
    addItemWishSuccess && Notify(addItemWishSuccess, "success");
    addItemWishSuccess && dispatch(resetaddItemWishSuccess());
    UserID && addItemWishSuccess && dispatch(getMyWishList({ UserID }));
  }, [addItemWishSuccess]);

  // when any item added to wishlist after login then this function is called after adding item to cart
  useEffect(() => {
    addItemWishError && Notify(addItemWishError, "error");
    addItemWishError && dispatch(resetaddItemWishError());
  }, [addItemWishError]);

  return (
    !isEmptyObj(product) && (
      <>
        <section
          className="stock-container bg-cmn-property product-detail-container"
          id="productdetail_main"
        >
          <div className="container px-0 box-shadow">
            <div className="container white-bg-container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="previewer-carousel">
                    {imageMode && (
                      <img
                        src={
                          product.Stone_Img_url === ""
                            ? "assets/images/procuctdetails/no-image-large2.png"
                            : product.Stone_Img_url
                        }
                        className="img-fluid diamondimg"
                        alt="dimaond"
                      />
                    )}

                    {!imageMode && (
                      <div className="mobile-iframe">
                        <iframe
                          src={product.Video_url}
                          scrolling="no"
                          frameBorder="0"
                          className="iframe"
                        />
                      </div>
                    )}
                  </div>
                  <div className="video-image-btn">
                    <i
                      style={{
                        cursor: !product.Stone_Img_url
                          ? "not-allowed"
                          : "pointer",
                      }}
                      className={`fas fa-image ${
                        imageMode && "video-image-active"
                      }`}
                      onClick={() =>
                        product.Stone_Img_url && setImageMode(true)
                      }
                    ></i>
                    <i
                      style={{
                        cursor: !product.Video_url ? "not-allowed" : "pointer",
                      }}
                      className={`fas fa-video ${
                        !imageMode && "video-image-active"
                      }`}
                      onClick={() => product.Video_url && setImageMode(false)}
                    ></i>

                    <a
                      href={product.Certificate_file_url}
                      target="_blank"
                      className="certificate_link"
                    >
                      <i
                        className="fas fa-file-certificate igi-logo2"
                        style={{
                          cursor: !product.Certificate_file_url
                            ? "not-allowed"
                            : "pointer",
                        }}
                        onClick={() => product.Certificate_file_url}
                      ></i>
                    </a>
                    {/* <a href={product.Certificate_file_url} target="_blank">
                      <div className="product-page-report-id d-flex">
                        <span className="igi-logo2">
                          <img
                            src={`assets/images/stock/${product.Lab}.png`}
                            className="img-fluid"
                            alt="labicon"
                          />
                        </span>
                        <span className="reportid-img">
                          #{product.Lab_Report_No}
                        </span>
                      </div>
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="dimond-description">
                    <h3>
                      {product.Weight} Carat, {product.Shape} Shape Diamond
                    </h3>
                    <p>{product.Comment}</p>
                    <div>
                      <a href={product.Certificate_file_url} target="_blank">
                        <div className="product-page-report-id d-flex">
                          <span className="igi-logo2">
                            <img
                              src={`assets/images/stock/${product.Lab}.png`}
                              className="img-fluid"
                              alt="labicon"
                            />
                          </span>
                          <span className="reportid-img">
                            #{product.Lab_Report_No}
                          </span>
                        </div>
                      </a>
                      {UserID ? (
                        <span className="table-parice">
                          ${product.Cost_Amt}
                        </span>
                      ) : (
                        ""
                      )}

                      <div className="table-titile-with-image">
                        <img
                          src={`${Domain}/Content/DomainData/${DomainWithOutHttp}/img/Diamonds/Active/${product.Shape}_active.png`}
                          className="img-fluid"
                          alt="round-diamond-line"
                        />
                        <span>
                          {product.Shape} | {product.Weight} Ct |{" "}
                          {product.Color} | {product.Clarity}
                        </span>
                      </div>
                      <div className="product-page-table">
                        <div className="row">
                          <div className="col-6 col-md-4 text-center mb-4">
                            <h6 className="mb-1 text-uppercase">Cut</h6>
                            <span>{product.Cut}</span>
                          </div>
                          <div className="col-6 col-md-4 text-center mb-4">
                            <h6 className="mb-1 text-uppercase">Polish</h6>
                            <span>{product.Polish}</span>
                          </div>
                          <div className="col-6 col-md-4 text-center mb--4">
                            <h6 className="mb-1 text-uppercase">SYM</h6>
                            <span>{product.Symm}</span>
                          </div>
                          <div className="col-6 col-md-4 text-center mb-4">
                            <h6 className="mb-1 text-uppercase">FLR</h6>
                            <span>{product.FlrIntens}</span>
                          </div>
                          <div className="col-6 col-md-4 text-center mb-4">
                            <h6 className="mb-1 text-uppercase">Depth %</h6>
                            <span>{product.Total_Depth_Per}</span>
                          </div>
                          <div className="col-6 col-md-4 text-center mb-4">
                            <h6 className="mb-1 text-uppercase">Table %</h6>
                            <span>{product.Table_Diameter_Per}</span>
                          </div>
                          <div className="col-12 col-md-12 text-center d-block d-md-flex align-items-center justify-content-center">
                            <h6 className="mb-0 mr-1 text-uppercase">
                              Measurements :{" "}
                            </h6>{" "}
                            <span>{product.Measurement}</span>
                          </div>
                        </div>
                      </div>
                      {/* <div>
                        <span className="igi-logo2">
                          <img
                            src={`assets/images/procuctdetails/${product.Lab}.png`}
                            className="img-fluid"
                            alt=""
                          />
                        </span>
                      </div> */}
                      <div className="produt-page-like-wishlistbtn">
                        <a
                          onClick={() => {
                            if (UserID) {
                              dispatch(resetorderPlaced());
                              dispatch(
                                addToCart({
                                  CustomerID: UserID,
                                  StockIDs: product.Stock_ID,
                                })
                              );
                              if (isItemAdded) {
                                dispatch(getMyCart({ UserID }));
                                // Notify(
                                //   "Stone added successfully to cart.",
                                //   "success"
                                // );
                              } else {
                                //addItemError && Notify(addItemError, "error");
                              }
                            } else {
                              dispatch(addToCartLocal(product));
                              // Notify(
                              //   "Stone added successfully to cart.",
                              //   "success"
                              // );
                            }
                          }}
                        >
                          <img
                            src="assets/images/stock/cart1.svg"
                            className="img-fluid"
                            alt="cart1"
                          />
                          Add To Cart
                        </a>
                        <a
                          onClick={() => {
                            if (UserID) {
                              dispatch(
                                addToWishlist({
                                  CustomerID: UserID,
                                  StockIDs: product.Stock_ID,
                                })
                              );
                              if (isItemAddedToWishlist) {
                                dispatch(getMyWishList({ UserID }));
                                // Notify(
                                //   "Stone added successfully to wishlist.",
                                //   "success"
                                // );
                              } else {
                                // Notify(addItemWishError, "error");
                              }
                            } else {
                              Notify("Please login first!", "error");
                            }
                          }}
                        >
                          <img
                            src="assets/images/stock/like-heart.svg"
                            alt="like-heart"
                            className="img-fluid"
                          />
                          Wishlist
                        </a>
                      </div>
                      {/* <div className="social-link-drop-list">
                      <div>
                        <span>Share:</span>
                        <a href="">
                      
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="">
                        
                          <i className="fab fa-instagram"></i>
                        </a>
                        <a href="">
                         
                          <i className="fab fa-pinterest-p"></i>
                        </a>
                        <a href="">
                         
                          <i className="fab fa-viber"></i>
                        </a>
                        <a href="">
                         
                          <i className="fal fa-comments"></i>
                        </a>
                      </div>
                      <div className="drop-hint">
                        <a href="">
                         
                          <i className="fal fa-envelope-open-text"></i>
                          Drop A Hint
                        </a>
                      </div>
                    </div> */}
                      {/* <div className="shipping-details">
                        <div>
                          <img
                            src="assets/images/stock/delivery.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div>
                          <p>Free Shipping & Free Returns</p>
                          <p>
                            Order now for delivery by Tuesday, June 22, 2021
                          </p>
                        </div>
                      </div> */}
                      <div className="back-btn-container">
                        {!pageLinkShare && (
                          <Link
                            className="mt-3 d-block backbtn"
                            to={`/diamond/${product.Lab_Report_No}`}
                          >
                            <i className="fal fa-long-arrow-left"></i> Back To
                            Stock
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="diamond-detils-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="cmn-diamond-with-title-wrapper d-flex flex-wrap">
                        <div>
                          <h1 className="cmn-section-titile cmn-color-title diamond-details-titile">
                            Diamond Details
                            {/*  <span className="igi-logo2">
                            <img
                              src={`assets/images/procuctdetails/${product.Lab}.png`}
                              className="img-fluid"
                              alt=""
                            />
                          </span> */}
                          </h1>
                          <div className="cmn-diamond-below-title">
                            <img
                              src="assets/images/home/grey-small-diamod.png"
                              className="img-fluid"
                              alt="grey-small-diamod.png"
                            />
                          </div>
                        </div>
                        {/* <div className="igi-logo2">
                        <img
                          src={`assets/images/procuctdetails/${product.Lab}.png`}
                          className="img-fluid"
                          alt=""
                        />
                      </div> */}
                      </div>
                      <button type="button" className="view-report">
                        <a
                          className="view-report"
                          href={product.Certificate_file_url}
                          target="_blank"
                        >
                          View Report
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <div>
                      <div className="d-flex justify-content-between flex-wrap">
                        <div className="diamond-details-sub-table">
                          <div>
                            <span className="diamond-details-sub-table-header">
                              Diamond Info
                            </span>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Report#</td>
                                  <td> {product.Lab_Report_No}</td>
                                </tr>
                                <tr>
                                  <td>Shape</td>
                                  <td> {product.Shape} </td>
                                </tr>
                                <tr>
                                  <td>Carat</td>
                                  <td> {product.Weight} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div>
                            <span className="diamond-details-sub-table-header">
                              Diamond Conformity
                            </span>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Depth % </td>
                                  <td>{product.Total_Depth_Per}</td>
                                </tr>
                                <tr>
                                  <td>Table % </td>
                                  <td>{product.Table_Diameter_Per}</td>
                                </tr>
                                <tr>
                                  <td>Measurements</td>
                                  <td>{product.Measurement}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="diamond-details-sub-table sub-table-right">
                          <div>
                            <span className="diamond-details-sub-table-header">
                              Diamond Structure
                            </span>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Color</td>
                                  <td>{product.Color}</td>
                                </tr>
                                <tr>
                                  <td>Clarity</td>
                                  <td>{product.Clarity}</td>
                                </tr>
                                <tr>
                                  <td>Cut</td>
                                  <td>{product.Cut}</td>
                                </tr>
                                <tr>
                                  <td>Polish</td>
                                  <td>{product.Polish}</td>
                                </tr>
                                <tr>
                                  <td>Symmentry</td>
                                  <td>{product.Symm}</td>
                                </tr>
                                <tr>
                                  <td>Flourescence</td>
                                  <td>{product.FlrIntens}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12 d-flex flex-wrap justify-content-between">
                    <div>
                      <img
                        src="assets/images/procuctdetails/grid-diam1.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        src="assets/images/procuctdetails/grid-diam2.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="big-container carat-size-container">
              <h2>
                Carat Size : <span>{product.Weight}</span>
              </h2>
              <div className="image-with-tooltip image-with-tooltip2">
                <div className="image-with-tooltip-inner">
                  <div className="carat-size-single-image">
                    <span>
                      <img
                        src="assets/images/procuctdetails/0.25 1 (1).png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>

                    <div className="diamond-size">
                      0.25ct
                      <span>(4.1mm)</span>
                    </div>
                    {product.Weight >= 0.25 && product.Weight < 0.5 && (
                      <div className="dimond-below-box-show-size">
                        <span>Your Diamond</span>
                        <span> {product.Weight}</span>
                        <div className="arrow-up"></div>
                      </div>
                    )}
                  </div>
                  <div className="carat-size-single-image">
                    <span>
                      <img
                        src="assets/images/procuctdetails/0.5 1 (1).png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>

                    <div className="diamond-size">
                      0.5ct
                      <span>(5.1mm)</span>
                    </div>
                    {product.Weight >= 0.5 && product.Weight < 0.75 && (
                      <div className="dimond-below-box-show-size">
                        <span>Your Diamond</span>
                        <span> {product.Weight}</span>
                        <div className="arrow-up"></div>
                      </div>
                    )}
                  </div>
                  <div className="carat-size-single-image">
                    <span>
                      <img
                        src="assets/images/procuctdetails/0.75 1 (1).png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    <div className="diamond-size">
                      0.75ct
                      <span>(5.8mm)</span>
                    </div>
                    {product.Weight >= 0.75 && product.Weight < 1 && (
                      <div className="dimond-below-box-show-size">
                        <span>Your Diamond</span>
                        <span>{product.Weight}</span>
                        <div className="arrow-up"></div>
                      </div>
                    )}
                  </div>
                  <div className="carat-size-single-image">
                    <span>
                      <img
                        src="assets/images/procuctdetails/1 10 (1).png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    <div className="diamond-size">
                      1ct
                      <span>(6.4mm)</span>
                    </div>
                    {product.Weight >= 1 && product.Weight < 1.25 && (
                      <div className="dimond-below-box-show-size">
                        <span>Your Diamond</span>
                        <span> {product.Weight}</span>
                        <div className="arrow-up"></div>
                      </div>
                    )}
                  </div>
                  <div className="carat-size-single-image">
                    <span>
                      <img
                        src="assets/images/procuctdetails/1.25 1 (1).png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    <div className="diamond-size">
                      1.25ct
                      <span>(6.9mm)</span>
                    </div>
                    {product.Weight >= 1.25 && product.Weight < 1.5 && (
                      <div className="dimond-below-box-show-size">
                        <span>Your Diamond</span>
                        <span> {product.Weight}</span>
                        <div className="arrow-up"></div>
                      </div>
                    )}
                  </div>
                  <div className="carat-size-single-image">
                    <span>
                      <img
                        src="assets/images/procuctdetails/1.5 1 (1).png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    <div className="diamond-size">
                      1.5ct
                      <span>(7.4mm)</span>
                    </div>
                  </div>
                  <div className="carat-size-single-image">
                    <span>
                      <img
                        src="assets/images/procuctdetails/1.75 1 (1).png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    <div className="diamond-size">
                      1.75ct
                      <span>(7.8mm)</span>
                    </div>
                    {product.Weight >= 1.75 && product.Weight < 2 && (
                      <div className="dimond-below-box-show-size">
                        <span>Your Diamond</span>
                        <span> {product.Weight}</span>
                        <div className="arrow-up"></div>
                      </div>
                    )}
                  </div>
                  <div className="carat-size-single-image">
                    <span>
                      <img
                        src="assets/images/procuctdetails/2 10 (1).png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    <div className="diamond-size">
                      2ct
                      <span>(8.1mm)</span>
                    </div>
                  </div>
                </div>
                <div className="linear-line d-lg-block d-md-block d-none"></div>
                {/*  <div className="dimond-below-box-show-size">
                <span>Your Diamond</span>
                <span> 19.74ct</span>
                <div className="arrow-up"></div>
              </div> */}
              </div>
            </div>
            <div className="big-container big-container-white-bg">
              <h2>
                Cut : <span> {product.Cut}</span>
              </h2>
              <div className="image-with-tooltip">
                <div>
                  <div className="row">
                    <div className=" col-lg-5 col-md-4 col-sm-12">
                      <div>
                        <img
                          src="assets/images/procuctdetails/regular-cut-grid-diam3.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-8 col-sm-12">
                      <div className="button-with-tooltip">
                        <div className="single-button-with-tooltip">
                          <button
                            type="button"
                            className={`btn  popeover-btn ${
                              product.Cut == "ID" ? "button-actives" : ""
                            }`}
                          >
                            Ideal
                          </button>
                          <div className="pop-content">
                            <span className="popover-header">Ideal</span>
                            <p>
                              An ideal cut diamond is a round, brilliant, or
                              princess cut diamond that is cut to ideal
                              proportions and angles and has excellent polish
                              and symmetry ratings.Ideal diamonds are perfectly
                              proportioned to refract light, producing that fire
                              and brilliance up through to the table and crown.
                            </p>
                          </div>
                        </div>
                        <div className="single-button-with-tooltip">
                          <button
                            type="button"
                            className={`btn  popeover-btn ${
                              product.Cut == "EX" ? "button-actives" : ""
                            }`}
                          >
                            Excellent
                          </button>
                          <div
                            className={`pop-content ${
                              product.Cut == "EX" ? "pop-show" : ""
                            }`}
                          >
                            <span className="popover-header">Excellent</span>
                            <p>
                              Excellent Cut Diamonds provide the highest level
                              of fire and brilliance. Because almost all of the
                              incoming light is reflected through the table, the
                              diamond radiates with magnificent sparkle.
                            </p>
                          </div>
                        </div>
                        <div className="single-button-with-tooltip">
                          <button
                            type="button"
                            className={`btn  popeover-btn ${
                              product.Cut == "VG" ? "button-actives" : ""
                            }`}
                          >
                            Very Good
                          </button>
                          <div
                            className={`pop-content ${
                              product.Cut == "VG" ? "pop-show" : ""
                            }`}
                          >
                            <span className="popover-header">Very Good</span>
                            <p>
                              Very Good Cut Diamonds offer exceptional
                              brilliance and fire. A large majority of the
                              entering light reflects through the diamond’s
                              table. To the naked eye, Very Good diamonds
                              provide similar sparkle to those of Excellent
                              grade.
                            </p>
                          </div>
                        </div>
                        <div className="single-button-with-tooltip">
                          <button
                            type="button"
                            className={`btn  popeover-btn ${
                              product.Cut == "GD" ? "button-actives" : ""
                            }`}
                          >
                            Good
                          </button>
                          <div
                            className={`pop-content single-button-with-tooltip4 ${
                              product.Cut == "GD" ? "pop-show" : ""
                            }`}
                          >
                            <span className="popover-header">Good</span>
                            <p>
                              Good Cut Diamonds showcase brilliance and sparkle,
                              with much of the light reflecting through the
                              table to the viewer’s eye. These diamonds provide
                              beauty at a lower price point.
                            </p>
                          </div>
                        </div>
                        <div className="single-button-with-tooltip">
                          <button type="button" className="btn  popeover-btn">
                            Fair
                          </button>
                          <div className="pop-content single-button-with-tooltip5">
                            <span className="popover-header">Fair</span>
                            <p>
                              Fair Cut Diamonds offer little brilliance, as
                              light easily exits through the bottom and sides of
                              the diamond. Diamonds of a Fair Cut may be a
                              satisfactory choice for smaller carats and those
                              acting as side stones.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="big-container">
              <h2>
                Color : <span>{product.Color}</span>
              </h2>

              <div className="color-image-details-wrapper">
                <div className="color-image-details-wrapper-row">
                  <div className="color-image-details-wrapper-column1">
                    <div className="color-image-details-wrapper">
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 1.png"
                          alt=""
                        />
                        <span>D</span>
                      </div>
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 2.png"
                          alt=""
                        />
                        <span>E</span>
                      </div>
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 3.png"
                          alt=""
                        />
                        <span>F</span>
                      </div>
                    </div>
                    <div className="color-image-details-wrapper-bottom-titile">
                      <span>Colorless</span>
                    </div>
                    <div
                      className={`hover-content-on-image  ${
                        (product.Color === "D" ||
                          product.Color === "E" ||
                          product.Color === "F") &&
                        "show"
                      }`}
                    >
                      <h3>
                        Colorless
                        <span>D-E-F</span>
                      </h3>
                      <p>
                        Highest-quality color grade a diamond can receive. A
                        D-color diamond is extremely rare and emits unrivaled
                        brilliance. Containing very minute traces of color, an E
                        or F-color rated diamond emits a high level of
                        brilliance and sparkle.
                      </p>
                    </div>
                  </div>
                  <div className="color-image-details-wrapper-column2">
                    <div className="color-image-details-wrapper color-image-details-wrapper2">
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 4.png"
                          alt=""
                        />
                        <span>G</span>
                      </div>
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 5.png"
                          alt=""
                        />
                        <span>H</span>
                      </div>
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 6.png"
                          alt=""
                        />
                        <span>I</span>
                      </div>
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 7.png"
                          alt=""
                        />
                        <span>J</span>
                      </div>
                    </div>
                    <div className="color-image-details-wrapper-bottom-titile color-image-details-wrapper-bottom-titile2">
                      <span>Near Colorless</span>
                    </div>
                    <div
                      className={`hover-content-on-image  ${
                        (product.Color === "G" ||
                          product.Color === "H" ||
                          product.Color === "I" ||
                          product.Color === "J") &&
                        "show"
                      }`}
                    >
                      <h3>
                        Near Colorless
                        <span>G-H-I-J</span>
                      </h3>
                      <p>
                        Containing noticeable color only when compared to higher
                        color grades, G or H color diamonds provide an excellent
                        value. Whereas an I or J-color diamond typically appears
                        colorless to the untrained eye yet contains slightly
                        detectable color.
                      </p>
                    </div>
                  </div>
                  <div className="color-image-details-wrapper-column1">
                    <div className="color-image-details-wrapper">
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 8.png"
                          alt=""
                        />
                        <span>K</span>
                      </div>
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 9.png"
                          alt=""
                        />
                        <span>L</span>
                      </div>
                      <div className="color-image-details-single">
                        <img
                          src="assets/images/procuctdetails/Color 10.png"
                          alt=""
                        />
                        <span>M</span>
                      </div>
                    </div>
                    <div className="color-image-details-wrapper-bottom-titile">
                      <span>Faint Color</span>
                    </div>
                    <div
                      className={`hover-content-on-image faint-color  ${
                        (product.Color === "K" ||
                          product.Color === "L" ||
                          product.Color === "M") &&
                        "show"
                      }`}
                    >
                      <h3>
                        Faint Color
                        <span>K-L-M</span>
                      </h3>
                      <p>
                        Faint color is noticeable. Even with the presence of
                        color, these grades of diamonds can emit fire and
                        beauty. Consider selecting a lower color when setting in
                        yellow gold as color will be not as apparent.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="big-container big-container-white-bg last-container-white">
              <h2>
                Clarity : <span> {product.Clarity}</span>
              </h2>
              <div className="image-with-tooltip">
                <div>
                  <div className="row">
                    <div className="col-lg-5 col-md-4 col-sm-12 text-center">
                      <div>
                        <img
                          src="assets/images/procuctdetails/Clarity 1.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-8 col-sm-12">
                      <div className="button-with-tooltip">
                        <div className="single-button-with-tooltip">
                          <button
                            type="button"
                            className={`btn  popeover-btn ${
                              product.Clarity == "FL" || product.Clarity == "IF"
                                ? "button-actives"
                                : ""
                            }`}
                          >
                            FL / IF
                          </button>
                          {(product.Clarity == "FL" ||
                            product.Clarity == "IF") && (
                            <div className="pop-content pop-show">
                              <span className="popover-header">FL / IF</span>
                              <p>
                                No inclusions and no blemishes visible under 10x
                                magnification/ No inclusions visible under 10x
                                magnification
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="single-button-with-tooltip">
                          <button
                            type="button"
                            className={`btn  popeover-btn ${
                              product.Clarity == "VVS1" ||
                              product.Clarity == "VVS2"
                                ? "button-actives"
                                : ""
                            }`}
                          >
                            VVS1 / VVS2
                          </button>
                          {(product.Clarity == "VVS1" ||
                            product.Clarity == "VVS2") && (
                            <div className="pop-content pop-show">
                              <span className="popover-header">
                                VVS1 / VVS2
                              </span>
                              <p>
                                Inclusions so slight they are difficult for a
                                skilled grader to see under 10x magnification
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="single-button-with-tooltip">
                          <button
                            type="button"
                            className={`btn  popeover-btn ${
                              product.Clarity == "VS1" ||
                              product.Clarity == "VS2"
                                ? "button-actives"
                                : ""
                            }`}
                          >
                            VS1 / VS2
                          </button>
                          {(product.Clarity == "VS1" ||
                            product.Clarity == "VS2") && (
                            <div className="pop-content pop-show">
                              <span className="popover-header">VS1 / VS2</span>
                              <p>
                                Inclusions are observed with effort under 10x
                                magnification, but can be characterized as minor
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="single-button-with-tooltip">
                          <button
                            type="button"
                            className={`btn  popeover-btn ${
                              product.Clarity == "SI1" ||
                              product.Clarity == "SI2"
                                ? "button-actives"
                                : ""
                            }`}
                          >
                            SI1 / SI2
                          </button>
                          {(product.Clarity == "SI1" ||
                            product.Clarity == "SI2") && (
                            <div className="pop-content single-button-with-tooltip4 pop-show">
                              <span className="popover-header">SI1 / SI2</span>
                              <p>
                                Inclusions are noticeable under 10x
                                magnification
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="single-button-with-tooltip">
                          <button
                            type="button"
                            className={`btn  popeover-btn ${
                              product.Clarity == "I1" ||
                              product.Clarity == "I2" ||
                              product.Clarity == "I3"
                                ? "button-actives"
                                : ""
                            }`}
                          >
                            I1 / I2 / I3
                          </button>
                          {(product.Clarity == "I1" ||
                            product.Clarity == "I2" ||
                            product.Clarity == "I3") && (
                            <div className="pop-content single-button-with-tooltip5 pop-show">
                              <span className="popover-header">
                                I1 / I2 / I3
                              </span>
                              <p>
                                Inclusions are obvious under 10x magnification
                                which may affect transparency and brilliance
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
}
export default ProductDetail;
