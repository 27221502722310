import React from "react";
import Layout from "../../../Layout";

function Index() {
  return (
    <Layout>
      <section className="lab-grown-dimaond-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
                <h1 className="cmn-section-titile cmn-color-title">
                  Diamonds In Classic Shapes
                </h1>
                <div className="cmn-diamond-below-title mx-auto">
                  <img
                    src="assets/images/home/grey-small-diamod.png"
                    className="img-fluid"
                    alt="grey-small-diamod.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmn-content-wrapper">
                <h2>Round Cut Diamond Sizes in (MM)</h2>
                <ul>
                  <li>
                    The Round Cut Diamond is the most ideal and well-knowing
                    shape from the beginning. If we go back to history, the
                    first diamond engagement ring was a round cut diamond. Round
                    cut diamond engagement rings are best known for their
                    charming design that can rock any look. The round diamond
                    shape stands out as it allows the diamond to sparkle in its
                    beauty. Hence, the most famous solitaire diamond engagement
                    ring comes mainly in a round-cut shape. In the publication
                    of Marcel Tolkowsky's thesis "Diamond Design," the
                    round-shaped diamond first comes into prominence. The color
                    white is more seen and loved because Its 57 to 58 facets
                    bring out the most brilliance, fire, and sparkle of all the
                    shapes.
                  </li>
                  <li>
                    {" "}
                    One can fashion a round-cut diamond in any color, but the
                    sophistication in white round-cut diamonds is
                    unmatched. Round cut diamond earrings have also paved their
                    way through popularity with rings because of their subtle
                    design. That's the beauty of round-cut diamonds; it defines
                    sophistication in such a subtly elegant manner that it
                    attracts people like magnets. Round cut diamonds would be
                    suitable and best set-in prongs, tension setting, and bezel
                    setting since they increase the diamond's expression and
                    bring out its brilliance. Additionally, an ample variety of
                    similar-sized small round diamonds set in pave setting or
                    flush setting makes an aesthetically crafted diamond ring
                    for an anniversary or an engagement ring..
                  </li>
                </ul>
                <h2>Cushion-Cut Diamonds Information</h2>
                <ul>
                  <li>
                    Cushion cut diamond defines the shape of the diamond
                    sophistically cut in a square with soft corners. From the
                    1700s till today, cushion cut diamonds have helped people
                    engage in a classy, out-of-the-box look. Cushion cut
                    diamonds are a perfect example of the artistry of the
                    workman as the creation of the perfect cushion cut diamonds
                    requires utmost dedication and years of skill. 
                  </li>
                  <li>
                    Cushion Shape diamond has 58 facets with four curved corners
                    (hence the name), making it perfect for any studded jewelry
                    like cushion cut diamond earrings.
                  </li>
                </ul>
                <h2>Emerald-Cut diamond market price chart</h2>
                <ul>
                  <li>
                    The word "emerald" stands for the word "green" in the
                    ancient Greek language. The emerald cut has 25 pavilions, 25
                    crowns, and eight girdles, which means, in total, it has 50
                    to 58 facets and an octagon side (eight sides). Emerald-Cut
                    is more transparent than other shapes because of its unique
                    cutting and shapes. Emerald cut diamond has the identity
                    that it shows a hall-of-mirrors effect with light and dark
                    planes. And 'step cut' refers to the step-like look of the
                    facet cuts.
                  </li>
                  <li>
                    Royal Family are very much addicted to this beautiful
                    emerald cut diamond Grace Kelly, Simpson, and Camilla
                    (Duchess of Cornwall) are some of them. The best
                    length-to-width ratio is 1.25 to 1.5.
                  </li>
                  <li>
                    The emerald-cut shape of the diamond shows organized,
                    resourceful, disciplined, and decisive personalities.
                  </li>
                  <li>
                    Emerald cut diamonds are bold, beautiful, and quite a
                    beauty! The beauty of an Emerald cut diamond lies in its
                    unique design with 25 pavilions, 25 crowns, and eight
                    girdles, which means it has 50 to 58 facets and an octagon
                    side (eight sides). The unique cutting of an emerald-cut
                    diamond gives it a stunning transparent look that you cannot
                    find in any other diamond shape. Emerald cut diamond has the
                    identity that it shows a hall-of-mirrors effect with light
                    and dark planes
                  </li>
                </ul>
                <h2>Oval-Cut Diamond in Carat</h2>
                <ul>
                  <li>
                    The Oval Shape Diamond is first created in 1960 by Lazare
                    Kaplan. It became loveable among people because of its
                    character, which is the same as the classic round cut
                    diamond. Still, it also looks more prominent than a round
                    cut, making the wearer's finger appear longer and slimmer
                    because of its oval shape. It has 56 facets and is most
                    suitable for the 1.35 to 1.50 side of the diamond.
                  </li>
                  <li>
                    Scarlett Johansson, Catherine (Duchess of Cambridge), and
                    Blake Ellender Lively are Some well-known Celebrities who
                    have owned the oval-cut diamond ring in their hand. One of
                    the famous and largest "Koh-i-Noor" diamonds is also in the
                    shape of an oval.
                  </li>
                  <li>
                    If you are the person who loves classic with a little edge,
                    then this Oval Cut Diamond is the best match for you.
                  </li>
                  <li>
                    The Oval Cut diamond, significant for its most famous royal
                    design, gives your jewelry a refining touch. If you are a
                    fan of the round cut diamond but want to refine your look,
                    an oval cut diamond is a suitable choice for you. Oval-cut
                    diamonds are one of the oldest diamond shapes prevalent
                    since 1304. 
                  </li>
                  <li>
                    Oval-cut diamond rings are perfect for a unique, stunning
                    look. The shape of an oval-cut diamond ring perfectly fits
                    the finger, giving them a royal, sumptuous look, classic
                    enough to grab all the eyeballs. 
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;
