import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const Carousel = () => {
  var settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  };
  return (
    <section className="carousel-wrapper">
      <div className="carousel-container">
        <Slider {...settings} className="multiple-items">
          {/* <div className="cmn-slider sldier1">
            <div className="cmn-inner-slider">
              <span>Lab Grown Diamonds</span>
              <h1> What Is CVD Diamond?</h1>
              <p>
                CVD diamonds are manmade diamonds. Unlike natural diamonds,
                which take billions of years to form deep beneath the Earth's
                surface, CVD diamonds are grown in labs. Specifically, CVD
                diamonds rely on a unique process called Chemical Vapor
                Deposition (or CVD)
              </p>
              <Link to="/banner-content-1">Learn More</Link>
            </div>
          </div> */}
          {/* <div className="cmn-slider sldier1">
            <div className="cmn-inner-slider">
              <span>Lab Grown Diamonds</span>
              <h1>Are CVD Diamonds Real Diamonds?</h1>
              <p>
                They may be called "synthetic," but don't let the term fool you. CVD diamonds look exactly like so-called "natural" diamonds.  Everything, from their internal atomic structure to the way they glitter on your finger. In fact, even a trained jeweler cannot identify the difference.
              </p>
              <Link to="/banner-content-2">Learn More</Link>
            </div>
          </div> */}
          <div className="cmn-slider1 sldier1">
            <div className="cmn-inner-slider1">
              {/* <span>Lab Grown Diamonds</span>
              <h1>Are CVD Diamonds Real Diamonds?</h1>
              <p>
                They may be called "synthetic," but don't let the term fool you.
                CVD diamonds look exactly like so-called "natural" diamonds.
                Everything, from their internal atomic structure to the way they
                glitter on your finger. In fact, even a trained jeweler cannot
                identify the difference.
              </p>
              <Link to="/banner-content-2">Learn More</Link> */}
            </div>
          </div>
          {/*   <div className="cmn-slider2 sldier2">
            <div className="cmn-inner-slider2"></div>
          </div> */}
          <div className="cmn-slider sldier1">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-md-6">
                  <div className="cmn-inner-slider">
                    <span>Lab Grown Diamonds</span>
                    <h1>Why to Choose CVD Diamond?</h1>
                    <p>
                      Natural diamonds are rare and adorable, but what if you
                      get better than rare at your price? Yes, you read it right
                      BETTER than natural diamond.There are many aspects where
                      CVD diamonds are better than natural diamonds, like
                      purity, price, and environmental effect.
                    </p>
                    <Link to="/banner-content-3">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Carousel;
