import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Domain } from "../../constant";
import {
  PrivacyPolicy,
  TermsConditions,
  CookiePolicy,
  DisclaimerPolicy,
  shippinginformation,
  naturalvslabcreateddiamonds,
} from "../../htmlApisConstant";

const galleryArry = [
  "assets/images/gallery/1.jpeg",
  "assets/images/gallery/2.jpeg",
  "assets/images/gallery/3.jpeg",
  "assets/images/gallery/4.jpeg",
  "assets/images/gallery/5.jpeg",
  "assets/images/gallery/6.jpeg",
  "assets/images/gallery/7.jpeg",
  "assets/images/gallery/8.jpeg",
  "assets/images/gallery/9.jpeg",
  "assets/images/gallery/10.jpeg",
  "assets/images/gallery/gallery-img-1.jpg",
  "assets/images/gallery/gallery-img-2.jpg",
  "assets/images/gallery/gallery-img-3.jpg",
  "assets/images/gallery/gallery-img-4.jpg",
  "assets/images/gallery/gallery-img-5.jpg",
  "assets/images/gallery/gallery-img-6.jpg",
  "assets/images/gallery/gallery-img-7.jpg",
  "assets/images/gallery/gallery-img-8.jpg",
  "assets/images/gallery/gallery-img-9.jpg",
  "assets/images/gallery/gallery-img-10.jpg",
  "assets/images/gallery/gallery-img-11.jpg",
  "assets/images/gallery/gallery-img-12.jpg",
];

const initialState = {
  content: {
    privacyPolicy: "",
    cookiePolicy: "",
    termsConditions: "",
    disclaimer: "",
    shippinginformation: "",
    naturalvslabcreateddiamonds: "",
    gallery: [],
  },
};
export const getPrivacyPolicy = createAsyncThunk("getprivacy", async () => {
  const response = await fetch(`${Domain}/mobile-api/get-privacy-list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  let data = await response.json();
  return data.Result;
});
export const getTermsConditions = createAsyncThunk(
  "getTermsConditions",
  async () => {
    const response = await fetch(`${Domain}/mobile-api/get-privacy-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    return data.Result;
  }
);
export const getCookiePolicy = createAsyncThunk("getCookiePolicy", async () => {
  const response = await fetch(`${Domain}/mobile-api/get-privacy-list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  let data = await response.json();
  return data.Result;
});
export const getDisclaimer = createAsyncThunk("getDisclaimer", async () => {
  const response = await fetch(`${Domain}/mobile-api/get-privacy-list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  let data = await response.json();
  return data.Result;
});
export const getGallery = createAsyncThunk("getGallery", async () => {
  const response = await fetch(`${Domain}/mobile-api/get-privacy-list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  let data = await response.json();
  return data.Result;
});
export const getShippingInformation = createAsyncThunk(
  "getShippingInformation",
  async () => {
    const response = await fetch(`${Domain}/mobile-api/get-privacy-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    return data.Result;
  }
);
export const HtmlApiSlice = createSlice({
  name: "htmlapi_slice",
  initialState,
  reducers: {
    setPrivacyPolicy: (state, action) => {
      state.content.privacyPolicy = PrivacyPolicy.content;
    },
    setTermsConditions: (state, action) => {
      state.content.termsConditions = TermsConditions;
    },
    setCookiePolicy: (state, action) => {
      state.content.cookiePolicy = CookiePolicy;
    },
    setDisclaimer: (state, action) => {
      state.content.disclaimer = DisclaimerPolicy;
    },
    setGallery: (state, action) => {
      state.content.gallery = galleryArry;
    },
    cleanState: (state, action) => {
      state.content.gallery = [];
    },
    setShippingInformation: (state, action) => {
      state.content.shippinginformation = shippinginformation;
    },
    setNaturalvsLabCreatedDiamonds: (state, action) => {
      state.content.naturalvslabcreateddiamonds = naturalvslabcreateddiamonds;
    },
  },
  extraReducers: (builder) => {
    //get html content case
    builder
      .addCase(getPrivacyPolicy.pending, (state) => {
        state.content.privacyPolicy = "";
        state.loading = true;
        state.error = "";
      })
      .addCase(getPrivacyPolicy.fulfilled, (state, action) => {
        state.content.privacyPolicy =
          (action.payload && action.payload.rows) || [];
        state.loading = false;
        state.error = action.payload === null ? "Records not found!" : "";
        if (action.payload.IsSuccess) {
          state.content.privacyPolicy = action.payload.Result.rows;
          state.loading = false;
          state.error = "";
        } else {
          state.content.privacyPolicy = "";
          state.loading = false;
          state.error = action.payload.Message;
        }
      })
      .addCase(getPrivacyPolicy.rejected, (state, action) => {
        state.content.privacyPolicy = [];
        state.loading = false;
        state.error = action.payload.Message;
      });
    builder
      .addCase(getTermsConditions.pending, (state) => {
        state.content.termsConditions = [];
        state.loading = true;
        state.error = "";
      })
      .addCase(getTermsConditions.fulfilled, (state, action) => {
        state.content.termsConditions =
          (action.payload && action.payload.rows) || [];
        state.loading = false;
        state.error = action.payload === null ? "Records not found!" : "";
        if (action.payload.IsSuccess) {
          state.content.termsConditions = action.payload.Result.rows;
          state.loading = false;
          state.error = "";
        } else {
          state.content.termsConditions = [];
          state.loading = false;
          state.error = action.payload.Message;
        }
      })
      .addCase(getTermsConditions.rejected, (state, action) => {
        state.content.termsConditions = [];
        state.loading = false;
        state.error = action.payload.Message;
      });
    builder
      .addCase(getCookiePolicy.pending, (state) => {
        state.content.cookiePolicy = [];
        state.loading = true;
        state.error = "";
      })
      .addCase(getCookiePolicy.fulfilled, (state, action) => {
        state.content.cookiePolicy =
          (action.payload && action.payload.rows) || [];
        state.loading = false;
        state.error = action.payload === null ? "Records not found!" : "";
        if (action.payload.IsSuccess) {
          state.content.cookiePolicy = action.payload.Result.rows;
          state.loading = false;
          state.error = "";
        } else {
          state.content.cookiePolicy = [];
          state.loading = false;
          state.error = action.payload.Message;
        }
      })
      .addCase(getCookiePolicy.rejected, (state, action) => {
        state.content.cookiePolicy = [];
        state.loading = false;
        state.error = action.payload.Message;
      });
    builder
      .addCase(getDisclaimer.pending, (state) => {
        state.content.disclaimer = [];
        state.loading = true;
        state.error = "";
      })
      .addCase(getDisclaimer.fulfilled, (state, action) => {
        state.content.disclaimer =
          (action.payload && action.payload.rows) || [];
        state.loading = false;
        state.error = action.payload === null ? "Records not found!" : "";
        if (action.payload.IsSuccess) {
          state.content.disclaimer = action.payload.Result.rows;
          state.loading = false;
          state.error = "";
        } else {
          state.content.disclaimer = [];
          state.loading = false;
          state.error = action.payload.Message;
        }
      })
      .addCase(getDisclaimer.rejected, (state, action) => {
        state.content.disclaimer = [];
        state.loading = false;
        state.error = action.payload.Message;
      });
    builder
      .addCase(getGallery.pending, (state) => {
        state.content.Gallery = [];
        state.loading = true;
        state.error = "";
      })
      .addCase(getGallery.fulfilled, (state, action) => {
        state.content.gallery = (action.payload && action.payload.rows) || [];
        state.loading = false;
        state.error = action.payload === null ? "Records not found!" : "";
        if (action.payload.IsSuccess) {
          state.content.gallery = action.payload.Result.rows;
          state.loading = false;
          state.error = "";
        } else {
          state.content.gallery = [];
          state.loading = false;
          state.error = action.payload.Message;
        }
      })
      .addCase(getGallery.rejected, (state, action) => {
        state.content.gallery = [];
        state.loading = false;
        state.error = action.payload.Message;
      });
    builder
      .addCase(getShippingInformation.pending, (state) => {
        state.content.shippinginformation = [];
        state.loading = true;
        state.error = "";
      })
      .addCase(getShippingInformation.fulfilled, (state, action) => {
        state.content.gallery = (action.payload && action.payload.rows) || [];
        state.loading = false;
        state.error = action.payload === null ? "Records not found!" : "";
        if (action.payload.IsSuccess) {
          state.content.shippinginformation = action.payload.Result.rows;
          state.loading = false;
          state.error = "";
        } else {
          state.content.shippinginformation = [];
          state.loading = false;
          state.error = action.payload.Message;
        }
      })
      .addCase(getShippingInformation.rejected, (state, action) => {
        state.content.shippinginformation = [];
        state.loading = false;
        state.error = action.payload.Message;
      });
  },
});

export const {
  setPrivacyPolicy,
  setTermsConditions,
  setCookiePolicy,
  setDisclaimer,
  setGallery,
  cleanState,
  setShippingInformation,
  setNaturalvsLabCreatedDiamonds,
} = HtmlApiSlice.actions;
export default HtmlApiSlice.reducer;
