import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";

function PriceFilter() {
  const { stockFilter, isFilterReset } = useSelector(
    (state) => state.stockFilterReducer
  );

  let PriceFrom = stockFilter.PriceFrom && stockFilter.PriceFrom;
  let PriceTo = stockFilter.PriceTo && stockFilter.PriceTo;
  let filterArray = [PriceFrom | 100, PriceTo | 100];
  const [sliderValue, setSliderValue] = useState(filterArray);

  useEffect(() => {
    isFilterReset && setSliderValue([100, 100]);
    isFilterReset && setState({ min: 100, max: 50000 });
  }, [isFilterReset]);

  const dispatch = useDispatch();
  const [state, setState] = useState({ min: 100, max: 50000 });

  function log(value) {
    dispatch(
      addStockFilter({
        PriceFrom: value[0],
        PriceTo: value[1],
        loadmore: false,
        PageNum: 1,
      })
    );
    dispatch(resetProductPageNum());
    stockFilter.lodmore && dispatch(getProduct(stockFilter));
  }

  return (
    <div className="range-slider-wrapper ">
      <span className="filter-sub-title">Price:</span>
      <div className="range-slider price-filter">
        <Slider.Range
          onAfterChange={log}
          min={100}
          max={50000}
          value={sliderValue}
          onChange={(value) => {
            setState({ min: value[0], max: value[1] });
            setSliderValue(value);
          }}
        />
        <div className="slider-value">
          <div className="slidervalue-box">
            <label>Min</label>
            <div>$ {state.min}</div>
          </div>
          <div className="slidervalue-box slidervalue-box-right-text ">
            <label>Max</label>
            <div>$ {state.max}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceFilter;
