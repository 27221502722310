import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FetchApi } from "../../helper/helperFunction";
import useNotify from "../../hooks/useNotify";
import { Domain } from "../../constant";
const contactusSchema = Yup.object().shape({
  Fname: Yup.string()
    .min(4, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  Lname: Yup.string()
    .min(4, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phoneno: Yup.number().min(10, "Please valid mobile no!").required("Required"),
  message: Yup.string().required("Required"),
});

function HeroContent() {
  const [loader, setLoader] = useState(false);
  const Notify = useNotify();
  return (
    <section className="hero-contact">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Formik
              initialValues={{
                Fname: "",
                Lname: "",
                email: "",
                message: "",
                phoneno: "",
              }}
              validationSchema={contactusSchema}
              onSubmit={async (values, { resetForm }) => {
                try {
                  setLoader(true);
                  const response = await FetchApi(
                    `${Domain}/mobile-api/save-contact-detail`,
                    "POST",
                    values
                  );
                  const res = await response.json();
                  if (res.IsSuccess) {
                    Notify("Thank you for getting in touch!", "success");
                    resetForm();
                    setLoader(false);
                  } else {
                    Notify(res.Message, "error");
                    setLoader(false);
                  }
                } catch (error) {
                  Notify(error, "error");
                  setLoader(false);
                }
              }}
            >
              {({ errors, touched }) => (
                <Form id="contact_us">
                  <div className="cart-summary-box contact-card">
                    <div className="contact-title">
                      <h2>Contact Us</h2>
                    </div>
                    <div className="billing-input-box">
                      <div className="input-box">
                        <label>First Name*</label>
                        <Field
                          type="text"
                          placeholder="Enter your first name"
                          name="Fname"
                          autoComplete="off"
                        />
                        {errors.Fname && touched.Fname ? (
                          <div className="error">{errors.Fname}</div>
                        ) : null}
                      </div>
                      <div className="input-box">
                        <label>Last Name*</label>
                        <Field
                          type="text"
                          placeholder="Enter your last name"
                          name="Lname"
                          autoComplete="off"
                        />
                        {errors.Lname && touched.Lname ? (
                          <div className="error">{errors.Lname}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="billing-input-box">
                      <div className="input-box">
                        <label>Email*</label>
                        <Field
                          type="email"
                          placeholder="Enter your email address..."
                          name="email"
                          autoComplete="off"
                        />
                        {errors.email && touched.email ? (
                          <div className="error">{errors.email}</div>
                        ) : null}
                      </div>
                      <div className="input-box">
                        <label>Phone Number*</label>
                        <Field
                          type="text"
                          placeholder="Enter your phone number"
                          name="phoneno"
                          autoComplete="off"
                        />
                        {errors.phoneno && touched.phoneno ? (
                          <div className="error">{errors.phoneno}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="billing-input-box">
                      <div className="input-box input-box2">
                        <label>Message*</label>
                        <Field
                          component="textarea"
                          cols="30"
                          rows="6"
                          name="message"
                          autoComplete="off"
                        />
                        {errors.message && touched.message ? (
                          <div className="error">{errors.message}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="cmn-learnmore-btn-grey text-center mt-2 contact-us-submit">
                      <button type="submit" disabled={loader}>
                        Submit
                        {loader && (
                          <i className="fa fa-spinner fa-spin ml-2"> </i>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroContent;
