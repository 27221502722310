import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";
import {
  getStringFilter,
  getNumericFilter,
} from "../../../helper/helperFunction";
function ClarityFilter() {
  const { stockFilter, isFilterReset } = useSelector(
    (state) => state.stockFilterReducer
  );
  const clarityData = useSelector(
    (state) => state.commonReducer.masterlist.clarity
  );

  let customIndex = 0;
  const getclarityData =
    clarityData &&
    clarityData.map((clarity, index) => {
      customIndex += 10;
      return { [customIndex]: clarity.DisplayName };
    });

  const existingFilter = stockFilter.Clarity && stockFilter.Clarity;
  const marks = Object.assign({}, ...getclarityData);

  let myFilter = getNumericFilter(marks, existingFilter);
  let filterArray = myFilter && myFilter.length ? myFilter : [10, 10];
  const [sliderValue, setSliderValue] = useState(filterArray);

  useEffect(() => {
    isFilterReset && setSliderValue([10, 10]);
  }, [isFilterReset]);

  const dispatch = useDispatch();

  function log(value) {
    let Clarity = getStringFilter(marks, value, 10);
    dispatch(
      addStockFilter({
        Clarity: Clarity,
        loadmore: false,
        PageNum: 1,
      })
    );

    stockFilter.lodmore && dispatch(getProduct(stockFilter));
    dispatch(resetProductPageNum());
  }
  return (
    <div className="range-slider-wrapper">
      <span className="filter-sub-title">Clarity:</span>
      <div className="cmn-scroll">
        <div className="range-slider cmn-range clarity-range">
          <Slider.Range
            onAfterChange={log}
            marks={marks}
            min={10}
            max={140}
            step={10}
            value={sliderValue}
            onChange={(value) => {
              setSliderValue(value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ClarityFilter;
