import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addToCart,
  getMyCart,
  addToCartLocal,
} from "../../redux/reducers/ProductSlice";
import useNotify from "../../hooks/useNotify";
import {
  removeWishListItem,
  removeLocalWishList,
} from "../../redux/reducers/WishListSlice";
import { addToCompare, getMyCompare } from "../../redux/reducers/CompareSlice";
import { setViewProduct } from "../../redux/reducers/CommonSlice";
import { resetorderPlaced } from "../../redux/reducers/CheckoutSlice";
import { Link } from "react-router-dom";
function WishListItem({ product }) {
  const Notify = useNotify();
  const dispatch = useDispatch();

  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  // let { isItemDeletedToWishlist } = useSelector(
  //   (state) => state.WishlistReducer.wishlist
  // );

  let { isItemAddedToCompare, addItemCompareError } = useSelector(
    (state) => state.CompareReducer.compare
  );
  return (
    <div className="col-lg-3 col-md-6 cmn-diamonds-box-container ">
      <div className="cmn-diamond-box-inner-wrapper">
        <div className="report-id top-idlarge-view">
          <span>Report#</span>
          <span>{product.Lab_Report_No}</span>
          <span
            className="mycart-close"
            style={{ float: "right" }}
            onClick={() => {
              dispatch(
                removeWishListItem({
                  CustomerID: UserID,
                  StockIDs: product.Stock_ID,
                })
              );

              dispatch(removeLocalWishList(product.Stock_ID));
              Notify("Remove stone from wishlist successfully!", "success");
            }}
          >
            <i className="fa fa-times wishlist-close" aria-hidden="true"></i>
          </span>
        </div>
        <div className="large-view">
          <div className="image-overlay">
            <div className="wishlist-image">
              <img
                src={
                  product.Stone_Img_url === ""
                    ? "assets/images/procuctdetails/no-image.png"
                    : product.Stone_Img_url
                }
                className="img-fluid"
                alt="dimaond"
              />
            </div>
            <a href="" className=" over-lay-container">
              <img
                src="assets/images/stock/view1.svg"
                alt="view1"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="large-view-size-table-shapw-wrapper">
            <div className="shap-rating cmn-child-div-box">
              <span>
                {product.Shape} |{product.Color}
              </span>
              <div id="full-stars-example-two"></div>
            </div>
            <div className="carat-size cmn-child-div-box ">
              <span>Carat Size:</span>
              <span>{product.Weight}</span>
            </div>
            <div className="igi-logo-table cmn-child-div-box ">
              <span>Table:</span>
              <span className="certilogo">
                {product.Certificate_file_url && (
                  <img
                    src={`assets/images/stock/${product.Lab}.png`}
                    alt="logo_IGI"
                    className="img-fluid"
                  />
                )}
              </span>
            </div>
            {UserID ? <div className="price">${product.Cost_Amt}</div> : ""}
          </div>
          <div className="cart-like-detail-btn">
            <a
              onClick={() => {
                if (UserID) {
                  dispatch(resetorderPlaced());
                  dispatch(
                    addToCart({
                      CustomerID: UserID,
                      StockIDs: product.Stock_ID,
                    })
                  );
                } else {
                  dispatch(addToCartLocal(product));
                  Notify("Stone added successfully to cart.", "success");
                }
              }}
              className="large-none"
            >
              <img
                src="assets/images/stock/cart1.svg"
                className="img-fluid"
                alt="cart1"
              />
            </a>

            <a
              onClick={() => {
                if (UserID) {
                  dispatch(
                    addToCompare({
                      CustomerID: UserID,
                      StockIDs: product.Stock_ID,
                    })
                  );
                  if (isItemAddedToCompare) {
                    dispatch(getMyCompare({ UserID }));
                    Notify("Stone added successfully to compare.", "success");
                  } else {
                    Notify(addItemCompareError, "error");
                  }
                } else {
                  Notify("Please login first!", "error");
                }
              }}
              className="large-none"
            >
              <img
                src="assets/images/stock/compare-match.svg"
                alt="like-heart"
                className="img-fluid"
              />
            </a>

            <Link
              to="/productdetail"
              className="details-btn2"
              onClick={() => dispatch(setViewProduct(product))}
            >
              <img
                src="assets/images/stock/view-details.svg"
                alt="view-details"
                className="img-fluid"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WishListItem;
