import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Domain, BackEndClientId } from "../../constant";
const initialState = {
  checkout: {
    data: {
      Customer_ID: 0,
      Billing_Name: "",
      Billing_Email: "",
      Billing_Phone: "",
      Billing_AddressLine1: "",
      Billing_AddressLine2: "",
      Billing_City: 0,
      Billing_State: 0,
      Billing_Country_ID: 0,
      Billing_Zip_Code: "",
      Shipping_Name: "",
      Shipping_Email: "",
      Shipping_Phone: "",
      Shipping_AddressLine1: "",
      Shipping_AddressLine2: "",
      Shipping_City: 0,
      Shipping_State: 0,
      Shipping_Country_ID: 0,
      Shipping_Zip_Code: "",
      Payment_TransactionId: "",
      BackEndClientId,
    },

    cartSummary: {},
    taxSummary: [],
    transactionId: "",
    orderPlacedLoading: false,
    orderPlaceError: "",
    lastPlaceOrderNo: "",
    orderPlaceMessage: "",
  },
};

export const getCartSummary = createAsyncThunk(
  "cartsummary",
  async (searchParam) => {
    const response = await fetch(
      `${Domain}/mobile-api/mapp-get-web-customer-order-amt-with-taxes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...searchParam,
          BackEndClientId,
        }),
      }
    );
    let data = await response.json();
    return data.Result;
  }
);

export const placeOrder = createAsyncThunk(
  "placeorder",
  async (searchParam) => {
    const response = await fetch(
      `${Domain}/mobile-api/save-place-order-detail`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...searchParam,
          BackEndClientId,
        }),
      }
    );
    let data = await response.json();
    return data;
  }
);

// export const confirmOrder = createAsyncThunk(
//   "confirm_order",
//   async (searchParam) => {
//     const response = await fetch(`${Domain}/mobile-api/confirm-order`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         ...searchParam,
//         BackEndClientId,
//       }),
//     });
//     let data = await response.json();
//     return data;
//   }
// );

export const confirmOrder = createAsyncThunk(
  "confirm_order",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/confirm-order-parin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
        BackEndClientId,
      }),
    });
    let data = await response.json();
    return data;
  }
);
export const saveAddressFromOrder = createAsyncThunk(
  "saveaddress_fromorder",
  async (searchParam) => {
    const response = await fetch(`${Domain}/cadd-customer-address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
        BackEndClientId,
      }),
    });
    let data = await response.json();
    return data;
  }
);

export const getTaxDetail = createAsyncThunk(
  "getTaxDetail",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/get-branch-tax-Detail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
      }),
    });
    let data = await response.json();
    return data;
  }
);

export const CheckoutSlice = createSlice({
  name: "checkout_slice",
  initialState,
  reducers: {
    setCheckoutData: (state, action) => {
      state.checkout.data = { ...state.checkout.data, ...action.payload };
    },
    setTransactionId: (state, action) => {
      state.checkout.transactionId = action.payload;
    },
    resetTransactionId: (state, action) => {
      state.checkout.transactionId = "";
    },
    resetorderPlaced: (state) => {
      state.checkout.orderPlaced = false;
    },
    resetOrderPlaceError: (state) => {
      state.checkout.orderPlaceError = "";
    },
    resetOrderPlaceMessage: (state) => {
      state.checkout.orderPlaceMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartSummary.pending, (state) => {
        state.checkout.cartSummary = {};
      })
      .addCase(getCartSummary.fulfilled, (state, action) => {
        state.checkout.cartSummary = action.payload;
      })
      .addCase(getCartSummary.rejected, (state, action) => {
        state.checkout.cartSummary = {};
      });
    builder
      .addCase(getTaxDetail.pending, (state) => {
        state.checkout.taxSummary = {};
      })
      .addCase(getTaxDetail.fulfilled, (state, action) => {
        state.checkout.taxSummary = action.payload.Result;
      })
      .addCase(getTaxDetail.rejected, (state, action) => {
        state.checkout.taxSummary = {};
      });

    builder
      .addCase(placeOrder.pending, (state) => {
        state.checkout.orderPlacedLoading = true;
        state.checkout.lastPlaceOrderNo = "";
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        if (action.payload.IsSuccess) {
          state.checkout.orderPlacedLoading = false;
          state.checkout.orderPlaceError = "";
          state.checkout.orderPlaced = true;
          state.checkout.lastPlaceOrderNo = action.payload.Result.ordereNumber;
        } else {
          state.checkout.orderPlaced = false;
          state.checkout.orderPlaceError = action.payload.Message;
          // state.checkout.orderPlaced = false;
          state.checkout.lastPlaceOrderNo = "";
          // state.checkout.errorMessage = action.payload.message;
        }
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.checkout.orderPlacedLoading = false;
        state.checkout.orderPlaceError = "Something went wrong! Server error!";
        state.checkout.orderPlaced = false;
      });
    // confirm order
    builder
      .addCase(confirmOrder.pending, (state) => {
        state.checkout.orderPlacedLoading = true;
        state.checkout.lastPlaceOrderNo = "";
        state.checkout.orderPlaceMessage = "";
      })
      .addCase(confirmOrder.fulfilled, (state, action) => {
        if (action.payload.IsSuccess) {
          state.checkout.orderPlacedLoading = false;
          state.checkout.orderPlaceError = "";
          state.checkout.orderPlaced = true;
          state.checkout.orderPlaceMessage = action.payload.Message;
          //state.checkout.lastPlaceOrderNo = action.payload.Result.ordereNumber;
        } else {
          state.checkout.orderPlaced = false;
          state.checkout.orderPlaceError = action.payload.Message;
          state.checkout.orderPlaced = false;
          state.checkout.lastPlaceOrderNo = "";
        }
      })
      .addCase(confirmOrder.rejected, (state, action) => {
        state.checkout.orderPlacedLoading = false;
        state.checkout.orderPlaceMessage = "";
        state.checkout.orderPlaceError = "Something went wrong! Server error!";
        state.checkout.orderPlaced = false;
      });
  },
});

export const {
  setCheckoutData,
  setTransactionId,
  resetTransactionId,
  resetorderPlaced,
  resetOrderPlaceMessage,
  resetOrderPlaceError,
} = CheckoutSlice.actions;
export default CheckoutSlice.reducer;
