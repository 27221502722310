import React from "react";
import Layout from "../../Layout";
import Carousel from "./Carousel";
import Aboutus from "./Aboutus";
import Labgrown from "./Labgrown";
import DiamondClassic from "./DiamondClassic";
import ClientServing from "./ClientServing";
import LabGrownNatural from "./LabGrownNatural";
import Diamond4c from "./Diamond4c";

function Index() {
  return (
    <>
      <Layout>
        <Carousel />
        <Aboutus />
        <Labgrown />
        <DiamondClassic />
        <ClientServing />
        <LabGrownNatural />
        <Diamond4c />
      </Layout>
    </>
  );
}

export default Index;
