import React from "react";
import { Link } from "react-router-dom";
function Diamond4c() {
  return (
    <section className="diamond-4c">
      <div className="container c4-container bg-cmn-property">
        <div className="row">
          <div className="col-lg-4 col-md-7 diamond-4c-inner">
            <h2>What is 4c's Of Diamonds​</h2>
            <p>
              Diamonds are graded and categorized through its different
              characteristics in the diamond industry. Any diamond is graded on
              the basis of 4C’S.
            </p>
            <p>
              These are the basic details Gemologists and jewelers see in
              diamonds:
            </p>
            <Link to="/c4diamondfootepage" className="4c-diamond-btn">
              4c’s Of Diamonds
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Diamond4c;
