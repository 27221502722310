import React from "react";
import { Link } from "react-router-dom";
function Help() {
  return (
    <div className="cart-summary-box mt-2">
      <div className="cart-title">
        <h5>Need Help?</h5>
      </div>
      <div className="cart-box">
        <ul>
          <li>
            <Link to="/diamond">
              <span>Shopping</span>
              <span></span>
            </Link>
          </li>
          <li>
            <Link to="/termsconditions">
              <span> Returns & Exchanges</span>
              <span></span>
            </Link>
          </li>
          <li>
            <Link to="/contactus">
              <span>Contact Us</span>

              <span></span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Help;
