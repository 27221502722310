import React from "react";

function LabGrownNatural() {
  return (
    <section className="labgrown-natural-diamond">
      <div className="container">
        <div
          className="
            cmn-diamond-with-title-wrapper
            text-center
            labgrown-title-container
          "
        >
          <span className="labgrown-span"> What Is Lab Grown Diamond ?</span>
          <h1 className="cmn-section-titile cmn-color-title">
            Differance Beetween Lab Grown Diamond & Natural Diamond
          </h1>
          <div className="cmn-diamond-below-title mx-auto">
            <img
              src="assets/images/home/grey-small-diamod.png"
              className="img-fluid"
              alt="grey-small-diamod.png"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <table className="table labgrown-table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" className="f-16">
                Lab Grown Diamond
              </th>
              <th scope="col" className="f-16">
                Natural Diamond
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Is it a diamond?</th>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <th scope="row">Is it Real Diamond?</th>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <th scope="row">What is its source?</th>
              <td>Greenhouse</td>
              <td>Mines</td>
            </tr>
            <tr>
              <th scope="row">Is it eco-conscious?</th>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <th scope="row">What is its life expectancy?</th>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <th scope="row">Is it artificial?</th>
              <td>No</td>
              <td>No</td>
            </tr>
            <tr>
              <th scope="row">Is it a conflict diamond?</th>
              <td>No</td>
              <td>Maybe</td>
            </tr>
            <tr>
              <th scope="row">Engages child labor?</th>
              <td>No</td>
              <td>Maybe</td>
            </tr>
            <tr>
              <th scope="row">Can it be customized?</th>
              <td>Yes</td>
              <td>Maybe</td>
            </tr>
            <tr>
              <th scope="row">Is it affordable?</th>
              <td>Yes</td>
              <td>Maybe</td>
            </tr>
            <tr>
              <th scope="row">Can it meet quantity demands?</th>
              <td>Yes</td>
              <td>Maybe</td>
            </tr>
            <tr>
              <th scope="row">Is it a conflict-free?</th>
              <td>Yes</td>
              <td>Maybe</td>
            </tr>
            <tr>
              <th scope="row">Whether it is real or not?</th>
              <td>100%</td>
              <td>100%</td>
            </tr>
            <tr>
              <th scope="row">Is it eco-friendly?</th>
              <td>100%</td>
              <td>Not at all</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default LabGrownNatural;
