import React from "react";

function Heading() {
  return (
    <div className="container container-2">
      {/*  <div className="baneer_img">
        <img src={Banner1} alt="" className="w-100" />
      </div> */}
      <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
        <span>Our</span>
        <h1 className="cmn-section-titile cmn-color-title">
          Stock Information
        </h1>
        <div className="cmn-diamond-below-title mx-auto">
          <img
            src="assets/images/home/grey-small-diamod.png"
            className="img-fluid"
            alt="grey-small-diamod.png"
          />
        </div>
      </div>
    </div>
  );
}

export default Heading;
