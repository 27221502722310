import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import LabGrownDiamond from "./LabGrownDiamond";
function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="Lab Grown Diamonds" />
        <LabGrownDiamond />
      </Layout>
    </>
  );
}

export default Index;
