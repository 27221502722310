import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Cart from "./Cart";

function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="Shopping Cart" />
        <Cart />
      </Layout>
    </>
  );
}

export default Index;
