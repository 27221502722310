import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import SignUp from "../Signup/Signup";

function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="SignUp" />
        <SignUp />
      </Layout>
    </>
  );
}

export default Index;
