import { configureStore } from "@reduxjs/toolkit";
import stockFilterReducer from "./reducers/StockFilterSlice";
import commonReducer from "./reducers/CommonSlice";
import productReducer from "./reducers/ProductSlice";
import userReducer from "./reducers/UserSlice";
import WishlistReducer from "./reducers/WishListSlice";
import CompareReducer from "./reducers/CompareSlice";
import CheckoutReducer from "./reducers/CheckoutSlice";
import HtmlApiContantReducer from "./reducers/HtmlApiSlice";
export const store = configureStore({
  reducer: {
    stockFilterReducer,
    commonReducer,
    productReducer,
    userReducer,
    WishlistReducer,
    CompareReducer,
    CheckoutReducer,
    HtmlApiContantReducer
  },
});
