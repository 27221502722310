import React from "react";

function EmptyCart() {
  return (
    <section className="stock-container bg-cmn-property wishlist-container">
      <div className="container container5">
        <div className="cmn-diamond-with-title-wrapper text-center">
          <div className="thankyou-wrapper">
            <div className="right-icon empty-cart">
              <i className="fal fa-shopping-cart"></i>
            </div>
            <h3>Your cart is empty!</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EmptyCart;
