import React, { useState } from "react";
import { Domain } from "../../constant";
import useNotify from "../../hooks/useNotify";
const initialState = {
  EmailID: "",
};
const forgotPassword = async (detail) => {
  try {
    const result = await fetch(`${Domain}/mobile-api/forget-password`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(detail),
    });
    let data = await result.json();
    return data.Result;
  } catch (error) {
    return error;
  }
};
function ForgotPassword() {
  const Notify = useNotify();
  const [detail, setDetail] = useState(initialState);
  const [loader, setLoader] = useState(false);
  const submithandle = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (detail.EmailID) {
      let data = await forgotPassword(detail);
      // console.log("data", data);
      if (data[0].ApiStatus > 0) {
        Notify(data[0].StatusMsg, "success");
        setLoader(false);
      } else {
        Notify(data[0].StatusMsg, "error");
        setLoader(false);
      }
    }
  };

  const handleChangeInput = (e) => {
    setDetail({ [e.target.name]: e.target.value });
  };
  return (
    <section className="stock-container bg-cmn-property">
      <div className="container container5">
        <div className="cmn-diamond-with-title-wrapper signin-wrappper">
          <div className="cmn-shadow-bg">
            <div className="cmn-title-form">
              <span>Forgot Password</span>
            </div>
            <div className="billing-form">
              <form onSubmit={(e) => submithandle(e)} action="">
                <div className="checkout-input-box">
                  <div className="billing-input-box sign-in-input">
                    <div className="input-box ">
                      <label>Email</label>
                      <input
                        type="email"
                        name="EmailID"
                        onChange={(e) => handleChangeInput(e)}
                        placeholder="Enter your email address"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="register-btn signin-btn">
                  <button type="submit">
                    Submit
                    {loader && <i className="fa fa-spinner fa-spin ml-2"> </i>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
