import React from "react";
import Heading from "./Heading";

function StockContainer() {
  return (
    <section className="stock-container bg-cmn-property">
      <Heading />
    </section>
  );
}

export default StockContainer;
