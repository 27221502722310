import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import { Logout } from "../../redux/reducers/UserSlice";
import useNotify from "../../hooks/useNotify";
import { useHistory } from "react-router-dom";
import SmallCart from "../Cart/SmallCart";
const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const Notify = useNotify();
  const dispatch = useDispatch();
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);

  useEffect(() => {
    let unMounted = false;
    if (!unMounted) {
      AOS.init({
        duration: 800,
        offset: 0,
      });
    }
    return () => {
      unMounted = true;
    };
  }, []);

  useEffect(() => {
    let unMounted = false;
    if (!unMounted) {
      $(document).on("click", function (e) {
        var container = $(".dropdown-content");
        if (container.is(e.target) || container.has(e.target).length === 0) {
          container.removeClass("shows");
        }
      });

      $(".dropdownMenuButton").on("click", function (e) {
        e.stopPropagation();
        $(".dropdown-content").toggleClass("shows");
      });
    }
    return () => {
      unMounted = true;
    };
  }, []);

  useEffect(() => {
    let unMounted = false;
    if (!unMounted) {
      $(document).on("click", function (e) {
        var container = $(".go-to-cart");
        //  console.log(container.has(e.target).length);
        if (
          container.has(e.target).length === 0 &&
          e.target.parentElement?.className !== "mycart-close"
        ) {
          container.removeClass("shows1");
        }
      });

      $(".dropdown-buttons").on("click", function (e) {
        e.stopPropagation();
        $(".go-to-cart").toggleClass("shows1");
      });

      $(document).on("click", function (e) {
        $(".go-to-cart").removeClass("shows1");
      });

      $(".go-to-cart").on("click", function (e) {
        e.stopPropagation();
      });

      // $(".cartclose").on("click", function (e) {
      //   e.stopPropagation();
      //   $(".go-to-cart").removeClass("shows1");
      // });
    }
    return () => {
      unMounted = true;
    };
  }, []);

  return (
    <>
      <header className="header" id="header">
        <div className="container">
          <div className="logo-curency-menu-wrapper">
            <div className="logo-container d-md-block d-none">
              <Link to="/" className="logo">
                <img
                  src="assets/images/home/Logo.png"
                  alt="Logo"
                  className="img-fluid"
                />
              </Link>
            </div>
            <div className="account-nav-wrapper">
              <div className="account-currency d-md-flex d-none">
                {/* <div className="currency">
                  <select className="select">
                    <option value="usd">USD</option>
                  </select>
                </div> */}
                {/* <div className="vertical-line"></div> */}
                <div className="call_wrap">
                  <ul>
                    <li>
                      <a href="tel:+16467368625">+16467368625</a>
                    </li>
                    <li>
                      <a href="mailto:parinlabgrown@gmail.com">
                        parinlabgrown@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="account">
                  <div className="dropdown">
                    <button className="dropdownMenuButton" type="button">
                      <span className="d-md-inline d-none">
                        <img
                          src="assets/images/home/account-icon.svg"
                          alt="account-icon"
                          className="img-fluid"
                        />
                      </span>
                      <span className="d-md-inline-block d-none">
                        {UserID ? (
                          "My Account"
                        ) : (
                          <Link to="/signin" style={{ color: "#ffffff" }}>
                            Sign In
                          </Link>
                        )}
                      </span>
                      <span className="d-md-inline-block d-none ml-3">
                        {!UserID ? (
                          <Link to="/signup" style={{ color: "#ffffff" }}>
                            Sign Up
                          </Link>
                        ) : (
                          ""
                        )}
                      </span>
                      <i className="fas fa-ellipsis-v d-md-none d-block"></i>
                    </button>
                    {UserID && (
                      <div className="dropdown-content">
                        {UserID && (
                          <>
                            <Link to="/my-profile" className="dropdown-item">
                              My Profile
                            </Link>
                            <Link to="/my-orders" className="dropdown-item">
                              My Orders
                            </Link>
                          </>
                        )}
                        {/* {!UserID && (
                        <Link to="/signin" className="dropdown-item">
                          Sign In
                        </Link>
                      )}
                      {!UserID && (
                        <Link to="/signup" className="dropdown-item">
                          Sign Up
                        </Link>
                      )} */}
                        {UserID && (
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              dispatch(Logout());
                              history.push("/");
                              Notify("Sing out successfully!", "success");
                            }}
                          >
                            Log Out
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="menu-container">
                <div className="menu-wrapper">
                  <nav className="navbar navbar-expand-lg">
                    <button
                      className="navbar-toggler navbar-toggle d-flex flex-column d-lg-none"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarNav"
                    >
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <b>Menu</b>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <Link
                            to="/"
                            className={`nav-link  ${
                              location.pathname === "/" && "active"
                            }`}
                          >
                            Home
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/labgrowndiamond"
                            className={`nav-link  ${
                              location.pathname === "/labgrowndiamond" &&
                              "active"
                            }`}
                          >
                            Lab Grown Diamonds
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/diamond"
                            className={`nav-link  ${
                              location.pathname === "/diamond" && "active"
                            }`}
                          >
                            Our Stock
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/aboutus"
                            className={`nav-link  ${
                              location.pathname === "/aboutus" && "active"
                            }`}
                          >
                            About Us
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/gallery"
                            className={`nav-link  ${
                              location.pathname === "/gallery" && "active"
                            }`}
                          >
                            Gallery
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/contactus"
                            className={`nav-link  ${
                              location.pathname === "/contactus" && "active"
                            }`}
                          >
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  <div className="logo-container d-md-none d-block">
                    <Link to="/" className="logo">
                      <img
                        src="assets/images/home/Logo.png"
                        alt="Logo"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
                <div className="wishlist-cart-wrapper">
                  <div className="wishlist">
                    <Link to="/wishlist">
                      <span>
                        <img
                          src="assets/images/home/wishlist-icon.svg"
                          alt="cart-icon"
                          className="img-fluid"
                        />
                      </span>
                      <span className="d-md-inline d-none">Wishlist</span>
                    </Link>
                  </div>
                  <div className="vertical-line"></div>
                  <SmallCart />
                  <div className="vertical-line d-md-none d-inline"></div>
                  <div className="account d-md-none d-inline-block">
                    <div className="dropdown">
                      <button className="dropdownMenuButton" type="button">
                        <span className="d-md-inline d-none">
                          <img
                            src="assets/images/home/account-icon.svg"
                            alt="account-icon"
                            className="img-fluid"
                          />
                        </span>
                        <span className="d-md-inline d-none">My Account</span>
                        <i className="fas fa-ellipsis-v d-md-none d-block"></i>
                      </button>
                      <div className="dropdown-content">
                        {UserID && (
                          <>
                            <Link to="/my-profile" className="dropdown-item">
                              My Profile
                            </Link>
                            <Link to="/my-orders" className="dropdown-item">
                              My Orders
                            </Link>
                          </>
                        )}
                        {!UserID && (
                          <Link to="/signin" className="dropdown-item">
                            Sign In
                          </Link>
                        )}
                        {!UserID && (
                          <Link to="/signup" className="dropdown-item">
                            Sign Up
                          </Link>
                        )}
                        {UserID && (
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              dispatch(Logout());
                              history.push("/");
                              Notify("Sing out successfully!", "success");
                            }}
                          >
                            Log Out
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="dummy-div"></div>
    </>
  );
};

export default Header;
