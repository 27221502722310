import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrderList,
  getOrderListItem,
  resetMyOrderDetailView,
} from "../../redux/reducers/CommonSlice";
import EmpthOrderlist from "./EmpthOrderlist";
import OrderItemList from "./OrderItemList";
import OrderItemDetail from "./OrderItemDetail";
import Skeleton from "react-loading-skeleton";
import { isEmptyObj } from "../../helper/helperFunction";
import { useLocation } from "react-router-dom";

function MyOrders() {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    order_list,
    myOrderDetailView,
    order_list_item,
    loading_item,
    myOrderDetail,
    myOrderAddressDetail,
  } = useSelector((state) => state.commonReducer);
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  useEffect(() => {
    dispatch(
      getOrderList({
        UserID,
        Sort: "Desc",
      })
    );
  }, []);

  useEffect(() => {
    myOrderDetailView &&
      dispatch(getOrderListItem({ Web_OrderId: myOrderDetailView, UserID }));
  }, [myOrderDetailView]);
  return (
    <div
      className={`tab-pane fade my-order-main-wrapper  ${
        location.pathname === "/my-orders" && "active show"
      }`}
      id="orders"
      role="tabpanel"
    >
      <div className="tabs-titile addres-titile my-order-title">
        {myOrderDetailView > 0 ? (
          <span>
            Order Details
            <i
              className="fas fa-arrow-circle-left"
              onClick={() => dispatch(resetMyOrderDetailView())}
              style={{ cursor: "pointer", marginRight: "0px" }}
            ></i>
          </span>
        ) : (
          "My Orders"
        )}
        {myOrderDetailView > 0 ? (
          <div style={{ fontSize: "16px", color: "black" }}>
            {myOrderDetail.OrderNo} &nbsp;/ &nbsp;
            {myOrderDetail.OrderDate}
          </div>
        ) : null}
      </div>

      <div className="my-order-wrapper-table">
        {myOrderDetailView > 0 ? (
          <span
            className="payment-info-subtiltle"
            style={{ paddingLeft: "15px" }}
          >
            Ordered Items
          </span>
        ) : null}
        {!myOrderDetailView ? (
          <div className="row mx-0">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">Order No</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">Amount</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Details</th>
                </tr>
              </thead>
              <tbody>
                {order_list && order_list.length ? (
                  order_list.map((item, index) => {
                    return (
                      <OrderItemList key={item.Web_OrderId} order={item} />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      Your order is empty
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <>
            <div className="row mx-0">
              {!loading_item && (
                <>
                  <div className="my-order-wrapper-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Report No</th>
                          <th className="text-center">Details</th>
                          <th className="text-right">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading_item &&
                          order_list_item.map((item) => {
                            return (
                              <OrderItemDetail
                                key={item.Web_OrderId}
                                product={item}
                              />
                            );
                          })}
                        {/*  <tr>
                          <td></td>
                          <td className="text-right">Sub Total</td>
                          <td className="text-right">
                            <b>{myOrderAddressDetail.Total_Amt}</b>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="text-right">Tax</td>
                          <td className="text-right">
                            <b>0</b>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="text-right">Shipping Charge</td>
                          <td className="text-right">
                            <b>0</b>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="text-right">Total</td>
                          <td className="text-right">
                            <b>{myOrderAddressDetail.Total_Amt}</b>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                    <div className="order-details-total-wapper">
                      <div className="order-details-total-row">
                        <span>Sub Total</span> :
                        <span>
                          <b>{myOrderAddressDetail.Total_Amt}</b>
                        </span>
                      </div>
                      <div className="order-details-total-row">
                        <span>Tax</span> :
                        <span>
                          <b>{myOrderAddressDetail.SumOfCharges}</b>
                        </span>
                      </div>
                      <div className="order-details-total-row">
                        <span>Shipping Charge</span> :
                        <span>
                          <b>{myOrderAddressDetail.Shipping_Charges}</b>
                        </span>
                      </div>
                      <div className="order-details-total-row">
                        <span>Total</span> :
                        <span>
                          <b>
                            {myOrderAddressDetail.Total_Amt_With_All_Charges}
                          </b>
                        </span>
                      </div>
                    </div>
                  </div>
                  {!isEmptyObj(myOrderAddressDetail) && (
                    <div className="payment-info-billing-shipping-wrapper">
                      <div className="billing-input-box flex-column">
                        <span className="payment-info-subtiltle">
                          Billing address
                        </span>
                        <div className="existing-address-after-select">
                          <div className="existing-address-after-select-inner">
                            <label>
                              {myOrderAddressDetail.Billing_First_Name}
                            </label>
                            <div>
                              {myOrderAddressDetail.Billing_AddressLine1},
                            </div>
                            <div>
                              {myOrderAddressDetail.Billing_AddressLine2}
                              {myOrderAddressDetail.Billing_AddressLine2 && ","}
                            </div>

                            <div>
                              {myOrderAddressDetail.BillingCity},
                              {myOrderAddressDetail.BillingState},
                              {myOrderAddressDetail.BillingCountry}-
                              {myOrderAddressDetail.Billing_Zip_Code}
                            </div>
                            <div className="existing-address-after-select-inner-number">
                              {myOrderAddressDetail.Billing_Phone}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="billing-input-box flex-column">
                        <span className="payment-info-subtiltle">
                          Shipping address
                        </span>
                        <div className="existing-address-after-select">
                          <div className="existing-address-after-select-inner">
                            <label>
                              {myOrderAddressDetail.Shipping_First_Name}
                            </label>
                            <div>
                              {myOrderAddressDetail.Shipping_AddressLine1},
                            </div>
                            <div>
                              {myOrderAddressDetail.Shipping_AddressLine2}
                              {myOrderAddressDetail.Shipping_AddressLine2 &&
                                ","}
                            </div>

                            <div>
                              {myOrderAddressDetail.ShippingCity},
                              {myOrderAddressDetail.shippingState},
                              {myOrderAddressDetail.shippingCountry}-
                              {myOrderAddressDetail.Shipping_Zip_Code}
                            </div>
                            <div className="existing-address-after-select-inner-number">
                              {myOrderAddressDetail.Shipping_Phone}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {/* {loading_item && (
                here loading display
            )} */}
          </>
        )}
      </div>
    </div>
  );
}

export default MyOrders;
