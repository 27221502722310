import React from "react";
import ReactHtmlParser from "react-html-parser";
function ErrorBox({ errorMessage }) {
  return (
    <>
      <div className="col-md-12 col-sm-12 error-box">
        <div className="cmn-diamond-with-title-wrapper text-center error-box-inner">
          <div className="thankyou-wrapper">
            <div className="right-icon empty-cart">
              <i className="fas fa-exclamation-triangle"></i>
            </div>
            <h3>{ReactHtmlParser(errorMessage)}</h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorBox;
