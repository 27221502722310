import React, { useState, useEffect, useRef } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./checkout.css";
import { Domain } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import {
  setTransactionId,
  resetTransactionId,
} from "../../redux/reducers/CheckoutSlice";
import useNotify from "../../hooks/useNotify";

import { setCheckoutData } from "../../redux/reducers/CheckoutSlice";
export default function CheckoutForm() {
  const Notify = useNotify();
  const submitBtn = useRef();
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  const dispatch = useDispatch();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      // console.log(payload.id, payload);
      dispatch(setTransactionId(payload.paymentIntent.id));
      dispatch(
        setCheckoutData({
          Payment_TransactionId: payload.paymentIntent.id,
          ClientSecret: payload.paymentIntent.client_secret,
        })
      );
    }
  };

  const generateSecret = (e) => {
    window
      .fetch(`${Domain}/mobile-api/create-payment-intent-stripe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Customer_ID: UserID }),
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.IsSuccess) {
          setClientSecret(data.Result.ClientSecret);
          dispatch(resetTransactionId());
        } else {
          Notify(data.Message, "error");
        }
      });
  };
  useEffect(() => {
    clientSecret && submitBtn.current.click();
  }, [clientSecret]);
  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
          hidePostalCode={true}
        />
        <button
          type="submit"
          style={{ display: "none" }}
          ref={submitBtn}
        ></button>
        <button
          type="button"
          onClick={generateSecret}
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          <span id="button-text">
            {processing ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" style={{ color: "red" }} role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? "result-message" : "result-message hidden"}>
          Payment succeeded,
          <span style={{ color: "red" }}>
            Do not refresh the page , your order is placing..
          </span>
        </p>
      </form>
      <div>
        <div className="payment-card">
          <ul>
            <li>
              <a href="/#">
                <img
                  src="assets/images/stock/card1.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="/#">
                <img
                  src="assets/images/stock/card2.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="/#">
                <img
                  src="assets/images/stock/card3.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="/#">
                <img
                  src="assets/images/stock/card4.png"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
