import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
// import Lightroom from "react-lightbox-gallery";
// import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { setGallery, cleanState } from "../../redux/reducers/HtmlApiSlice";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback } from "react";

// $(document).ready(function ($) {
//   $(document).on("click", '[data-toggle="lightbox"]', function (event) {
//     //alert("clicked"); //to test this function ran
//     event.preventDefault();
//     $(this).ekkoLightbox();
//   });
// });

function Gallerys() {
  // useLayoutEffect(() => {
  //   $(document).on("click", '[data-toggle="lightbox"]', function (event) {
  //     event.preventDefault();
  //     $(this).ekkoLightbox();
  //   });
  // }, []);

  // var settings = {
  //   columnCount: {
  //     default: 5,
  //     mobile: 3,
  //     tab: 4,
  //   },
  //   mode: "dark",
  // };
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(0);
  const photos = useSelector(
    (state) => state.HtmlApiContantReducer.content.gallery
  );
  // const openLightbox = useCallback((event, { photo, index }) => {
  //   setCurrentImage(index);
  //   setViewerIsOpen(true);
  // }, []);

  // const closeLightbox = () => {
  //   setCurrentImage(0);
  //   setViewerIsOpen(false);
  // };
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(cleanState());
    };
  }, []);
  useEffect(() => {
    dispatch(setGallery());
  }, []);

  return (
    <section className="lab-grown-dimaond-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12">
            <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
              <span>Media Event Photo Gallery</span>
              <h1 className="cmn-section-titile cmn-color-title">Gallery</h1>
              <div className="cmn-diamond-below-title mx-auto">
                <img
                  src="assets/images/home/grey-small-diamod.png"
                  className="img-fluid"
                  alt="grey-small-diamod.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {photos &&
            photos.map((items, index) => {
              return (
                <div className="col-lg-4 col-sm-6 col-xs-12" key={index}>
                  <div className="gallery-img">
                    <button
                      className="plus_btn"
                      onClick={() => {
                        setViewerIsOpen(items);
                      }}
                      // data-toggle="lightbox"
                      // data-gallery="photos"
                    >
                      <img
                        src={items}
                        className="img-fluid rounded"
                        alt="diamond"
                      />
                      <div className="overlay">
                        <img
                          src={"assets/images/gallery/plus.svg"}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </button>
                  </div>
                </div>
              );
            })}
          {!!viewerIsOpen && (
            <Lightbox
              mainSrc={photos[currentImage]}
              nextSrc={photos[(currentImage + 1) % photos.length]}
              prevSrc={
                photos[(currentImage + photos.length - 1) % photos.length]
              }
              onCloseRequest={() => setViewerIsOpen(false)}
              onMovePrevRequest={() =>
                setCurrentImage(
                  (currentImage + photos.length - 1) % photos.length
                )
              }
              onMoveNextRequest={() => {
                setCurrentImage((currentImage + 1) % photos.length);
              }}
            />
          )}
        </div>
        {/* <div className="row">
          <div className="col-12">
            <div className="cmn-learnmore-btn-grey text-center classNameic-leartmore-btn">
              <a>Show More</a>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default Gallerys;
