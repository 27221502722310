import React, { useEffect } from "react";
import $ from "jquery";
function LabGrownDiamond() {
  useEffect(() => {
    $(function () {
      $(".acc-header-common button").on("click", function () {
        $(".acc-header-common button.color-active").removeClass("color-active");
        if ($(this).children().closest("i").hasClass("fa-plus")) {
          $(this).addClass("color-active");
          $(this).children().closest("i").addClass("fa-minus");
          $(this).children().closest("i").removeClass("fa-plus");
        } else {
          $(this).children().closest("i").removeClass("fa-minus");
          $(this).children().closest("i").addClass("fa-plus");
        }
      });
    });
  }, []);
  return (
    <section className="lab-grown-dimaond-page bg-cmn-property">
      <div className="container-fluid">
        <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
          <span>Category</span>
          <h1 className="cmn-section-titile cmn-color-title">
            What is Lab-Grown Diamond?
          </h1>
          <div className="cmn-diamond-below-title mx-auto">
            <img
              src="assets/images/home/grey-small-diamod.png"
              className="img-fluid"
              alt="grey-small-diamod.png"
            />
          </div>
        </div>
        <div className="row labgrown-block">
          <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="ractangle-img spining">
              <img
                src="assets/images/labgrown-page/Ellipse.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="interesting-img">
              <img
                src="assets/images/labgrown-page/Interesting-Diamond.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="dot-img spining">
              <img
                src="assets/images/labgrown-page/frame.svg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-xs-12 flex-display">
            <div className="line-img">
              <img
                src="assets/images/labgrown-page/curve-line.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="grown-cont">
              <h1>What is a Lab-Grown Diamond?</h1>
              <p>
                For a very long time, there has been only one type of gem, that
                is, a quality diamond which is often known as real, natural, or
                earth mined diamond. But here, we are talking about Lab grown
                diamonds. But, wait. Can we actually create diamonds? Is it a
                human thing to do? Well, maybe not some time ago. But over the
                years, scientists have created technologies and processes to
                produce engineered diamonds that are lay-out by FTC as lab-grown
                diamonds.One might wonder how genuine and original Lab grown
                Diamonds are? But these are not diamond simulants like zirconia
                or moissanite but are the same, rather better than natural
                Diamonds- chemically, physically, and optically due to the
                controlled lab manufacturing processes. Science truly has the
                competency to make the impossible very much possible.
              </p>
            </div>
          </div>
        </div>
        <div className="row labgrown-block">
          <div className="col-lg-6 col-sm-12 col-xs-12 flex-display flex-display2">
            <div className="grown-cont grown-cont2 image-line-2">
              <h1>Why Buy Lab-Grown Diamond? </h1>
              <p>
                Mined diamonds are valuable for sure but at what cost? There are
                many active mines that supply blood diamonds- diamonds that are
                mined in war zones, even though the governments restrict blood
                diamonds officially; It is very easy to sell them through social
                media. Besides that, in the diamond mining industry, many
                laborers get injured and there is a high frequency of accidents
                while extracting rough diamond. And to be fair, the only option
                to put an end to all this is to switch your preference and buy
                lab grown diamonds instead.Lab-made diamonds also usher the
                following benefits over Natural diamonds:1. Cheaper 2. Ethically
                Sourced 3. Conflict-free 4. Easily available 5. High quality
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="ractangle-img ractangle-img2">
              <img
                src="assets/images/labgrown-page/curve-line.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="interesting-img interesting-img2">
              <img
                src="assets/images/labgrown-page/Interesting-Diamond-2.svg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row labgrown-block">
          <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="ractangle-img polygon-img spining">
              <img
                src="assets/images/labgrown-page/Polygon.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="interesting-img">
              <img
                src="assets/images/labgrown-page/Interesting-Diamond-3.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="dot-img ractangle-img2 dot-img2">
              <img
                src="assets/images/labgrown-page/curve-line.svg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-xs-12 flex-display">
            <div className="line-img">
              <img
                src="assets/images/labgrown-page/dot.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="grown-cont image-line-3">
              <h1>Do Lab-grown Diamonds prefer certification? </h1>
              <p>
                A Lab Grown Diamond is provided certification by IGI
                (International Gemological Institute). IGI is the global leader
                for jewelry assessment, with laboratories in many different
                parts of the world. The reports of IGI are considered to be an
                important source of authentication in almost all parts of the
                world. IGI follows the same procedure of testing as that used
                for natural diamonds.Even GIA also provides certification for
                lab created or manmade diamond since 2007.
              </p>
            </div>
          </div>
        </div>
        <div className="row labgrown-block">
          <div className="col-lg-6 col-sm-12 col-xs-12 flex-display flex-display2">
            <div className="grown-cont grown-cont2  image-line-4 ">
              <h1>
                Does the color of the Lab Grown Diamond fade away with time?{" "}
              </h1>
              <p>
                No, Lab grown diamonds never fade their color, neither do they
                become cloudy with time as they are made with the same substance
                the natural diamond is made. In fact, lab made diamonds are
                strong enough to stay in hydrochloric acid, rather it gains more
                shine, and by showering in hydrochloric acid the diamond
                cleanses itself.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="interesting-img interesting-img2">
              <img
                src="assets/images/labgrown-page/Interesting-Diamond-4.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="dot-img cvd-image spining">
              <img
                src="assets/images/labgrown-page/frame.svg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row labgrown-block">
          <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="interesting-img">
              <img
                src="assets/images/labgrown-page/Interesting-Diamond-5.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="dot-img">
              <img
                src="assets/images/labgrown-page/square.svg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-xs-12 flex-display">
            <div className="grown-cont image-line-5">
              <h1>Future of Lab-created Diamond </h1>
              <p>
                Celebrities like Meghan Markle and Emma Watson have promoted lab
                grown diamonds by adding them to their jewelry collection.
                Because lab diamonds are environmentally and humanitarian
                friendly. 70% of Millennial and Generation Z are shifting to lab
                grown diamond engagement rings instead of natural earth mined
                diamond. According to AWDC (Antwerp World Diamond Centre)
                report. Market of lab created diamonds have increased 15% to 20%
                in the year 2019. Aided by the widening price differential of
                lab-grown diamonds versus natural ones and campaigns that
                leveraged the “green” benefits of manufactured stones. Select
                jewelry designers and retailers are beginning to use lab-grown
                diamonds, signaling their acceptance and driving lab-grown
                jewelry sales.
              </p>
            </div>
          </div>
        </div>
        <div className="row labgrown-block">
          <div className="col-lg-6 col-sm-12 col-xs-12 flex-display flex-display2">
            <div className="grown-cont grown-cont2 image-line-6">
              <h1>How Long Does It Take to Grow a Diamond? </h1>
              <p>
                It takes 5-15 days to grow a 1.50-carat lab diamond, and a few
                months to grow a 3-carat diamond.If you are trying to grow a
                diamond any faster, the diamond crystal will fracture. Thus,
                there’s a physical regulation to how briskly you’ll grow
                diamonds
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="interesting-img interesting-img2">
              <img
                src="assets/images/labgrown-page/Interesting-Diamond-6.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="dot-img spining">
              <img
                src="assets/images/labgrown-page/frame.svg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row labgrown-block">
          <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="dot-img created">
              <img
                src="assets/images/labgrown-page/curve-line.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="interesting-img">
              <img
                src="assets/images/labgrown-page/Interesting-Diamond-7.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="dot-img ractangle-img2 dot-img2 curve-img">
              <img
                src="assets/images/labgrown-page/curve-line.svg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-xs-12 flex-display">
            <div className="grown-cont image-line-7">
              <h1>Lab Made diamond is as tough as born diamond.</h1>
              <p>
                It’s substantiated that lab-Made diamonds similarly possess 10
                on 10 mathematics on Mohs scale of hardship which is identified
                as a born diamond. verily, it’s a Human-manufactured diamond,
                but its plots cannot live undermined as a diamond, at least by
                the play of scientific discounts.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12">
            <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
              <span>Faq</span>
              <h1 className="cmn-section-titile cmn-color-title">
                Frequently Asked Questions
              </h1>
              <div className="cmn-diamond-below-title mx-auto">
                <img
                  src="assets/images/home/grey-small-diamod.png"
                  className="img-fluid"
                  alt="grey-small-diamod.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className=" row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>Lab-Grown diamond isn't a Real diamond.</span>
                      <i className="fa fa-plus"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    It’s a congress that the diamonds which are fabricated by
                    gent ought to breathe factitiously because presumably a
                    certifiable and congenitally chancing making cannot breathe
                    aped. By this, a lab-created diamond, like any dissimilar
                    gent – framed diamonds – moissanite and zirconia, won’t
                    exist a diamond in the honest feeling.
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>
                        Lab-Grown diamond has the even fundamentals as Natural
                        diamond.
                      </span>{" "}
                      <i className="fa fa-plus"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    With no distrustfulness lab, cropped diamond is fabricated
                    by the bastard. But the actuality is that a lab-cropped
                    diamond is fabricated with the coequal digit and the number
                    of clone dribbles as a born diamond. Accordingly, it carries
                    all the lots of its booby-trapped coequals – the coequal
                    chemical, ardor, strain, class as all right as the
                    adversity. Accordingly, it can exist held fair the equal as
                    a diamond.
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>
                        Lab - cultivated diamonds aren't as hard-bitten as born
                        ones.
                      </span>{" "}
                      <i className="fa fa-plus"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Chiefly, born diamonds are visible for their polish,
                    occurrence, freight, and stability. Diamond is one of the
                    toughened souls on this globe at all setup. A pertinacious
                    legend is that a lab-Made diamond can no way rival the
                    hardship of a born diamond.
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFourth"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>Lab Made diamond is as tough as born diamond.</span>{" "}
                      <i className="fa fa-plus"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseFourth"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    It’s substantiated that lab-Made diamonds similarly possess
                    10 on 10 mathematics on Mohs scale of hardship which is
                    identified as a born diamond. verily, it’s a
                    Human-manufactured diamond, but its plots cannot live
                    undermined as a diamond, at least by the play of scientific
                    discounts.
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFifth"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>Lab made diamonds have no Resale value.</span>{" "}
                      <i className="fa fa-plus"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseFifth"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Multifold jewelers alert clients that while dealing lab
                    cultured diamonds, their resale valuation won’t breathe all
                    right or fine. Because of the emergence of lab-cultured
                    diamonds, the call of the booby-trapped gravestone is in
                    sedate imminence, and jewelers can earn less lucre as
                    assimilated to that of a born diamond.
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseSixth"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>Lab-grown diamond has its own resale market.</span>{" "}
                      <i className="fa fa-plus"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseSixth"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    In all actuality, Lab developed jewels additionally have
                    decent resale esteem and an appropriate market for resale,
                    although you can sell it on eBay too. If you have the
                    testament and the precious stone is in acceptable condition,
                    you can resale the jewel effectively with the equivalent of
                    10-20% less sum as indicated by the interest of the market.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LabGrownDiamond;
