import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setListView,
  setGridView,
  setListBtnView,
  setGridBtnView,
} from "../../../redux/reducers/CommonSlice";
// import { setOrder } from "../../../redux/reducers/ProductSlice";
import $ from "jquery";
// import {
//   sortDiamondByAsc,
//   sortDiamondByDesc,
// } from "../../../helper/helperFunction";
import { setCaratSort } from "../../../redux/reducers/CommonSlice";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import { resetProductPageNum } from "../../../redux/reducers/ProductSlice";

function ChangeView() {
  const sort = useRef("ASC");
  // const [selected, setSelected] = useState("grid");
  const TotalRows = useSelector(
    (state) => state.productReducer.products.length
  );
  const { stockFilter, searchRemember } = useSelector(
    (state) => state.stockFilterReducer
  );
  // const apiProduct = useSelector((state) => state.productReducer.apiproducts);
  // const Loading = useSelector((state) => state.productReducer.loading);
  const dispatch = useDispatch();

  const sortProduct = () => {
    if (sort.current.value === "ASC") {
      // const sortProduct = Product && sortDiamondByAsc(Product);
      // dispatch(setOrder(sortProduct));
      dispatch(setCaratSort("ASC"));

      dispatch(
        addStockFilter({
          ...stockFilter,
          loadmore: false,
          PageNum: 1,
          OrderByType: "Weight asc",
        })
      );
      // dispatch(resetProduct());
      dispatch(resetProductPageNum());
    } else {
      // const sortProduct = Product && sortDiamondByDesc(Product);
      // dispatch(setOrder(sortProduct));
      dispatch(setCaratSort("DESC"));
      dispatch(
        addStockFilter({
          ...stockFilter,
          loadmore: false,
          PageNum: 1,
          OrderByType: "Weight desc",
        })
      );
      // dispatch(resetProduct());
      dispatch(resetProductPageNum());
    }
  };

  useEffect(() => {
    $(function () {
      $(".mobile-filter i").on("click", function (e) {
        $(".mobile-view-slide").toggleClass("mobile-view-toggle");
        $(".mobile-filter").toggleClass("circle-shadow");
      });
      $(".close_btn").on("click", function (e) {
        $(".mobile-view-slide").removeClass("mobile-view-toggle");
      });

      $(document).on("click", function (e) {
        var target = $(e.target);
        if (target.find(".mobile-filter").length === 1) {
          $(".mobile-view-slide").removeClass("mobile-view-toggle");
        }
      });
    });
    dispatch(setGridView("col-lg-4 col-md-6"));
  }, [dispatch]);
  useEffect(() => {
    $(".on").on("click", function () {
      if ($(".off").hasClass("sleep-mode-off")) {
        $(".off").removeClass("sleep-mode-off");

        $(this).addClass("sleep-mode-on");
      }
    });
    $(".off").on("click", function () {
      if ($(".on").hasClass("sleep-mode-on")) {
        $(".on").removeClass("sleep-mode-on");
        $(this).addClass("sleep-mode-off");
      }
    });
  }, []);

  return (
    <div className="row sort-row">
      <div className="col-lg-4 col-md-12">
        <div className="change-view-btn-wrapper">
          {/* <span>Change View:</span> */}
          <span className="change-view-btn mode-on-off-wrapper ">
            <div className="switch-mode">
              <span
                className="off btn sleep-mode-off grid-view "
                onClick={() => {
                  dispatch(setGridView("col-lg-4 col-md-6"));
                  dispatch(setGridBtnView(""));
                  //setSelected("grid");
                }}
              >
                <i className="fas fa-th"></i>
              </span>
              <span
                className="on btn large-view "
                onClick={() => {
                  dispatch(setListView("col-lg-12 col-md-12"));
                  dispatch(
                    setListBtnView("cart-like-detail-btn-tab-view-list ")
                  );
                  //setSelected("list");
                }}
              >
                <i className="far fa-list-ul"></i>
              </span>
            </div>

            {/* <button
              className={`large-view ${
                selected === "list" ? "iconselected" : ""
              }`}
              onClick={() => {
                dispatch(setListView("col-lg-12 col-md-12"));
                dispatch(setListBtnView("cart-like-detail-btn-tab-view-list "));
                setSelected("list");
              }}
            >
              <img src="assets/images/stock/list.svg" alt="" className="" />
            </button>
            <button
              className={`grid-view ${
                selected === "grid" ? "iconselected" : ""
              }`}
              onClick={() => {
                dispatch(setGridView("col-lg-4 col-md-6"));
                dispatch(setGridBtnView(""));
                setSelected("grid");
              }}
            >
              <img src="assets/images/stock/menu.svg" alt="" className="" />
            </button> */}
          </span>
        </div>
      </div>
      <div className="col-lg-8 col-md-12">
        <div className="sortby">
          <div className="d-md-none d-inline-block mobile-filter">
            <i className="far fa-filter"></i>
          </div>
          <div className="total">
            Total <span> {TotalRows}</span> Diamonds Found
          </div>
          <div className="sort-by-select-wrapper">
            <label htmlFor="Sort">Sort By:</label>
            <div className="sort-by-select-innner">
              <select ref={sort} onChange={sortProduct}>
                <option value="ASC">Carat Low To High</option>
                <option value="DESC">Carat High To Low</option>
              </select>
              <i className="arrow-select down"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeView;
