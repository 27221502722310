import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stockFilter: {
    shape: "ROUND",
    PageSize: 9,
    PageNum: 1,
    loadmore: false,
    OrderByType: "Weight asc",
  },
  isFilterSet: false,
  isFilterReset: false,
  searchRemember: false,
};

export const StockFilterSlice = createSlice({
  name: "stock_filter",
  initialState,
  reducers: {
    addStockFilter: (state, action) => {
      state.stockFilter = { ...state.stockFilter, ...action.payload };
      state.isFilterSet = true;
      state.isFilterReset = false;
    },
    removeStockFilter: (state, action) => {
      state.stockFilter = {
        shape: "ROUND",
        PageSize: 9,
        PageNum: 1,
        loadmore: false,
        OrderByType: "Weight asc",
      };
      state.isFilterSet = false;
      state.isFilterReset = true;
    },
    setSearchRemember: (state) => {
      state.searchRemember = true;
    },
    resetSearchRemember: (state) => {
      state.searchRemember = false;
    },
  },
});

export const {
  addStockFilter,
  removeStockFilter,
  setSearchRemember,
  resetSearchRemember,
} = StockFilterSlice.actions;
export default StockFilterSlice.reducer;
