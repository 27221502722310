import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SmallCartItem from "./SmallCartItem";
import EmptyCart from "./EmptyCart";
import { useSelector } from "react-redux";
import $ from "jquery";
import cartIcon from "../../assets/images/validationIcon/cart_icon.png";
function SmallCart() {
  let cart = useSelector((state) => state.productReducer.cart.items);
  let totalPrice = cart.reduce((total, product) => {
    total += parseFloat(product.Cost_Amt);
    return total;
  }, 0);

  // useEffect(() => {
  //   $(".dropdown-buttons").on("click", function () {
  //     $(".go-to-cart").toggleClass("shows1");
  //   });
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("click", function (e) {
  //     if (document.getElementById("small-cart").contains(e.target)) {
  //       // Clicked in box
  //       console.log("click in box");
  //     } else {
  //       // Clicked outside the box
  //       console.log("click outside the box");
  //       document.getElementById("small-cart").style.display = "none";
  //     }
  //   });
  // }, []);

  return (
    <div className="cart">
      <div className="dropdown">
        <button className="dropdown-buttons" type="button">
          <span className="cart-count-image-wrapper">
            <i className="fas fa fa-shopping-cart fa-lg"></i>
            <span className="cart-basket d-flex align-items-center justify-content-center">
              {cart.length}
            </span>
            {/* <img
              src={cartIcon}
              alt="account-icon"
              className="img-fluid"
              width="45px"
            />
            <span className="badge badge-secondary">{cart.length}</span>
            <span className="count">{cart.length}</span> */}
          </span>
        </button>
        <div className="go-to-cart">
          <div className="mycart-wrapper">
            <div className="cart-header">
              <span>My Cart</span>
              {/* <span className="float-right mr-3 cartclose">
                <i className="fa fa-times" aria-hidden="true"></i>
              </span> */}
            </div>
            <div className="purchase-items-container cmn-scroll cmn-scroll2">
              {cart.length ? (
                cart.map((product) => {
                  return (
                    <SmallCartItem product={product} key={product.Stock_ID} />
                  );
                })
              ) : (
                <h4 className="text-center" style={{ color: "black" }}>
                  Your cart is empty.
                </h4>
                //<EmptyCart />
              )}
            </div>
            {cart.length ? (
              <>
                <div className="mycart-total">
                  <span>Total</span>
                  <span>${totalPrice.toFixed(2)}</span>
                </div>
                <div className="goto-cart-btn">
                  <Link to="/cart">Go to cart</Link>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmallCart;
