import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { DeviceType, BackEndClientId } from "../../constant";
import {
  confirmOrder,
  getCartSummary,
  getTaxDetail,
} from "../../redux/reducers/CheckoutSlice";
import {
  resetorderPlaced,
  resetOrderPlaceError,
  resetOrderPlaceMessage,
} from "../../redux/reducers/CheckoutSlice";
import { getMyCart } from "../../redux/reducers/ProductSlice";
import useNotify from "../../hooks/useNotify";

function CartSummary() {
  const Notify = useNotify();
  const dispatch = useDispatch();
  const history = useHistory();
  let cart = useSelector((state) => state.productReducer.cart.items);
  const [taxDetail, setTaxDetail] = useState([]);
  /*   const [taxDetail, setTaxDetail] = useState({
    Tax_Id: 0,
    Charge_Id: 0,
    Percentage: 0,
    Amount: 0,
    Gross_Amt: 0,
    Total_Amt: 0,
    Final_Amt: 0,
    Ledger_Id: 0,
  }); */
  const [isCheck, setIsCheck] = useState(false);
  let totalPrice = cart.reduce((total, product) => {
    total += parseFloat(product.Cost_Amt);
    return total;
  }, 0);

  const [countDiscount, setCountDiscount] = useState([]);
  const [finalAmount, setFinalAmount] = useState(0);

  // let finalAmount = (totalPrice - discountAmount).toFixed(2);
  let cartSummary = useSelector(
    (state) => state.CheckoutReducer.checkout.cartSummary
  );
  let taxSummary = useSelector(
    (state) => state.CheckoutReducer.checkout.taxSummary
  );
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  const { orderPlaced, orderPlaceMessage, orderPlaceError } = useSelector(
    (state) => state.CheckoutReducer.checkout
  );

  useEffect(() => {
    UserID &&
      dispatch(getCartSummary({ Customer_ID: UserID, BackEndClientId: 3 }));
    UserID && dispatch(getTaxDetail({ CustID: UserID, BackEndClientId: 3 }));
  }, [UserID]);

  useEffect(() => {
    orderPlaceMessage && Notify(orderPlaceMessage, "success");
    orderPlaceMessage && dispatch(resetOrderPlaceMessage());
    UserID && orderPlaceMessage && dispatch(getMyCart({ UserID }));
  }, [orderPlaceMessage]);

  // when any item added to cart after login then this function is called after adding item to cart
  useEffect(() => {
    orderPlaceError && Notify(orderPlaceError, "error");
    orderPlaceError && dispatch(resetOrderPlaceError());
  }, [orderPlaceError]);

  useEffect(() => {
    let dummyData = [];
    let dummyCountDetail = [];
    let finalTotalAmt = totalPrice;
    let dummyList =
      taxSummary.length >= 0 &&
      taxSummary?.map((item) => {
        let disChargeName = "";
        let amountName = "";
        let disper = 0;
        let disamt = 0;
        let per = 0;
        if (
          item.Is_Type === "Per" ||
          item.Is_Type === "per" ||
          item.Is_Type === "Percentage" ||
          item.Is_Type === "percentage"
        ) {
          disChargeName = item.Charge_Name;
          let discount = parseFloat(
            ((totalPrice * item.Percentage) / 100).toFixed(2)
          );
          per = item.Percentage;
          // discountPer += ((totalPrice * percentage) / 100).toFixed(2);
          disper = discount;
          if (item.AddLess_Type === "Add") {
            finalTotalAmt = finalTotalAmt + parseFloat(discount);
          } else {
            finalTotalAmt = finalTotalAmt - parseFloat(discount);
          }
          dummyData.push({
            Tax_Id: 0,
            Charge_Id: item.Charge_Id,
            Percentage: item.Is_Type !== "Amt" ? item.Percentage : 0,
            Amount: item.Is_Type === "Amt" ? item.Percentage : 0,
            Gross_Amt: Number(parseFloat(totalPrice).toFixed(2)),
            Total_Amt: discount,
            Final_Amt: discount,
            Ledger_Id: item.Ledger_Id ? item.Ledger_Id : 0,
          });
          dummyCountDetail.push({
            discountPer: discount,
            discountAmt: 0,
            finalAmount: 0,
            discountChargeName: disChargeName,
            amountChargeName: "",
            isType: item.AddLess_Type,
            percentage: per,
            totalAmount: totalPrice,
          });
        } else {
          amountName = item.Charge_Name;
          // disper += item.Percentage;
          disamt = item.Percentage;
          if (item.AddLess_Type === "Add") {
            finalTotalAmt = finalTotalAmt + item.Percentage;
          } else {
            finalTotalAmt = finalTotalAmt - item.Percentage;
          }
          dummyData.push({
            Tax_Id: 1,
            Charge_Id: item.Charge_Id,
            Percentage: item.Is_Type !== "Amt" ? item.Percentage : 0,
            Amount: item.Is_Type === "Amt" ? item.Percentage : 0,
            Gross_Amt: Number(parseFloat(totalPrice).toFixed(2)),
            Total_Amt: item.Percentage,
            Final_Amt: item.Percentage,
            Ledger_Id: item.Ledger_Id ? item.Ledger_Id : 0,
          });
          dummyCountDetail.push({
            discountPer: 0,
            discountAmt: disamt,
            finalAmount: 0,
            discountChargeName: "",
            amountChargeName: amountName,
            isType: item.AddLess_Type,
            percentage: per,
            totalAmount: finalTotalAmt,
          });
        }
        // setCountDiscount({
        //   ...countDiscount,
        //   isType: item.AddLess_Type,
        //   discountAmt: disamt.toFixed(2),
        //   discountPer: disper.toFixed(2),
        //   finalAmount: finalTotalAmt.toFixed(2),
        //   totalAmount: 0,
        //   discountChargeName: disChargeName,
        //   amountChargeName: amountName,
        // });
      });

    setTaxDetail(dummyData);
    setCountDiscount(dummyCountDetail);
    setFinalAmount(finalTotalAmt.toFixed(2));
  }, [taxSummary, cart]);
  //confirm order dispatch

  // const placeOrder = () => {
  //   const StockIDs = cart.map((item) => item.Stock_ID);
  //   const confirmData = {
  //     StockIDs: StockIDs.toString(),
  //     CustomerID: UserID,
  //     SellerId: 0,
  //     DeviceType: DeviceType,
  //     BackEndClientId: BackEndClientId,
  //     Remark: "",
  //   };

  //   dispatch(confirmOrder(confirmData));

  //   // dispatch(resetorderPlaced(false));
  // };
  console.log("countDiscount", countDiscount);
  console.log("taxDetail", taxDetail);
  const placeOrder = () => {
    const StockIDs = cart.map((item) => item.Stock_ID);
    const confirmData = {
      StockIDs: StockIDs.toString(),
      CustomerID: UserID,
      SellerId: 0,
      DeviceType: DeviceType,
      BackEndClientId: BackEndClientId,
      Remark: "",
      Total_Pcs: cart.length,
      Total_Amt: Number(parseFloat(finalAmount)),
      Shipping_Amt: 0,
      _TaxDetails: taxDetail,
    };

    dispatch(confirmOrder(confirmData));

    // dispatch(resetorderPlaced(false));
  };

  useEffect(() => {
    orderPlaced && history.replace("/success");
  }, [orderPlaced]);

  return (
    <>
      <div className="cart-summary-box">
        <div className="cart-title">
          <h5>Cart Summary</h5>
        </div>
        <div className="cart-box">
          <ul>
            <li>
              <span>Shipping fee*</span>
              <span>
                {/* {cart.every((item) => item.Location !== "IND")
								? "Free"
								: "150 USD"} */}
                Free
              </span>
            </li>
            <li>
              <span>Tax</span>
              {/* <span>${UserID ? cartSummary.SumOfCharges : 0}</span> */}
              <span>TBA</span>
            </li>
            <li>
              <span className="tax_span">
                {" "}
                tax will be applied according to the country of purchase
              </span>
            </li>
            <li>
              <span>Actual Amount</span>
              <span>${totalPrice.toFixed(2)}</span>
            </li>
            {countDiscount.map((item) => {
              return (
                <li>
                  <span>
                    {item.discountChargeName !== ""
                      ? item.discountChargeName
                      : item.amountChargeName}{" "}
                    {item.percentage !== 0
                      ? " (" + item.percentage + "%" + ")"
                      : ""}
                  </span>
                  <span>
                    ${item.isType === "Less" ? " -" : ""}
                    {item.discountPer !== 0
                      ? item.discountPer
                      : item.discountAmt}
                  </span>
                </li>
              );
            })}
            {/* <li>
              <span>{countDiscount.discountChargeName} </span>
              <span>${countDiscount.discountPer}</span>
            </li>
            {countDiscount.discountAmt !== 0 && (
              <li>
                <span>{countDiscount.amountChargeName}</span>
                <span>${countDiscount.discountAmt}</span>
              </li>
            )} */}
            <li className="carttotal">
              <span>Total</span>
              {/* <span>
              $
              {UserID
                ? cart.every((item) => item.Location !== "IND")
                  ? cartSummary.Total_WebOrder_Amt_With_Charges
                  : cartSummary.Total_WebOrder_Amt_With_Charges + 150
                : cart.every((item) => item.Location !== "IND")
                ? totalPrice.toFixed(2)
                : parseFloat(totalPrice.toFixed(2)) + 150}
            </span> */}
              <span>${finalAmount}</span>
            </li>
          </ul>
        </div>
        <div className="return_check">
          <input
            type="checkbox"
            id="return_exchange"
            name="return_exchange"
            onClick={() => {
              setIsCheck(!isCheck);
            }}
          />
          <label htmlFor="return_exchange">
            I have read and agree to the
            <Link
              className="active-link"
              target="_blank"
              to="/shippinginformation"
            >
              {" "}
              Return & Exchange
            </Link>
          </label>
        </div>
        <div className="cart-button">
          {/* <Link to="/checkout" className="cart-btn">
          Proceed To Checkout
        </Link> */}

          {UserID && (
            <button
              onClick={placeOrder}
              className="cart-btn"
              disabled={!isCheck}
            >
              Confirm Order
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default CartSummary;
