import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import HeroContent from "./HeroContent";
import HeroShape from "./HeroShape";
import Faq from "./Faq";
function Aboutus() {
  return (
    <Layout>
      <Breadcrumb pagetitle="About Us" />
      <HeroContent />
      <HeroShape />
      <Faq />
    </Layout>
  );
}

export default Aboutus;
