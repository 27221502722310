import React from "react";
import Layout from "../../../Layout";

function Index() {
  return (
    <Layout>
      <section className="lab-grown-dimaond-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
                <h1 className="cmn-section-titile cmn-color-title">
                  Are CVD Diamonds Real Diamonds?
                </h1>
                <div className="cmn-diamond-below-title mx-auto">
                  <img
                    src="assets/images/home/grey-small-diamod.png"
                    className="img-fluid"
                    alt="grey-small-diamod.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmn-content-wrapper">
                {/* <h2>What Is CVD Diamond?</h2> */}
                <ul>
                  <li>
                    They may be called "synthetic," but don't let the term fool
                    you. CVD diamonds look exactly like so-called "natural"
                    diamonds. Everything, from their internal atomic structure
                    to the way they glitter on your finger. In fact, even a
                    trained jeweler cannot identify the difference.
                  </li>
                  <li>
                    In 2018, the FTC ruled that synthetic diamonds and natural
                    diamonds are the same thing for all intents and purposes:
                    100% a diamond! Manmade diamonds and natural diamonds are
                    like identical brothers. Both have crystallized carbon- 10
                    on 10 in Mohs scale of hardness, the same method of grading
                    is applied, the similar shine and reflection of light
                    elucidation- so much so that no one can state the difference
                    between a lab diamond and a natural diamond with unaided
                    eyes.
                  </li>
                </ul>
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;
