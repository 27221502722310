import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FetchApi, showPassword } from "../../helper/helperFunction";
import useNotify from "../../hooks/useNotify";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoginInfo } from "../../redux/reducers/UserSlice";
import { addToCart, getMyCart } from "../../redux/reducers/ProductSlice";
import { resetorderPlaced } from "../../redux/reducers/CheckoutSlice";

import { Domain } from "../../constant";
const signinSchema = Yup.object().shape({
  Username: Yup.string().email("Invalid email").required("Required"),
  Password: Yup.string().required("Required"),
});
function SignIn() {
  let cartdata = JSON.parse(localStorage.getItem("localcart"));
  let { isItemAdded } = useSelector((state) => state.productReducer.cart);

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const Notify = useNotify();
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      const passwordEye = document.getElementById("passwordeye");
      const passworField = document.getElementById("Password");
      passwordEye.addEventListener("click", () => {
        showPassword(passworField, passwordEye);
      });
    }

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <section className="stock-container bg-cmn-property">
      <div className="container container5">
        <div className="cmn-diamond-with-title-wrapper signin-wrappper">
          <div className="cmn-shadow-bg">
            <div className="cmn-title-form">
              <span>Sign In</span>
            </div>
            <div className="billing-form">
              <Formik
                initialValues={{
                  Username: "",
                  Password: "",
                }}
                validationSchema={signinSchema}
                onSubmit={async (values, { resetForm }) => {
                  //   console.log("Formik is submited!");
                  try {
                    setLoader(true);
                    const response = await FetchApi(
                      `${Domain}/mobile-api/login`,
                      "POST",
                      values
                    );
                    const res = await response.json();
                    if (res.IsSuccess) {
                      Notify("Sign in successfully!", "success");
                      dispatch(setLoginInfo(res.Result));
                      let UserID = res.Result.UserID;
                      if (cartdata && UserID) {
                        //prepared stockids to save in cart database

                        let StockIDs = cartdata.filter(
                          (product) => !product.hasOwnProperty("Cart_ID")
                        );

                        StockIDs = StockIDs.map((product) => product.Stock_ID);
                        dispatch(resetorderPlaced());
                        dispatch(
                          addToCart({
                            CustomerID: UserID,
                            StockIDs: StockIDs.toString(),
                          })
                        );
                        if (isItemAdded)
                          dispatch(
                            getMyCart({
                              UserID,
                            })
                          );
                      }
                      resetForm();
                      setLoader(false);

                      history.push("/cart");
                    } else {
                      Notify(res.Message, "error");
                      setLoader(false);
                    }
                  } catch (error) {
                    Notify(error, "error");
                    setLoader(false);
                  }
                }}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <div className="checkout-input-box">
                      <div className="billing-input-box sign-in-input">
                        <div className="input-box ">
                          <Field
                            type="email"
                            placeholder="Enter email address"
                            name="Username"
                            autoComplete="off"
                            onChange={handleChange}
                            values={values.Username}
                            className={
                              errors.Username && touched.Username
                                ? "error-border"
                                : !errors.Username && touched.Username
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="billing-input-box sign-in-input">
                        <div className="input-box">
                          <Field
                            id="Password"
                            type="password"
                            placeholder="Enter password"
                            name="Password"
                            autoComplete="off"
                            onChange={handleChange}
                            values={values.Password}
                            className={
                              errors.Password && touched.Password
                                ? "error-border"
                                : !errors.Password && touched.Password
                                ? ""
                                : ""
                            }
                          />
                          <span
                            id="passwordeye"
                            className="fa fa-fw fa-eye password-eye"
                          ></span>
                        </div>
                      </div>

                      <div className="forgote-password">
                        <span className="text-right">
                          <Link
                            to="/forgotpass"
                            style={{
                              color: "#005fc1",
                              textDecoration: "underline",
                            }}
                          >
                            <b>Forgot password?</b>
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div className="register-btn signin-btn">
                      <button type="submit">
                        Sign In
                        {loader && (
                          <i className="fa fa-spinner fa-spin ml-2"> </i>
                        )}
                      </button>
                      <div className="text-center">
                        <span className="create-account-link">
                          <span style={{ color: "#787878", fontSize: "14px" }}>
                            New here?
                          </span>
                          &nbsp;
                          <Link
                            to="/signup"
                            style={{
                              color: "#005fc1",
                              textDecoration: "underline",
                            }}
                          >
                            <b>Create an Account</b>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
