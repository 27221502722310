import React from "react";

function HeroContent() {
  return (
    <section className="hero-about">
      <div className="container">
        <div className="row">
          <div className="col-lg-5  col-md-5 col-sm-12 col-xs-12">
            <div className="about-img" data-aos="fade-right">
              <img
                src="assets/images/about/about-img.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 flex-display">
            <div>
              <div
                className="cmn-diamond-with-title-wrapper ml-different-title"
                data-aos="fade-left"
              >
                <h1 className="cmn-section-titile cmn-color-title title-margin">
                  About Us
                </h1>
                <div className="cmn-diamond-below-title">
                  <img
                    src="assets/images/home/grey-small-diamod.png"
                    className="img-fluid"
                    alt="grey-small-diamod.png"
                  />
                </div>
              </div>
              <h3 className="cmn-sub-title">
                We are leading brand with the collection of Lab Grown diamonds
              </h3>
              <p className="cmn-peragraph">
                At Parin Gems, we create lab grown diamonds using low energy
                process with the aim to keep the earth safe from mining impact
                and presenting a diamond which is Eco Friendly, Clean & Green.
                We've began the manufacturing of the lab grown diamonds with
                several aims, such as; to fulfill the increasing demand of
                diamonds in the jewelry industry, to Establish Effortless supply
                chain management with our customers to meet the industry demand,
                and to steer the industry in providing the best-in-className Lab
                Grown Diamonds for the fine and economic jewelry industry.
              </p>
              {/* <div className="cmn-learnmore-btn-grey">
                <a href="">Learn More</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroContent;
