import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObj } from "../../helper/helperFunction";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { FetchApi } from "../../helper/helperFunction";
import useNotify from "../../hooks/useNotify";
import { useHistory } from "react-router-dom";
// import { Link, useHistory } from "react-router-dom";
import {
  setCheckoutData,
  placeOrder,
  saveAddressFromOrder,
} from "../../redux/reducers/CheckoutSlice";
import {
  getCountry,
  getBillState,
  getShipState,
  getCityList,
  getShipCityList,
} from "../../redux/reducers/CommonSlice";
import { getUserAddresses } from "../../redux/reducers/CommonSlice";
import $ from "jquery";
const promise = loadStripe("pk_test_ehCPVY9vB4syk6fmCss0WEMd");

function BillingDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const submitBtn = useRef();
  const billCountrySelect = useRef();
  const billStateSelect = useRef();
  const billCitySelect = useRef();
  const shipCountrySelect = useRef();
  const shipStateSelect = useRef();
  const shipCitySelect = useRef();
  const Billing_Fname_Ele = useRef();

  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  let ReduxCheckoutData = useSelector(
    (state) => state.CheckoutReducer.checkout.data
  );
  const country_list = useSelector((state) => state.commonReducer.country_list);
  const { bill_state_list, ship_state_list, bill_city_list, ship_city_list } =
    useSelector((state) => state.commonReducer);
  const user_addresses_list = useSelector(
    (state) => state.commonReducer.user_addresses_list
  );
  const { data, orderPlaced } = useSelector(
    (state) => state.CheckoutReducer.checkout
  );

  useEffect(() => {
    data.Payment_TransactionId && dispatch(placeOrder(data));
  }, [data.Payment_TransactionId]);

  const [sameShippingAddress, setSameShippingAddress] = useState(true);
  const [existingBillingAddress, setExistingBillingAddress] = useState(true);
  const [existingShippingAddress, setExistingShippingAddress] = useState(true);
  const [paymentOptionShow, setPaymentOptionShow] = useState(false);
  const [billCountryChange, setBillCountryChange] = useState(0);
  const [shipCountryChange, setShipCountryChange] = useState(0);
  const [billStateChange, setBillStateChange] = useState(0);
  const [shipStateChange, setShipStateChange] = useState(0);
  const [Billing_Address_Selected, setBilling_Address_Selected] = useState({});
  const [Shipping_Address_Selected, setShipping_Address_Selected] = useState(
    {}
  );
  const [formikData, setFormikData] = useState(null);
  const [formikErrors, setFormikErrors] = useState(null);
  const BillingAddressChange = (e) => {
    let filterAddress = user_addresses_list.filter(
      (item) => item.Id == e.target.value
    );

    setBilling_Address_Selected(...filterAddress);
  };

  const ShippingAddressChange = (e) => {
    let filterAddress = user_addresses_list.filter(
      (item) => item.Id == e.target.value
    );

    setShipping_Address_Selected(...filterAddress);
  };

  let activeColor = !sameShippingAddress ? "#005FC1" : "";
  let activeColor1 = sameShippingAddress ? "#005FC1" : "";
  let activeColor2 = existingBillingAddress ? "#005FC1" : "";
  let activeColor3 = !existingBillingAddress ? "#005FC1" : "";
  let activeColor4 = existingShippingAddress ? "#005FC1" : "";
  let activeColor5 = !existingShippingAddress ? "#005FC1" : "";
  // const [loader, setLoader] = useState(false);
  // const history = useHistory();
  const Notify = useNotify();

  useEffect(() => {
    dispatch(getCountry());
    dispatch(getUserAddresses({ CustID: UserID }));
  }, []);

  useEffect(() => {
    dispatch(getBillState(billCountryChange));
  }, [billCountryChange]);

  useEffect(() => {
    dispatch(getShipState(shipCountryChange));
  }, [shipCountryChange]);

  useEffect(() => {
    dispatch(
      getCityList({ CountryID: billCountryChange, StateID: billStateChange })
    );
  }, [billStateChange, billCountryChange]);

  useEffect(() => {
    dispatch(
      getShipCityList({
        CountryID: shipCountryChange,
        StateID: shipStateChange,
      })
    );
  }, [shipStateChange, shipCountryChange]);

  const BillingSchema = Yup.object().shape({
    Billing_Fname: Yup.string().required("Required"),
    Billing_Email: Yup.string().email().required("Required"),
    Billing_Phone: Yup.number().required("Required"),
    Billing_AddressLine1: Yup.string().required("Required"),
    Billing_Country_ID: Yup.number().required("Required").min(1),
    Billing_State: Yup.number().required("Required").min(1),
    Billing_City: Yup.number().required("Required").min(1),
    Billing_Zip_Code: Yup.number().required("Required"),
    Shipping_Fname: Yup.string().required("Required"),
    Shipping_Email: Yup.string().required("Required").email(),
    Shipping_Phone: Yup.number().required("Required"),
    Shipping_AddressLine1: Yup.string().required("Required"),
    Shipping_Country_ID: Yup.number().required("Required").min(1),
    Shipping_State: Yup.number().required("Required").min(1),
    Shipping_City: Yup.number().required("Required").min(1),
    Shipping_Zip_Code: Yup.number().required("Required"),
  });

  const handleNext = () => {
    let selectedBillAdr = {};
    let selectedShipAdr = {};
    let checkOutObj = {};
    checkOutObj.Customer_ID = UserID;
    let errorFound = false;

    //if billing address selected from existing
    if (existingBillingAddress) {
      selectedBillAdr = Billing_Address_Selected;
      checkOutObj.Billing_Name = selectedBillAdr.Name;
      checkOutObj.Billing_Email = selectedBillAdr.Email;
      checkOutObj.Billing_Phone = selectedBillAdr.PhoneNo;
      checkOutObj.Billing_AddressLine1 = selectedBillAdr.Address1;
      checkOutObj.Billing_AddressLine2 = selectedBillAdr.Address2;
      checkOutObj.Billing_City = selectedBillAdr.CityID;
      checkOutObj.Billing_State = selectedBillAdr.StateID;
      checkOutObj.Billing_Country_ID = selectedBillAdr.CountryID;
      checkOutObj.Billing_CityName = selectedBillAdr.CityName;
      checkOutObj.Billing_StateName = selectedBillAdr.StateName;
      checkOutObj.Billing_CountryName = selectedBillAdr.CountryName;
      checkOutObj.Billing_Zip_Code = selectedBillAdr.ZipCode;
      // if shipping address is same as billing
      if (isEmptyObj(Billing_Address_Selected)) {
        Notify("Please select billing address!", "error");
        return;
      }
      if (sameShippingAddress) {
        selectedShipAdr = selectedBillAdr;
        checkOutObj.Shipping_Name = selectedShipAdr.Name;
        checkOutObj.Shipping_Email = selectedShipAdr.Email;
        checkOutObj.Shipping_Phone = selectedShipAdr.PhoneNo;
        checkOutObj.Shipping_AddressLine1 = selectedShipAdr.Address1;
        checkOutObj.Shipping_AddressLine2 = selectedShipAdr.Address2;
        checkOutObj.Shipping_City = selectedShipAdr.CityID;
        checkOutObj.Shipping_State = selectedShipAdr.StateID;
        checkOutObj.Shipping_Country_ID = selectedShipAdr.CountryID;
        checkOutObj.Shipping_Zip_Code = selectedShipAdr.ZipCode;
        checkOutObj.Shipping_CityName = selectedShipAdr.CityName;
        checkOutObj.Shipping_StateName = selectedShipAdr.StateName;
        checkOutObj.Shipping_CountryName = selectedShipAdr.CountryName;
        //console.log("same as adr", checkOutObj);
      }
    } else {
      //add new billing address

      let ErrorArray = Object.keys(formikErrors);
      let validError = ErrorArray.find((item) => item.includes("Billing_"));
      //  console.log(formikData, "validError");
      if (!validError) {
        checkOutObj.Billing_Name =
          formikData.Billing_Fname + " " + formikData.Billing_Lname;
        checkOutObj.Billing_Email = formikData.Billing_Email;
        checkOutObj.Billing_Phone = formikData.Billing_Phone;
        checkOutObj.Billing_AddressLine1 = formikData.Billing_AddressLine1;
        checkOutObj.Billing_AddressLine2 = formikData.Billing_AddressLine2;
        checkOutObj.Billing_City = formikData.Billing_City;
        checkOutObj.Billing_State = formikData.Billing_State;
        checkOutObj.Billing_Country_ID = formikData.Billing_Country_ID;
        checkOutObj.Billing_CityName =
          billCitySelect.current.selectedOptions[0].text;
        checkOutObj.Billing_StateName =
          billStateSelect.current.selectedOptions[0].text;
        checkOutObj.Billing_CountryName =
          billCountrySelect.current.selectedOptions[0].text;
        checkOutObj.Billing_Zip_Code = formikData.Billing_Zip_Code;
        let addNewAddress = {};
        addNewAddress.Cust_ID = UserID;
        addNewAddress.Name =
          formikData.Billing_Fname + " " + formikData.Billing_Lname;
        addNewAddress.Email = formikData.Billing_Email;
        addNewAddress.PhoneNo = formikData.Billing_Phone;
        addNewAddress.Address1 = formikData.Billing_AddressLine1;
        addNewAddress.Address2 = formikData.Billing_AddressLine2;
        addNewAddress.CityID = formikData.Billing_City;
        addNewAddress.StateID = formikData.Billing_State;
        addNewAddress.CountryID = formikData.Billing_Country_ID;
        addNewAddress.ZipCode = formikData.Billing_Zip_Code;

        dispatch(saveAddressFromOrder(addNewAddress));

        if (sameShippingAddress) {
          checkOutObj.Shipping_Name =
            formikData.Billing_Fname + " " + formikData.Billing_Lname;
          checkOutObj.Shipping_Email = formikData.Billing_Email;
          checkOutObj.Shipping_Phone = formikData.Billing_Phone;
          checkOutObj.Shipping_AddressLine1 = formikData.Billing_AddressLine1;
          checkOutObj.Shipping_AddressLine2 = formikData.Billing_AddressLine2;
          checkOutObj.Shipping_City = formikData.Billing_City;
          checkOutObj.Shipping_State = formikData.Billin_State;
          checkOutObj.Shipping_Country_ID = formikData.Billing_Country_ID;
          checkOutObj.Shipping_CityName =
            billCitySelect.current.selectedOptions[0].text;
          checkOutObj.Shipping_StateName =
            billStateSelect.current.selectedOptions[0].text;
          checkOutObj.Shipping_CountryName =
            billCountrySelect.current.selectedOptions[0].text;
          checkOutObj.Shipping_Zip_Code = formikData.Billing_Zip_Code;
        }
      } else {
        errorFound = true;
      }
    }
    //if shipping address selected from existing
    if (existingShippingAddress && !sameShippingAddress) {
      selectedShipAdr = Shipping_Address_Selected;
      checkOutObj.Shipping_Name = selectedShipAdr.Name;
      checkOutObj.Shipping_Email = selectedShipAdr.Email;
      checkOutObj.Shipping_Phone = selectedShipAdr.PhoneNo;
      checkOutObj.Shipping_AddressLine1 = selectedShipAdr.Address1;
      checkOutObj.Shipping_AddressLine2 = selectedShipAdr.Address2;
      checkOutObj.Shipping_City = selectedShipAdr.CityID;
      checkOutObj.Shipping_State = selectedShipAdr.StateID;
      checkOutObj.Shipping_Country_ID = selectedShipAdr.CountryID;
      checkOutObj.Shipping_Zip_Code = selectedShipAdr.ZipCode;

      if (isEmptyObj(Shipping_Address_Selected)) {
        Notify("Please select shipping address!", "error");
        return;
      }
    } else {
      //add new shipping address
      if (!sameShippingAddress) {
        let ErrorArray = Object.keys(formikErrors);
        let validError = ErrorArray.find((item) => item.includes("Shipping_"));
        if (!validError) {
          checkOutObj.Shipping_Name =
            formikData.Shipping_Fname + " " + formikData.Shipping_Lname;
          checkOutObj.Shipping_Email = formikData.Shipping_Email;
          checkOutObj.Shipping_Phone = formikData.Shipping_Phone;
          checkOutObj.Shipping_AddressLine1 = formikData.Shipping_AddressLine1;
          checkOutObj.Shipping_AddressLine2 = formikData.Shipping_AddressLine2;
          checkOutObj.Shipping_City = formikData.Shipping_City;
          checkOutObj.Shipping_State = formikData.Shipping_State;
          checkOutObj.Shipping_Country_ID = formikData.Shipping_Country_ID;
          checkOutObj.Shipping_Zip_Code = formikData.Shipping_Zip_Code;
          checkOutObj.Shipping_CityName =
            shipCitySelect.current.selectedOptions[0].text;
          checkOutObj.Shipping_StateName =
            shipStateSelect.current.selectedOptions[0].text;
          checkOutObj.Shipping_CountryName =
            shipCountrySelect.current.selectedOptions[0].text;
          let addNewAddress = {};
          addNewAddress.Cust_ID = UserID;
          addNewAddress.Name =
            formikData.Shipping_Fname + " " + formikData.Shipping_Lname;
          addNewAddress.Email = formikData.Shipping_Email;
          addNewAddress.PhoneNo = formikData.Shipping_Phone;
          addNewAddress.Address1 = formikData.Shipping_AddressLine1;
          addNewAddress.Address2 = formikData.Shipping_AddressLine2;
          addNewAddress.CityID = formikData.Shipping_City;
          addNewAddress.StateID = formikData.Shipping_State;
          addNewAddress.CountryID = formikData.Shipping_Country_ID;
          addNewAddress.ZipCode = formikData.Shipping_Zip_Code;

          dispatch(saveAddressFromOrder(addNewAddress));
        } else {
          errorFound = true;
        }
      }
    }
    //console.log(errorFound, "errorFound");
    if (!errorFound) {
      setPaymentOptionShow(true);
      //console.log(checkOutObj, "checkOutObj");
      dispatch(setCheckoutData(checkOutObj));
    }
  };

  useEffect(() => {
    orderPlaced && history.replace("/success");
  }, [orderPlaced]);

  const setBillFocus = () => {
    //.current.click();
    // Billing_Fname_Ele.current.focus();
    // document.getElementById("Billing_Fname") &&
    //   document.getElementById("Billing_Fname").focus();
  };

  useEffect(() => {
    document.getElementById("Billing_Fname") &&
      document.getElementById("Billing_Fname").focus();
  }, [existingBillingAddress]);

  useEffect(() => {
    document.getElementById("Shipping_Fname") &&
      document.getElementById("Shipping_Fname").focus();
  }, [existingShippingAddress]);
  return (
    <>
      <div
        className="cart-summary-box cart-summary-box2"
        style={{ display: paymentOptionShow ? "none" : "block" }}
      >
        <div className="cart-title">
          <h5>Mail Details</h5>
        </div>
        <div className="checkout-input-box-exisitng">
          <div className="cart-title">
            <div className="billing-input-box billing-input-box2">
              <div
                className="input-box radio-input"
                onClick={() => setExistingBillingAddress(true)}
              >
                <input
                  type="radio"
                  name="tab"
                  value="igotnone"
                  checked={existingBillingAddress}
                />
                <label style={{ color: activeColor2 }}>
                  Existing addresses
                </label>
              </div>
              <div
                className="input-box radio-input"
                onClick={() => {
                  setExistingBillingAddress(false);
                  setBillFocus();
                }}
              >
                <input
                  type="radio"
                  name="tab"
                  value="igottwo"
                  checked={!existingBillingAddress}
                />

                <label htmlFor="tab" style={{ color: activeColor3 }}>
                  Add new address
                </label>
              </div>
            </div>
          </div>
          <div
            className="billing-input-box flex-column"
            style={{ display: !existingBillingAddress && "none" }}
          >
            <div className="input-box exisiting-address-dropdow">
              <label>
                Select billing address<span className="red-star">*</span>
              </label>
              <select
                name="Billing_Address_Box"
                onChange={BillingAddressChange}
              >
                <option value="">---Select Address---</option>
                {user_addresses_list.map((item) => {
                  let address = `${item.Name} (${item.CityName} - ${item.ZipCode}) `;
                  return <option value={item.Id}>{address}</option>;
                })}
              </select>
            </div>
            {!isEmptyObj(Billing_Address_Selected) && (
              <div className="existing-address-after-select">
                <div className="existing-address-after-select-inner">
                  <label>{Billing_Address_Selected.Name}</label>
                  <div>{Billing_Address_Selected.Address1},</div>
                  <div>
                    {Billing_Address_Selected.Address2}
                    {Billing_Address_Selected.Address2 && ","}
                  </div>
                  <div>
                    {Billing_Address_Selected.CityName},
                    {Billing_Address_Selected.StateName},
                    {Billing_Address_Selected.CountryName}-
                    {Billing_Address_Selected.ZipCode}
                  </div>

                  <div className="existing-address-after-select-inner-number">
                    {Billing_Address_Selected.PhoneNo}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="billing-form">
          <Formik
            //enableReinitialize={true}
            initialValues={{
              Billing_Fname: "",
              Billing_Lname: "",
              Billing_Email: "",
              Billing_Phone: "",
              Billing_AddressLine1: "",
              Billing_AddressLine2: "",
              Billing_City: 0,
              Billing_State: 0,
              Billing_Country_ID: 0,
              Billing_Zip_Code: "",
              Shipping_Fname: "",
              Shipping_Lname: "",
              Shipping_Email: "",
              Shipping_Phone: "",
              Shipping_AddressLine1: "",
              Shipping_AddressLine2: "",
              Shipping_City: 0,
              Shipping_State: 0,
              Shipping_Country_ID: 0,
              Shipping_Zip_Code: "",
            }}
            validationSchema={BillingSchema}
            onSubmit={async (values, { resetForm }) => {}}
          >
            {({ errors, touched, handleChange, values }) => (
              <Form>
                {/* {errors && existingBillingAddress ? (errors = null) : errors} */}
                {setBillCountryChange(values.Billing_Country_ID)}
                {setShipCountryChange(values.Shipping_Country_ID)}
                {setBillStateChange(values.Billing_State)}
                {setShipStateChange(values.Shipping_State)}
                {setFormikData(values)}
                {setFormikErrors(errors)}
                <div className="checkout-input-box">
                  <div style={{ display: existingBillingAddress && "none" }}>
                    <div className="billing-input-box">
                      <div className="input-box">
                        <label>
                          First Name<span className="red-star">*</span>
                        </label>
                        <Field
                          name="Billing_Fname"
                          type="text"
                          placeholder="Enter your first name"
                          className={
                            errors.Billing_Fname && touched.Billing_Fname
                              ? "error-border"
                              : !errors.Billing_Fname && touched.Billing_Fname
                              ? "isValid"
                              : null
                          }
                          id="Billing_Fname"
                          innerRef={Billing_Fname_Ele}
                        />
                      </div>
                      <div className="input-box">
                        <label>Last Name</label>
                        <Field
                          type="text"
                          name="Billing_Lname"
                          placeholder="Enter your last name"
                        />
                      </div>
                    </div>
                    <div className="billing-input-box">
                      <div className="input-box">
                        <label>
                          Email<span className="red-star">*</span>
                        </label>
                        <Field
                          name="Billing_Email"
                          type="email"
                          placeholder="Enter your email address..."
                          className={
                            errors.Billing_Email && touched.Billing_Email
                              ? "error-border"
                              : !errors.Billing_Email && touched.Billing_Email
                              ? "isValid"
                              : null
                          }
                        />
                      </div>
                      <div className="input-box">
                        <label>
                          Phone Number<span className="red-star">*</span>
                        </label>
                        <Field
                          name="Billing_Phone"
                          type="number"
                          placeholder="Enter your phone number"
                          className={
                            errors.Billing_Phone && touched.Billing_Phone
                              ? "error-border"
                              : !errors.Billing_Phone && touched.Billing_Phone
                              ? "isValid"
                              : null
                          }
                        />
                      </div>
                    </div>
                    <div className="billing-input-box">
                      <div className="input-box input-box2">
                        <label>
                          Address Line 1<span className="red-star">*</span>
                        </label>
                        <Field
                          type="text"
                          name="Billing_AddressLine1"
                          placeholder="Street address1"
                          className={
                            errors.Billing_AddressLine1 &&
                            touched.Billing_AddressLine1
                              ? "error-border"
                              : !errors.Billing_AddressLine1 &&
                                touched.Billing_AddressLine1
                              ? "isValid"
                              : null
                          }
                        />
                      </div>
                    </div>
                    <div className="billing-input-box">
                      <div className="input-box input-box2">
                        <label>Address Line 2</label>
                        <Field
                          type="text"
                          name="Billing_AddressLine2"
                          placeholder="Street address2"
                        />
                      </div>
                    </div>
                    <div className="billing-input-box">
                      <div className="input-box">
                        <label>
                          Country<span className="red-star">*</span>
                        </label>
                        <Field
                          as="select"
                          name="Billing_Country_ID"
                          placeholder="Select Country"
                          // onChange={handleChange}
                          // value={values.Billing_Country_ID}

                          className={
                            errors.Billing_Country_ID &&
                            touched.Billing_Country_ID
                              ? "error-border"
                              : !errors.Billing_Country_ID &&
                                touched.Billing_Country_ID
                              ? "isValid"
                              : null
                          }
                          innerRef={billCountrySelect}
                        >
                          <option value="">---Select Country---</option>
                          {country_list.map((country) => {
                            return (
                              <option value={country.Value} key={country.Value}>
                                {country.Text}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <div className="input-box">
                        <label>
                          State/Province<span className="red-star">*</span>{" "}
                        </label>
                        <Field
                          as="select"
                          name="Billing_State"
                          placeholder="Select State"
                          // onChange={handleChange}
                          // value={values.Billing_State}

                          className={
                            errors.Billing_State && touched.Billing_State
                              ? "error-border"
                              : !errors.Billing_State && touched.Billing_State
                              ? "isValid"
                              : null
                          }
                          innerRef={billStateSelect}
                        >
                          <option value="">---Select State---</option>
                          {bill_state_list.map((statelist) => {
                            return (
                              <option
                                value={statelist.Value}
                                key={statelist.Value}
                              >
                                {statelist.Text}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                    </div>
                    <div className="billing-input-box">
                      <div className="input-box">
                        <label>
                          City<span className="red-star">*</span>
                        </label>
                        <Field
                          name="Billing_City"
                          as="select"
                          // onChange={handleChange}
                          // value={values.Billing_City}

                          className={
                            errors.Billing_City && touched.Billing_City
                              ? "error-border"
                              : !errors.Billing_City && touched.Billing_City
                              ? "isValid"
                              : null
                          }
                          innerRef={billCitySelect}
                        >
                          <option value="">---Select City---</option>
                          {bill_city_list.map((citylist) => {
                            return (
                              <option
                                value={citylist.Value}
                                key={citylist.Value}
                              >
                                {citylist.Text}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <div className="input-box">
                        <label>
                          Zip/Postal Code<span className="red-star">*</span>
                        </label>
                        <Field
                          type="text"
                          name="Billing_Zip_Code"
                          placeholder="Zip/Postal Code"
                          className={
                            errors.Billing_Zip_Code && touched.Billing_Zip_Code
                              ? "error-border"
                              : !errors.Billing_Zip_Code &&
                                touched.Billing_Zip_Code
                              ? "isValid"
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cart-title">
                    <div className="billing-input-box billing-input-box2">
                      <div
                        className="input-box radio-input"
                        onClick={() => setSameShippingAddress(true)}
                      >
                        <Field
                          type="radio"
                          name="sameShip"
                          value="sameShipAddr"
                          checked={sameShippingAddress}
                        />
                        <label style={{ color: activeColor1 }}>
                          Ship to the same as billing address
                        </label>
                      </div>
                      <div
                        className="input-box radio-input"
                        onClick={() => setSameShippingAddress(false)}
                      >
                        <Field
                          type="radio"
                          name="sameShip"
                          value="difShipAddr"
                          checked={!sameShippingAddress}
                        />

                        <label htmlFor="tab" style={{ color: activeColor }}>
                          Use different shipping address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    id="div1"
                    style={{ display: sameShippingAddress ? "none" : "block" }}
                  >
                    <div className="cart-title  use-different-address">
                      <div className="billing-input-box billing-input-box2">
                        <div
                          className="input-box radio-input"
                          onClick={() => setExistingShippingAddress(true)}
                        >
                          <input
                            type="radio"
                            name="tab"
                            value="igotnone"
                            checked={existingShippingAddress}
                          />
                          <label style={{ color: activeColor4 }}>
                            Existing addresses
                          </label>
                        </div>
                        <div
                          className="input-box radio-input"
                          onClick={() => {
                            setExistingShippingAddress(false);
                            //setBillFocus();
                          }}
                        >
                          <input
                            type="radio"
                            name="tab"
                            value="igottwo"
                            checked={!existingShippingAddress}
                          />

                          <label htmlFor="tab" style={{ color: activeColor5 }}>
                            Add new address
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="billing-input-box flex-column"
                      style={{ display: !existingShippingAddress && "none" }}
                    >
                      <div className="input-box exisiting-address-dropdow">
                        <label>
                          Select shipping address
                          <span className="red-star">*</span>
                        </label>
                        <select
                          name="Shipping_Address_Box"
                          onChange={ShippingAddressChange}
                        >
                          <option value="">---Select Address---</option>
                          {user_addresses_list.map((item) => {
                            let address = `${item.Name} (${item.CityName} - ${item.ZipCode}) `;
                            return <option value={item.Id}>{address}</option>;
                          })}
                        </select>
                      </div>
                      {!isEmptyObj(Shipping_Address_Selected) && (
                        <div className="existing-address-after-select">
                          <div className="existing-address-after-select-inner">
                            <label>{Shipping_Address_Selected.Name}</label>
                            <div>{Shipping_Address_Selected.Address1},</div>
                            <div>
                              {Shipping_Address_Selected.Address2}
                              {Shipping_Address_Selected.Address2 && ","}
                            </div>
                            <div>
                              {Shipping_Address_Selected.CityName},
                              {Shipping_Address_Selected.StateName},
                              {Shipping_Address_Selected.CountryName}-
                              {Shipping_Address_Selected.ZipCode}
                            </div>

                            <div className="existing-address-after-select-inner-number">
                              {Shipping_Address_Selected.PhoneNo}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{ display: existingShippingAddress && "none" }}>
                      <div className="billing-input-box">
                        <div className="input-box">
                          <label>
                            First Name<span className="red-star">*</span>
                          </label>
                          <Field
                            name="Shipping_Fname"
                            type="text"
                            placeholder="Enter your first name"
                            className={
                              errors.Shipping_Fname && touched.Shipping_Fname
                                ? "error-border"
                                : !errors.Shipping_Fname &&
                                  touched.Shipping_Fname
                                ? "isValid"
                                : null
                            }
                            id="Shipping_Fname"
                          />
                        </div>
                        <div className="input-box">
                          <label>Last Name</label>
                          <Field
                            type="text"
                            name="Shiping_Lname"
                            placeholder="Enter your last name"
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box">
                          <label>
                            Email<span className="red-star">*</span>
                          </label>
                          <Field
                            name="Shipping_Email"
                            type="email"
                            placeholder="Enter your email address..."
                            className={
                              errors.Shipping_Email && touched.Shipping_Email
                                ? "error-border"
                                : !errors.Shipping_Email &&
                                  touched.Shipping_Email
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                        <div className="input-box">
                          <label>
                            Phone Number<span className="red-star">*</span>
                          </label>
                          <Field
                            name="Shipping_Phone"
                            type="number"
                            placeholder="Enter your phone number"
                            className={
                              errors.Shipping_Phone && touched.Shipping_Phone
                                ? "error-border"
                                : !errors.Shipping_Phone &&
                                  touched.Shipping_Phone
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box input-box2">
                          <label>
                            Address Line 1<span className="red-star">*</span>
                          </label>
                          <Field
                            type="text"
                            name="Shipping_AddressLine1"
                            placeholder="Street address1"
                            className={
                              errors.Shipping_AddressLine1 &&
                              touched.Shipping_AddressLine1
                                ? "error-border"
                                : !errors.Shipping_AddressLine1 &&
                                  touched.Shipping_AddressLine1
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box input-box2">
                          <label>Address Line 2</label>
                          <Field
                            type="text"
                            name="Shipping_AddressLine2"
                            placeholder="Street address2"
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box">
                          <label>
                            Country<span className="red-star">*</span>
                          </label>
                          <Field
                            as="select"
                            name="Shipping_Country_ID"
                            placeholder="Select Country"
                            onChange={handleChange}
                            value={values.Shipping_Country_ID}
                            className={
                              errors.Shipping_Country_ID &&
                              touched.Shipping_Country_ID
                                ? "error-border"
                                : !errors.Shipping_Country_ID &&
                                  touched.Shipping_Country_ID
                                ? "isValid"
                                : null
                            }
                            innerRef={shipCountrySelect}
                          >
                            <option value="">---Select Country---</option>
                            {country_list.map((country) => {
                              return (
                                <option
                                  value={country.Value}
                                  key={country.Value}
                                >
                                  {country.Text}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <div className="input-box">
                          <label>
                            State/Province<span className="red-star">*</span>
                          </label>
                          <Field
                            as="select"
                            name="Shipping_State"
                            placeholder="Select State"
                            onChange={handleChange}
                            value={values.Shipping_State}
                            className={
                              errors.Shipping_State && touched.Shipping_State
                                ? "error-border"
                                : !errors.Shipping_State &&
                                  touched.Shipping_State
                                ? "isValid"
                                : null
                            }
                            innerRef={shipStateSelect}
                          >
                            <option value="">---Select State---</option>
                            {ship_state_list.map((statelist) => {
                              return (
                                <option
                                  value={statelist.Value}
                                  key={statelist.Value}
                                >
                                  {statelist.Text}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box">
                          <label>
                            City<span className="red-star">*</span>
                          </label>
                          <Field
                            as="select"
                            name="Shipping_City"
                            onChange={handleChange}
                            value={values.Shipping_City}
                            className={
                              errors.Shipping_City && touched.Shipping_City
                                ? "error-border"
                                : !errors.Shipping_City && touched.Shipping_City
                                ? "isValid"
                                : null
                            }
                            innerRef={shipCitySelect}
                          >
                            <option value="">---Select City---</option>
                            {ship_city_list.map((citylist) => {
                              return (
                                <option
                                  value={citylist.Value}
                                  key={citylist.Value}
                                >
                                  {citylist.Text}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <div className="input-box">
                          <label>
                            Zip/Postal Code<span className="red-star">*</span>
                          </label>
                          <Field
                            type="text"
                            name="Shipping_Zip_Code"
                            placeholder="Zip/Postal Code"
                            className={
                              errors.Shipping_Zip_Code &&
                              touched.Shipping_Zip_Code
                                ? "error-border"
                                : !errors.Shipping_Zip_Code &&
                                  touched.Shipping_Zip_Code
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={submitBtn}
                ></button> */}
                <div className="cmn-learnmore-btn-grey text-right">
                  <button type="submit" onClick={handleNext}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div style={{ display: paymentOptionShow ? "block" : "none" }}>
        <div className="cart-summary-box">
          <div className="cart-title">
            <h5>Payment Information</h5>
          </div>
          <div className="chechout-payment-address-title">
            Address Information
          </div>
          <div className="payment-info-billing-shipping-wrapper">
            <div className="billing-input-box flex-column">
              <span className="payment-info-subtiltle">Billing address</span>
              <div className="existing-address-after-select">
                <div className="existing-address-after-select-inner">
                  <label>{ReduxCheckoutData.Billing_Name}</label>
                  <div>{ReduxCheckoutData.Billing_AddressLine1},</div>
                  <div>
                    {ReduxCheckoutData.Billing_AddressLine2}
                    {ReduxCheckoutData.Billing_AddressLine2 && ","}
                  </div>
                  <div>
                    {ReduxCheckoutData.Billing_CityName},
                    {ReduxCheckoutData.Billing_StateName},
                    {ReduxCheckoutData.Billing_CountryName}-
                    {ReduxCheckoutData.Billing_Zip_Code}
                  </div>

                  <div className="existing-address-after-select-inner-number">
                    {ReduxCheckoutData.Billing_Phone}
                  </div>
                </div>
              </div>
            </div>

            <div className="billing-input-box flex-column">
              <span className="payment-info-subtiltle">Shipping address</span>
              <div className="existing-address-after-select">
                <div className="existing-address-after-select-inner">
                  <label>{ReduxCheckoutData.Shipping_Name}</label>
                  <div>{ReduxCheckoutData.Shipping_AddressLine1},</div>
                  <div>
                    {ReduxCheckoutData.Shipping_AddressLine2}
                    {ReduxCheckoutData.Shipping_AddressLine2 && ","}
                  </div>
                  <div>
                    {ReduxCheckoutData.Shipping_CityName},
                    {ReduxCheckoutData.Shipping_StateName},
                    {ReduxCheckoutData.Shipping_CountryName}-
                    {ReduxCheckoutData.Shipping_Zip_Code}
                  </div>

                  <div className="existing-address-after-select-inner-number">
                    {ReduxCheckoutData.Shipping_Phone}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="strip-payment-title">Payment Details</div>
          <Elements stripe={promise}>
            <CheckoutForm />
          </Elements>
          {/* <div className="checkout-input-box">
            <div className="payment-input">
              <div className="input-box radio-payemt">
                <input type="radio" name="tab" value="igotnone" checked />
                <label>Credit / Debit card</label>
              </div>
              <div className="input-box radio-payemt">
                <input type="radio" name="tab" value="igottwo" />
                <label htmlFor="tab">Pay Pal </label>
              </div>
            </div>
            <div className="billing-input-box">
              <div className="input-box">
                <label>Card Number*</label>
                <input type="text" placeholder="**** / **** / **** / ****" />
              </div>
              <div className="input-box">
                <label>Card Name</label>
                <input type="text" placeholder="Enter your card name" />
              </div>
            </div>
            <div className="billing-input-box">
              <div className="input-box">
                <label>Expiration Date</label>
                <input type="text" placeholder="MM / YY" />
              </div>
              <div className="input-box">
                <label>CVV</label>
                <input type="text" placeholder="CVV" />
              </div>
            </div>
          </div> */}
        </div>
        <div className="billing-address">
          <div className="billing-address-cont">
            {/* <h5>Billing Address </h5>
            <input type="radio" name="billing" value="igottwo" checked />
            <label>Shipping address is same as billing</label> */}
          </div>
          <div className="billing-address-btn">
            <div className="cmn-learnmore-btn-grey cmn-learnmore-btn-grey2 text-right">
              <a className="mr-2" onClick={() => setPaymentOptionShow(false)}>
                Back
              </a>
              {/* <a href="/#">Pay Now</a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillingDetails;
