import React from "react";

function HeroShape() {
  return (
    <section className="hero-shape">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div
              className="value-box"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="value-img">
                <img
                  src="assets/images/about/diamond.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="value-cont">
                <h5>Values</h5>
                <p>
                  To Establish flow less supply chain with our customers to meet
                  the industry needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4  col-md-4 col-sm-12 col-xs-12">
            <div
              className="value-box"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <div className="value-img">
                <img
                  src="assets/images/about/eye.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="value-cont">
                <h5>Vision</h5>
                <p>
                  To Lead the industry in providing the highest quality Single
                  Crystal Lab Grown Diamonds for the fine jewelry industry.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4  col-md-4 col-sm-12 col-xs-12">
            <div
              className="value-box"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <div className="value-img">
                <img
                  src="assets/images/about/mission.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="value-cont">
                <h5>Mission</h5>
                <p>
                  To fulfill the need of diamonds for the jewelry industry by
                  manufacturing the best Lab Grown Diamonds.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroShape;
