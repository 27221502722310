import React, { useEffect } from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import StockContainer from "./StockContainer";
import SearchPanel from "./SidePanel/SearchPanel";
import DaimondShop from "./DaimondShop";
import { useSelector, useDispatch } from "react-redux";
import { getMaster } from "../../redux/reducers/CommonSlice";
import { isEmptyObj } from "../../helper/helperFunction";

function Index() {
  const masterData = useSelector((state) => state.commonReducer.masterlist);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      if (isEmptyObj(masterData)) dispatch(getMaster());
    };
  }, []);
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="Our Stock" />
        <StockContainer />
        {!isEmptyObj(masterData) && <SearchPanel />}
        {/* <DaimondShop /> */}
      </Layout>
    </>
  );
}

export default Index;
