const UserID =
  (localStorage.getItem("userLoginInfo") &&
    JSON.parse(localStorage.getItem("userLoginInfo")).UserID) ||
  0;
//const DomainWithOutHttp = "app.parinlabgrown.com";
// const Domain = "http://local.diamx.net";
// const DomainWithOutHttp = "local.diamx.net";
const DomainWithOutHttp = "parinlabgrown.com8080";
const Domain = "https://parinlabgrown.com:8080";
const DeviceType = "B2C";
const BackEndClientId = 3;
export { UserID, Domain, DeviceType, BackEndClientId, DomainWithOutHttp };
