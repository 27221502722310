import React, { useEffect } from "react";
import $ from "jquery";
function Faq() {
  useEffect(() => {
    $(function () {
      $(".acc-header-common button").on("click", function () {
        $(".acc-header-common button.color-active").removeClass("color-active");
        if ($(this).children().closest("i").hasClass("fa-plus")) {
          $(this).addClass("color-active");
          $(this).children().closest("i").addClass("fa-minus");
          $(this).children().closest("i").removeClass("fa-plus");
        } else {
          $(this).children().closest("i").removeClass("fa-minus");
          $(this).children().closest("i").addClass("fa-plus");
        }
      });
    });
  }, []);

  return (
    <section className="accordion-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12">
            <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
              <span>Faq</span>
              <h1 className="cmn-section-titile cmn-color-title">
                Frequently Asked Questions
              </h1>
              <div className="cmn-diamond-below-title mx-auto">
                <img
                  src="assets/images/home/grey-small-diamod.png"
                  className="img-fluid"
                  alt="grey-small-diamod.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className=" row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link question"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>Why to Buy Lab Grown Diamonds from us?</span>{" "}
                      <i className="fa fa-plus arrow-rotate arrow-rotate"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    As aware about made-made diamond is produced in a laboratory
                    but not all the sellers of lab grown diamonds are
                    manufactured on their own. They might purchase it from the
                    manufacturer and then sell it to you, which is not price
                    competitively and trustworthy. While our company is
                    manufacture, the lab made diamond by own from raw diamond to
                    finish cut diamond. By which the cost of mid-man reduced,
                    and the quality of a diamond maintained.
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link question"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>
                        Is a Synthetic Diamond an equivalent Thing as a Lab
                        Grown Diamond?{" "}
                      </span>{" "}
                      <i className="fa fa-plus arrow-rotate arrow-rotate"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Yes. Laboratory grown diamonds are often mentioned as
                    lab-created diamonds, grown diamonds, synthetic diamonds,
                    man-made diamonds, cultivated diamonds, or cultured
                    diamonds. That being said, parin gems believes that
                    synthetic may be a scientifically inaccurate term for
                    laboratory-grown diamonds.
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link question"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>How Long Does It Take to Grow a Diamond?</span>{" "}
                      <i className="fa fa-plus arrow-rotate"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    It takes 5-15 days to grow a 1.50-carat lab diamond, and a
                    few months to grow a 3-carat diamond. If you are trying to
                    grow a diamond any faster, the diamond crystal will
                    fracture. Thus, there’s a physical regulation to how briskly
                    you’ll grow diamonds
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link question"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFourth"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>Are Lab Diamonds Cheaper than Mined Diamonds?</span>{" "}
                      <i className="fa fa-plus arrow-rotate"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseFourth"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Yes. Lab-created diamonds tend to retail at a 40-50%
                    discount off of equivalent quality natural diamonds.
                    Lab-grown diamonds are priced supported their quality, a bit
                    like mined diamond.
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link question"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFifth"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>
                        Are Lab Diamonds Graded by Third Party Organizations?{" "}
                      </span>{" "}
                      <i className="fa fa-plus arrow-rotate"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseFifth"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Yes. Nearly all lab diamonds above 0.50ct are graded and
                    authorized by reputable non-profit gemological laboratories
                    like AGS, GIA, IGI, or GCAL. Up until recently most of the
                    diamonds sold by parin gems had been graded by IGI; however,
                    we’ve begun having the bulk of our rounds certified by AGS.
                    GIA will begin grading lab diamonds with full fidelity in
                    late 2020 and that we anticipate to offering GIA certified
                    lab diamonds. We always recommend purchasing a lab diamond
                    that has an independent certification.
                  </div>
                </div>
              </div>

              {/* <div className="card">
                <div className="card-header acc-header-common" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseSixth"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>
                        We Ship lab diamonds Worldwide with Fedex , Brinks and
                        UPS
                      </span>{" "}
                      <i className="fa fa-plus arrow-rotate"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseSixth"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Please schedule a demo with our consultants to see the
                    product in action and discuss potential use cases for your
                    business.
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
