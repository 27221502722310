import React, { useEffect } from "react";
import CartItem from "./CartItem";
import CartSummary from "./CartSummary";
import ShippingBox from "./ShippingBox";
import Help from "./Help";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import EmptyCart from "./EmptyCart";
import { getCartSummary } from "../../redux/reducers/CheckoutSlice";
import useNotify from "../../hooks/useNotify";
import {
  getMyWishList,
  resetaddItemWishSuccess,
  resetaddItemWishError,
} from "../../redux/reducers/WishListSlice";
function Cart() {
  let cart = useSelector((state) => state.productReducer.cart.items);
  const dispatch = useDispatch();
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  let { isItemDeleted } = useSelector((state) => state.productReducer.cart);
  const history = useHistory();
  const Notify = useNotify();
  useEffect(() => {
    //console.log(isItemDeleted, "isItemDeleted");
    isItemDeleted && dispatch(getCartSummary({ Customer_ID: UserID }));
  }, [isItemDeleted]);

  // useEffect(() => {
  //   if (!cart.length) {
  //     Notify("Your cart is empty,please add something to it!", "error");
  //     history.push("/");
  //   }
  // }, [cart]);
  let { addItemWishError, addItemWishSuccess } = useSelector(
    (state) => state.WishlistReducer.wishlist
  );

  useEffect(() => {
    addItemWishSuccess && Notify(addItemWishSuccess, "success");
    addItemWishSuccess && dispatch(resetaddItemWishSuccess());
    UserID && addItemWishSuccess && dispatch(getMyWishList({ UserID }));
  }, [addItemWishSuccess]);

  useEffect(() => {
    addItemWishError && Notify(addItemWishError, "error");
    addItemWishError && dispatch(resetaddItemWishError());
  }, [addItemWishError]);
  return (
    <section className="stock-container bg-cmn-property">
      <div className="container">
        <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
          <span>Your</span>
          <h1 className="cmn-section-titile cmn-color-title">Shopping Cart</h1>
          <div className="cmn-diamond-below-title mx-auto">
            <img
              src="assets/images/home/grey-small-diamod.png"
              className="img-fluid"
              alt="grey-small-diamod.png"
            />
          </div>
        </div>
        <div className="row">
          <div
            className={`${
              cart.length ? "col-lg-7" : "col-lg-12"
            } col-md-8 col-sm-12 col-xs-12`}
          >
            {!UserID && cart.length > 0 && (
              <div className="Create">
                <p className="total">
                  <Link to="/signup">Create an account</Link> to easily track
                  your order. <br />
                  Already have a DW Diamonds account?
                  <Link to="/signin"> Sign in</Link>
                </p>
              </div>
            )}

            {cart.length ? (
              cart.map((product) => {
                return <CartItem product={product} key={product.Stock_ID} />;
              })
            ) : (
              <>
                <EmptyCart />
                <div className="cmn-learnmore-btn-grey text-center">
                  <Link to="/diamond">Continue Shopping</Link>
                </div>
              </>
            )}
            {cart.length ? (
              <div className="cmn-learnmore-btn-grey text-right">
                <Link to="/diamond">Continue Shopping</Link>
              </div>
            ) : null}
          </div>
          {cart.length ? (
            <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12 cart-summary-px ">
              <CartSummary />
              {/* <ShippingBox /> */}

              {/* <Help /> */}
              <div className="cart-summary-box mt-3">
                <div className="cart-box">
                  <ul>
                    <li>
                      <span>Surat No.</span>
                      <span>
                        {" "}
                        <a href="tel:+91 9924070051">+91 9924070051</a>
                      </span>
                    </li>

                    <li>
                      <span>Mumbai No.</span>
                      <span>
                        {" "}
                        <a
                          href="tel:+919920995457
"
                        >
                          +91 9920995457
                        </a>
                      </span>
                    </li>
                    <li>
                      <span>New York No.</span>
                      <span>
                        {" "}
                        <a href="tel:+16467368625">+1(646)736-8625</a>
                      </span>
                    </li>
                    <li>
                      <span>New York Email</span>
                      <span>
                        {" "}
                        {/* <a href="mailto:paringems@gmail.com">
                          paringems@gmail.com
                        </a> */}
                        <a href="mailto: parinlabgrown@gmail.com">
                          parinlabgrown@gmail.com
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default Cart;
