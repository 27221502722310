import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEmptyObj } from "../../helper/helperFunction";
import { Domain, BackEndClientId } from "../../constant";
const initialState = {
  userLoginInfo: [],
  userProfileInfo: {},
  loading: false,
  error: "",
};

export const getProfile = createAsyncThunk(
  "user_profile",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/get-my-profile-detail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
        BackEndClientId,
      }),
    });
    // The value we return becomes the `fulfilled` action payload
    let data = await response.json();
    return data.Result;
  }
);

export const UserSlice = createSlice({
  name: "user_slice",
  initialState,
  reducers: {
    setLoginInfo: (state, action) => {
      state.userLoginInfo = action.payload;
      localStorage.setItem("userLoginInfo", JSON.stringify(action.payload));
    },
    getLoginInfo: (state, action) => {
      if (isEmptyObj(state.userLoginInfo)) {
        const userInfo =
          JSON.parse(localStorage.getItem("userLoginInfo")) || [];
        state.userLoginInfo = userInfo;
      }
    },

    Logout: (state, action) => {
      state.userLoginInfo = [];
      localStorage.removeItem("localcart");
      localStorage.removeItem("localwishlist");
      localStorage.removeItem("userLoginInfo");
      localStorage.removeItem("localcompare");
      state.userProfileInfo = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.userProfileInfo = {};
        state.loading = true;
        state.error = "";
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.userProfileInfo = action.payload || {};
        state.loading = false;
        state.error = action.payload == null ? "Data not found!" : "";
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.userProfileInfo = {};
        state.loading = false;
        state.error = "Some thing went wrong! Internal server error";
      });
  },
});

export const { setLoginInfo, getLoginInfo, Logout } = UserSlice.actions;
export default UserSlice.reducer;
