import React from "react";
import Help from "../Cart/Help";
import ShippingBox from "../Cart/ShippingBox";
import BillingDetails from "./BillingDetails";
import CartSummary from "./CartSummary";

function Checkout() {
  return (
    <section className="stock-container bg-cmn-property">
      <div className="container">
        <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
          <h1 className="cmn-section-titile cmn-color-title">Checkout</h1>
          <div className="cmn-diamond-below-title mx-auto">
            <img
              src="assets/images/home/grey-small-diamod.png"
              className="img-fluid"
              alt="grey-small-diamod.png"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-sm-12 col-xs-12">
            <BillingDetails />
          </div>
          <div className="col-lg-4 col-sm-12 col-xs-12">
            <CartSummary />
            <ShippingBox />
            <Help />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Checkout;
