import React from "react";
import Layout from "../../Layout";

function Index() {
  return (
    <Layout>
      <section className="lab-grown-dimaond-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
                <h1 className="cmn-section-titile cmn-color-title">
                  What is 4c's Of Diamonds
                </h1>
                <div className="cmn-diamond-below-title mx-auto">
                  <img
                    src="assets/images/home/grey-small-diamod.png"
                    className="img-fluid"
                    alt="grey-small-diamod.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmn-content-wrapper">
                <p>
                  Every diamond, like a human fingerprint, has specific
                  distinguishing characteristics. The 4Cs—color, clarity, cut,
                  and carat weight—are the globally accepted standards for
                  assessing the quality of a diamond.
                </p>

                <h2>CUT :</h2>
                <h5>What Is Diamond Cut?</h5>
                <p>
                  {" "}
                  The most important of the 4Cs—cut—refers to how a diamond's
                  facets interact with light. It is determined by symmetry,
                  proportion and polish. More than any other factor, the cut
                  determines the beauty of the stone. Parin gems, from the
                  smallest to the largest stone, are cut to exacting standards.
                </p>
                <h5>Why Is Diamond Cut Important?</h5>
                <p>
                  If a diamond is cut poorly, it will appear dull even with a
                  high color and clarity grade. If a diamond is cut well, it
                  will reflect and refract light for maximum brightness and
                  sparkle. At Parin gems, we are committed to crafting the most
                  expertly proportioned diamonds
                </p>

                <h2>CLARITY :</h2>
                <p>
                  Clarity events are the percentage of natural inclusions or
                  imperfections found in a diamond. The closer a diamond is to
                  flawless, i.e., no inclusions visible by a jeweler's loupe,
                  the rarer it is and the far away ahead of its value. Most
                  inclusions are not noticeable to the naked eye; hence diamonds
                  are examined asleep a 10x magnifying loupe to determine their
                  clarity.
                </p>
                <h5>
                  Clarity characteristics are usually not speaking into two
                  categories:
                </h5>
                <ul>
                  <li>
                    <span className="static-page-small-title">
                      Inclusions :{" "}
                    </span>
                    A disease that occurs, needless to say, inside the diamond
                    itself.These were grown miles sedated the surface back the
                    diamond was formed out cold, extreme heat and pressure
                  </li>
                  <li>
                    <span className="static-page-small-title">
                      Blemishes :{" "}
                    </span>
                    Imperfections that occur regarding the diamond's surface and
                    occur during the hostile and polishing process.
                  </li>
                </ul>
                <p>
                  Evaluating diamond clarity involves determining the number,
                  size, assist, nature, and position of these characteristics,
                  as skillfully as how these have an emotional impact on the
                  overall impression of the stone. If you are exasperating to
                  determine what is the best clarity for a diamond, recall that
                  no diamond is perfectly real. But the closer it comes to
                  purity, the enlarged its clarity.
                </p>
                <p>
                  The GIA Diamond Clarity Scale has six categories, some of
                  which are estranged, for a quantity of 11 specific grades.
                </p>
                <ul>
                  <li>
                    <span className="static-page-small-title">
                      Flawless (FL) :{" "}
                    </span>
                    No inclusions and no blemishes visible below 10x
                    strengthening.
                  </li>
                  <li>
                    <span className="static-page-small-title">
                      Internally Flawless (IF) :{" "}
                    </span>
                    No inclusions visible below 10x intensification.
                  </li>
                  <li>
                    <span className="static-page-small-title">
                      Very, Very Slightly Included (VVS1andVVS2) :{" "}
                    </span>
                    Inclusions thus slur they are hard for an adroit grader to
                    see below 10x intensification.
                  </li>
                  <li>
                    <span className="static-page-small-title">
                      Very Slightly Included (VS1andVS2) :{" "}
                    </span>
                    Inclusions are observed in by now effort knocked out 10x
                    further details but can be characterized as young.
                  </li>
                  <li>
                    <span className="static-page-small-title">
                      Slightly Included (SI1andSI2) :{" "}
                    </span>
                    Inclusions are noticeable out cold 10x intensification.
                  </li>
                  <li>
                    <span className="static-page-small-title">
                      Included (I1, I2, andI3) :{" "}
                    </span>{" "}
                    Inclusions are obvious out chilly 10x strengthening which
                    may function transparency and brilliance
                  </li>
                </ul>

                <h2>Color :</h2>
                <p>
                  Color refers to the natural tint inherent in white diamonds.
                  In nature, most white diamonds have a slight tint of yellow.
                  The closer to being "colorless" a diamond is, the rarer it is.
                  The industry standard for grading color is to evaluate each
                  stone against a master set and assign a letter grade from "D"
                  (colorless) to "Z" (light yellow).
                </p>
                <div className="c4-diamonds-staticpage">
                  <img
                    src="assets/images/labgrown-page/Diamonds.png"
                    className="img-fluid"
                    alt="clean"
                  />
                </div>
                <div className="table-responsive static-page-table">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Grade </th>
                        <th scope="col">Subgrade/Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>D, E, and F </td>
                        <td>Colorless</td>
                      </tr>
                      <tr>
                        <td>G, H, I, and J </td>
                        <td>Near Colorless </td>
                      </tr>
                      <tr>
                        <td>K, L, and M </td>
                        <td>Faint Yellow </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  The gemological standards in this section refer only to
                  individually registered engagement diamonds set in certain
                  ring styles.
                </p>
                <h2>Carat :</h2>
                <p>Carat denotes the weight of a diamond, not the size.</p>
                <h5>Diamond Carat Charts</h5>
                <p>
                  Carat weight can appear differently across different diamond
                  shapes such as round brilliant, princess, pear, oval, cushion,
                  marquise, emerald, radiant or heart. A diamond may have a
                  higher carat weight without appearing larger, and two diamonds
                  of the same carat weight can vary in size if one is cut deeper
                  than the other. In other words, it is essential to note that
                  carat weight does not necessarily denote size.
                </p>
                <p>
                  Carat introduces the weight of a diamond and late accrual
                  gemstones. A carat is a unit of play equal to 0.2gm or 200mg.
                  In the diamond industry, size does add together occurring
                  taking place. Large diamonds are unusually found in the
                  natural world, making them more panicky than smaller diamonds
                  of the linked color and clarity.
                </p>
                <p>
                  This means that the price of a diamond will soar dramatically
                  as the carat size of the diamond rises. Beware that the size
                  or carat weight of a diamond individually cannot be used to go
                  into detail about its value. Factors such as color and clarity
                  should along with being examined. A large diamond subsequent
                  to a deep color and clarity will be less costly than a smaller
                  diamond following fantastic color and clarity.
                </p>
                <p>
                  Another common misunderstanding is that a 2-carat diamond will
                  see twice the size of a 1-carat diamond. As the carat weight
                  is measured in volume and not being size, a 2-carat diamond
                  will not be twice the size of a 1.00-carat diamond, but it
                  will be twice the weight.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;
