import React from "react";

function Labgrown() {
  return (
    <section className="lab-grown-diamond-section">
      <div className="container">
        <div className="row mb-lab-grown-diamond">
          <div className="col-md-12 col-sm-12">
            <div className="cmn-diamond-with-title-wrapper text-center">
              <h1 className="cmn-section-titile cmn-color-title">
                Lab Grown Diamonds
              </h1>
              <div className="cmn-diamond-below-title mx-auto">
                <img
                  src="assets/images/home/grey-small-diamod.png"
                  className="img-fluid"
                  alt="grey-small-diamod.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12" data-aos="fade-right">
            <h3 className="cmn-sub-title">What Is CVD Diamond?</h3>
            <p className="cmn-peragraph">
              A CVD (chemical vapour deposition) diamond is a lab created
              diamond that is produced artificially in a laboratory unlike the
              natural diamond which creates itself through natural geological
              process. Diamond is made using pure carbon that is crystallized in
              isotropic 3D form.
            </p>
            {/* <div className="cmn-learnmore-btn-grey">
              <a href="">Learn More</a>
            </div> */}
          </div>
          <div className="col-md-4 col-sm-12">
            <div data-aos="zoom-in">
              <img
                src="assets/images/home/LabGrownDiamonds.png"
                className="img-fluid"
                alt="LabGrownDiamonds"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-12" data-aos="fade-left">
            <h3 className="cmn-sub-title">Why To Choose CVD Diamond?</h3>
            <p className="cmn-peragraph">
              Natural diamonds are rare and adorable, but what if you get better
              than rare at your price? Yes, you read it right BETTER than
              natural diamond. There are many aspects where CVD diamonds are
              better than natural diamonds like purity, price, and environmental
              effect.
            </p>
            {/* <div className="cmn-learnmore-btn-grey">
              <a href="">Learn More</a>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Labgrown;
