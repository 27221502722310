import React, { useState } from "react";

function Diamond() {
  const [cityName, setCityName] = useState("surat");
  return (
    <section className="lab-grown-dimaond-page">
      <div className="container-fluid px-md-0">
        <div className="row mx-md-0">
          <div className="col-lg-12 col-sm-12 col-xs-12">
            <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
              <span>Contact Us</span>
              <h1 className="cmn-section-titile cmn-color-title">
                Get In Touch
              </h1>
              <div className="cmn-diamond-below-title mx-auto">
                <img
                  src="assets/images/home/grey-small-diamod.png"
                  className="img-fluid"
                  alt="grey-small-diamod.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-0 map">
          <div className="col-lg-3 p-0">
            <div className="map-card">
              <div className="map-title">
                <h2>Our Offices</h2>
              </div>
              <div className="area-title">
                <h5>
                  <a onClick={() => setCityName("surat")}>SURAT</a>
                </h5>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/location.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    435, Anjiriyawadi, Laldarwaja Main Rd, Gotawadi, Surat,
                    Gujarat - 395004
                  </p>
                </div>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/phone.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    Phone: <a href="tel:+91 9924070051">+91 9924070051</a>
                  </p>
                </div>
              </div>

              <div className="area-title">
                <h5>
                  <a onClick={() => setCityName("mumbai")}>Mumbai</a>
                </h5>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/location.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    JW 2040, Bharat Diamond Bourse, Bandra Kurla Complex,
                    Mumbai, Maharashtra - 400051
                  </p>
                </div>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/phone.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    Phone:{" "}
                    <a
                      href="tel:+919920995457
"
                    >
                      +91 9920995457
                    </a>
                  </p>
                </div>
              </div>

              <div className="area-title">
                <h5>
                  <a onClick={() => setCityName("newYork")}>New York</a>
                </h5>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/location.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>62 w ,47 th street, suite 305,New York, NY-10036</p>
                </div>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/phone.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    Phone: <a href="tel:+16467368625">+1(646)736-8625</a>
                  </p>
                </div>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/message.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    Email:{" "}
                    <a href="mailto:parinnlabgrown@gmail.com">
                      parinlabgrown@gmail.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="area-title">
                <h5>
                  <a onClick={() => setCityName("newYork2")}>New York</a>
                </h5>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/location.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>66 w ,47 th street, #B14, New York, NY-10036</p>
                </div>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/phone.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    Phone: <a href="tel:+19175870307">+1(917)587-0307</a>
                  </p>
                </div>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/message.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    Email:{" "}
                    <a href="mailto:dhruv@parinlabgrown.com">
                      dhruv@parinlabgrown.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="area-title">
                <h5>
                  <a onClick={() => setCityName("chicago")}>Chicago</a>
                </h5>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/location.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>1 n wabash ave, #B12, Chicago, IL-60602</p>
                  {/* <p>66 w ,47 th street, #B14, New York, NY-10036</p> */}
                </div>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/phone.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    Phone: <a href="tel:+13129872384">+1(312)987-2384</a>
                  </p>
                </div>
              </div>
              <div className="address-box">
                <div className="add-icon">
                  <img
                    src="assets/images/gallery/message.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="add-cont">
                  <p>
                    Email:{" "}
                    <a href="mailto:parinlabgrown1@gmail.com">
                      parinlabgrown1@gmail.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="social-icon">
                <ul>
                  <li>Social :</li>
                  <li>
                    <a
                      href="https://www.facebook.com/parin.gems.94"
                      target="_blank"
                    >
                      <i className="fab fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/GemsParin" target="_blank">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/parin_gems_/"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 p-0">
            {cityName === "surat" && (
              /* <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7439.157306760186!2d72.82919385019338!3d21.208890689693657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04ef22c88bbdf%3A0x475b9bf9be6ee9ba!2sThe%20Surat%20Mercantile%20Co.Op.%20Bank%20Ltd.!5e0!3m2!1sen!2sin!4v1624616065590!5m2!1sen!2sin"
                  loading="lazy"
                ></iframe> */
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.5119612993135!2d72.82976761533408!3d21.211537886888177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04ef2761fb927%3A0x22250c9c7cbb23ab!2sParin%20Gems!5e0!3m2!1sen!2sin!4v1660133287178!5m2!1sen!2sin"
                loading="lazy"
              ></iframe>
            )}
            {cityName === "mumbai" && (
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9564633535765!2d72.8633676!3d19.065651700000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8e61d6586f5%3A0xb18150b233839e34!2sBharat%20Diamond%20Bourse%2C%20Bharat%20Diamond%20Bourse%20Internal%20Rd%2C%20G%20Block%20BKC%2C%20Bandra%20Kurla%20Complex%2C%20Bandra%20East%2C%20Mumbai%2C%20Maharashtra%20400051!5e0!3m2!1sen!2sin!4v1718777717603!5m2!1sen!2sin"
                loading="lazy"
              ></iframe>
            )}
            {cityName === "newYork" && (
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.2017835529196!2d-73.98352718434353!3d40.75758644276185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258ff0b9ebfc3%3A0xa11dd6952e55c927!2s62%20W%2047th%20St%20STE%20305%2C%20New%20York%2C%20NY%2010036%2C%20USA!5e0!3m2!1sen!2sin!4v1660133598682!5m2!1sen!2sin"
                loading="lazy"
              ></iframe>
            )}
            {cityName === "newYork2" && (
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.1953207607917!2d-73.9813633!3d40.7577286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258ff0b7f593b%3A0x990802c8eba99d7a!2s66%20W%2047th%20St%2C%20New%20York%2C%20NY%2010036%2C%20USA!5e0!3m2!1sen!2sin!4v1718776588668!5m2!1sen!2sin"
                loading="lazy"
              ></iframe>
            )}
            {cityName === "chicago" && (
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2970.502832416954!2d-87.6286199239152!3d41.882041971241364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDUyJzU1LjQiTiA4N8KwMzcnMzMuOCJX!5e0!3m2!1sen!2sin!4v1718776392365!5m2!1sen!2sin"
                loading="lazy"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Diamond;
