import React from "react";

function ClientServing() {
  return (
    <section className="client-serving bg-cmn-property">
      <div className="container">
        <div className="client-serving-wrapper">
          <div className="white-header">
            <span>Why Choose Us</span>
            <div className="cmn-diamond-with-title-wrapper text-center">
              <h1 className="cmn-section-titile cmn-color-title serving-client-header">
                It's Been 10 Years Since We Are Serving The Best To Our Clients.
              </h1>
              <div className="cmn-diamond-below-title mx-auto">
                <img
                  src="assets/images/home/whitw-small-diamond.png"
                  className="img-fluid"
                  alt="grey-small-diamod.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="serving-client-wrapper">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-4 "
              data-aos="fade-down"
              data-aos-delay="100"
            >
              <div className="serving-client-box">
                <div className="box-image">
                  <span>
                    <img
                      src="assets/images/home/lab-microscope1.png"
                      alt="lab-microscope 1"
                      className="img-fluid"
                    />
                  </span>
                  <span>
                    <img
                      src="assets/images/home/lab-microscope2.png"
                      alt="lab-microscope 2"
                      className="img-fluid"
                    />
                  </span>
                </div>
                <h3>Technology</h3>
                <p className="cmn-peragraph">
                  Lab Grown Diamonds widely known as HPHT Diamonds or CVD
                  diamonds are the two common production methods (referring to
                  the High- pressure high-temperature and chemical vapor
                  deposition, respectively.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              <div className="serving-client-box">
                <div className="box-image">
                  <span>
                    <img
                      src="assets/images/home/diamond1.png"
                      alt="diamond 1.png"
                      className="img-fluid"
                    />
                  </span>
                  <span>
                    <img
                      src="assets/images/home/diamond2.png"
                      alt="diamond 2.png"
                      className="img-fluid"
                    />
                  </span>
                </div>
                <h3>Lab Grown Diamond</h3>
                <p className="cmn-peragraph">
                  Lab Grown Diamonds grow through the same natural
                  crystallisation process as mined diamonds, as well as share
                  the same physical, chemical and optical properties.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              <div className="serving-client-box">
                <div className="box-image">
                  <span>
                    <img
                      src="assets/images/home/morality1.png"
                      alt="lab-microscope 1"
                      className="img-fluid"
                    />
                  </span>
                  <span>
                    <img
                      src="assets/images/home/morality2.png"
                      alt="lab-microscope 2"
                      className="img-fluid"
                    />
                  </span>
                </div>
                <h3>Ethically Sourced</h3>
                <p className="cmn-peragraph">
                  All in all, mined diamonds result in 1 injury for every 1,000
                  workers annually, while lab-grown diamonds result in zero.
                </p>
              </div>
            </div>
          </div>
          <div className="row serving-client-row2">
            <div
              className="col-lg-4 col-md-4"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <div className="serving-client-box">
                <div className="box-image">
                  <span>
                    <img
                      src="assets/images/home/environment1.png"
                      alt="environment 1.png"
                      className="img-fluid"
                    />
                  </span>
                  <span>
                    <img
                      src="assets/images/home/environment2.png"
                      alt="environment 2.png"
                      className="img-fluid"
                    />
                  </span>
                </div>
                <h3>Environment Friendly</h3>
                <p className="cmn-peragraph">
                  Parin Gems Grown uses less than half the amount of energy to
                  produce similar amounts of rough carats compared to natural
                  mines.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              <div className="serving-client-box">
                <div className="box-image">
                  <span>
                    <img
                      src="assets/images/home/conflict1.png"
                      alt="conflict 1.png"
                      className="img-fluid"
                    />
                  </span>
                  <span>
                    <img
                      src="assets/images/home/conflict2.png"
                      alt="conflict 2.png"
                      className="img-fluid"
                    />
                  </span>
                </div>
                <h3>Conflict Free</h3>
                <p className="cmn-peragraph">
                  Parin Gems the leading brand name with the widest collection
                  of Type IIa Lab created diamonds – The purest of pure grade in
                  diamonds.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4"
              data-aos="fade-down"
              data-aos-delay="350"
            >
              <div className="serving-client-box">
                <div className="box-image">
                  <span>
                    <img
                      src="assets/images/home/dollar-coin1.png"
                      alt="dollar-coin1.png"
                      className="img-fluid"
                    />
                  </span>
                  <span>
                    <img
                      src="assets/images/home/dollar-coin2.png"
                      alt="dollar-coin1.png"
                      className="img-fluid"
                    />
                  </span>
                </div>
                <h3>Competitively Priced</h3>
                <p className="cmn-peragraph">
                  No diamond is free, but due to the shorter supply chain,
                  lab-grown diamonds can be anywhere from 20-30% less expensive
                  than mined diamonds.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientServing;
