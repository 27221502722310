import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Logout } from "../../redux/reducers/UserSlice";
import useNotify from "../../hooks/useNotify";
import { useDispatch } from "react-redux";
import { resetMyOrderDetailView } from "../../redux/reducers/CommonSlice";
function MyProfileTabs() {
  const location = useLocation();
  const history = useHistory();
  const Notify = useNotify();
  const dispatch = useDispatch();
  return (
    <div
      className="nav flex-column cmn-shadow-bg"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <a
        className={`nav-link ${location.pathname !== "/my-orders" && "active"}`}
        id="v-pills-home-tab"
        data-toggle="pill"
        href="#profile"
        role="tab"
        aria-controls="v-pills-home"
        aria-selected="true"
      >
        My Profile
      </a>
      {/* <a
        className="nav-link"
        id="v-pills-profile-tab"
        data-toggle="pill"
        href="#address"
        role="tab"
        aria-controls="v-pills-profile"
        aria-selected="false"
      >
        Shipping Address
      </a> */}
      <a
        className={`nav-link ${location.pathname === "/my-orders" && "active"}`}
        id="v-pills-messages-tab"
        data-toggle="pill"
        href="#orders"
        role="tab"
        aria-controls="v-pills-messages"
        aria-selected="true"
        onClick={() => dispatch(resetMyOrderDetailView())}
      >
        My Orders
      </a>
      <a
        className="nav-link"
        id="v-pills-settings-tab"
        data-toggle="pill"
        href="#change-pass"
        role="tab"
        aria-controls="v-pills-settings"
        aria-selected="false"
      >
        Change Password
      </a>
      <a
        className="nav-link"
        id="v-pills-settings-tab"
        data-toggle="pill"
        href="#logout"
        role="tab"
        aria-controls="v-pills-settings"
        aria-selected="false"
        onClick={() => {
          dispatch(Logout());
          history.push("/");
          Notify("Sing out successfully!", "success");
        }}
      >
        Logout
      </a>
    </div>
  );
}

export default MyProfileTabs;
