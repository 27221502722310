import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Route } from "react-router-dom";

function Footer() {
  const ref = useRef(null);

  return (
    <footer className="footer">
      <div className="container position-relative">
        <div className="row footer-row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-logo">
              <Link to="/">
                <img
                  src="assets/images/home/Logo.png"
                  className="img-fluid"
                  alt="Logo.png"
                />
              </Link>
            </div>
            <div className="address-number">
              <div className="location">
                <a
                  href="https://www.google.com/maps/place/Parin+Gems/@21.2115379,72.8297676,17z/data=!3m1!4b1!4m5!3m4!1s0x3be04ef2761fb927:0x22250c9c7cbb23ab!8m2!3d21.2113297!4d72.831974"
                  target="_blank"
                >
                  <img src="assets/images/home/location.svg" alt="" />
                  <p>
                    435, Anjiriyawadi, Laldarwaja Main Rd, Gotawadi, Surat,
                    Gujarat - 395004
                  </p>
                </a>
              </div>
              <div className="location">
                <a href="https://www.google.com/maps/place/PARIN+LABGROWN+LLC/@40.7575192,-73.983195,17z/data=!4m9!1m2!2m1!1sParin+lab+grown+usa+inc!3m5!1s0x89c259a224be39bb:0x3b4e26013858e563!8m2!3d40.7575824!4d-73.9813385!15sChdQYXJpbiBsYWIgZ3Jvd24gdXNhIGluY5IBDG1hbnVmYWN0dXJlcg">
                  <img src="assets/images/home/location.svg" alt="" />
                  <p>
                    Parin lab grown usa inc. <br />
                    62 w, 47 th street, suite 305,New york,ny-10036
                  </p>
                </a>
              </div>
              <div className="number">
                <a href="tel:++1 6467368625">
                  <img
                    src="assets/images/home/call.svg"
                    alt="call"
                    className="img-fluid"
                  />
                  <p>+1 6467368625</p>
                </a>
              </div>
              <div className="number">
                <a href="mailTo:parinlabgrown@gmail.com">
                  {/* <img src="assets/images/gallery/message.svg" className="img-fluid" alt="" /> */}
                  <i
                    className="fas fa-envelope"
                    style={{ color: " #005fc1" }}
                  ></i>
                  <p>parinlabgrown@gmail.com</p>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-links">
              <h4>Quick link</h4>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/labgrowndiamond">Lab Grown Diamond</Link>
                </li>
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/contactus">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-links">
              <h4>Help</h4>
              <ul>
                <li>
                  <Link to="/privacypolicy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/termsconditions">Terms & Conditions</Link>
                </li>
                {/* <li>
                  <Link to="/cookiepolicy">Cookie Policy </Link>
                </li>
                <li>
                  <Link to="/disclaimer">Disclaimer</Link>
                </li> */}
                <li>
                  <Link to="/shippinginformation">Shipping Information</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-links">
              <h4>Diamonds</h4>
              <ul>
                <li>
                  <Link to="/c4diamondfootepage">The 4C’s of Diamond</Link>
                </li>
                <li>
                  <Link to="/cvdpage">CVD Diamonds</Link>
                </li>
                <li>
                  <Link to="/labcreateddiamonds">Lab Created Diamonds</Link>
                </li>
                {/* <li>
                  <Link to="/gallery">Gallery</Link>
                </li> */}
                <li>
                  <Link to="/naturallabvscreated">
                    Natural vs Lab Created Diamonds
                  </Link>
                </li>
                <li>
                  <a href="/#">
                    <img
                      src="assets/images/home/dmca_protected_sml_120m 1.png"
                      alt="dmca_protected_sml_120m 1"
                      className="img-fluid"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row footer-row2">
          <div className="col-md-12 col-sm-12">
            <div className="image-mail-btn">
              <div className="footer-image-container">
                <img
                  className="img-fluid"
                  src="assets/images/home/diam3.png"
                  alt="diam3"
                />
              </div>
              <span>
                <div className="spacer"></div>
                Ready to join the fastest growing lab grown diamond platform ?
              </span>
              <div className="mail-submit">
                <form>
                  <div className="spacer"></div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      ref={ref}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-reserved-social">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 d-lg-block d-none"></div>
            <div
              className="
                col-lg-10 col-md-12
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <p>
                Copyright © {new Date().getFullYear()} Parin Gems. All rights
                reserved.
              </p>
              <div>
                {/* <a
									href="https://www.facebook.com/parin.gems.94"
									target="_blank"
								>
									<i className="fab fa-facebook-f"></i>
								</a> */}
                <a href="https://twitter.com/GemsParin" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/paringemssurat/"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="call_button">
        <Link to={`/contactus`} className="active-link">
          <i className="far fa-phone-alt"></i>
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
