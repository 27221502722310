import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";

function TableFilter() {
  const { stockFilter, isFilterReset } = useSelector(
    (state) => state.stockFilterReducer
  );

  let TableFrom = stockFilter.TableFrom && stockFilter.TableFrom;
  let TableTo = stockFilter.TableTo && stockFilter.TableTo;
  let filterArray = [TableFrom | 1, TableTo | 1];
  const [sliderValue, setSliderValue] = useState(filterArray);

  useEffect(() => {
    isFilterReset && setSliderValue([1, 1]);
  }, [isFilterReset]);
  const dispatch = useDispatch();
  const [state, setState] = useState({ min: 1, max: 100 });

  function log(value) {
    dispatch(
      addStockFilter({
        TableFrom: value[0],
        TableTo: value[1],
        loadmore: false,
        PageNum: 1,
      })
    );
    dispatch(resetProductPageNum());
    stockFilter.lodmore && dispatch(getProduct(stockFilter));
  }
  return (
    <div className="range-slider-wrapper ">
      <span className="filter-sub-title">Table%</span>
      <div className="range-slider cmn-mb-filter">
        <Slider.Range
          onAfterChange={log}
          min={1}
          max={100}
          value={sliderValue}
          onChange={(value) => {
            setState({ min: value[0], max: value[1] });
            setSliderValue(value);
          }}
        />
        <div className="slider-value">
          <div className="slidervalue-box">
            <label>Min</label>
            <div>{state.min}</div>
          </div>
          <div className="slidervalue-box  slidervalue-box-right-text">
            <label>Max</label>
            <div>{state.max}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableFilter;
