import React from "react";

function Loader() {
  return (
    <div className="diamond-loader">
      <div className="cmn-diamond-with-title-wrapper text-center">
        <div className="thankyou-wrapper loading-loader">
          {/* <i className="fas fa-heart"></i> */}
          <div className="logo-blink">
            <img
              src="assets/images/home/Logo3.png"
              alt="Logo"
              className="img-fluid"
            ></img>
          </div>

          <h4>Loading Diamonds</h4>
        </div>
      </div>
    </div>
  );
}

export default Loader;
