import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Home from "./components/Home/Index";
import Product from "./components/Product/Index";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getMaster } from "./redux/reducers/CommonSlice";
import { getLoginInfo, getProfile } from "./redux/reducers/UserSlice";
import { getMyWishList } from "./redux/reducers/WishListSlice";
import { ViewCartLocal, getMyCart } from "./redux/reducers/ProductSlice";
import Aboutus from "./components/Aboutus/Index";
import Gallery from "./components/Gallery/Index";
import Contactus from "./components/Contactus/Index";
import LabGrownDiamond from "./components/LabGrownDiamond/Index";
import ScrollToTop from "./components/Common/ScrollToTop";
import Signup from "./components/Signup/Index";
import SignIn from "./components/SignIn/Index";
// import PrivacyPolicy from "./components/PrivacyPolicy/Index";
// import TermsConditions from "./components/TermsConditions/Index";
import CookiePolicy from "./components/CookiePolicy/Index";
import MyProfile from "./components/MyProfile/Index";
import Wishlist from "./components/Wishlist/Index";
import ProductDetail from "./components/ProductDetail/Index";
import ForgotPassword from "./components/ForgotPassword/Index";
import { ToastProvider } from "react-toast-notifications";
import "./App.css";
import ResetPassword from "./components/ResetPassword/Index";
import Cart from "./components/Cart/Index";
import Compare from "./components/Compare/Index";
import Checkout from "./components/Checkout/Index";
import { getMyCompare } from "./redux/reducers/CompareSlice";
import Disclaimer from "./components/Disclaimer/Index";
// import ShippingInformation from "./components/ShippingInformation/Index";
// import NaturalvsLabCreatedDiamonds from "./components/NaturalvsLabCreatedDiamonds/Index";
import ThankYou from "./components/ThankYou/Index";
import Banner1 from "./components/Home/Banner/Banner1";
import Banner2 from "./components/Home/Banner/Banner2";
import Banner3 from "./components/Home/Banner/Banner3";
import ClassicShapes from "./components/Home/Content/ClassicShapes";
import C4DiamondFootepage from "./components/StaticPages/C4DiamondFootepage";
import CvdDaimons from "./components/StaticPages/CvdDaimons";
import LabcreatedDiamonds from "./components/StaticPages/LabcreatedDiamonds";
import NaturalLabvsCreated from "./components/StaticPages/NaturalLabvsCreated";
import PrivacyPolicy from "./components/StaticPages/PrivacyPolicy";
import ShippingInformation from "./components/StaticPages/ShippingInformation";
import TermsConditions from "./components/StaticPages/TermsConditions";

function App() {
  const dispatch = useDispatch();
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);

  useEffect(() => {
    dispatch(getMaster());
    dispatch(getLoginInfo());
    dispatch(ViewCartLocal());
    if (UserID) {
      dispatch(getProfile({ UserID }));
      dispatch(getMyWishList({ UserID }));
      dispatch(getMyCompare({ UserID }));
      dispatch(getMyCart({ UserID }));
    }
  }, [UserID]);
  return (
    <Router>
      <ToastProvider>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/diamond" component={Product} />
          <Route path="/diamond/:reportid" component={Product} />
          <Route exact path="/aboutus" component={Aboutus} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/contactus" component={Contactus} />
          <Route exact path="/labgrowndiamond" component={LabGrownDiamond} />
          <Route exact path="/signup" component={UserID ? MyProfile : Signup} />
          <Route exact path="/signin" component={UserID ? MyProfile : SignIn} />
          <Route
            exact
            path="/my-profile"
            component={UserID ? MyProfile : SignIn}
          />
          <Route
            exact
            path="/my-orders"
            component={UserID ? MyProfile : SignIn}
          />
          <Route exact path="/banner-content-1" component={Banner1} />
          <Route exact path="/banner-content-2" component={Banner2} />
          <Route exact path="/banner-content-3" component={Banner3} />
          <Route exact path="/classic-shapes" component={ClassicShapes} />
          <Route
            exact
            path="/c4diamondfootepage"
            component={C4DiamondFootepage}
          />
          <Route exact path="/cvdpage" component={CvdDaimons} />
          <Route
            exact
            path="/naturallabvscreated"
            component={NaturalLabvsCreated}
          />
          <Route
            exact
            path="/labcreateddiamonds"
            component={LabcreatedDiamonds}
          />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/termsconditions" component={TermsConditions} />
          <Route exact path="/cookiepolicy" component={CookiePolicy} />
          <Route exact path="/disclaimer" component={Disclaimer} />
          <Route
            exact
            path="/shippinginformation"
            component={ShippingInformation}
          />
          {/* <Route
            exact
            path="/naturalvsLabcreateddiamonds"
            component={NaturalvsLabCreatedDiamonds}
          /> */}

          <Route
            exact
            path="/wishlist"
            component={UserID ? Wishlist : SignIn}
          />
          <Route exact path="/productdetail" component={ProductDetail} />
          <Route path="/product/:stonenos/:details" component={ProductDetail} />
          <Route exact path="/forgotpass" component={ForgotPassword} />
          <Route exact path="/resetpass" component={ResetPassword} />
          <Route exact path="/cart" component={UserID ? Cart : SignIn} />
          {/* <Route exact path="/cart" component={Cart} /> */}
          <Route exact path="/compare" component={UserID ? Compare : SignIn} />
          <Route
            exact
            path="/checkout"
            component={UserID ? Checkout : SignIn}
          />
          <Route exact path="/success" component={UserID ? ThankYou : SignIn} />
        </Switch>
      </ToastProvider>
    </Router>
  );
}

export default App;
