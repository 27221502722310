import React, { useEffect } from "react";
import CompareItem from "./CompareItem";
import FixPart from "./FixPart";
import { useSelector } from "react-redux";

function Compare() {
  let compare = useSelector((state) => state.CompareReducer.compare.items);

  useEffect(() => {
    const container = document.querySelector(".compare-wrapper");
    let startY;
    let startX;
    let scrollLeft;
    let scrollTop;
    let isDown;

    container.addEventListener("mousedown", (e) => mouseIsDown(e));
    container.addEventListener("mouseup", (e) => mouseUp(e));
    container.addEventListener("mouseleave", (e) => mouseLeave(e));
    container.addEventListener("mousemove", (e) => mouseMove(e));

    function mouseIsDown(e) {
      isDown = true;
      startY = e.pageY - container.offsetTop;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
      scrollTop = container.scrollTop;
    }
    function mouseUp(e) {
      isDown = false;
    }
    function mouseLeave(e) {
      isDown = false;
    }
    function mouseMove(e) {
      if (isDown) {
        e.preventDefault();
        //Move vertcally
        const y = e.pageY - container.offsetTop;
        const walkY = y - startY;
        container.scrollTop = scrollTop - walkY;

        //Move Horizontally
        const x = e.pageX - container.offsetLeft;
        const walkX = x - startX;
        container.scrollLeft = scrollLeft - walkX;
      }
    }
  }, []);

  return (
    <section className="stock-container bg-cmn-property">
      <div className="container container3">
        <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
          <h1 className="cmn-section-titile cmn-color-title">Compare</h1>
          <div className="cmn-diamond-below-title mx-auto">
            <img
              src="assets/images/home/grey-small-diamod.png"
              className="img-fluid"
              alt="grey-small-diamod.png"
            />
          </div>
        </div>
        <div className="compare-wrapper compare-scroll">
          <FixPart />
          <div className="compare-scrollpart">
            {compare.length
              ? compare.map((product) => {
                  return <CompareItem product={product} />;
                })
              : ""}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Compare;
