import React, { useEffect } from "react";
import ShapeFilter from "./ShapeFilter";
import CaratFilter from "./CaratFilter";
import ColorFilter from "./ColorFilter";
import ClarityFilter from "./ClarityFilter";
import CutFilter from "./CutFilter";
import PriceFilter from "./PriceFilter";
import PolishFilter from "./PolishFilter";
import SymmetryFilter from "./SymmetryFilter";
import FlourescenceFilter from "./FlourescenceFilter";
import DepthFilter from "./DepthFilter";
import TableFilter from "./TableFilter";
import LabFilter from "./LabFilter";
import { useDispatch } from "react-redux";
import { removeStockFilter } from "../../../redux/reducers/StockFilterSlice";
import { useState } from "react";

function SidePanelFilter() {
  const [isCollapseMinimize, setIsCollapseMinimize] = useState(false);
  /*   useEffect(() => {
    let advanceSearch = document.querySelector(".advace-search");
    let targetElem = document.querySelector(".arrow-rotate");
    advanceSearch.addEventListener("click", () => {
      if (targetElem.className.indexOf("fa-angle-up") !== -1) {
        targetElem.classList.remove("fa-angle-up");
        targetElem.classList.add("fa-angle-down");
      } else {
        targetElem.classList.remove("fa-angle-down");
        targetElem.classList.add("fa-angle-up");
      }
    });
  }, []); */

  const dispatch = useDispatch();
  return (
    <div className="col-lg-3 col-md-4 mobile-view-slide">
      <div className="side-panel-filter">
        <div className="side-panel-fliter-titile">
          <span className="filter">Filter By:</span>
          <span onClick={() => dispatch(removeStockFilter())}>
            <i className="fas fa-times"></i>
            <span>Clear Filter</span>
          </span>
          <span className="close_btn d-sm-none">
            <i className="fas fa-times"></i>
          </span>
        </div>
        <div className="filter-option">
          <ShapeFilter />
          <CaratFilter />
          <ColorFilter />
          <ClarityFilter />
          <CutFilter />
          <PriceFilter />
          <PolishFilter />
          <div className="">
            <span
              className={
                !isCollapseMinimize
                  ? `advace-search`
                  : "advace-search collapsed"
              }
              data-toggle="collapse"
              role="button"
              aria-expanded={!isCollapseMinimize ? true : false}
              aria-controls="advacesearch"
              onClick={() => setIsCollapseMinimize(!isCollapseMinimize)}
            >
              Advance{" "}
              <i
                className={`${
                  isCollapseMinimize ? "fas fa-angle-down" : "fas fa-angle-up"
                } arrow-rotate`}
              ></i>
            </span>
            <div
              className={!isCollapseMinimize ? "collapse" : "collapse show"}
              id="advacesearch"
            >
              <SymmetryFilter />
              <FlourescenceFilter />
              <DepthFilter />
              <TableFilter />
              <LabFilter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidePanelFilter;
