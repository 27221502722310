const isEmptyObj = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const FetchApi = async (url, method, body) => {
  try {
    const result = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method,
      body: JSON.stringify(body),
    });
    return result;
  } catch (error) {
    return error;
  }
};

const getStringFilter = (marks, value, step) => {
  let originalValue = "";
  for (let i = value[0]; i <= value[1]; i++) {
    originalValue += marks[i] + ",";
    i = i + step - 1;
  }
  let StringFilter = originalValue.substring(0, originalValue.length - 1);
  return StringFilter;
};

const getNumericFilter = (marks, strValue, step) => {
  if (strValue) {
    let myValArray = strValue.split(",");
    let firstStr = myValArray[0];
    let lastStr = myValArray[myValArray.length - 1];
    let myIndexArray = [];
    Object.entries(marks).map((item) => {
      if (item[1] === firstStr) {
        myIndexArray.push(item[0]);
      }
      if (item[1] === lastStr) {
        myIndexArray.push(item[0]);
      }
    });
    return myIndexArray;
  }
};

const sortDiamondByAsc = (product) => {
  const oldArray = [...product];
  const newProduct = oldArray.sort((a, b) => {
    return a.Weight - b.Weight;
  });

  return newProduct;
};

const sortDiamondByDesc = (product) => {
  const oldArray = [...product];
  const newProduct = oldArray.sort((a, b) => {
    return b.Weight - a.Weight;
  });
  return newProduct;
};

const showPassword = (elem, passwordEye) => {
  if (elem.type === "password") {
    elem.type = "text";
    passwordEye.classList.add("fa-eye-slash");
  } else {
    elem.type = "password";
    passwordEye.classList.remove("fa-eye-slash");
  }
};

export {
  isEmptyObj,
  FetchApi,
  getStringFilter,
  sortDiamondByAsc,
  sortDiamondByDesc,
  getNumericFilter,
  showPassword,
};
