import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Disclaimer from "../Disclaimer/Disclaimer";

function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="Disclaimer" />
        <Disclaimer />
      </Layout>
    </>
  );
}

export default Index;
