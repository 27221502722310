import React from "react";
import { Link } from "react-router-dom";
function DiamondClassic() {
  return (
    <section className="diamond-classic-shapes-section">
      <div className="diamond-classic-shapes bg-cmn-property">
        <div className="white-header">
          <span>Lab Created</span>
          <div className="cmn-diamond-with-title-wrapper text-center">
            <h1 className="cmn-section-titile cmn-color-title">
              Diamonds In Classic Shapes
            </h1>
            <div className="cmn-diamond-below-title mx-auto">
              <img
                src="assets/images/home/whitw-small-diamond.png"
                className="img-fluid"
                alt="grey-small-diamod.png"
              />
            </div>
          </div>
          <p>
            A rough lab grown diamond is shapeless, dull and lifeless just as
            mined diamonds.
          </p>
        </div>
      </div>
      <div className="container pb-classic">
        <div className="row nmt-9">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div
              className="round-look-classic-shape-container"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <div className="round-look-classic-shape-image">
                <img
                  src="assets/images/home/Round1.png"
                  alt="Round1"
                  className="img-fluid"
                />
                <span>Round</span>
              </div>
              <p className="cmn-peragraph pera-89">
                The most popular shape among all, representing approximately 75%
                of all diamonds sold. Due to the mechanics of its shape, the
                round diamond is generally superior to fancy shapes at the
                proper reflection of light, maximizing potential brightness.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div
              className="round-look-classic-shape-container"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <div className="round-look-classic-shape-image">
                <img
                  src="assets/images/home/Cushion1.png"
                  alt="Round1"
                  className="img-fluid"
                />
                <span>Cushion</span>
              </div>
              <p className="cmn-peragraph pera-89">
                The cushion cut diamond combines a square cut with rounded
                corners, much like a pillow. This classic cut has been around
                for almost 200 years, and for the first century of its existence
                was the most popular diamond shape. Refinements in cut have led
                to a recent resurgence in popularity.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div
              className="round-look-classic-shape-container"
              data-aos="fade-right"
              data-aos-delay="500"
            >
              <div className="round-look-classic-shape-image">
                <img
                  src="assets/images/home/Emerald1.png"
                  alt="Round1"
                  className="img-fluid"
                />
                <span>Emerald</span>
              </div>
              <p className="cmn-peragraph pera-89">
                The step cuts of its pavilion and its large, open table gives
                the unique look to the emerald cut diamond. Instead of the
                sparkle of a brilliant-cut, emerald cut diamonds produce a
                hall-of-mirrors effect, with the interplay of light and dark
                planes. Often, inclusions or body color are easier to see in an
                emerald cut diamond.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div
              className="round-look-classic-shape-container"
              data-aos="fade-right"
              data-aos-delay="600"
            >
              <div className="round-look-classic-shape-image">
                <img
                  src="assets/images/home/Oval1.png"
                  alt="Round1"
                  className="img-fluid"
                />
                <span>Oval</span>
              </div>
              <p className="cmn-peragraph pera-89">
                Because the oval diamond is a modified brilliant-cut (like
                virtually all round cut diamonds), the two diamond shapes
                possess a similar fire and brilliance. However, oval cut
                diamonds have the added advantage of an elongated shape, which
                can create the illusion of greater size.
              </p>
            </div>
          </div>
        </div>
        <div className="cmn-learnmore-btn-grey text-center classic-leartmore-btn">
          <Link to="/classic-shapes">Learn More</Link>

        </div>
      </div>
    </section>
  );
}

export default DiamondClassic;
