import React from "react";
import Layout from "../../../Layout";

function Index() {
  return (
    <Layout>
      <section className="lab-grown-dimaond-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
                <h1 className="cmn-section-titile cmn-color-title">
                  Why to Choose CVD Diamond?
                </h1>
                <div className="cmn-diamond-below-title mx-auto">
                  <img
                    src="assets/images/home/grey-small-diamod.png"
                    className="img-fluid"
                    alt="grey-small-diamod.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmn-content-wrapper">
                {/* <h2>What Is CVD Diamond?</h2> */}
                <ul>
                  <li>
                    Natural diamonds are rare and adorable, but what if you get
                    better than rare at your price? Yes, you read it right
                    BETTER than natural diamond.There are many aspects where CVD
                    diamonds are better than natural diamonds, like purity,
                    price, and environmental effect.
                  </li>
                  <li>
                    {" "}
                    A CVD diamond is a diamond created by a process called
                    Chemical Vapor Deposition. This process begins with a
                    diamond seed crystal in a vacuum chamber, typically from a
                    high-quality synthetic diamond. A natural gas, such as
                    methane, is then pumped into the chamber and broken down
                    into carbon atoms that accumulate on the crystal and form a
                    diamond. In addition to natural gas, the crystal is treated
                    with high temperatures and pressures within the chamber to
                    remove any coloration and make the diamond colorless.
                  </li>
                </ul>
                <p>
                  Aside from the great price point, CVD diamonds appeal to
                  consumers due to their conflict-free nature.
                </p>
                <h2>A quick factoid:</h2>
                <p>1 CARAT CVD Diamond can SAVE:</p>
                <ul>
                  <li>109 Gallons of WATER</li>
                  <li>250 Tons of LAND</li>
                  <li>50% of your MONEY</li>
                </ul>
                <p>
                  When you purchase a lab-grown diamond, you know exactly where
                  the stone comes from. When you purchase a mined diamond, your
                  stone was likely sourced from a region that's teeming with
                  conflict. The traditional diamond industry is guilty of child
                  labor, human rights violations, poor working conditions and
                  funding armed conflicts. Buying a diamond that's grown in a
                  lab helps consumers avoid contributing to this cycle of
                  cruelty.
                </p>
                <p>
                  In addition, manmade diamonds have a smaller environmental
                  footprint. Contrary to what marketers want us to believe,
                  diamonds are not rare, and they have never been. Mining for
                  stones is also highly disruptive to local landscapes, habitats
                  and wildlife. To find just a single 1-carat diamond, miners
                  must sift through thousands, sometimes hundreds of thousands,
                  pounds of dirt.
                </p>
                <p>
                  CVD diamonds create almost no waste, they aren't used to fund
                  conflict, and they don't originate in mines making Lab-grown
                  stones a favorite among ethically-minded shoppers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;
