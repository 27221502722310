import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";
import {
  getStringFilter,
  getNumericFilter,
} from "../../../helper/helperFunction";
function ColorFilter() {
  const dispatch = useDispatch();
  const { stockFilter, isFilterReset } = useSelector(
    (state) => state.stockFilterReducer
  );
  const ColorData = useSelector(
    (state) => state.commonReducer.masterlist.color
  );

  let customIndex = 0;
  const getColorData =
    ColorData &&
    ColorData.map((color, index) => {
      customIndex += 10;
      return { [customIndex]: color.DisplayName };
    });
  const existingFilter = stockFilter.Color && stockFilter.Color;
  const marks = getColorData ? Object.assign({}, ...getColorData) : {};

  let myFilter = getNumericFilter(marks, existingFilter);
  let filterArray = myFilter && myFilter.length ? myFilter : [10, 10];
  const [sliderValue, setSliderValue] = useState(filterArray);

  useEffect(() => {
    isFilterReset && setSliderValue([10, 10]);
  }, [isFilterReset]);

  function log(value) {
    let Color = getStringFilter(marks, value, 10);
    dispatch(
      addStockFilter({
        Color: Color,
        loadmore: false,
        PageNum: 1,
      })
    );
    stockFilter.lodmore && dispatch(getProduct(stockFilter));
    dispatch(resetProductPageNum());
  }

  return (
    <div className="range-slider-wrapper ">
      <span className="filter-sub-title">Color:</span>
      <div className="cmn-scroll">
        <div className="range-slider cmn-range color-range cmn-scroll color-range-slider">
          <Slider.Range
            onAfterChange={log}
            min={10}
            max={260}
            marks={marks}
            step={10}
            value={sliderValue}
            onChange={(value) => {
              setSliderValue(value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ColorFilter;
