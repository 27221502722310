import React from "react";
import Layout from "../../Layout";

function Index() {
  return (
    <Layout>
      <section className="lab-grown-dimaond-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
                <h1 className="cmn-section-titile cmn-color-title">
                  Shipping Information
                </h1>
                <div className="cmn-diamond-below-title mx-auto">
                  <img
                    src="assets/images/home/grey-small-diamod.png"
                    className="img-fluid"
                    alt="grey-small-diamod.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmn-content-wrapper">
                <h2>Shipping</h2>
                <p>
                  parin gems ("we" and "us") is the operator of{" "}
                  <a href="www.paringems.in)">(www.paringems.in)</a>
                  By placing an order through this Website you will be agreeing
                  to the terms below. These are provided to ensure both parties
                  are aware of and agree upon this arrangement to mutually
                  protect and set expectations on our service.
                </p>
                <p>
                  <strong>1. General : </strong>Subject to stock availability.
                  We try to maintain accurate stock counts on our website but
                  from time-to-time there may be a stock discrepancy and we will
                  not be able to fulfill all your items at time of purchase. In
                  this instance, we will fulfill the available products to you,
                  and contact you about whether you would prefer to await
                  restocking of the backordered item or if you would prefer for
                  us to process a refund.
                </p>
                <p>
                  <strong>2. Shipping Costs : </strong>Shipping costs are
                  calculated during checkout based on weight, dimensions and
                  destination of the items in the order. Payment for shipping
                  will be collected with the purchase. This price will be the
                  final price for shipping cost to the customer.
                </p>
                <p className="mb-1">
                  <strong>3. Returns : </strong>
                </p>
                <ul>
                  <li>
                    <strong>Return Due To Change Of Mind : </strong>Parin gems
                    will happily accept returns due to change of mind as long as
                    a request to return is received by us within 7 days of
                    receipt of item and are returned to us in original
                    packaging, unused and in resalable condition. Return
                    shipping will be paid at the customers expense and will be
                    required to arrange their own shipping. Once returns are
                    received and accepted, refunds will be processed to store
                    credit for a future purchase. We will notify you once this
                    has been completed through email. (parin gems) will refund
                    the value of the goods returned but will NOT refund the
                    value of any shipping paid.
                  </li>
                  <li>
                    <strong>Warranty Returns : </strong>parin gems will happily
                    honor any valid warranty claims, provided a claim is
                    submitted within 90 days of receipt of items. Customers will
                    be required to pre-pay the return shipping, however we will
                    reimburse you upon successful warranty claim. Upon return
                    receipt of items for warranty claim, you can expect parin
                    gems to process your warranty claim within 7 days.
                  </li>
                </ul>
                <h2>
                  Once warranty claim is confirmed, you will receive the choice
                  of:
                </h2>
                <p>
                  <strong>(a) </strong>Refund to your payment method <br />
                  <strong>(b) </strong>A refund in store credit <br />
                  <strong>(c) </strong> A replacement item sent to you (if stock
                  is available)
                </p>
                <p className="mb-1">
                  <strong>4. Delivery Terms</strong>
                </p>
                <ul>
                  <li>
                    <strong>Transit Time Domestically : </strong>In general,
                    domestic shipments are in transit for 2 - 7 days
                  </li>
                  <li>
                    <strong>Transit time Internationally : </strong>Generally,
                    orders shipped internationally are in transit for 4 - 22
                    days. This varies greatly depending on the courier you have
                    selected. We are able to offer a more specific estimate when
                    you are choosing your courier at checkout.
                  </li>
                  <li>
                    <strong>Dispatch Time : </strong>Orders are usually
                    dispatched within 2 business days of payment of order Our
                    warehouse operates on Monday - Friday during standard
                    business hours, except on national holidays at which time
                    the warehouse will be closed. In these instances, we take
                    steps to ensure shipment delays will be kept to a minimum.
                  </li>
                  <li>
                    <strong>Change Of Delivery Address : </strong>For change of
                    delivery address requests, we are able to change the address
                    at any time before the order has been dispatched.
                  </li>
                  <li>
                    <strong>P.O. Box Shipping : </strong>parin gems will ship to
                    P.O. box addresses using postal services only. We are unable
                    to offer couriers services to these locations.
                  </li>
                  <li>
                    <strong> Military Address Shipping : </strong>We are able to
                    ship to military addresses using USPS. We are unable to
                    offer this service using courier services
                  </li>
                  <li>
                    <strong>Items Out Of Stock : </strong>If an item is out of
                    stock, we will cancel and refund the out-of-stock items and
                    dispatch the rest of the order.
                  </li>
                  <li>
                    <strong>Delivery Time Exceeded : </strong>If delivery time
                    has exceeded the forecasted time, please contact us so that
                    we can conduct an investigation
                  </li>
                </ul>
                <p>
                  <strong>5. Tracking Notifications </strong> Upon dispatch,
                  customers will receive a tracking link from which they will be
                  able to follow the progress of their shipment based on the
                  latest updates made available by the shipping provider.
                </p>
                <p>
                  <strong>6. Parcels Damaged In Transit</strong>If you find a
                  parcel is damaged in-transit, if possible, please reject the
                  parcel from the courier and get in touch with our customer
                  service. If the parcel has been delivered without you being
                  present, please contact customer service with next steps.
                </p>
                <p className="mb-1">
                  <strong> 7. Duties & Taxes</strong>
                </p>
                <ul>
                  <li>
                    <strong>7.1 Sales Tax</strong>Sales tax has already been
                    applied to the price of the goods as displayed on the
                    website
                  </li>
                  <li>
                    <strong>7.2 Import Duties & Taxes</strong>Import duties and
                    taxes for international shipments may be liable to be paid
                    upon arrival in destination country. This varies by country,
                    and parin gems encourage you to be aware of these potential
                    costs before placing an order with us.If you refuse to to
                    pay duties and taxes upon arrival at your destination
                    country, the goods will be returned to parin gems at the
                    customers expense, and the customer will receive a refund
                    for the value of goods paid, minus the cost of the return
                    shipping. The cost of the initial shipping will not be
                    refunded.
                  </li>
                </ul>
                <p className="mb-1">
                  <strong>8. Cancellations</strong>
                </p>
                <p>
                  If you change your mind before you have received your order,
                  we are able to accept cancellations at any time before the
                  order has been dispatched. If an order has already been
                  dispatched, please refer to our refund policy.
                </p>
                <p className="mb-1">
                  <strong>9. Insurance</strong>
                </p>
                <p>
                  Parcels are insured for loss and damage up to the value as
                  stated by the courier.
                </p>
                <ul>
                  <li>
                    <strong>Process for parcel damaged in-transit : </strong>
                    We will process a refund or replacement as soon as the
                    courier has completed their investigation into the claim.
                  </li>
                  <li>
                    <strong>Process for parcel lost in-transit : </strong>We
                    will process a refund or replacement as soon as the courier
                    has conducted an investigation and deemed the parcel lost.
                  </li>
                </ul>
                <p className="mb-1">
                  <strong>10. Customer service</strong>
                </p>
                <p className="mb-1">
                  For all customer service enquiries, please email us at
                  paringems@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;
