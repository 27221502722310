import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { resetorderPlaced } from "../../redux/reducers/CheckoutSlice";
import { getMyCart } from "../../redux/reducers/ProductSlice";

function ThankYou({ orderno }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderPlaced, lastPlaceOrderNo } = useSelector(
    (state) => state.CheckoutReducer.checkout
  );
  useEffect(() => {
    !orderPlaced && history.replace("/");
  }, [orderPlaced]);

  // useEffect(() => {
  //   // orderPlaced && dispatch(resetorderPlaced());
  // }, []);

  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);

  useEffect(() => {
    if (UserID) {
      dispatch(getMyCart({ UserID }));
    }
  }, [UserID]);
  return (
    <section className="stock-container bg-cmn-property">
      {orderPlaced ? (
        <div className="container container5">
          <div className="cmn-diamond-with-title-wrapper text-center">
            <div className="thankyou-wrapper">
              <div className="thakyou-logo">
                <img
                  src="assets/images/home/Logo.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="right-icon">
                <img
                  src="assets/images/procuctdetails/right-icon.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h3>Thank You For Your Order!</h3>
              {/* <span className="order-number">
                Your Order Number: {lastPlaceOrderNo}
              </span> */}
              <p>
                <span> Your order has been placed successfully.</span>
                for check order status please go to{" "}
                <Link to="/my-orders">My Orders</Link> section.
              </p>
              <Link to="/" className="back-to-home-btn">
                Back To Home
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}

export default ThankYou;
