import React from "react";
import { useSelector, useDispatch } from "react-redux";
import useNotify from "../../hooks/useNotify";
import {
  removeCompareItem,
  removeLocalCompare,
} from "../../redux/reducers/CompareSlice";

function CompareItem({ product }) {
  const Notify = useNotify();
  const dispatch = useDispatch();

  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);

  return (
    <div className="compare-cmn-part compare-scroll-inner">
      <ul>
        <li className="id-close-icon">
          <span>Report# {product.Lab_Report_No}</span>
          <span>
            <img
              src="assets/images/stock/close.png"
              alt=""
              className="img-fluid"
              onClick={() => {
                dispatch(
                  removeCompareItem({
                    CustomerID: UserID,
                    StockIDs: product.Stock_ID,
                  })
                );

                dispatch(removeLocalCompare(product.Stock_ID));
                Notify("Remove stone from compare successfully!", "success");
              }}
            />
          </span>
        </li>
        <li className="image-li">
          <img
            src={
              product.Stone_Img_url === ""
                ? "assets/images/procuctdetails/no-image.png"
                : product.Stone_Img_url
            }
            className="img-fluid"
            alt="diamond"
          />
        </li>
        <li className="title cmn-not-shown-item"></li>
        <li> {product.Shape}</li>
        <li> {product.Weight}</li>
        <li> {product.Color}</li>
        <li> {product.Clarity}</li>
        <li> {product.Cut || "-"}</li>
        <li> {product.Polish}</li>
        <li className="title cmn-not-shown-item"></li>
        <li> {product.Symm}</li>
        <li> {product.FlrIntens}</li>
        <li>{product.Total_Depth || "-"}</li>
        <li>
          {product.Certificate_file_url && (
            <a
              href={product.Certificate_file_url}
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={`assets/images/stock/${product.Lab}.png`}
                alt="logo_IGI"
                className="img-fluid certilogo"
              />
            </a>
          )}
        </li>
        <li className="compare-lab-icon">
          <img
            src={`assets/images/stock/${product.Lab}.png`}
            className="img-fluid"
            alt="Lab"
          />
        </li>
        <li>{product.Girdle_Per}</li>
        <li>{product.CrownHeight}</li>
        <li>{product.CrownAngle}</li>
        <li>{product.PavillionHeight}</li>
        <li>{product.PavillionAngle}</li>
        <li>{product.CuletSize}</li>
        <li className="none-yellow-btn">
          <span>{product.Shade || "-"}</span>
          {/* <button>Yellow</button> */}
        </li>
        <li>{product.HnA || "-"}</li>
        <li>{product.LowerHalve}</li>
        <li>{product.Tinge}</li>
        <li>{product.Milkey}</li>
        <li>{product.Eyeclean}</li>
        <li>{product.KeyToSymbols}</li>
        <li>{product.Stone_Comment}</li>
        <li className="title cmn-not-shown-item"></li>
        <li>{product.BIS || "-"}</li>
        <li>{product.BIC || "-"}</li>
        <li>{product.WIS || "-"}</li>
        <li>{product.WIC || "-"}</li>
      </ul>
    </div>
  );
}

export default CompareItem;
