import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";
import {
  getStringFilter,
  getNumericFilter,
} from "../../../helper/helperFunction";

function CutFilter() {
  const { stockFilter, isFilterReset } = useSelector(
    (state) => state.stockFilterReducer
  );

  const cutData = useSelector((state) => state.commonReducer.masterlist.cut);
  let customIndex = 0;
  const getcutData =
    cutData &&
    cutData.map((cut, index) => {
      customIndex += 10;
      return { [customIndex]: cut.DisplayName };
    });

  const existingFilter = stockFilter.Cut && stockFilter.Cut;
  const marks = Object.assign({}, ...getcutData);

  let myFilter = getNumericFilter(marks, existingFilter);
  let filterArray = myFilter && myFilter.length ? myFilter : [10, 10];
  const [sliderValue, setSliderValue] = useState(filterArray);

  useEffect(() => {
    isFilterReset && setSliderValue([10, 10]);
  }, [isFilterReset]);

  const dispatch = useDispatch();

  function log(value) {
    let Cut = getStringFilter(marks, value, 10);
    dispatch(addStockFilter({ Cut: Cut, loadmore: false, PageNum: 1 }));
    stockFilter.lodmore && dispatch(getProduct(stockFilter));
    dispatch(resetProductPageNum());
  }
  return (
    <div className="range-slider-wrapper ">
      <span className="filter-sub-title">Cut:</span>
      <div className="range-slider">
        <Slider.Range
          onAfterChange={log}
          marks={marks}
          min={10}
          max={70}
          step={10}
          value={sliderValue}
          onChange={(value) => {
            setSliderValue(value);
          }}
        />
      </div>
    </div>
  );
}

export default CutFilter;
