import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Diamond from "./Diamond";
import HeroContent from "./HeroContent";
function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="Contact Us" />
        <Diamond />
        <HeroContent />
      </Layout>
    </>
  );
}

export default Index;
