import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb({ pagetitle, pagetitle2, path }) {
  return (
    <>
      <div className="flow">
        <div className="container">
          <div className="flow-innner">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" className="active-link">
                    Home
                  </Link>
                </li>
                <li>
                  <i className="fas fa-angle-right"></i>
                </li>
                <li className="breadcrumb-item">
                  {pagetitle2 ? (
                    <Link className="active-link" to={path}>
                      {pagetitle}
                    </Link>
                  ) : (
                    <a> {pagetitle}</a>
                  )}
                </li>

                {pagetitle2 && (
                  <>
                    <li>
                      <i className="fas fa-angle-right"></i>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/#">{pagetitle2}</a>
                    </li>
                  </>
                )}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Breadcrumb;
