import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserID, Domain, BackEndClientId } from "../../constant";

const orderFackData = [
  {
    Stone_Img_url: "assets/images/home/order-image.png",
    title: "0.00 - 19.74 Carat, ROUND Shape Diamond",
    Shape: "Round",
    Color: "D-E-F",
    Cost_Amt: "$21,500.00",
    status: "Cancelled",
    date: "June 08, 2021",
  },
  {
    Stone_Img_url: "assets/images/home/order-image.png",
    title: "0.00 - 19.74 Carat, ROUND Shape Diamond",
    Shape: "Round",
    Color: "D-E-F",
    Cost_Amt: "$21,500.00",
    status: "Delivered",
    date: "June 09, 2021",
  },
  {
    Stone_Img_url: "assets/images/home/order-image.png",
    title: "0.00 - 19.74 Carat, ROUND Shape Diamond",
    Shape: "Round",
    Color: "D-E-F",
    Cost_Amt: "$21,500.00",
    status: "Pending",
    date: "June 10, 2021",
  },
  {
    Stone_Img_url: "assets/images/home/order-image.png",
    title: "0.00 - 19.74 Carat, ROUND Shape Diamond",
    Shape: "Round",
    Color: "D-E-F",
    Cost_Amt: "$21,500.00",
    status: "Delivered",
    date: "June 11, 2021",
  },
];

const initialState = {
  productChangeView: "col-lg-4 col-md-6",
  ListBtnChangeView: "",
  masterlist: [],
  viewProductDetail: [],
  country_list: [],
  state_list: [],
  user_addresses_list: [],
  city_list: [],

  isAddressDeleted: false,
  isEditAddressMode: false,
  isAddressAdded: false,
  isAddressUpdated: false,
  bill_state_list: [],
  ship_state_list: [],
  bill_city_list: [],
  ship_city_list: [],
  appendMore: false,
  order_list_item: [],
  myOrderDetailView: "",
  loading_item: false,
  myOrderDetail: "",
  myOrderAddressDetail: "",
  caratSort: "ASC",
};

export const getMaster = createAsyncThunk("master_list", async () => {
  const response = await fetch(`${Domain}/mobile-api/stock-search-diamond`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ UserID }),
  });
  let data = await response.json();
  return data.Result;
});

export const getBillState = createAsyncThunk(
  "billstate_list",
  async (CountryID) => {
    const response = await fetch(
      `${Domain}/mobile-api/get-state-list?CountryID=${CountryID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();
    return data.Result;
  }
);

export const getShipState = createAsyncThunk(
  "shipstate_list",
  async (CountryID) => {
    const response = await fetch(
      `${Domain}/mobile-api/get-state-list?CountryID=${CountryID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();
    return data.Result;
  }
);

export const getCountry = createAsyncThunk("country_list", async () => {
  const response = await fetch(`${Domain}/mobile-api/get-country-list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  let data = await response.json();
  return data.Result;
});

export const getUserAddresses = createAsyncThunk(
  "user_addresses",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/get-customer-address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
        BackEndClientId,
      }),
    });
    // The value we return becomes the `fulfilled` action payload
    let data = await response.json();
    return data.Result;
  }
);

export const getCityList = createAsyncThunk(
  "city_list",
  async (searchParam) => {
    const response = await fetch(
      `${Domain}/mobile-api/get-city-list?CountryID=${searchParam.CountryID}&StateID=${searchParam.StateID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();
    return data.Result;
  }
);

export const getShipCityList = createAsyncThunk(
  "city_ship_list",
  async (searchParam) => {
    const response = await fetch(
      `${Domain}/mobile-api/get-city-list?CountryID=${searchParam.CountryID}&StateID=${searchParam.StateID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();
    return data.Result;
  }
);

export const getOrderList = createAsyncThunk(
  "order_list",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/get-order-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
        BackEndClientId,
      }),
    });
    let data = await response.json();
    return data;
  }
);

export const getOrderListItem = createAsyncThunk(
  "order_list_item",
  async (searchParam) => {
    const response = await fetch(
      `${Domain}/mobile-api/get-order-detail-by-order-id`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...searchParam,
          BackEndClientId,
        }),
      }
    );
    let data = await response.json();
    return data;
  }
);

export const getProductDetails = createAsyncThunk(
  "product_details",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/get-search-stock`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
        BackEndClientId,
      }),
    });
    let data = await response.json();
    return data.Result.rows[0];
  }
);

export const CommonSlice = createSlice({
  name: "common_slice",
  initialState,
  reducers: {
    setListView: (state, action) => {
      state.productChangeView = action.payload;
    },
    setGridView: (state, action) => {
      state.productChangeView = action.payload;
    },
    setViewProduct: (state, action) => {
      state.viewProductDetail = action.payload;
      localStorage.setItem("viewProductDetail", JSON.stringify(action.payload));
    },
    setListBtnView: (state, action) => {
      state.ListBtnChangeView = action.payload;
    },
    setGridBtnView: (state, action) => {
      state.ListBtnChangeView = action.payload;
    },
    setIsAddressDeleted: (state, action) => {
      state.isAddressDeleted = action.payload;
    },
    setEditAddressMode: (state, action) => {
      state.isEditAddressMode = true;
    },
    resetEditAddressMode: (state, action) => {
      state.isEditAddressMode = false;
    },
    getViewProduct: (state, action) => {
      let viewProductDetail = JSON.parse(
        localStorage.getItem("viewProductDetail")
      );
      state.viewProductDetail = viewProductDetail;
    },
    setIsAddressAdded: (state, action) => {
      state.isAddressAdded = action.payload;
    },
    setAppendMore: (state) => {
      state.appendMore = true;
    },
    resetAppendMore: (state) => {
      state.appendMore = false;
    },
    setMyOrderDetailView: (state, action) => {
      state.myOrderDetailView = action.payload.WebOrder_ID;
      state.myOrderDetail = action.payload;
    },
    resetMyOrderDetailView: (state) => {
      state.myOrderDetailView = "";
      state.myOrderDetail = "";
    },
    setCaratSort: (state, action) => {
      state.caratSort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMaster.pending, (state) => {
        state.masterlist = [];
      })
      .addCase(getMaster.fulfilled, (state, action) => {
        state.masterlist = action.payload;
      });

    builder
      .addCase(getCountry.pending, (state) => {
        state.country_list = [];
      })
      .addCase(getCountry.fulfilled, (state, action) => {
        state.country_list = action.payload;
      });

    builder
      .addCase(getBillState.pending, (state) => {
        state.state_list = [];
        state.bill_state_list = [];
      })
      .addCase(getBillState.fulfilled, (state, action) => {
        state.state_list = action.payload;
        state.bill_state_list = action.payload;
      });

    builder
      .addCase(getShipState.pending, (state) => {
        state.state_list = [];
        state.ship_state_list = [];
      })
      .addCase(getShipState.fulfilled, (state, action) => {
        state.state_list = action.payload;
        state.ship_state_list = action.payload;
      });
    builder
      .addCase(getUserAddresses.pending, (state) => {
        state.user_addresses_list = [];
        state.loading = true;
        state.error = "";
      })
      .addCase(getUserAddresses.fulfilled, (state, action) => {
        state.user_addresses_list = action.payload || [];
        state.loading = false;
        state.error = action.payload == null ? "Data not found!" : "";
      })
      .addCase(getUserAddresses.rejected, (state, action) => {
        state.user_addresses_list = [];
        state.loading = false;
        state.error = action.payload.message;
      });
    builder
      .addCase(getCityList.pending, (state) => {
        state.city_list = [];
        state.loading = true;
        state.bill_city_list = [];
        state.error = "";
      })
      .addCase(getCityList.fulfilled, (state, action) => {
        state.city_list = action.payload || [];
        state.bill_city_list = action.payload || [];
        state.loading = false;
        state.error = action.payload == null ? "Data not found!" : "";
      })
      .addCase(getCityList.rejected, (state, action) => {
        state.city_list = [];
        state.bill_city_list = [];
        state.loading = false;
        state.error = action.payload.message;
      });
    builder
      .addCase(getOrderList.pending, (state) => {
        state.order_list = [];
        state.loading = true;
        state.error = "";
      })
      .addCase(getOrderList.fulfilled, (state, action) => {
        if (action.payload.IsSuccess) {
          state.order_list = action.payload.Result.rows;
          state.loading = false;
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(getOrderList.rejected, (state, action) => {
        state.order_list = [];
        state.loading = false;
        state.error = "Something went wrong!,Internal server error!";
      });

    builder
      .addCase(getOrderListItem.pending, (state) => {
        state.order_list_item = [];
        state.loading_item = true;
        state.error_item = "";
        state.myOrderAddressDetail = {};
      })
      .addCase(getOrderListItem.fulfilled, (state, action) => {
        if (action.payload.IsSuccess) {
          state.order_list_item = action.payload.Result._OrderDetailList;
          state.myOrderAddressDetail = action.payload.Result._OrderDetail;
          state.loading_item = false;
        } else {
          state.error_item = action.payload.message;
        }
      })
      .addCase(getOrderListItem.rejected, (state, action) => {
        state.order_list_item = [];
        state.loading_item = false;
        state.loading_item = "Something went wrong!,Internal server error!";
        state.myOrderAddressDetail = {};
      });
    builder
      .addCase(getShipCityList.pending, (state) => {
        state.ship_city_list = [];
      })
      .addCase(getShipCityList.fulfilled, (state, action) => {
        state.ship_city_list = action.payload || [];
      })
      .addCase(getShipCityList.rejected, (state, action) => {
        state.ship_city_list = [];
      });

    builder
      .addCase(getProductDetails.pending, (state) => {
        state.viewProductDetail = [];
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        //console.log(action.payload, "action.payload");
        state.viewProductDetail = action.payload;
      });
  },
});

export const {
  setListView,
  setGridView,
  setViewProduct,
  setListBtnView,
  setGridBtnView,
  getViewProduct,
  setIsAddressDeleted,
  resetEditAddressMode,
  setEditAddressMode,
  setIsAddressAdded,
  setAppendMore,
  resetAppendMore,
  setMyOrderDetailView,
  resetMyOrderDetailView,
  setCaratSort,
} = CommonSlice.actions;
export default CommonSlice.reducer;
