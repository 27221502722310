import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FetchApi, showPassword } from "../../helper/helperFunction";
import useNotify from "../../hooks/useNotify";
import { Domain } from "../../constant";
import { Link } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
const signupSchema = Yup.object().shape({
  Fname: Yup.string()
    .min(4, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  Login_Name: Yup.string().email("Invalid email").required("Required"),
  Contact_No: Yup.number()
    .min(10, "Please valid mobile no!")
    .required("Required"),
  Company_Name: Yup.string().required("Required"),
  Login_Pass: Yup.string().required("Required").min(6, "Too Short!"),
  // Login_Pass_Comp: Yup.string()
  //   .required("Required")
  //   .oneOf([Yup.ref("Login_Pass"), null], "Passwords must match"),
});

function Signup() {
  const [loader, setLoader] = useState(false);
  const Notify = useNotify();

  const options = useMemo(() => countryList().getData(), []);
  useEffect(() => {
    const passwordEye = document.getElementById("passwordeye");
    const passworField = document.getElementById("Login_Pass");
    passwordEye.addEventListener("click", () =>
      showPassword(passworField, passwordEye)
    );
  }, []);

  return (
    <section className="stock-container bg-cmn-property">
      <div className="container container4">
        <div className="cmn-diamond-with-title-wrapper">
          <div className="cmn-shadow-bg">
            <div className="cmn-title-form">
              <span>Create an Account</span>
            </div>
            <div className="billing-form">
              <Formik
                initialValues={{
                  Fname: "",
                  Lname: "",
                  Full_Name: "",
                  Company_Name: "",
                  Login_Name: "",
                  Login_Pass: "",
                  Login_Pass_Comp: "",
                  Contact_No: "",
                  Address_1: "",
                  Address_2: "",
                  Country: "",
                  State: "",
                  City: "",
                  Pin_Code: "",
                }}
                validationSchema={signupSchema}
                onSubmit={async (values, { resetForm }) => {
                  const data = {
                    ...values,
                    Full_Name: values.Fname + " " + values.Lname,
                  };

                  try {
                    setLoader(true);
                    const response = await FetchApi(
                      `${Domain}/mobile-api/registration`,
                      "POST",
                      data
                    );
                    const res = await response.json();
                    if (res.IsSuccess) {
                      Notify(
                        "Sing up process is done successfully!",
                        "success"
                      );
                      resetForm();
                      setLoader(false);
                    } else {
                      Notify(res.Message, "error");
                      setLoader(false);
                    }
                  } catch (error) {
                    Notify(error, "error");
                    setLoader(false);
                  }
                }}
              >
                {({ errors, touched, handleChange, setFieldValue }) => (
                  <Form>
                    <div className="checkout-input-box">
                      <div className="billing-input-box">
                        <div className="input-box">
                          <label>
                            First Name<span className="red-star">*</span>
                          </label>
                          <Field
                            type="text"
                            placeholder="Enter first name"
                            name="Fname"
                            autoComplete="off"
                            className={
                              errors.Fname && touched.Fname
                                ? "error-border"
                                : !errors.Fname && touched.Fname
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                        <div className="input-box">
                          <label>Last Name</label>
                          <Field
                            type="text"
                            placeholder="Enter last name"
                            name="Lname"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box sign-up-input">
                          <label>
                            Company Name<span className="red-star">*</span>
                          </label>
                          <Field
                            type="text"
                            placeholder="Enter company name"
                            name="Company_Name"
                            autoComplete="off"
                            className={
                              errors.Company_Name && touched.Company_Name
                                ? "error-border"
                                : !errors.Company_Name && touched.Company_Name
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box sign-up-input">
                          <label>
                            Phone Number<span className="red-star">*</span>
                          </label>
                          <Field
                            type="number"
                            placeholder="Enter phone number"
                            name="Contact_No"
                            autoComplete="off"
                            className={
                              errors.Contact_No && touched.Contact_No
                                ? "error-border"
                                : !errors.Contact_No && touched.Contact_No
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box sign-up-input">
                          <label>
                            Address 1<span className="red-star">*</span>
                          </label>
                          <textarea
                            placeholder="Enter Address 1"
                            name="Address_1"
                            className={
                              errors.Address_1 && touched.Address_1
                                ? "error-border"
                                : !errors.Address_1 && touched.Address_1
                                ? "isValid"
                                : null
                            }
                            rows={3}
                            onChange={(e) => {
                              handleChange(e.target.value);
                              setFieldValue("Address_1", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box sign-up-input">
                          <label>
                            Address 2<span className="red-star">*</span>
                          </label>
                          <textarea
                            placeholder="Enter Address 2"
                            name="Address_2"
                            className={
                              errors.Address_2 && touched.Address_2
                                ? "error-border"
                                : !errors.Address_2 && touched.Address_2
                                ? "isValid"
                                : null
                            }
                            rows={3}
                            onChange={(e) => {
                              handleChange(e.target.value);
                              setFieldValue("Address_2", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box sign-up-input">
                          <label>
                            Country<span className="red-star">*</span>
                          </label>
                          <Select
                            options={options}
                            name="Country"
                            onChange={(selectedOption) => {
                              let e = {
                                target: {
                                  name: "Country",
                                  value: selectedOption,
                                },
                              };
                              handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box sign-up-input">
                          <label>
                            State<span className="red-star">*</span>
                          </label>
                          <Field
                            type="text"
                            placeholder="Enter State Name"
                            name="State"
                            className={
                              errors.State && touched.State
                                ? "error-border"
                                : !errors.State && touched.State
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box sign-up-input">
                          <label>
                            City<span className="red-star">*</span>
                          </label>
                          <Field
                            type="text"
                            placeholder="Enter City Name"
                            name="City"
                            className={
                              errors.City && touched.City
                                ? "error-border"
                                : !errors.City && touched.City
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box sign-up-input">
                          <label>
                            Pin Code<span className="red-star">*</span>
                          </label>
                          <Field
                            type="number"
                            placeholder="Enter pin Code"
                            name="Pin_Code"
                            className={
                              errors.Pin_Code && touched.Pin_Code
                                ? "error-border"
                                : !errors.Pin_Code && touched.Pin_Code
                                ? "isValid"
                                : null
                            }
                            onChange={(e) => {
                              handleChange(e.target.value);
                              setFieldValue("Pin_Code", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="billing-input-box">
                        <div className="input-box sign-up-input">
                          <label>
                            Email<span className="red-star">*</span>
                          </label>
                          <Field
                            type="email"
                            placeholder="Enter email address"
                            name="Login_Name"
                            autoComplete="off"
                            className={
                              errors.Login_Name && touched.Login_Name
                                ? "error-border"
                                : !errors.Login_Name && touched.Login_Name
                                ? "isValid"
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="billing-input-box ">
                        <div className="input-box sign-up-input">
                          <label>
                            Password<span className="red-star">*</span>
                          </label>
                          <Field
                            id="Login_Pass"
                            type="password"
                            placeholder="Enter password"
                            name="Login_Pass"
                            autoComplete="off"
                            className={
                              errors.Login_Pass && touched.Login_Pass
                                ? "error-border"
                                : !errors.Login_Pass && touched.Login_Pass
                                ? ""
                                : ""
                            }
                          />
                          <span
                            id="passwordeye"
                            className="fa fa-fw fa-eye password-eye"
                          ></span>
                        </div>
                        {/* <div className="input-box">
                          <label>Confirm Password</label>
                          <Field
                            type="password"
                            placeholder="Enter your confirm password"
                            name="Login_Pass_Comp"
                            autoComplete="off"
                          />
                          {errors.Login_Pass_Comp && touched.Login_Pass_Comp ? (
                            <div className="error">
                              {errors.Login_Pass_Comp}
                            </div>
                          ) : null}
                        </div> */}
                      </div>
                    </div>

                    <div className="register-btn">
                      <button type="submit" disabled={loader}>
                        Sign Up
                        {loader && (
                          <i className="fa fa-spinner fa-spin ml-2"> </i>
                        )}
                      </button>
                      <div className="text-link">
                        <span style={{ color: "#787878", fontSize: "14px" }}>
                          Already registered?&nbsp;
                          <Link to="/signin">
                            <b
                              style={{
                                color: "#005fc1",
                                textDecoration: "underline",
                              }}
                            >
                              Sign In
                            </b>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
