import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCookiePolicy } from "../../redux/reducers/HtmlApiSlice";
import ContainerWrapper from "../Common/ContainerWrapper";

function CookiePolicy() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCookiePolicy());
  }, []);
  const contenteOfPrivacyPolicy = useSelector(
    (state) => state.HtmlApiContantReducer.content.cookiePolicy
  );
  return (
    <ContainerWrapper title="Cookie Policy" content={contenteOfPrivacyPolicy} />
  );
}

export default CookiePolicy;
