import React from "react";
import Layout from "../../../Layout";

function Index() {
  return (
    <Layout>
      <section className="lab-grown-dimaond-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
                <h1 className="cmn-section-titile cmn-color-title">
                  What Is CVD Diamond?
                </h1>
                <div className="cmn-diamond-below-title mx-auto">
                  <img
                    src="assets/images/home/grey-small-diamod.png"
                    className="img-fluid"
                    alt="grey-small-diamod.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmn-content-wrapper">
                {/* <h2>What Is CVD Diamond?</h2> */}
                <ul>
                  <li>
                    CVD diamonds are manmade diamonds. Unlike natural diamonds,
                    which take billions of years to form deep beneath the
                    Earth's surface, CVD diamonds are grown in labs.
                    Specifically, CVD diamonds rely on a unique process called
                    Chemical Vapor Deposition (or CVD).
                  </li>
                  <li>
                    {" "}
                    A CVD diamond is a diamond created by a process called
                    Chemical Vapor Deposition. This process begins with a
                    diamond seed crystal in a vacuum chamber, typically from a
                    high-quality synthetic diamond. A natural gas, such as
                    methane, is then pumped into the chamber and broken down
                    into carbon atoms that accumulate on the crystal and form a
                    diamond. In addition to natural gas, the crystal is treated
                    with high temperatures and pressures within the chamber to
                    remove any coloration and make the diamond colorless.
                  </li>
                </ul>
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;
