import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";

function PolishFilter() {
  const { stockFilter, isFilterReset } = useSelector(
    (state) => state.stockFilterReducer
  );

  const dispatch = useDispatch();
  const masterData = useSelector(
    (state) => state.commonReducer.masterlist.polish
  );

  const selectedPolish = useSelector(
    (state) => state.stockFilterReducer.stockFilter.Polish
  );

  const [polish, setPolish] = useState({
    data: masterData.map((item) => item.MasterTypeValue_Code),
    activevalue: "NONE",
  });

  useEffect(() => {
    isFilterReset &&
      setPolish((state) => {
        return { ...state, activevalue: "NONE" };
      });
  }, [isFilterReset]);

  const handleClick = (e) => {
    setPolish({ ...polish, activevalue: e.target.innerHTML });
    dispatch(
      addStockFilter({
        Polish: e.target.innerHTML,
        loadmore: false,
        PageNum: 1,
      })
    );
    dispatch(resetProductPageNum());
    stockFilter.lodmore && dispatch(getProduct(stockFilter));
  };

  return (
    <div className="cmn-cirlce-value-secetion-wrapper">
      <span className="filter-sub-title">Polish:</span>
      <div className="circle-value">
        {polish.data.map((item) => {
          return (
            <span
              className={selectedPolish === item ? "polish-active" : ""}
              onClick={handleClick}
              key={item}
            >
              {item}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default PolishFilter;
