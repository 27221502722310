import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";

function CarateFilter() {
  const dispatch = useDispatch();
  const { stockFilter, isFilterReset, searchRemember } = useSelector(
    (state) => state.stockFilterReducer
  );

  const { appendMore } = useSelector((state) => state.commonReducer);
  const { products } = useSelector((state) => state.productReducer);
  const [state, setState] = useState({ min: 0, max: 100 });
  const existingFilter =
    stockFilter.CaratsizeIds && stockFilter.CaratsizeIds.split("-");
  let filterArray =
    existingFilter && existingFilter.length
      ? [Number(existingFilter[0]), Number(existingFilter[1])]
      : [0, 0];

  const [sliderValue, setSliderValue] = useState(filterArray);
  function log(value) {
    dispatch(
      addStockFilter({
        CaratsizeIds: `${value[0]}-${value[1]}`,
        loadmore: false,
      })
    );

    dispatch(resetProductPageNum());
    stockFilter.loadmore && dispatch(getProduct(stockFilter));
  }

  useEffect(() => {
    isFilterReset && setSliderValue([0, 0]);
    isFilterReset && setState({ min: 0, max: 100 });
  }, [isFilterReset]);
  // useEffect(() => {
  //   if (products?.length > 0) {
  //     let maxValues = parseFloat(products[0].Weight);
  //     products.map((item) => {
  //       if (maxValues < parseFloat(item.Weight)) {
  //         maxValues = item.Weight;
  //       }
  //     });
  //     setSliderValue([sliderValue[0], maxValues]);
  //   }
  // }, [products]);

  return (
    <div className="range-slider-wrapper ">
      <span className="filter-sub-title">Carat Size:</span>
      <div data-role="rangeslider" className="carat-slize-slider">
        <Slider.Range
          onAfterChange={log}
          min={0}
          max={100}
          step={0.01}
          value={sliderValue}
          onChange={(value) => {
            setState({ min: value[0], max: value[1] });
            setSliderValue(value);
          }}
        />
        <div className="slider-value">
          <div className="slidervalue-box">
            <label>Min</label>

            <input
              type="number"
              className="range-input"
              value={sliderValue[0]}
              min="0"
              onChange={(e) => {
                setSliderValue([e.target.value, sliderValue[1]]);
                setTimeout(() => {
                  dispatch(
                    addStockFilter({
                      CaratsizeIds: `${e.target.value}-${sliderValue[1]}`,
                      loadmore: false,
                      PageNum: 1,
                    })
                  );

                  stockFilter.lodmore && dispatch(getProduct(stockFilter));
                }, 2000);
              }}
            />
          </div>
          <div className="slidervalue-box  slidervalue-box-right-text">
            <label>Max</label>
            <input
              type="number"
              className="range-input"
              value={sliderValue[1]}
              max="100"
              onChange={(e) => {
                setSliderValue([sliderValue[0], e.target.value]);
                setTimeout(() => {
                  dispatch(
                    addStockFilter({
                      CaratsizeIds: `${sliderValue[0]}-${e.target.value}`,
                      loadmore: false,
                      PageNum: 1,
                    })
                  );

                  stockFilter.lodmore && dispatch(getProduct(stockFilter));
                }, 2000);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarateFilter;
