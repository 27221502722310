import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Domain, BackEndClientId } from "../../constant";

const initialState = {
  compare: {
    items: [],
    isItemAddedToCompare: true,
    addItemCompareError: "",
    isGetItemFromCompare: false,
    getItemCompareError: "",
    isItemDeletedFromCompare: true,
  },
};

export const addToCompare = createAsyncThunk(
  "addtocompare",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/add-to-compare`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
      }),
    });
    // The value we return becomes the `fulfilled` action payload
    let data = await response.json();
    return data;
  }
);

export const getMyCompare = createAsyncThunk(
  "getmycompare",
  async (searchParam) => {
    const response = await fetch(`${Domain}/mobile-api/get-compare-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...searchParam,
        BackEndClientId,
      }),
    });

    let data = await response.json();
    return data.Result;
  }
);

export const removeCompareItem = createAsyncThunk(
  "remove_compare_item",
  async (searchParam) => {
    const response = await fetch(
      `${Domain}/mobile-api/remove-from-compare-list`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...searchParam,
          BackEndClientId,
        }),
      }
    );
    // The value we return becomes the `fulfilled` action payload
    let data = await response.json();
    return data.Result;
  }
);

export const CompareSlice = createSlice({
  name: "compare_slice",
  initialState,
  reducers: {
    removeLocalCompare: (state, action) => {
      let olditem = state.compare.items;
      let filterItem = olditem.filter(
        (product) => product.Stock_ID !== action.payload
      );
      state.compare.items = filterItem;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCompare.pending, (state) => {
        state.compare.isItemAddedToCompare = false;
      })
      .addCase(addToCompare.fulfilled, (state, action) => {
        if (action.payload.IsSuccess) {
          state.compare.isItemAddedToCompare = true;
          state.compare.addItemCompareError = "";
        } else {
          state.compare.isItemAddedToCompare = false;
          state.compare.addItemCompareError = action.payload.Message;
        }
      })
      .addCase(addToCompare.rejected, (state, action) => {
        state.compare.isItemAddedToCompare = false;
        state.compare.addItemCompareError =
          "Some thing went wrong! Internal server error";
      });

    builder
      .addCase(getMyCompare.pending, (state) => {
        state.compare.items = [];
      })
      .addCase(getMyCompare.fulfilled, (state, action) => {
        state.compare.items = action.payload || [];
        state.isGetItemFromCompare =
          action.payload === null ? "Data not found!" : "";
        action.payload !== null &&
          localStorage.setItem("localcompare", JSON.stringify(action.payload));
      })
      .addCase(getMyCompare.rejected, (state, action) => {
        state.compare.items = [];
        state.getItemCompareError =
          "Some thing went wrong! Internal server error";
      });

    builder
      .addCase(removeCompareItem.pending, (state) => {
        state.isItemDeletedFromCompare = false;
      })
      .addCase(removeCompareItem.fulfilled, (state, action) => {
        state.isItemDeletedFromCompare = true;
      })
      .addCase(removeCompareItem.rejected, (state, action) => {
        state.isItemDeletedFromCompare = false;
      });
  },
});
export const { removeLocalCompare } = CompareSlice.actions;
export default CompareSlice.reducer;
