import React from "react";

function ShippingBox() {
  return (
    <div className="shipping-box">
      <div className="shipping-icon">
        <img
          src="assets/images/stock/delivery.png"
          className="img-fluid"
          alt=""
        />
      </div>
      <div className="shipping-cont">
        <h5>Free Shipping & Free Returns</h5>
        <p>Order now for delivery by Tuesday, June 22, 2021</p>
      </div>
    </div>
  );
}

export default ShippingBox;
