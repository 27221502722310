import React from "react";
import { useDispatch } from "react-redux";
import { setMyOrderDetailView } from "../../redux/reducers/CommonSlice";
function OrderItem({ order }) {
  const dispatch = useDispatch();
  return (
    <tr>
      <td className="text-center">{order.OrderNo}</td>
      <td className="text-center">{order.OrderDate}</td>
      <td className="text-center">{order.Total_Pcs}</td>
      <td className="text-center">{order.Total_Amt}</td>
      <td className="text-center">
        <span
          className={`cmn-dots ${
            order.WebOrder_Status == "Cancelled"
              ? "cancle"
              : order.WebOrder_Status == "Delivered"
              ? "delivered"
              : "pending"
          }`}
        >
          {order.WebOrder_Status}
        </span>
      </td>
      <td
        className="text-center"
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(setMyOrderDetailView(order));
          // console.log(order);
        }}
      >
        <i className="fas fa-info-circle"></i>
      </td>
    </tr>
  );
}

export default OrderItem;
