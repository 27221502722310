import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FetchApi } from "../../helper/helperFunction";
import useNotify from "../../hooks/useNotify";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObj } from "../../helper/helperFunction";
import { getProfile } from "../../redux/reducers/UserSlice";
import { BackEndClientId, Domain } from "../../constant";
import { useLocation } from "react-router-dom";
import {
  getCountry,
  getBillState,
  getCityList,
} from "../../redux/reducers/CommonSlice";
const profileSchema = Yup.object().shape({
  Fname: Yup.string()
    .min(4, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  // Lname: Yup.string()
  //   .min(4, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Required"),
  Login_Name: Yup.string().email("Invalid email").required("Required"),
  Contact_No: Yup.number()
    .min(10, "Please valid mobile no!")
    .required("Required"),
});

function MyProfileContent() {
  const location = useLocation();
  const dispatch = useDispatch();
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  let { userProfileInfo } = useSelector((state) => state.userReducer);
  const { country_list, state_list, city_list } = useSelector(
    (state) => state.commonReducer
  );
  const [countryChangeHandle, setCountryChangeHandle] = useState(0);
  const [stateChangeHandle, setStateChangeHandle] = useState(0);

  useEffect(() => {
    dispatch(getCountry());
  }, []);
  useEffect(() => {
    countryChangeHandle > 0 && dispatch(getBillState(countryChangeHandle));
  }, [countryChangeHandle]);

  useEffect(() => {
    stateChangeHandle > 0 &&
      countryChangeHandle > 0 &&
      dispatch(
        getCityList({
          CountryID: countryChangeHandle,
          StateID: stateChangeHandle,
        })
      );
  }, [stateChangeHandle]);

  const [loader, setLoader] = useState(false);
  const Notify = useNotify();
  let [initVal, setInitVal] = useState({
    Fname: "",
    Lname: "",
    Full_Name: "",
    Login_Name: "",
    Contact_No: "",
    Address_1: "",
    Address_2: "",
    Country_Id: "",
    State_Id: "",
    City_Name: "",
    Zip_Code: "",
  });

  useEffect(() => {
    let newObj = {};
    if (!isEmptyObj(userProfileInfo)) {
      let Full_Name =
        userProfileInfo.Full_Name && userProfileInfo.Full_Name.split(" ");
      newObj = {
        Fname: Full_Name[0],
        Lname: Full_Name[1],
        Full_Name: userProfileInfo.Full_Name,
        Login_Name: userProfileInfo.Login_Name,
        Contact_No: userProfileInfo.Contact_No,
        Address_1: userProfileInfo.Address_1,
        Address_2: userProfileInfo.Address_2,
        Country_Id: userProfileInfo.Country_Id,
        State_Id: userProfileInfo.State_Id,
        City_Id: userProfileInfo.City_Id,
        Zip_Code: userProfileInfo.Zip_Code,
      };
    }

    if (userProfileInfo.Country_Id > 0 && userProfileInfo.State_Id > 0) {
      dispatch(getBillState(userProfileInfo.Country_Id));
      dispatch(
        getCityList({
          CountryID: userProfileInfo.Country_Id,
          StateID: userProfileInfo.State_Id,
        })
      );
    }

    setInitVal({ ...initVal, ...newObj });
  }, [userProfileInfo]);

  return (
    <div
      className={`tab-pane fade  ${
        location.pathname !== "/my-orders" && "active show"
      }`}
      id="profile"
      role="tabpanel"
    >
      <span className="tabs-titile">My Profile</span>
      <div>
        <div className="billing-form">
          <Formik
            enableReinitialize={true}
            initialValues={initVal}
            validationSchema={profileSchema}
            onSubmit={async (values, { resetForm }) => {
              const data = {
                ...values,
                Full_Name: values.Fname + " " + values.Lname,
                Cust_ID: UserID,
                BackEndClientId: BackEndClientId,
              };

              try {
                setLoader(true);
                const response = await FetchApi(
                  `${Domain}/mobile-api/edit-my-profile-rushi`,
                  "POST",
                  data
                );
                const res = await response.json();
                if (res.IsSuccess) {
                  Notify("Profile updated successfully!", "success");

                  setLoader(false);
                  if (UserID) dispatch(getProfile({ UserID }));
                } else {
                  Notify(res.Message, "error");
                  setLoader(false);
                }
              } catch (error) {
                Notify(error, "error");
                setLoader(false);
              }
            }}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div className="checkout-input-box ">
                  <div className="billing-input-box billing-input-px-0">
                    <div className="input-box">
                      <label>
                        First Name<span className="red-star">*</span>
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter your first name"
                        name="Fname"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.Fname}
                        className={
                          errors.Fname && touched.Fname
                            ? "error-border"
                            : !errors.Fname && touched.Fname
                            ? "isValid"
                            : null
                        }
                      />
                    </div>
                    <div className="input-box">
                      <label>Last Name</label>
                      <Field
                        type="text"
                        placeholder="Enter your last name"
                        name="Lname"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.Lname}
                      />
                    </div>
                  </div>
                  <div className="billing-input-box billing-input-px-0">
                    <div className="input-box">
                      <label>
                        Email<span className="red-star">*</span>
                      </label>
                      <Field
                        type="email"
                        placeholder="Enter your email address..."
                        name="Login_Name"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.Login_Name}
                        className={
                          errors.Login_Name && touched.Login_Name
                            ? "error-border"
                            : !errors.Login_Name && touched.Login_Name
                            ? "isValid"
                            : null
                        }
                      />
                    </div>
                    <div className="input-box">
                      <label>
                        Phone Number<span className="red-star">*</span>
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter you phone number"
                        name="Contact_No"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.Contact_No}
                        className={
                          errors.Contact_No && touched.Contact_No
                            ? "error-border"
                            : !errors.Contact_No && touched.Contact_No
                            ? "isValid"
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="billing-input-box billing-input-px-0">
                    <div className="input-box">
                      <label>Address Line1</label>
                      <Field
                        type="text"
                        placeholder="Enter your address line 1"
                        name="Address_1"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.Address_1}
                      />
                      {/* {errors.Address_1 && touched.Address_1 ? (
                        <div className="error">{errors.Address_1}</div>
                      ) : null} */}
                    </div>

                    <div className="input-box">
                      <label>Address Line2</label>
                      <Field
                        type="text"
                        placeholder="Enter your address line2"
                        name="Address_2"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.Address_2}
                      />
                      {/* {errors.Address_2 && touched.Address_2 ? (
                        <div className="error">{errors.Address_2}</div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="billing-input-box billing-input-px-0">
                    <div className="input-box">
                      <label>Country</label>
                      {/* <Field
                        type="text"
                        placeholder="Enter your country name"
                        name="Country_Name"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.Country_Name}
                      /> */}
                      <Field
                        as="select"
                        name="Country_Id"
                        placeholder="Select Country"
                        onChange={(e) => {
                          handleChange("Country_Id")(e);
                          setCountryChangeHandle(e.currentTarget.value);
                        }}
                        value={values.Country_Id}
                      >
                        <option value=""> ---Select Country---</option>
                        {country_list.map((country) => {
                          return (
                            <option value={country.Value} key={country.Value}>
                              {country.Text}
                            </option>
                          );
                        })}
                      </Field>

                      {/* {errors.Country_Id && touched.Country_Id ? (
                        <div className="error">{errors.Country_Id}</div>
                      ) : null} */}
                    </div>

                    <div className="input-box">
                      <label>State</label>
                      {/* <Field
                        type="text"
                        placeholder="Enter your state name"
                        name="State_Name"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.State_Name}
                      /> */}
                      <Field
                        as="select"
                        name="State_Id"
                        placeholder="Select State"
                        onChange={(e) => {
                          handleChange("State_Id")(e);
                          setStateChangeHandle(e.currentTarget.value);
                        }}
                        value={values.State_Id}
                      >
                        <option value=""> ---Select State---</option>
                        {state_list.map((statelist) => {
                          return (
                            <option
                              value={statelist.Value}
                              key={statelist.Value}
                            >
                              {statelist.Text}
                            </option>
                          );
                        })}
                      </Field>
                      {/* {errors.State_Id && touched.State_Id ? (
                        <div className="error">{errors.State_Id}</div>
                      ) : null} */}
                    </div>
                  </div>{" "}
                  <div className="billing-input-box billing-input-px-0">
                    <div className="input-box">
                      <label>City</label>
                      {/* <Field
                        type="text"
                        placeholder="Enter your city name and pincode"
                        name="City_Name"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.City_Name}
                      /> */}
                      <Field
                        as="select"
                        name="City_Id"
                        placeholder="City"
                        value={values.City_Id}
                      >
                        <option value=""> ---Select City---</option>
                        {city_list.map((citylist) => {
                          return (
                            <option value={citylist.Value} key={citylist.Value}>
                              {citylist.Text}
                            </option>
                          );
                        })}
                      </Field>
                      {/* {errors.City_Id && touched.City_Id ? (
                        <div className="error">{errors.City_Id}</div>
                      ) : null} */}
                    </div>
                    <div className="input-box">
                      <label>Zip/Postal Code</label>
                      <Field
                        type="text"
                        name="Zip_Code"
                        placeholder="Zip/Postal Code"
                        value={values.Zip_Code}
                        autoComplete="off"
                      />
                      {/* {errors.Zip_Code && touched.Zip_Code ? (
                        <div className="error">{errors.Zip_Code}</div>
                      ) : null} */}
                    </div>
                  </div>
                </div>
                <div className="save-btn">
                  <button type="submit">
                    Save
                    {loader && <i className="fa fa-spinner fa-spin ml-2"> </i>}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default MyProfileContent;
