import React, { useEffect, useState, useRef } from "react";
import ChangeView from "./ChangeView";
import DiamondCard from "./DiamondCard";
import { useSelector, useDispatch } from "react-redux";
import {
  getMyCart,
  getProduct,
  setProductPageNum,
  setProduct,
  resetCartisItemAdded,
  resetaddItemSuccess,
  resetaddItemError,
  setOrder,
  resetProductLoader,
} from "../../../redux/reducers/ProductSlice";
import {
  getMyWishList,
  resetaddItemWishSuccess,
  resetaddItemWishError,
} from "../../../redux/reducers/WishListSlice";
import { resetSearchRemember } from "../../../redux/reducers/StockFilterSlice";
import { Link, useParams } from "react-router-dom";

import useNotify from "../../../hooks/useNotify";
import Loader from "../../Common/Loader";
import ErrorBox from "../../Common/ErrorBox";
import $ from "jquery";
import Loader2 from "../../Common/Loader2";
import {
  sortDiamondByAsc,
  sortDiamondByDesc,
} from "../../../helper/helperFunction";

function TabContainer() {
  const dispatch = useDispatch();
  const params = useParams();
  const Notify = useNotify();
  const [pageInit, setPageInit] = useState(true);
  const { stockFilter, searchRemember } = useSelector(
    (state) => state.stockFilterReducer
  );
  const Loading = useSelector((state) => state.productReducer.loading);
  const { productAdded } = useSelector((state) => state.productReducer);
  const PageNum = useSelector((state) => state.productReducer.PageNum);
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  let { isItemAdded, addItemError, addItemSuccess } = useSelector(
    (state) => state.productReducer.cart
  );

  let { addItemWishError, addItemWishSuccess } = useSelector(
    (state) => state.WishlistReducer.wishlist
  );

  useEffect(() => {
    if (params.reportid) {
      setTimeout(() => {
        if (document.getElementById("prd_" + params.reportid)) {
          $("html, body").animate(
            {
              scrollTop: $("#prd_" + params.reportid).offset().top,
            },
            "slow"
          );
        }
      }, 1000);
    }
  }, [params]);

  // useEffect(() => {
  //   const pageInitStatus = () => setTimeout(() => setPageInit(false), 5000);
  //   pageInitStatus();

  //   return () => {
  //     clearTimeout(pageInitStatus);
  //   };
  // }, []);

  // when any item added to cart before login then this function is called after adding item to cart
  useEffect(() => {
    addItemSuccess && Notify(addItemSuccess, "success");
    addItemSuccess && dispatch(resetaddItemSuccess());
    UserID && addItemSuccess && dispatch(getMyCart({ UserID }));
  }, [addItemSuccess]);

  // when any item added to cart after login then this function is called after adding item to cart
  useEffect(() => {
    addItemError && Notify(addItemError, "error");
    addItemError && dispatch(resetaddItemError());
  }, [addItemError]);

  useEffect(() => {
    addItemWishSuccess && Notify(addItemWishSuccess, "success");
    addItemWishSuccess && dispatch(resetaddItemWishSuccess());
    UserID && addItemWishSuccess && dispatch(getMyWishList({ UserID }));
  }, [addItemWishSuccess]);

  // when any item added to wishlist after login then this function is called after adding item to cart
  useEffect(() => {
    addItemWishError && Notify(addItemWishError, "error");
    addItemWishError && dispatch(resetaddItemWishError());
  }, [addItemWishError]);

  // when click on load more button then pageno is updated in store
  // const showMore = () => {
  //   dispatch(addStockFilter({ loadmore: true, PageNum: PageNum + 1 }));
  //   stockFilter.loadmore && dispatch(setProductPageNum());
  // };

  //when pageno is updated then its updated in stockfilter section
  // useEffect(() => {
  //   dispatch(addStockFilter({ PageNum: PageNum + 1 }));
  // }, [PageNum]);

  //stock filter section is updated then its find diamond according to that filter using api call and update it in apiproduct variable of store
  useEffect(() => {
    // return () => {
    !searchRemember &&
      stockFilter.loadmore &&
      stockFilter.PageNum &&
      dispatch(getProduct(stockFilter));
    // };
  }, [stockFilter]);

  // when apiproduct is updated then add that product to product listing array
  useEffect(() => {
    !searchRemember && !Loading && dispatch(setProduct());
    !searchRemember && !Loading && dispatch(resetProductLoader());
  }, [Loading]);

  const Products = useSelector((state) => state.productReducer.products);
  const { error, searchMoreError } = useSelector(
    (state) => state.productReducer
  );
  const { caratSort } = useSelector((state) => state.commonReducer);
  // useEffect(() => {
  //   console.log(productAdded);
  //   if (caratSort && productAdded) {
  //     if (caratSort === "ASC") {
  //       console.log(Products, "ASC");
  //       const sortProduct = Products && sortDiamondByAsc(Products);
  //       dispatch(setOrder(sortProduct));
  //       dispatch(resetProductLoader());
  //     } else {
  //       console.log(Products, "DESC");
  //       const sortProduct = Products && sortDiamondByDesc(Products);
  //       dispatch(setOrder(sortProduct));
  //       dispatch(resetProductLoader());
  //     }
  //   }
  // }, [caratSort, productAdded]);

  // lazy loading scroll
  const loader = useRef(null);
  const [page, setPage] = useState(0);
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);
  useEffect(() => {
    !searchRemember &&
      PageNum &&
      dispatch(
        getProduct({
          ...stockFilter,
          PageNum: PageNum,
          OrderByType: "Weight " + caratSort.toLowerCase(),
        })
      );
  }, [PageNum]);

  const handleObserver = (entities) => {
    const target = entities[0];

    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
      if (PageNum >= page) {
        //  console.log("call when reload", PageNum, page);
        searchRemember && dispatch(resetSearchRemember());
        dispatch(setProductPageNum(PageNum));
      }
    }
  };

  return (
    <>
      <div className="tabs-container">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="view-shortby">
              <Link to="/compare">
                <div className="goto-compare">
                  <span className="tooltips">
                    <img
                      src="assets/images/stock/compare-match.svg"
                      alt="like-heart"
                      className="img-fluid"
                    />
                    <span className="tooltiptext tooltips-bottom tooltip-tops">
                      View Compare
                    </span>
                  </span>
                </div>
              </Link>
              <ChangeView />
              <div className="row ">
                {Products &&
                  !error &&
                  Products.map((product) => {
                    return (
                      <DiamondCard
                        product={product}
                        isItemAdded={isItemAdded}
                        //key={product.Stone_No}
                        key={product.Lab_Report_No}
                      />
                    );
                  })}
                {error && <ErrorBox errorMessage={error} />}
              </div>
              <div ref={loader}></div>
              {Loading && pageInit && <Loader />}
              {Loading && !pageInit && <Loader2 />}
              {searchMoreError && <ErrorBox errorMessage={searchMoreError} />}
              {/* {!Loading && !Error && (
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="load-more">
                      <button type="button" onClick={showMore}>
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            Comparison
          </div>
        </div>
      </div>
    </>
  );
}

export default TabContainer;
