import React from "react";

function ResetPassword() {
  return (
    <section className="stock-container bg-cmn-property">
      <div className="container container5">
        <div className="cmn-diamond-with-title-wrapper signin-wrappper">
          <div className="cmn-shadow-bg">
            <div className="cmn-title-form">
              <span>Reset Password</span>
            </div>
            <div className="billing-form">
              <form action="">
                <div className="checkout-input-box">
                  <div className="billing-input-box sign-in-input">
                    <div className="input-box">
                      <label>New Password</label>
                      <input
                        type="password"
                        placeholder="Enter your new password"
                        required
                      />
                    </div>
                  </div>
                  <div className="billing-input-box sign-in-input">
                    <div className="input-box">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        placeholder="Enter your confirm password"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="register-btn signin-btn">
                  <button>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
