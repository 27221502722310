export const PrivacyPolicy = {
  content: `<h2>Privacy and Security Policy</h2>
    <h5>Last updated June 2, 2017</h5>
    <p>
      Parin Gems (including Parin Gems, Inc., its subsidiaries and affiliates)
      (hereafter “us,” “we,” “our,” “Parin Gems,” or the “Company”) wants you to
      know that we take your privacy seriously. We are passionate about our
      business and we owe it all to you, our valued customer. We know that our
      biggest compliment is when you visit us again or refer us to a friend. We
      know that you would not do either if you did not feel safe and secure in
      doing business with us. The information below is to let you know just how
      seriously we take protecting your privacy by telling you why we collect
      your personal information, what information we collect, how we use that
      information, and how to access and update information. All references to
      our “Site” includes our Parin Gems website, internet presence, mobile
      applications and social media platforms, and all references to our
      “Services” includes our Site, customer service functions and other
      shopping or product services offered by us. This Privacy Policy is
      incorporated into our Terms of Service. If any questions should arise
      after reviewing this Privacy Policy please email us at &nbsp;
      <a>advisor@cleanorigin.com</a>
    </p>
    <p>
      Under this policy, “personal information” means information relating to an
      identified or identifiable individual.
    </p>

    <h2>Our Collection of Your Personal Information</h2>
    <ul>
      <li>Expedite your purchases and enable the Services you request,</li>
      <li>Approve and monitor your purchases,</li>
      <li>Enhance and improve our services and products,</li>
      <li>
        Enrich the general customer experience though tailored advertising and
        marketing initiatives,
      </li>
      <li>Recognize and classify your customer products and preferences,</li>
      <li>Improve Services for our customer and user base,</li>
      <li> Answer your questions and respond to requests,</li>
      <li>Manage and control Parin Gems promotions and contests,</li>
      <li>
        Distribute information regarding Parin Gems (or our partners) products
        of interest,
      </li>
      <li>
        Analyze and assess your information for completeness, errors, and
        missing data,
      </li>
      <li>Deter and preempt abuse and fraud,</li>
      <li>
        Follow applicable legal requirements and Parin Gems's policies and
        procedures, and
      </li>
      <li>Other uses with your consent.</li>
    </ul>
    <p>
      Over time we may combine the information you provide to us with other
      information that is publicly available or collected though our cookies or
      other similar available technology. In addition, we may add that to other
      information that was collected through third parties, our affiliates and
      partners. Your comments and feedback are importance to us. Those
      communications directly help us in improving our site and services. By
      communicating to us that information you effectively give the rights to it
      to Parin Gems. Additionally, we reserve the right to post and use those
      comments on our Site and in our advertising and marketing material.
      Finally, we may use those comments and material as our own Site Materials.
    </p>
    <h2>The Information We Collect</h2>
    <p>
      <strong>Provided by you :</strong> When you use the Company site you may
      provide us your personal information. This information may include
      information about your purchases, billing address, shipping address,
      gender, occupation, birthday, marital status, anniversary, interests, and
      credit card information. It may also include information you provide to us
      about a fiancé, loved one or friend in order to send them a referral or
      information about a product or service. That information will be collected
      either by electronic, written, or oral means and the Company will store it
      and use it under the terms of this Agreement.
    </p>
    <p>
      <strong>1. Analytics and Customization:</strong> Parin Gems uses analytics
      and cookies, including third party cookies, to collect information just on
      how our Services are being used by our visitors and customers to measure
      how successful our marketing campaigns are, and to customize the content
      that each customer sees. Finally these cookies and third-party tracking
      software aid in our ability to prevent fraud.
    </p>
    <p>
    <strong>2. Performance and Functionality: :</strong> Cookies help improve the user experience by helping us improve our Site’s performance and functionality. The use of cookies enables us to recognize your computer, smart phone, or mobile device sooner. This also means that not only will our Site populate more quickly but it will allow you to see your previously viewed items and shopping cart prior to logging back into your account.
  </p>
  <p>
  <strong>3. Essential Operational:</strong>  Cookies are paramount in allowing us to operate our Services. For instance, cookies are necessary for you to access secure areas (i.e. your account and shopping list).

</p>
<p>
<strong>4. IP Address :</strong> When visiting our Site, Parin Gems gathers your IP address to analyze and diagnose problems, and improve the performance of the Site, servers, and hosting providers as well as to assess trends and traffic patterns, collect demographic and statistical information, and track when the visit was and its length. To continuously improve security protocols your IP address may also be used along with your personally identifiable information for credit fraud protection and risk reduction.
</p>
<p>
<strong>5. Social networking cookies :</strong> Cookies allow visitors to share our pages and content through third-party social networking websites.
</p>
<p>
<strong>6.Targeting/advertising :</strong> Cookies allow us to customize the advertisements you receive, and afford you with individualized offers, and quantify their effectiveness.
</p>
<p>Most internet browsers allow the user to decline cookies. As such you are always able to refuse the use and access of cookies. However, if you decline the use of cookies then you may be unable to access certain portion of the Site and some of our Services. Even if you decline the use of cookies, we may still be able to identify and recognize your computer, smart phone, or mobile device. Finally, some web browsers have a “Do Not Track” feature incorporated into them. Since this is a new technology and does not have an industry based standardize response to them, we do not respond to them. For more information about the use of cookies and how to block them, visit www.allaboutcookies.org, www.youronlinechoices.eu (Europe) or www.aboutads.info/choices (United States).</p>


    
    
    
    
    
    
    `,
};

export const TermsConditions = `
       <h2>Terms & Conditions</h2>
                <h5>Last updated June 2, 2017</h5>
                <p>
                  Please review and read these Terms and Conditions (these
                  “Terms”) carefully as they represent the binding terms with
                  which you may engage with Parin Gems, its website and/or other
                  Internet presence, including any of its social media
                  platforms, and other electronic and telecommunication services
                  (collectively, the “Site”, and/or “Services”). BY USING THE
                  SITE, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
                </p>
                <p>
                  These Terms constitute an agreement between you and Parin Gems
                  (“Parin Gems,” “we”, “our”) and govern any purchases that you
                  make through and your use, and interaction with the Site. The
                  right to modify, change, amend or update these Terms at any
                  time is reserved by Parin Gems in its sole and exclusive
                  discretion. Modifications, changes, amendments or updates will
                  take immediate effect and shall be posted on Parin Gems's
                  Site. Any such update shall be noted by the “last updated”
                  date at the beginning of these Terms. Your continued use of
                  the Site and engagement with Parin Gems for use of the
                  Services shall be deemed your consent to any such change. As
                  such, we recommend that you regularly review these Terms as
                  frequently as you use the Services and Site. If you do not
                  agree to the Terms as indicated herein, stop using the Site
                  immediately.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Our Site's Information:</h2>
                <p>
                  We make every effort to ensure that our online inventory is as
                  up-to-date and accurate as possible. It is our primary mission
                  to ensure that you have the best shopping experience as
                  possible while using our Site. To do so, some of our products
                  appear larger in their photographs to help you see their
                  clarity and characteristics in greater detail. Please note
                  that because of this some objects will appear larger in their
                  photograph than their actual size and that depending on your
                  computer monitor or viewing screen some products' color and
                  luster may vary. We take great pride in our efforts to provide
                  you with as much information as possible about our products on
                  our Sites and wish to ensure that you make informed decisions
                  about your purchases. To help to accomplish that goal please
                  note that all Lab Created diamonds weights are approximate.
                  Refer to the following for size ranges appropriate to each
                  carat weight listing:
                </p>
                <p>
                  1/20 carat 0.04-0.06; 1/10 carat 0.085-0.11; 1/8 carat
                  0.115-.14; 1/6 carat 0.145-0.17; 1/5 carat 0.18-0.22; 1/4
                  carat 0.23-0.28; 1/3 0.29-0.36; 3/8 carat 0.37-0.44; 1/2 carat
                  0.45-0.57; 5/8 carat 0.58-0.68; 3/4 carat 0.69-0.82; 7/8 carat
                  0.83-0.94; 1 carat 0.95-1.11; 1 1/5 carats 1.18-1.22; 1 1/4
                  carats 1.23-1.28; 1 1/3 carats 1.29-1.36; 1 3/8 carats
                  1.37-1.44; 1 1/2 carats 1.45-1.57; 1 5/8 carats 1.58-1.68; 1
                  3/4 carats 1.69-1.82; 1 7/8 carats 1.83-1.94; 2 carats
                  1.95-2.11; 3 carats 2.95-3.17; 4 carats 3.95-4.17; 5 carats
                  4.95-5.17; 6 carats 5.95-6.17; 7 carats 6.95-7.17.
                </p>
                <p>
                  Additionally, some tolerances may be allowed for based on
                  measurement and manufacturing. Up .20mm for wedding bands and
                  .24mm for manufactured gemstones and pearls may be allowed.
                </p>
                <p>
                  Lab Created diamond jewelry, whether natural, laboratory-made
                  or imitation, that is set with several Lab Created diamonds,
                  will be provided with the minimum total carat weight (TW) for
                  the entire piece. Clarity of grades and color will be
                  identified as a “minimum” or “average” contingent upon the
                  number of Lab Created diamonds. If a minimum is what is
                  stated, then all Lab Created diamonds in that piece will be at
                  or above that minimum weight. If the average is stated then
                  the total aggregate quality of the piece is that identified
                  weight.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Your Account</h2>
                <p>
                  You are solely liable and responsible for keeping and
                  protecting your Parin Gems account username and password
                  confidential and limiting its access to only yourself. In
                  addition, you are solely liable for activities that take place
                  under your individual account and accessed with your password.
                  You may only use our Site with the direction and guidance of a
                  parent or guardian if you are under the age of 18. Finally,
                  take note that we reserve the right to terminate personal
                  accounts, deny service, or cancel orders at any time in our
                  sole discretion and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Use of the Site</h2>
                <p>
                  By using the Site you are given an individual, non-exclusive,
                  non-sublicensable, and limited license to use and access our
                  Site, print, and electronically copy portions of our Site for
                  your personal non-commercial, and informational use only. This
                  license is governed by these Terms and prohibits: (a) any
                  commercial use of the Parin Gems Site or Materials; (b) use of
                  product listings, photographs, images, or descriptions in the
                  pursuit of commerce; (c) the public dissemination or posting
                  of Parin Gems Materials; (d) using the Site for alternative
                  purposes other than what is prescribed by the Terms; (e)
                  utilizing the Site for data mining purposes; (f) manipulating
                  the Site or its appearance, or creating alternative links or
                  other window-exiting programming or pop-up triggers; (g) use
                  of the Site in a way that inhibits or effects the daily
                  operation of the Site or Services; or (h) any other
                  non-intended use or purpose. Any unauthorized use of the Site
                  or Materials contained therein is strictly prohibited and will
                  prompt the immediate termination of the license granted
                  herein, unless otherwise authorized by Parin Gems in writing.
                  The aforementioned unauthorized use may additionally violate
                  copyright and trademark laws and communications regulations
                  and statutes and other applicable laws. Unless otherwise
                  stated, nothing in these Terms shall be interpreted as having
                  granted any license to intellectual property rights, whether
                  by implication, estoppel, or otherwise. This license is
                  revocable by Parin Gems at any time and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Privacy Policy</h2>
                <p>
                  Please see the Parin Gems <a href="#">Privacy Policy</a> for
                  information regarding how personally identifiable information
                  is used, collected, and disclosed.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Product Availability and Pricing</h2>
                <p>
                  If a product is temporarily unavailable, please contact us so
                  we can assist you by informing you when it will become
                  available next and to facilitate placing an order.
                  Occasionally, due to the volume of sales, an item will become
                  unavailable prior to our ability to update the Site. If such
                  an instance should occur, we will notify you as soon as
                  possible and discuss with you your options and possible
                  alternatives to proceed with a purchase or change your order
                  selection or give you a full or partial refund.
                </p>
                <p>
                  On occasion some data points, including prices, may be
                  displayed incorrectly due to a technical or scrivener error.
                  Though we make every reasonable attempt to prevent such errors
                  and confusion, mistakes happen. When such an instance arises
                  we will correct the error immediately upon its discovery.
                  However, we will not honor the erroneous price or listing. If
                  a product has been shipped and received by you, we will
                  contact you promptly and discuss your options to either return
                  the product or to charge you for the difference in price. If,
                  as a result of the error, the price is lower than what was
                  stated and you contact us, we will discuss your reimbursement
                  options. All our prices are final; we do not negotiate prices
                  on our products.
                </p>
       
`;

export const CookiePolicy = `
                <h2>Cookie Policy</h2>
                <h5>Last updated June 2, 2021</h5>
                <p>
                  Please review and read these Terms and Conditions (these
                  “Terms”) carefully as they represent the binding terms with
                  which you may engage with Parin Gems, its website and/or other
                  Internet presence, including any of its social media
                  platforms, and other electronic and telecommunication services
                  (collectively, the “Site”, and/or “Services”). BY USING THE
                  SITE, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
                </p>
                <p>
                  These Terms constitute an agreement between you and Parin Gems
                  (“Parin Gems,” “we”, “our”) and govern any purchases that you
                  make through and your use, and interaction with the Site. The
                  right to modify, change, amend or update these Terms at any
                  time is reserved by Parin Gems in its sole and exclusive
                  discretion. Modifications, changes, amendments or updates will
                  take immediate effect and shall be posted on Parin Gems's
                  Site. Any such update shall be noted by the “last updated”
                  date at the beginning of these Terms. Your continued use of
                  the Site and engagement with Parin Gems for use of the
                  Services shall be deemed your consent to any such change. As
                  such, we recommend that you regularly review these Terms as
                  frequently as you use the Services and Site. If you do not
                  agree to the Terms as indicated herein, stop using the Site
                  immediately.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Our Site's Information:</h2>
                <p>
                  We make every effort to ensure that our online inventory is as
                  up-to-date and accurate as possible. It is our primary mission
                  to ensure that you have the best shopping experience as
                  possible while using our Site. To do so, some of our products
                  appear larger in their photographs to help you see their
                  clarity and characteristics in greater detail. Please note
                  that because of this some objects will appear larger in their
                  photograph than their actual size and that depending on your
                  computer monitor or viewing screen some products' color and
                  luster may vary. We take great pride in our efforts to provide
                  you with as much information as possible about our products on
                  our Sites and wish to ensure that you make informed decisions
                  about your purchases. To help to accomplish that goal please
                  note that all Lab Created diamonds weights are approximate.
                  Refer to the following for size ranges appropriate to each
                  carat weight listing:
                </p>
                <p>
                  1/20 carat 0.04-0.06; 1/10 carat 0.085-0.11; 1/8 carat
                  0.115-.14; 1/6 carat 0.145-0.17; 1/5 carat 0.18-0.22; 1/4
                  carat 0.23-0.28; 1/3 0.29-0.36; 3/8 carat 0.37-0.44; 1/2 carat
                  0.45-0.57; 5/8 carat 0.58-0.68; 3/4 carat 0.69-0.82; 7/8 carat
                  0.83-0.94; 1 carat 0.95-1.11; 1 1/5 carats 1.18-1.22; 1 1/4
                  carats 1.23-1.28; 1 1/3 carats 1.29-1.36; 1 3/8 carats
                  1.37-1.44; 1 1/2 carats 1.45-1.57; 1 5/8 carats 1.58-1.68; 1
                  3/4 carats 1.69-1.82; 1 7/8 carats 1.83-1.94; 2 carats
                  1.95-2.11; 3 carats 2.95-3.17; 4 carats 3.95-4.17; 5 carats
                  4.95-5.17; 6 carats 5.95-6.17; 7 carats 6.95-7.17.
                </p>
                <p>
                  Additionally, some tolerances may be allowed for based on
                  measurement and manufacturing. Up .20mm for wedding bands and
                  .24mm for manufactured gemstones and pearls may be allowed.
                </p>
                <p>
                  Lab Created diamond jewelry, whether natural, laboratory-made
                  or imitation, that is set with several Lab Created diamonds,
                  will be provided with the minimum total carat weight (TW) for
                  the entire piece. Clarity of grades and color will be
                  identified as a “minimum” or “average” contingent upon the
                  number of Lab Created diamonds. If a minimum is what is
                  stated, then all Lab Created diamonds in that piece will be at
                  or above that minimum weight. If the average is stated then
                  the total aggregate quality of the piece is that identified
                  weight.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Your Account</h2>
                <p>
                  You are solely liable and responsible for keeping and
                  protecting your Parin Gems account username and password
                  confidential and limiting its access to only yourself. In
                  addition, you are solely liable for activities that take place
                  under your individual account and accessed with your password.
                  You may only use our Site with the direction and guidance of a
                  parent or guardian if you are under the age of 18. Finally,
                  take note that we reserve the right to terminate personal
                  accounts, deny service, or cancel orders at any time in our
                  sole discretion and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Use of the Site</h2>
                <p>
                  By using the Site you are given an individual, non-exclusive,
                  non-sublicensable, and limited license to use and access our
                  Site, print, and electronically copy portions of our Site for
                  your personal non-commercial, and informational use only. This
                  license is governed by these Terms and prohibits: (a) any
                  commercial use of the Parin Gems Site or Materials; (b) use of
                  product listings, photographs, images, or descriptions in the
                  pursuit of commerce; (c) the public dissemination or posting
                  of Parin Gems Materials; (d) using the Site for alternative
                  purposes other than what is prescribed by the Terms; (e)
                  utilizing the Site for data mining purposes; (f) manipulating
                  the Site or its appearance, or creating alternative links or
                  other window-exiting programming or pop-up triggers; (g) use
                  of the Site in a way that inhibits or effects the daily
                  operation of the Site or Services; or (h) any other
                  non-intended use or purpose. Any unauthorized use of the Site
                  or Materials contained therein is strictly prohibited and will
                  prompt the immediate termination of the license granted
                  herein, unless otherwise authorized by Parin Gems in writing.
                  The aforementioned unauthorized use may additionally violate
                  copyright and trademark laws and communications regulations
                  and statutes and other applicable laws. Unless otherwise
                  stated, nothing in these Terms shall be interpreted as having
                  granted any license to intellectual property rights, whether
                  by implication, estoppel, or otherwise. This license is
                  revocable by Parin Gems at any time and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Privacy Policy</h2>
                <p>
                  Please see the Parin Gems <a href="#">Privacy Policy</a> for
                  information regarding how personally identifiable information
                  is used, collected, and disclosed.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Product Availability and Pricing</h2>
                <p>
                  If a product is temporarily unavailable, please contact us so
                  we can assist you by informing you when it will become
                  available next and to facilitate placing an order.
                  Occasionally, due to the volume of sales, an item will become
                  unavailable prior to our ability to update the Site. If such
                  an instance should occur, we will notify you as soon as
                  possible and discuss with you your options and possible
                  alternatives to proceed with a purchase or change your order
                  selection or give you a full or partial refund.
                </p>
                <p>
                  On occasion some data points, including prices, may be
                  displayed incorrectly due to a technical or scrivener error.
                  Though we make every reasonable attempt to prevent such errors
                  and confusion, mistakes happen. When such an instance arises
                  we will correct the error immediately upon its discovery.
                  However, we will not honor the erroneous price or listing. If
                  a product has been shipped and received by you, we will
                  contact you promptly and discuss your options to either return
                  the product or to charge you for the difference in price. If,
                  as a result of the error, the price is lower than what was
                  stated and you contact us, we will discuss your reimbursement
                  options. All our prices are final; we do not negotiate prices
                  on our products.
                </p>
            `;

export const DisclaimerPolicy = `    <h2>Disclaimer Policy</h2>
                <h5>Last updated June 2, 2017</h5>
                <p>
                  Please review and read these Terms and Conditions (these
                  “Terms”) carefully as they represent the binding terms with
                  which you may engage with Parin Gems, its website and/or other
                  Internet presence, including any of its social media
                  platforms, and other electronic and telecommunication services
                  (collectively, the “Site”, and/or “Services”). BY USING THE
                  SITE, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
                </p>
                <p>
                  These Terms constitute an agreement between you and Parin Gems
                  (“Parin Gems,” “we”, “our”) and govern any purchases that you
                  make through and your use, and interaction with the Site. The
                  right to modify, change, amend or update these Terms at any
                  time is reserved by Parin Gems in its sole and exclusive
                  discretion. Modifications, changes, amendments or updates will
                  take immediate effect and shall be posted on Parin Gems's
                  Site. Any such update shall be noted by the “last updated”
                  date at the beginning of these Terms. Your continued use of
                  the Site and engagement with Parin Gems for use of the
                  Services shall be deemed your consent to any such change. As
                  such, we recommend that you regularly review these Terms as
                  frequently as you use the Services and Site. If you do not
                  agree to the Terms as indicated herein, stop using the Site
                  immediately.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Our Site's Information:</h2>
                <p>
                  We make every effort to ensure that our online inventory is as
                  up-to-date and accurate as possible. It is our primary mission
                  to ensure that you have the best shopping experience as
                  possible while using our Site. To do so, some of our products
                  appear larger in their photographs to help you see their
                  clarity and characteristics in greater detail. Please note
                  that because of this some objects will appear larger in their
                  photograph than their actual size and that depending on your
                  computer monitor or viewing screen some products' color and
                  luster may vary. We take great pride in our efforts to provide
                  you with as much information as possible about our products on
                  our Sites and wish to ensure that you make informed decisions
                  about your purchases. To help to accomplish that goal please
                  note that all Lab Created diamonds weights are approximate.
                  Refer to the following for size ranges appropriate to each
                  carat weight listing:
                </p>
                <p>
                  1/20 carat 0.04-0.06; 1/10 carat 0.085-0.11; 1/8 carat
                  0.115-.14; 1/6 carat 0.145-0.17; 1/5 carat 0.18-0.22; 1/4
                  carat 0.23-0.28; 1/3 0.29-0.36; 3/8 carat 0.37-0.44; 1/2 carat
                  0.45-0.57; 5/8 carat 0.58-0.68; 3/4 carat 0.69-0.82; 7/8 carat
                  0.83-0.94; 1 carat 0.95-1.11; 1 1/5 carats 1.18-1.22; 1 1/4
                  carats 1.23-1.28; 1 1/3 carats 1.29-1.36; 1 3/8 carats
                  1.37-1.44; 1 1/2 carats 1.45-1.57; 1 5/8 carats 1.58-1.68; 1
                  3/4 carats 1.69-1.82; 1 7/8 carats 1.83-1.94; 2 carats
                  1.95-2.11; 3 carats 2.95-3.17; 4 carats 3.95-4.17; 5 carats
                  4.95-5.17; 6 carats 5.95-6.17; 7 carats 6.95-7.17.
                </p>
                <p>
                  Additionally, some tolerances may be allowed for based on
                  measurement and manufacturing. Up .20mm for wedding bands and
                  .24mm for manufactured gemstones and pearls may be allowed.
                </p>
                <p>
                  Lab Created diamond jewelry, whether natural, laboratory-made
                  or imitation, that is set with several Lab Created diamonds,
                  will be provided with the minimum total carat weight (TW) for
                  the entire piece. Clarity of grades and color will be
                  identified as a “minimum” or “average” contingent upon the
                  number of Lab Created diamonds. If a minimum is what is
                  stated, then all Lab Created diamonds in that piece will be at
                  or above that minimum weight. If the average is stated then
                  the total aggregate quality of the piece is that identified
                  weight.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Your Account</h2>
                <p>
                  You are solely liable and responsible for keeping and
                  protecting your Parin Gems account username and password
                  confidential and limiting its access to only yourself. In
                  addition, you are solely liable for activities that take place
                  under your individual account and accessed with your password.
                  You may only use our Site with the direction and guidance of a
                  parent or guardian if you are under the age of 18. Finally,
                  take note that we reserve the right to terminate personal
                  accounts, deny service, or cancel orders at any time in our
                  sole discretion and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Use of the Site</h2>
                <p>
                  By using the Site you are given an individual, non-exclusive,
                  non-sublicensable, and limited license to use and access our
                  Site, print, and electronically copy portions of our Site for
                  your personal non-commercial, and informational use only. This
                  license is governed by these Terms and prohibits: (a) any
                  commercial use of the Parin Gems Site or Materials; (b) use of
                  product listings, photographs, images, or descriptions in the
                  pursuit of commerce; (c) the public dissemination or posting
                  of Parin Gems Materials; (d) using the Site for alternative
                  purposes other than what is prescribed by the Terms; (e)
                  utilizing the Site for data mining purposes; (f) manipulating
                  the Site or its appearance, or creating alternative links or
                  other window-exiting programming or pop-up triggers; (g) use
                  of the Site in a way that inhibits or effects the daily
                  operation of the Site or Services; or (h) any other
                  non-intended use or purpose. Any unauthorized use of the Site
                  or Materials contained therein is strictly prohibited and will
                  prompt the immediate termination of the license granted
                  herein, unless otherwise authorized by Parin Gems in writing.
                  The aforementioned unauthorized use may additionally violate
                  copyright and trademark laws and communications regulations
                  and statutes and other applicable laws. Unless otherwise
                  stated, nothing in these Terms shall be interpreted as having
                  granted any license to intellectual property rights, whether
                  by implication, estoppel, or otherwise. This license is
                  revocable by Parin Gems at any time and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Privacy Policy</h2>
                <p>
                  Please see the Parin Gems <a href="#">Privacy Policy</a> for
                  information regarding how personally identifiable information
                  is used, collected, and disclosed.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Product Availability and Pricing</h2>
                <p>
                  If a product is temporarily unavailable, please contact us so
                  we can assist you by informing you when it will become
                  available next and to facilitate placing an order.
                  Occasionally, due to the volume of sales, an item will become
                  unavailable prior to our ability to update the Site. If such
                  an instance should occur, we will notify you as soon as
                  possible and discuss with you your options and possible
                  alternatives to proceed with a purchase or change your order
                  selection or give you a full or partial refund.
                </p>
                <p>
                  On occasion some data points, including prices, may be
                  displayed incorrectly due to a technical or scrivener error.
                  Though we make every reasonable attempt to prevent such errors
                  and confusion, mistakes happen. When such an instance arises
                  we will correct the error immediately upon its discovery.
                  However, we will not honor the erroneous price or listing. If
                  a product has been shipped and received by you, we will
                  contact you promptly and discuss your options to either return
                  the product or to charge you for the difference in price. If,
                  as a result of the error, the price is lower than what was
                  stated and you contact us, we will discuss your reimbursement
                  options. All our prices are final; we do not negotiate prices
                  on our products.
                </p>
             `;

export const shippinginformation = `
                <h2>Shipping Information</h2>
                <h5>Last updated June 2, 2017</h5>
                <p>
                  Mauris bibendum quis urna et accumsan. Vestibulum et
                  pellentesque mauris. Mauris at elit commodo, pretium arcu ac,
                  viverra augue. Aenean consequat viverra lectus varius tempor.
                  Ut eu dolor est. Praesent suscipit rhoncus blandit. Quisque
                  ultricies diam id velit dignissim, vitae dignissim augue
                  finibus. Suspendisse in mauris nec urna lacinia aliquet varius
                  non quam. Nunc egestas id velit nec tincidunt. Etiam sit amet
                  sem luctus, ultricies ligula eu, rhoncus ligula. In congue ex
                  molestie urna congue tincidunt. Quisque sem magna, suscipit
                  vitae volutpat eget, ornare nec velit. Nullam velit lacus,
                  suscipit eget porttitor sed, interdum vel urna. Nunc lacinia
                  dapibus massa, ut convallis nunc porta sit amet.
                </p>
                <p>
                  Suspendisse eu sodales metus. Sed consequat eros quis suscipit
                  dapibus. Aliquam erat volutpat. Nulla et venenatis justo, nec
                  facilisis eros. Etiam vitae rhoncus dolor. Ut dapibus magna id
                  nisi luctus, at posuere ex consequat. Donec vitae risus ut
                  tellus varius porta. Maecenas at massa ac mauris feugiat
                  vestibulum. Sed interdum iaculis odio, ut rhoncus urna
                  vehicula at. Maecenas egestas consectetur ultrices. Maecenas a
                  semper lectus.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Our Site's Information:</h2>
                <p>
                  We make every effort to ensure that our online inventory is as
                  up-to-date and accurate as possible. It is our primary mission
                  to ensure that you have the best shopping experience as
                  possible while using our Site. To do so, some of our products
                  appear larger in their photographs to help you see their
                  clarity and characteristics in greater detail. Please note
                  that because of this some objects will appear larger in their
                  photograph than their actual size and that depending on your
                  computer monitor or viewing screen some products' color and
                  luster may vary. We take great pride in our efforts to provide
                  you with as much information as possible about our products on
                  our Sites and wish to ensure that you make informed decisions
                  about your purchases. To help to accomplish that goal please
                  note that all Lab Created diamonds weights are approximate.
                  Refer to the following for size ranges appropriate to each
                  carat weight listing:
                </p>
                <p>
                  Sed a fringilla arcu. Donec vehicula placerat mattis. Quisque
                  in lectus quis sem luctus porttitor. Fusce ac condimentum
                  odio, a ultricies ligula. Praesent at mauris et erat auctor
                  ultricies. Integer elementum bibendum eleifend. Nulla et purus
                  volutpat, suscipit erat sit amet, semper eros. Donec mollis
                  porttitor justo sed rutrum.
                </p>
                <p>
                  Additionally, some tolerances may be allowed for based on
                  measurement and manufacturing. Up .20mm for wedding bands and
                  .24mm for manufactured gemstones and pearls may be allowed.
                </p>
                <p>
                  Lab Created diamond jewelry, whether natural, laboratory-made
                  or imitation, that is set with several Lab Created diamonds,
                  will be provided with the minimum total carat weight (TW) for
                  the entire piece. Clarity of grades and color will be
                  identified as a “minimum” or “average” contingent upon the
                  number of Lab Created diamonds. If a minimum is what is
                  stated, then all Lab Created diamonds in that piece will be at
                  or above that minimum weight. If the average is stated then
                  the total aggregate quality of the piece is that identified
                  weight.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Your Address</h2>
                <p>
                  You are solely liable and responsible for keeping and
                  protecting your Parin Gems account username and password
                  confidential and limiting its access to only yourself. In
                  addition, you are solely liable for activities that take place
                  under your individual account and accessed with your password.
                  You may only use our Site with the direction and guidance of a
                  parent or guardian if you are under the age of 18. Finally,
                  take note that we reserve the right to terminate personal
                  accounts, deny service, or cancel orders at any time in our
                  sole discretion and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Use of the Site</h2>
                <p>
                  By using the Site you are given an individual, non-exclusive,
                  non-sublicensable, and limited license to use and access our
                  Site, print, and electronically copy portions of our Site for
                  your personal non-commercial, and informational use only. This
                  license is governed by these Terms and prohibits: (a) any
                  commercial use of the Parin Gems Site or Materials; (b) use of
                  product listings, photographs, images, or descriptions in the
                  pursuit of commerce; (c) the public dissemination or posting
                  of Parin Gems Materials; (d) using the Site for alternative
                  purposes other than what is prescribed by the Terms; (e)
                  utilizing the Site for data mining purposes; (f) manipulating
                  the Site or its appearance, or creating alternative links or
                  other window-exiting programming or pop-up triggers; (g) use
                  of the Site in a way that inhibits or effects the daily
                  operation of the Site or Services; or (h) any other
                  non-intended use or purpose. Any unauthorized use of the Site
                  or Materials contained therein is strictly prohibited and will
                  prompt the immediate termination of the license granted
                  herein, unless otherwise authorized by Parin Gems in writing.
                  The aforementioned unauthorized use may additionally violate
                  copyright and trademark laws and communications regulations
                  and statutes and other applicable laws. Unless otherwise
                  stated, nothing in these Terms shall be interpreted as having
                  granted any license to intellectual property rights, whether
                  by implication, estoppel, or otherwise. This license is
                  revocable by Parin Gems at any time and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Privacy Policy</h2>
                <p>
                  Please see the Parin Gems <a href="#">Privacy Policy</a> for
                  information regarding how personally identifiable information
                  is used, collected, and disclosed.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Product Availability and Pricing</h2>
                <p>
                  If a product is temporarily unavailable, please contact us so
                  we can assist you by informing you when it will become
                  available next and to facilitate placing an order.
                  Occasionally, due to the volume of sales, an item will become
                  unavailable prior to our ability to update the Site. If such
                  an instance should occur, we will notify you as soon as
                  possible and discuss with you your options and possible
                  alternatives to proceed with a purchase or change your order
                  selection or give you a full or partial refund.
                </p>
                <p>
                  On occasion some data points, including prices, may be
                  displayed incorrectly due to a technical or scrivener error.
                  Though we make every reasonable attempt to prevent such errors
                  and confusion, mistakes happen. When such an instance arises
                  we will correct the error immediately upon its discovery.
                  However, we will not honor the erroneous price or listing. If
                  a product has been shipped and received by you, we will
                  contact you promptly and discuss your options to either return
                  the product or to charge you for the difference in price. If,
                  as a result of the error, the price is lower than what was
                  stated and you contact us, we will discuss your reimbursement
                  options. All our prices are final; we do not negotiate prices
                  on our products.
                </p>
         `;
export const naturalvslabcreateddiamonds = `
                <h2>Natural vs Lab Created Diamonds</h2>
                <h5>Last updated June 2, 2017</h5>
                <p>
                  Pellentesque vel massa a neque efficitur tristique quis a
                  lorem. Pellentesque sit amet arcu ornare, tempor leo quis,
                  egestas libero. In eget turpis in turpis congue efficitur at
                  vitae enim. Quisque ullamcorper dui non dolor malesuada
                  tincidunt. Mauris justo nisl, sollicitudin a gravida non,
                  semper sit amet felis. Integer lacinia sem vitae leo faucibus
                  convallis. Suspendisse finibus ullamcorper sagittis. Praesent
                  nunc erat, placerat ac bibendum gravida, congue eget velit.
                  Vestibulum ante ipsum primis in faucibus orci luctus et
                  ultrices posuere cubilia curae; Sed egestas dui velit, ut
                  ultrices nisi porta vitae. Sed viverra lorem a tempor
                  vulputate. Nullam id eros ligula.
                </p>
                <p>
                  Ut mi nunc, ultricies nec arcu eu, ornare tempus nibh.
                  Suspendisse potenti. Quisque eu hendrerit libero. Morbi eu
                  diam tincidunt leo elementum fringilla. Nulla id consequat
                  quam, dignissim mollis purus. Etiam porttitor mattis nibh.
                  Duis consectetur est at enim tristique euismod. Quisque
                  vulputate, mauris eget malesuada eleifend, ipsum diam
                  consequat nunc, sit amet facilisis orci velit ut leo. Aliquam
                  diam risus, blandit et tristique quis, venenatis ac odio. Sed
                  quam purus, tincidunt eu neque nec, pharetra malesuada purus.
                  Nullam vulputate ac felis non dictum. Donec lacinia lobortis
                  ante non ultrices. Nam purus odio, accumsan hendrerit
                  elementum nec, viverra sit amet est. Aenean at sollicitudin
                  erat, id volutpat tortor. Nam porta ut leo non mollis.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Our Site's Information:</h2>
                <p>
                  We make every effort to ensure that our online inventory is as
                  up-to-date and accurate as possible. It is our primary mission
                  to ensure that you have the best shopping experience as
                  possible while using our Site. To do so, some of our products
                  appear larger in their photographs to help you see their
                  clarity and characteristics in greater detail. Please note
                  that because of this some objects will appear larger in their
                  photograph than their actual size and that depending on your
                  computer monitor or viewing screen some products' color and
                  luster may vary. We take great pride in our efforts to provide
                  you with as much information as possible about our products on
                  our Sites and wish to ensure that you make informed decisions
                  about your purchases. To help to accomplish that goal please
                  note that all Lab Created diamonds weights are approximate.
                  Refer to the following for size ranges appropriate to each
                  carat weight listing:
                </p>
                <p>
                  Sed a fringilla arcu. Donec vehicula placerat mattis. Quisque
                  in lectus quis sem luctus porttitor. Fusce ac condimentum
                  odio, a ultricies ligula. Praesent at mauris et erat auctor
                  ultricies. Integer elementum bibendum eleifend. Nulla et purus
                  volutpat, suscipit erat sit amet, semper eros. Donec mollis
                  porttitor justo sed rutrum.
                </p>
                <p>
                  Additionally, some tolerances may be allowed for based on
                  measurement and manufacturing. Up .20mm for wedding bands and
                  .24mm for manufactured gemstones and pearls may be allowed.
                </p>
                <p>
                  Lab Created diamond jewelry, whether natural, laboratory-made
                  or imitation, that is set with several Lab Created diamonds,
                  will be provided with the minimum total carat weight (TW) for
                  the entire piece. Clarity of grades and color will be
                  identified as a “minimum” or “average” contingent upon the
                  number of Lab Created diamonds. If a minimum is what is
                  stated, then all Lab Created diamonds in that piece will be at
                  or above that minimum weight. If the average is stated then
                  the total aggregate quality of the piece is that identified
                  weight.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Your Address</h2>
                <p>
                  You are solely liable and responsible for keeping and
                  protecting your Parin Gems account username and password
                  confidential and limiting its access to only yourself. In
                  addition, you are solely liable for activities that take place
                  under your individual account and accessed with your password.
                  You may only use our Site with the direction and guidance of a
                  parent or guardian if you are under the age of 18. Finally,
                  take note that we reserve the right to terminate personal
                  accounts, deny service, or cancel orders at any time in our
                  sole discretion and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Use of the Site</h2>
                <p>
                  By using the Site you are given an individual, non-exclusive,
                  non-sublicensable, and limited license to use and access our
                  Site, print, and electronically copy portions of our Site for
                  your personal non-commercial, and informational use only. This
                  license is governed by these Terms and prohibits: (a) any
                  commercial use of the Parin Gems Site or Materials; (b) use of
                  product listings, photographs, images, or descriptions in the
                  pursuit of commerce; (c) the public dissemination or posting
                  of Parin Gems Materials; (d) using the Site for alternative
                  purposes other than what is prescribed by the Terms; (e)
                  utilizing the Site for data mining purposes; (f) manipulating
                  the Site or its appearance, or creating alternative links or
                  other window-exiting programming or pop-up triggers; (g) use
                  of the Site in a way that inhibits or effects the daily
                  operation of the Site or Services; or (h) any other
                  non-intended use or purpose. Any unauthorized use of the Site
                  or Materials contained therein is strictly prohibited and will
                  prompt the immediate termination of the license granted
                  herein, unless otherwise authorized by Parin Gems in writing.
                  The aforementioned unauthorized use may additionally violate
                  copyright and trademark laws and communications regulations
                  and statutes and other applicable laws. Unless otherwise
                  stated, nothing in these Terms shall be interpreted as having
                  granted any license to intellectual property rights, whether
                  by implication, estoppel, or otherwise. This license is
                  revocable by Parin Gems at any time and for any reason.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Privacy Policy</h2>
                <p>
                  Please see the Parin Gems <a href="#">Privacy Policy</a> for
                  information regarding how personally identifiable information
                  is used, collected, and disclosed.
                </p>
              </div>
              <div className="policy-cont">
                <h2>Product Availability and Pricing</h2>
                <p>
                  If a product is temporarily unavailable, please contact us so
                  we can assist you by informing you when it will become
                  available next and to facilitate placing an order.
                  Occasionally, due to the volume of sales, an item will become
                  unavailable prior to our ability to update the Site. If such
                  an instance should occur, we will notify you as soon as
                  possible and discuss with you your options and possible
                  alternatives to proceed with a purchase or change your order
                  selection or give you a full or partial refund.
                </p>
                <p>
                  On occasion some data points, including prices, may be
                  displayed incorrectly due to a technical or scrivener error.
                  Though we make every reasonable attempt to prevent such errors
                  and confusion, mistakes happen. When such an instance arises
                  we will correct the error immediately upon its discovery.
                  However, we will not honor the erroneous price or listing. If
                  a product has been shipped and received by you, we will
                  contact you promptly and discuss your options to either return
                  the product or to charge you for the difference in price. If,
                  as a result of the error, the price is lower than what was
                  stated and you contact us, we will discuss your reimbursement
                  options. All our prices are final; we do not negotiate prices
                  on our products.
             `;
