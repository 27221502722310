import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";
import { resetSearchRemember } from "../../../redux/reducers/StockFilterSlice";
import { Domain, DomainWithOutHttp } from "../../../constant";
function ShapeFilter() {
  const { stockFilter, searchRemember } = useSelector(
    (state) => state.stockFilterReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    !searchRemember && dispatch(getProduct(stockFilter));
    !searchRemember && dispatch(resetProduct());
    searchRemember && dispatch(resetSearchRemember());
  }, [stockFilter]);

  const ShapeData = useSelector(
    (state) => state.commonReducer.masterlist.shape
  );

  const selectedShape = useSelector(
    (state) => state.stockFilterReducer.stockFilter.shape
  );

  const handleClick = (shape) => {
    dispatch(addStockFilter({ shape, loadmore: false, PageNum: 1 }));
    dispatch(resetProductPageNum());
  };

  const [show, SetShow] = useState({ showmore: false, showtitle: "Show More" });
  const showmoreClick = () => {
    if (!show.showmore) {
      SetShow({ showmore: true, showtitle: "Show Less" });
    } else {
      SetShow({ showmore: false, showtitle: "Show More" });
    }
  };

  const ListElement = ({ name, imageUrl }) => {
    return (
      <li
        className={`single-image ${selectedShape === name && "active"}`}
        onClick={() => handleClick(name)}
      >
        <span className="img-div">
          <img src={imageUrl} className="img-fluid" alt="diam" />
        </span>
        <span className="img-cont">{name}</span>
      </li>
    );
  };

  return (
    <div className="diamond-shapes">
      <div className="diamond-shap-titile">
        <span>Diamond Shape:</span>
        <span>
          <a className="moreless-button" onClick={showmoreClick}>
            {show.showtitle}
          </a>
        </span>
      </div>
      <div className="diffrent-shape-images">
        <ul>
          {ShapeData &&
            ShapeData.map((item, index) => {
              if (index < 4) {
                return (
                  <ListElement
                    name={item.DisplayName}
                    imageUrl={`${Domain}/Content/DomainData/${DomainWithOutHttp}/img/Diamonds/Active/${item.DisplayName}_active.png`}
                    key={item.DisplayName}
                  />
                );
              }
            })}

          {show.showmore &&
            ShapeData &&
            ShapeData.map((item, index) => {
              if (index >= 4) {
                return (
                  <ListElement
                    name={item.DisplayName}
                    imageUrl={`${Domain}/Content/DomainData/${DomainWithOutHttp}/img/Diamonds/Active/${item.DisplayName}_active.png`}
                    key={item.DisplayName}
                  />
                );
              }
            })}
        </ul>
      </div>
    </div>
  );
}

export default ShapeFilter;
