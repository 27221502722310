import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserID, Domain, DeviceType, BackEndClientId } from "../../constant";
import { resetorderPlaced } from "./CheckoutSlice";
import { useDispatch } from "react-redux";

const initialState = {
  products: [],
  apiproducts: [],
  loading: false,
  productAdded: false,
  error: "",
  searchMoreError: "",
  cart: {
    items: [],
    isItemAdded: false,
    addItemError: "",
    isGetItem: false,
    getItemError: "",
    isItemDeleted: false,
    deleteItemError: "",
    addItemSuccess: "",
  },
  PageNum: 0,
};

export const getProduct = createAsyncThunk("products", async (searchParam) => {
  const response = await fetch(`${Domain}/mobile-api/get-search-stock`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      customerId: UserID,
      PageSize: 9,
      ...searchParam,
      PageNum: searchParam.PageNum - 1,
      BackEndClientId,
    }),
  });
  let data = await response.json();
  if (searchParam.PageNum > 1) {
    data.searchMore = true;
  }

  return data;
});

export const addToCart = createAsyncThunk("addtocart", async (searchParam) => {
  const response = await fetch(`${Domain}/mobile-api/add-to-cart`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ DeviceType, ...searchParam, BackEndClientId }),
  });

  let data = await response.json();
  return data;
});

export const getMyCart = createAsyncThunk("getmycart", async (searchParam) => {
  const response = await fetch(`${Domain}/mobile-api/get-my-cart`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...searchParam,
      BackEndClientId,
    }),
  });

  let data = await response.json();
  return data.Result._CartList;
});

export const removeCartItem = createAsyncThunk(
  "remove_cart_item",
  async (searchParam) => {
    const response = await fetch(
      `${Domain}/mobile-api/remove-cart-item-by-cartids`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...searchParam,
          BackEndClientId,
        }),
      }
    );

    let data = await response.json();
    return data.Result;
  }
);

export const ProductSlice = createSlice({
  name: "product_slice",
  initialState,
  reducers: {
    addToCartLocal: (state, action) => {
      const newCartItem = action.payload;
      const cartItem = JSON.parse(localStorage.getItem("localcart")) || [];
      const itemExist = cartItem.filter(
        (item) => item.Stock_ID === newCartItem.Stock_ID
      );

      if (itemExist.length) {
        state.cart.addItemError = "Stone already exist in cart!";
        state.cart.addItemSuccess = "";
      } else {
        cartItem.push(action.payload);
        localStorage.setItem("localcart", JSON.stringify(cartItem));
        state.cart.items = cartItem;
        state.cart.addItemError = "";
        state.cart.addItemSuccess = "Stone added successfully to cart.";
      }
    },
    removeFromCartLocal: (state, action) => {
      const cartItem = JSON.parse(localStorage.getItem("localcart"));
      const filterItem = cartItem.filter(
        (item) => item.Stock_ID !== action.payload
      );
      state.cart.items = filterItem;
      localStorage.setItem("localcart", JSON.stringify(filterItem));
    },
    ViewCartLocal: (state, action) => {
      const cartItem = JSON.parse(localStorage.getItem("localcart")) || [];
      state.cart.items = cartItem ? [...cartItem] : [];
    },
    setProductPageNum: (state, action) => {
      state.PageNum = parseInt(state.PageNum) + 1;
    },
    resetProductPageNum: (state, action) => {
      state.PageNum = 0;
    },
    setProduct: (state, action) => {
      state.products = [...state.products, ...state.apiproducts];
      state.productAdded = true;
    },
    resetProduct: (state, action) => {
      state.products = state.apiproducts;
    },
    setOrder: (state, action) => {
      state.products = action.payload;
    },
    resetCartisItemAdded: (state, action) => {
      state.cart.isItemAdded = false;
    },
    resetaddItemError: (state, action) => {
      state.cart.addItemError = "";
    },
    resetaddItemSuccess: (state, action) => {
      state.cart.addItemSuccess = "";
    },
    resetProductLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    //get product case
    builder
      .addCase(getProduct.pending, (state) => {
        state.apiproducts = [];
        state.loading = true;
        state.error = "";
        state.searchMoreError = "";
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.apiproducts = (action.payload && action.payload.rows) || [];
        state.error =
          action.payload === null
            ? `Sorry, we couldn't find any results.<br/> Please try with different filter options.`
            : "";
        if (action.payload.IsSuccess) {
          state.apiproducts = action.payload.Result.rows;
          state.loading = false;
          state.error = "";
        } else {
          if (!action.payload.searchMore) {
            state.apiproducts = [];
            state.loading = false;
            state.error = `Sorry, we couldn't find any results.<br/> Please try with different filter options.`;
          } else {
            state.loading = false;
            state.searchMoreError = "Sorry,No more data available!";
          }
        }
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.apiproducts = [];
        state.loading = false;
        state.error = "Some thing went wrong! Internal server error";
      });

    builder
      .addCase(addToCart.pending, (state) => {
        state.cart.isItemAdded = false;
        state.cart.addItemSuccess = "";
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        if (action.payload.IsSuccess) {
          state.cart.isItemAdded = true;
          state.cart.addItemError = "";
          state.cart.addItemSuccess = action.payload.Message;
        } else {
          state.cart.isItemAdded = false;
          state.cart.addItemError = action.payload.Message;
          state.cart.addItemSuccess = "";
        }
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.cart.isItemAdded = false;
        state.cart.addItemError =
          "Some thing went wrong! Internal server error";
        state.cart.addItemSuccess = "";
      });

    builder
      .addCase(getMyCart.pending, (state) => {
        state.cart.items = [];
      })
      .addCase(getMyCart.fulfilled, (state, action) => {
        state.cart.items = action.payload || [];
        state.getItemError = action.payload === null ? "Data not found!" : "";
        action.payload !== null &&
          localStorage.setItem("localcart", JSON.stringify(action.payload));
      })
      .addCase(getMyCart.rejected, (state, action) => {
        state.cart.items = [];
        state.getItemError = "Some thing went wrong! Internal server error";
      });

    builder
      .addCase(removeCartItem.pending, (state) => {
        state.cart.isItemDeleted = false;
      })
      .addCase(removeCartItem.fulfilled, (state, action) => {
        state.cart.isItemDeleted = true;
      })
      .addCase(removeCartItem.rejected, (state, action) => {
        state.cart.isItemDeleted = false;
        state.cart.deleteItemError =
          "Some thing went wrong! Internal server error";
      });
  },
});

export const {
  addToCartLocal,
  removeFromCartLocal,
  ViewCartLocal,
  setProductPageNum,
  setProduct,
  resetProduct,
  setOrder,
  resetCartisItemAdded,
  resetaddItemError,
  resetProductPageNum,
  resetaddItemSuccess,
  resetProductLoader,
} = ProductSlice.actions;
export default ProductSlice.reducer;
