import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Wishlist from "./Wishlist";

function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="Wish List" />
        <Wishlist />
      </Layout>
    </>
  );
}

export default Index;
