import React from "react";
import { useDispatch } from "react-redux";

function OrderItem({ product }) {
  const dispatch = useDispatch();
  return (
    <tr>
      <td className="text-center">
        <img
          src={
            product.Stone_Img_url || "assets/images/procuctdetails/no-image.png"
          }
          className="img-fluid"
          alt="order-image.png"
          style={{ width: "50px" }}
        />
      </td>
      <td className="text-center">{product.Lab_Report_No}</td>
      <td className="text-center">
        {product.Weight} Carat | {product.Shape} Shape Diamond | {product.Color}
      </td>

      <td className="text-right"> {product.Cost_Amt}</td>
    </tr>
  );
}

export default OrderItem;
