import React from "react";

function CartItem({ product }) {
  return (
    <div className="shape-boxes">
      <div className="shape-box">
        <div className="shape-image">
          <img
            src={
              product.Stone_Img_url === ""
                ? "assets/images/procuctdetails/no-image.png"
                : product.Stone_Img_url
            }
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="shape-cont">
          <h5>
            {product.Weight} Carat, {product.Shape} <br /> Shape Diamond
          </h5>
        </div>
      </div>
      <div className="shape-nmbr">
        <h5>${product.Cost_Amt}</h5>
      </div>
    </div>
  );
}

export default CartItem;
