import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFromCartLocal,
  removeCartItem,
} from "../../redux/reducers/ProductSlice";
import useNotify from "../../hooks/useNotify";
function SmallCartItem({ product }) {
  const dispatch = useDispatch();
  const Notify = useNotify();
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  return (
    <div className="purchase-items">
      <div className="purchase-items-image">
        <img
          src={
            product.Stone_Img_url === ""
              ? "assets/images/procuctdetails/no-image.png"
              : product.Stone_Img_url
          }
          className="img-fluid"
          alt="my-cart"
        />
      </div>
      <div className="my-cart-shape">
        {product.Weight} Carat, {product.Shape} Shape Diamond &nbsp;
        <span className="purchase-price-qty">#{product.Lab_Report_No}</span>
      </div>
      {UserID ? (
        <div className="purchase-price-qty">
          <span>${product.Cost_Amt}</span>
        </div>
      ) : (
        ""
      )}

      <div
        className="mycart-close"
        onClick={() => {
          dispatch(removeFromCartLocal(product.Stock_ID));
          if (product.Cart_ID) {
            dispatch(removeCartItem({ UserID, cartIDs: product.Cart_ID }));
          }
          Notify("Cart item removed sucessfully!", "success");
        }}
      >
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>
  );
}

export default SmallCartItem;
