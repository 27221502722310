import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";

function SymmetryFilter() {
  const dispatch = useDispatch();
  const { stockFilter, isFilterReset } = useSelector(
    (state) => state.stockFilterReducer
  );

  const masterData = useSelector(
    (state) => state.commonReducer.masterlist.symmetry
  );

  const [symmetry, setSymmetry] = useState({
    data: masterData.map((item) => item.MasterTypeValue_Code),
    activevalue: "NONE",
  });

  const selectedSymm = useSelector(
    (state) => state.stockFilterReducer.stockFilter.Symm
  );

  useEffect(() => {
    isFilterReset &&
      setSymmetry((state) => {
        return { ...state, activevalue: "NONE" };
      });
  }, [isFilterReset]);

  const handleClick = (e) => {
    setSymmetry({ ...symmetry, activevalue: e.target.innerHTML });
    dispatch(
      addStockFilter({ Symm: e.target.innerHTML, loadmore: false, PageNum: 1 })
    );
    dispatch(resetProductPageNum());
    stockFilter.lodmore && dispatch(getProduct(stockFilter));
  };

  return (
    <div className="cmn-cirlce-value-secetion-wrapper">
      <span className="filter-sub-title">Symmetry:</span>
      <div className="circle-value">
        {symmetry.data.map((item) => {
          return (
            <span
              className={selectedSymm === item ? "symmetry-active" : ""}
              onClick={handleClick}
              key={item}
            >
              {item}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default SymmetryFilter;
