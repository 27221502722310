import React from "react";
import { Link } from "react-router-dom";
function Aboutus() {
  return (
    <section className="home-aboutus">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12 my-lg-0 my-md-auto">
            <div className="home-aboutus-image mt-home-aboutus-image">
              <img
                src="assets/images/home/aboutus.png"
                className="img-fluid"
                alt="aboutus.png"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-7 col-sm-12 mt-lg-auto mb-8">
            <div className="cmn-diamond-with-title-wrapper ml-different-title">
              <h1 className="cmn-section-titile cmn-color-title title-margin">
                About Us
              </h1>
              <div className="cmn-diamond-below-title">
                <img
                  src="assets/images/home/grey-small-diamod.png"
                  className="img-fluid"
                  alt="grey-small-diamod.png"
                />
              </div>
            </div>
            <h3 className="cmn-sub-title">
              We are leading brand with the collection of Lab Grown diamonds
            </h3>
            <p className="cmn-peragraph">
              At Parin Gems, we create lab grown diamonds using low energy
              process with the aim to keep the earth safe from mining impact and
              presenting a diamond which is Eco Friendly, Clean & Green. We've
              began the manufacturing of the lab grown diamonds with several
              aims, such as; to fulfill the increasing demand of diamonds in the
              jewelry industry, to Establish Effortless supply chain management
              with our customers to meet the industry demand, and to steer the
              industry in providing the best-in-className Lab Grown Diamonds for
              the fine and economic jewelry industry.
            </p>
            <div className="cmn-learnmore-btn-grey">
              <Link to="/aboutus">Learn More</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Aboutus;
