import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FetchApi } from "../../helper/helperFunction";
import useNotify from "../../hooks/useNotify";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Logout } from "../../redux/reducers/UserSlice";
import { Domain } from "../../constant";
const changePassSchema = Yup.object().shape({
  Login_Pass: Yup.string().required("Required").min(6, "Too Short!"),
  Login_Pass_New: Yup.string().required("Required").min(6, "Too Short!"),
  Login_Pass_Confirm: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("Login_Pass_New"), null], "Passwords must match"),
});

function ChangePassword() {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const Notify = useNotify();
  const dispatch = useDispatch();
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  return (
    <div className="tab-pane fade" id="change-pass" role="tabpanel">
      <span className="tabs-titile addres-titile">Change Password</span>
      <div>
        <div className="billing-form">
          <Formik
            initialValues={{
              Login_Pass: "",
              Login_Pass_New: "",
              Login_Pass_Confirm: "",
            }}
            validationSchema={changePassSchema}
            onSubmit={async (values, { resetForm }) => {
              const data = {
                ...values,
                Cust_ID: UserID,
              };

              try {
                setLoader(true);
                const response = await FetchApi(
                  `${Domain}/mobile-api/change-password`,
                  "POST",
                  data
                );
                const res = await response.json();
                if (res.IsSuccess) {
                  Notify("Password changed successfully!", "success");
                  history.push("/");
                  dispatch(Logout());
                  resetForm();
                  setLoader(false);
                } else {
                  Notify(res.Message, "error");
                  setLoader(false);
                }
              } catch (error) {
                Notify(error, "error");
                setLoader(false);
              }
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="checkout-input-box change-password">
                  <div className="billing-input-box billing-input-px-0">
                    <div className="input-box">
                      <label>Current Password</label>
                      <Field
                        type="password"
                        placeholder="Please enter current password!"
                        name="Login_Pass"
                      />
                      {errors.Login_Pass && touched.Login_Pass ? (
                        <div className="error">{errors.Login_Pass}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="billing-input-box billing-input-px-0">
                    <div className="input-box">
                      <label>New Password</label>
                      <Field
                        type="password"
                        placeholder="Please enter new password!"
                        name="Login_Pass_New"
                      />
                      {errors.Login_Pass_New && touched.Login_Pass_New ? (
                        <div className="error">{errors.Login_Pass_New}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="billing-input-box billing-input-px-0">
                    <div className="input-box">
                      <label>Confirm New Password</label>
                      <Field
                        type="password"
                        placeholder="Please enter new confirm password!"
                        name="Login_Pass_Confirm"
                      />
                      {errors.Login_Pass_Confirm &&
                      touched.Login_Pass_Confirm ? (
                        <div className="error">{errors.Login_Pass_Confirm}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="save-btn">
                  <button type="submit" disabled={loader}>
                    Save
                    {loader && <i className="fa fa-spinner fa-spin ml-2"> </i>}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
