import React from "react";

function EmptyWishlist() {
  return (
    <div className="container container5 ">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="cmn-diamond-with-title-wrapper text-center">
            <div className="thankyou-wrapper">
              <div className="right-icon empty-cart">
                <i className="fas fa-heart"></i>
              </div>
              <h3>Your wish list is empty!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptyWishlist;
