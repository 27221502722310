import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDisclaimer } from "../../redux/reducers/HtmlApiSlice";
import ContainerWrapper from "../../components/Common/ContainerWrapper";
function Disclaimer() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDisclaimer());
  }, []);
  const contenteOfDisclaimer = useSelector(
    (state) => state.HtmlApiContantReducer.content.disclaimer
  );
  return (
    <>
      <ContainerWrapper title="Disclaimer" content={contenteOfDisclaimer} />
    </>
  );
}

export default Disclaimer;
