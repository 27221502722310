import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFromCartLocal,
  removeCartItem,
} from "../../redux/reducers/ProductSlice";
import useNotify from "../../hooks/useNotify";
import { addToWishlist } from "../../redux/reducers/WishListSlice";
import { Link } from "react-router-dom";
import { setViewProduct } from "../../redux/reducers/CommonSlice";

function CartItem({ product }) {
  const dispatch = useDispatch();
  const Notify = useNotify();
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  return (
    <div className="cmn-diamond-box-inner-wrapper w-100">
      <div className="report-id top-idlarge-view" style={{ display: "none" }}>
        <span>Report#</span>
        <span>{product.Lab_Report_No}</span>
      </div>
      <div className="large-view-wrapper">
        <div className="shopping-img">
          <div className="goto-cart-image">
            <img
              src={
                product.Stone_Img_url === ""
                  ? "assets/images/procuctdetails/no-image.png"
                  : product.Stone_Img_url
              }
              className="img-fluid"
              alt="dimaond"
            />
          </div>
        </div>
        <div
          className="large-view-size-table-shapw-wrapper custom-demo"
          style={{ marginLeft: "20px" }}
        >
          <div className="carat-link">
            <Link
              to={`/product/${product.Stone_No}/${product.Weight}-Ct-${product.Shape}-ShapeDiamond-${product.Clarity}`}
              onClick={() => dispatch(setViewProduct(product))}
            >
              {product.Weight} Carat, {product.Shape} Shape Diamond
            </Link>
          </div>
          <div className="report-id" style={{ display: "none" }}>
            <span>Report#</span>
            <span>{product.Lab_Report_No}</span>
          </div>
          <div className="shap-rating cmn-child-div-box cart-item-infoshap-rating">
            <span>
              {product.Shape} | {product.Color} | {product.Cut} |{" "}
              {product.Clarity} | {product.Total_Depth_Per}
            </span>
            <div id="full-stars-example-two">
              {" "}
              <a
                onClick={() => {
                  dispatch(removeFromCartLocal(product.Stock_ID));
                  if (product.Cart_ID) {
                    dispatch(
                      removeCartItem({ UserID, cartIDs: product.Cart_ID })
                    );
                  }
                  Notify("Cart item removed sucessfully!", "success");
                }}
              >
                <i className="far fa-trash-alt"></i>
              </a>
              <i
                className="far fa-heart"
                onClick={() => {
                  if (UserID) {
                    dispatch(
                      addToWishlist({
                        CustomerID: UserID,
                        StockIDs: product.Stock_ID,
                      })
                    );
                  } else {
                    Notify("Please login first!", "error");
                  }
                }}
              ></i>
            </div>
          </div>
          {/* <div className="carat-size cmn-child-div-box ">
            <span>Cut:</span>
            <span></span>
          </div>
          <div className="carat-size cmn-child-div-box ">
            <span>Clarity:</span>
            <span>{product.Clarity}</span>
          </div>
          <div className="igi-logo-table cmn-child-div-box ">
            <span>Table:</span>
            <span>{product.Total_Depth_Per}</span>
          </div> */}
          {UserID ? (
            <div className="price d-md-none d-block">${product.Cost_Amt}</div>
          ) : (
            ""
          )}
        </div>
        {UserID ? (
          <div className="shopping-value">
            <div className="price d-md-block d-none">${product.Cost_Amt}</div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default CartItem;
