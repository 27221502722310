import React, { useEffect } from "react";
import WishListItem from "./WishListItem";
import { useSelector, useDispatch } from "react-redux";
import EmptyWishlist from "./EmptyWishlist";
import useNotify from "../../hooks/useNotify";
import {
  getMyCart,
  resetCartisItemAdded,
} from "../../redux/reducers/ProductSlice";
function Wishlist() {
  const Notify = useNotify();
  const dispatch = useDispatch();
  let wishlist = useSelector((state) => state.WishlistReducer.wishlist.items);
  let { isItemAdded, addItemError } = useSelector(
    (state) => state.productReducer.cart
  );
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  // when any item added to cart after login then this function is called after adding item to cart
  useEffect(() => {
    isItemAdded && Notify("Stone added successfully to cart.", "success");
    isItemAdded && dispatch(getMyCart({ UserID }));
    isItemAdded && dispatch(resetCartisItemAdded());
  }, [isItemAdded]);

  // when any item added to cart after login then this function is called after adding item to cart
  useEffect(() => {
    addItemError && Notify(addItemError, "error");
  }, [addItemError]);

  return (
    <section className="stock-container bg-cmn-property  ">
      <div className="container">
        <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
          <span>Your</span>
          <h1 className="cmn-section-titile cmn-color-title">Wishlist</h1>
          <div className="cmn-diamond-below-title mx-auto">
            <img
              src="assets/images/home/grey-small-diamod.png"
              className="img-fluid"
              alt="grey-small-diamod.png"
            />
          </div>
        </div>
        <div className="row">
          {wishlist.length ? (
            wishlist.map((product) => {
              return (
                <WishListItem product={product} key={product.Lab_Report_No} />
              );
            })
          ) : (
            <EmptyWishlist />
          )}
        </div>
      </div>
    </section>
  );
}

export default Wishlist;
