import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ProductDetail from "./ProductDetail";

function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb
          pagetitle="Our Stock"
          pagetitle2="Product Detail"
          path="/diamond"
        />
        <ProductDetail />
      </Layout>
    </>
  );
}

export default Index;
