import React from "react";
import SidePanelFilter from "./SidePanelFilter";
import TabContainer from "../TabContainer/TabContainer";

function SearchPanel() {
  return (
    <section className="stock-section-wrapper">
      <div className="container container-2">
        <div className="row">
          <SidePanelFilter />
          <div className=" col-lg-9 col-md-8">
            <TabContainer />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SearchPanel;
