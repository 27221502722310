import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Compare from "./Compare";
function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb
          pagetitle="Our Stock"
          pagetitle2="Compare"
          path="/diamond"
        />
        <Compare />
      </Layout>
    </>
  );
}

export default Index;
