import React from "react";
import Layout from "../../Layout";

function Index() {
  return (
    <Layout>
      <section className="lab-grown-dimaond-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
                <h1 className="cmn-section-titile cmn-color-title">
                  Privacy and Security Policy
                </h1>
                <div className="cmn-diamond-below-title mx-auto">
                  <img
                    src="assets/images/home/grey-small-diamod.png"
                    className="img-fluid"
                    alt="grey-small-diamod.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmn-content-wrapper">
                <h2>Last updated June 2, 2017</h2>
                <p>
                  Parin Gems (including Parin Gems, Inc., its subsidiaries and
                  affiliates) (hereafter “us,” “we,” “our,” “Parin Gems,” or the
                  “Company”) wants you to know that we take your privacy
                  seriously. We are passionate about our business and we owe it
                  all to you, our valued customer. We know that our biggest
                  compliment is when you visit us again or refer us to a friend.
                  We know that you would not do either if you did not feel safe
                  and secure in doing business with us. The information below is
                  to let you know just how seriously we take protecting your
                  privacy by telling you why we collect your personal
                  information, what information we collect, how we use that
                  information, and how to access and update information. All
                  references to our “Site” includes our Parin Gems website,
                  internet presence, mobile applications and social media
                  platforms, and all references to our “Services” includes our
                  Site, customer service functions and other shopping or product
                  services offered by us. This Privacy Policy is incorporated
                  into our Terms of Service. If any questions should arise after
                  reviewing this Privacy Policy please email us at &nbsp;
                  <a>advisor@cleanorigin.com</a>
                </p>
                <p>
                  Under this policy, “personal information” means information
                  relating to an identified or identifiable individual.
                </p>

                <h2>Our Collection of Your Personal Information</h2>
                <ul>
                  <li>
                    Expedite your purchases and enable the Services you request,
                  </li>
                  <li>Approve and monitor your purchases,</li>
                  <li>Enhance and improve our services and products,</li>
                  <li>
                    Enrich the general customer experience though tailored
                    advertising and marketing initiatives,
                  </li>
                  <li>
                    Recognize and classify your customer products and
                    preferences,
                  </li>
                  <li>Improve Services for our customer and user base,</li>
                  <li> Answer your questions and respond to requests,</li>
                  <li>
                    Manage and control Parin Gems promotions and contests,
                  </li>
                  <li>
                    Distribute information regarding Parin Gems (or our
                    partners) products of interest,
                  </li>
                  <li>
                    Analyze and assess your information for completeness,
                    errors, and missing data,
                  </li>
                  <li>Deter and preempt abuse and fraud,</li>
                  <li>
                    Follow applicable legal requirements and Parin Gems's
                    policies and procedures, and
                  </li>
                  <li>Other uses with your consent.</li>
                </ul>
                <p>
                  Over time we may combine the information you provide to us
                  with other information that is publicly available or collected
                  though our cookies or other similar available technology. In
                  addition, we may add that to other information that was
                  collected through third parties, our affiliates and partners.
                  Your comments and feedback are importance to us. Those
                  communications directly help us in improving our site and
                  services. By communicating to us that information you
                  effectively give the rights to it to Parin Gems. Additionally,
                  we reserve the right to post and use those comments on our
                  Site and in our advertising and marketing material. Finally,
                  we may use those comments and material as our own Site
                  Materials.
                </p>
                <h2>The Information We Collect</h2>
                <p>
                  <strong>Provided by you : </strong> When you use the Company
                  site you may provide us your personal information. This
                  information may include information about your purchases,
                  billing address, shipping address, gender, occupation,
                  birthday, marital status, anniversary, interests, and credit
                  card information. It may also include information you provide
                  to us about a fiancé, loved one or friend in order to send
                  them a referral or information about a product or service.
                  That information will be collected either by electronic,
                  written, or oral means and the Company will store it and use
                  it under the terms of this Agreement.
                </p>
                <p>
                  <strong>1. Analytics and Customization : </strong> Parin Gems
                  uses analytics and cookies, including third party cookies, to
                  collect information just on how our Services are being used by
                  our visitors and customers to measure how successful our
                  marketing campaigns are, and to customize the content that
                  each customer sees. Finally these cookies and third-party
                  tracking software aid in our ability to prevent fraud.
                </p>
                <p>
                  <strong>2. Performance and Functionality : </strong> Cookies
                  help improve the user experience by helping us improve our
                  Site’s performance and functionality. The use of cookies
                  enables us to recognize your computer, smart phone, or mobile
                  device sooner. This also means that not only will our Site
                  populate more quickly but it will allow you to see your
                  previously viewed items and shopping cart prior to logging
                  back into your account.
                </p>
                <p>
                  <strong>3. Essential Operational : </strong> Cookies are
                  paramount in allowing us to operate our Services. For
                  instance, cookies are necessary for you to access secure areas
                  (i.e. your account and shopping list).
                </p>
                <p>
                  <strong>4. IP Address : </strong> When visiting our Site,
                  Parin Gems gathers your IP address to analyze and diagnose
                  problems, and improve the performance of the Site, servers,
                  and hosting providers as well as to assess trends and traffic
                  patterns, collect demographic and statistical information, and
                  track when the visit was and its length. To continuously
                  improve security protocols your IP address may also be used
                  along with your personally identifiable information for credit
                  fraud protection and risk reduction.
                </p>
                <p>
                  <strong>5. Social networking cookies : </strong> Cookies allow
                  visitors to share our pages and content through third-party
                  social networking websites.
                </p>
                <p>
                  <strong>6.Targeting/advertising : </strong> Cookies allow us
                  to customize the advertisements you receive, and afford you
                  with individualized offers, and quantify their effectiveness.
                </p>
                <p>
                  Most internet browsers allow the user to decline cookies. As
                  such you are always able to refuse the use and access of
                  cookies. However, if you decline the use of cookies then you
                  may be unable to access certain portion of the Site and some
                  of our Services. Even if you decline the use of cookies, we
                  may still be able to identify and recognize your computer,
                  smart phone, or mobile device. Finally, some web browsers have
                  a “Do Not Track” feature incorporated into them. Since this is
                  a new technology and does not have an industry based
                  standardize response to them, we do not respond to them. For
                  more information about the use of cookies and how to block
                  them, visit{" "}
                  <a href="https://www.allaboutcookies.org/">
                    www.allaboutcookies.org
                  </a>
                  ,{" "}
                  <a href="https://www.youronlinechoices.eu/">
                    {" "}
                    www.youronlinechoices.eu
                  </a>{" "}
                  (Europe) or{" "}
                  <a href="https://optout.aboutads.info/?c=2&lang=EN">
                    {" "}
                    www.aboutads.info/choices
                  </a>{" "}
                  (United States).
                </p>

                <p>
                  We may permit our business partners to use cookies other
                  similar technologies for the same purposes as we do. For
                  Instance
                </p>
                <p>
                  {" "}
                  <strong> Tracking Images : </strong> These are small files
                  that we embed into an email or newsletter to ascertain whether
                  an email has been opened, forwarded, or its content having
                  been clicked on. The purpose of these tracking images is to
                  see if our customers and subscribers find our email and
                  newsletter service interesting, informative, and useful.
                </p>
                <p>
                  <strong>Local Storage : </strong>Parin Gems uses local storage
                  (HTML5 or Flash) for frequency capping purposes and to provide
                  you with relevant advertising.
                </p>

                <h2>What Information We Share with Others</h2>
                <h3>
                  The Company may share your personal information as follows:
                </h3>
                <p>
                  <strong>Parin Gems entities : </strong>Your personal
                  information may be shared within Parin Gems and with our
                  partners and affiliated entities if it is reasonably necessary
                  to accomplish any of the above noted purposes.
                </p>
                <p>
                  <strong>Events and Promotions :</strong>If reasonably
                  necessary, your personal information will be shared with event
                  staff and organizers if you decided to participate in a
                  special event or promotion.
                </p>
                <p>
                  <strong>Vendors and Service Providers : </strong>Third-party
                  service providers may be enlisted to complete and perform
                  services on our behalf. Some of these services include:
                  distribution, payment processing, order fulfillment, assembly,
                  advertising and marketing, and emailing.
                </p>
                <p>
                  <strong>Third-Party Analytics and Sharing :</strong>At times
                  we may share your personal information with third-parties and
                  others which are not part of Parin Gems. They may use this
                  information to provide, or enable other companies provide,
                  offers to you, to market products, services or opportunities
                  to you, and to perform data analytics regarding your use of
                  our Services.
                </p>
                <p>
                  Certain of our business partners may also use cookies on our
                  Site to accomplish some or all of the same general purposes
                  identified in this Privacy Policy. Third parties may use
                  cookies and other technologies to collect information about
                  your online activities over time and across different
                  websites. Companies that we share information with may use the
                  information we provide to them, often in combination with
                  their existing information, to provide you with more relevant
                  advertising. You may opt out of receiving personalized
                  advertisements from advertising companies that are members of
                  the Network Advertising Initiative or who subscribe to the
                  Digital Advertising Alliance’s Self-Regulatory Principles for
                  Online Behavioral Advertising by visiting{" "}
                  <a href="http://www.networkadvertising.org/choices">
                    http://www.networkadvertising.org/choices
                  </a>{" "}
                  and
                  <a href="http://www.aboutads.info/choices.">
                    {" "}
                    http://www.aboutads.info/choices.
                  </a>{" "}
                </p>
                <p>
                  <strong>Merger, Sale, or Business Transfer : </strong>Your
                  previously collected personal information may be shared or
                  disclosed as part of a merger, sale or transfer of acquired
                  Company assets. This includes merger, sale, or transfer in the
                  case of bankruptcy or receivership.
                </p>
                <p>
                  <strong>
                    Fraud Protection, Prevention, and Legal Compliance: Parin
                    Gems may disclose your information:
                  </strong>
                  (1) at times when we believe in good faith it is necessary to
                  comply with applicable regulation, law, judicial or legal
                  proceeding, or governmental agency request; (2) to
                  third-parties as to aid them in their attempts to root out
                  fraud or suspected fraud or illegal activity; (3) to combat
                  and prevent fraud, suspected fraud, illegal activities, and
                  threats to others’ physical safety; and (4) to uphold and
                  enforce an agreement we have with customers.
                </p>
                <p>
                  <strong>Collective Information : </strong>From time to time,
                  Parin Gems and authorized third-parties and partners may
                  aggregate information together. However, when that is done,
                  such aggregated information will not be traceable to
                  individuals. Finally, we allow and may authorize third-parties
                  to disclose and use non-personal information.
                </p>

                <h2>Links</h2>
                <p>
                  Our Site and Services may display links to third-party
                  websites that are not in the control of Parin Gems and also
                  outside the parameters of this Privacy Policy. It is advisable
                  that you read and familiarize yourself with their policies.
                </p>

                <h2>How You Can Access and Update Your Information</h2>
                <p>
                  Once you have created a Parin Gems account it will be possible
                  for you to update, review, and change some of your personal
                  information by logging into your account. This can be done
                  24/7. If you require assistance in changing or updating your
                  personal account information, please do not hesitate to
                  contact us.
                </p>

                <h2>Opting Out</h2>
                <p>
                  In order to provide you first-class service, we want to be
                  able to communicate with you and share with you offers and
                  promotions that we think you will want to hear. If at any time
                  you no longer wish to receive communications from us, let us
                  know.
                </p>
                <p>
                  To opt out of receiving emailed promotions from us: (1) go to
                  the Parin Gems web page and select "unsubscribe," then follow
                  the prompts and enter in the required information, or
                  conversely open the promotional email, click the “unsubscribe”
                  hyperlink and then follow the prompts to unsubscribe; or (2)
                  if you wish to unsubscribe from telephone based promotions
                  write to us at{" "}
                  <a href="mailTo: advisor@cleanorigin.com.">
                    {" "}
                    advisor@cleanorigin.com.
                  </a>{" "}
                  You can also tell us where to send written promotional
                  materials. (3) To opt out of regular mail promotions, please
                  write to us at{" "}
                  <a href="advisor@cleanorigin.com.">
                    advisor@cleanorigin.com.
                  </a>{" "}
                  Please note that opting out of communications with us does not
                  affect specific communications from us regarding transactions
                  and purchases. When required by applicable law, we will obtain
                  your prior consent at the time of data collection pursuant to
                  which you will opt-in to the processing of (i) personal
                  information deemed sensitive pursuant to applicable law, and
                  (ii) personal information for marketing purposes.
                </p>

                <h2>Your Consent and Adherence to Our Privacy Policy:</h2>
                <p>
                  From time to time, or as required by law, Parin Gems will
                  update this Privacy Policy. If a time arises where a change or
                  modification is made we will reflect that this Policy has been
                  updated by updating the “last updated” date at the beginning
                  of this Policy. You are deemed to have consented to and
                  accepted this Privacy Policy, including any changes or updates
                  by your continued use the Site or our Services, or by your
                  sharing of your information with us. Any additional features,
                  functionality, offers, activities, or events available to you
                  are subject to additional or different privacy rules that we
                  disclose in connection with those opportunities.
                </p>

                <h2>Children Under the Age of 13</h2>
                <p>
                  We also take the protection and data security of minor
                  children under the age of 13 seriously. To that end Parin Gems
                  does not knowingly collect personal information from children
                  under the age of 13. If it is discovered that we have done so,
                  we will promptly delete that data from out servers. For more
                  information on protecting the data privacy rights of minor
                  children under the age of 13 please see the FTC website at{" "}
                  <a href="https://www.ftc.gov"> https://www.ftc.gov.</a>
                </p>

                <h2>Security</h2>
                <p>
                  Parin Gems takes securing your personal information seriously.
                  As such we employ a number of electronic and physical security
                  protocols to ensure that your personal information remains
                  safe and secure. However, in today’s rapidly changing
                  technological environment, no security protocol is
                  impregnable. To that end, we cannot guarantee that your
                  personal information will never be compromised.
                </p>
                <h3>
                  Below are our multi-point electronic and physical security
                  protocols:
                </h3>
                <p>
                  <strong>SSL Encryption : </strong>Secure Sockets Layer (SSL)
                  encryption technology is the industry standard for protecting
                  data when computer servers are communicating and transferring
                  data. We use SSL when completing transaction online. SSL helps
                  to ensure that your sensitive personal information, such as
                  your credit card number, is not apprehended by adverse
                  third-parties.
                </p>
                <p>
                  Additionally, we have engaged with Verisign, the preeminent
                  leader in online authentication products and software.
                  Verisign helps us to ensure that your communications with us
                  are secure.
                </p>
                <p>
                  <strong>Credit Card Orders : </strong>To minimize unnecessary
                  risk of your sensitive personal information being compromised
                  we do not store your credit card information after an order
                  has been completed. To further minimize this risk, we complete
                  our credit card orders within 24 hours by working directly
                  with the authorizing bank. This enables us to ship products
                  sooner and delete temporarily stored information sooner.
                </p>
                <p>
                  <strong>Telephone Orders :</strong>If you would rather
                  transact by telephone you can call us at: 1-888-487-2145 to
                  speak with a sales representative and complete your order.
                </p>
                <p>
                  <strong>Signature Requirements for Delivery : </strong>We
                  always insure and register items shipped via mail to their
                  fullest value. Finally, we only ship to physical business or
                  residential addresses and require that a person over 18 years
                  of age sign for the delivery.
                </p>

                <h2>
                  California Privacy Rights Under California Civil Code Section
                  1798.83
                </h2>
                <p>
                  California Civil Code Section 1798.83 permits end users who
                  are California residents to request certain information
                  regarding our disclosure of Personal Information to third
                  parties for their direct marketing purposes. To make such a
                  request, please send an email to{" "}
                  <a href="advisor@cleanorigin.com"> advisor@cleanorigin.</a>com
                  or write us at:
                </p>
                <ul>
                  <li>LBVllc ( Little Bear Ventures )</li>
                  <li>68 Southfield Avenue, Two Stamford Landing</li>
                  <li>Suite 100</li>
                  <li>Stamford, Connecticut 06902</li>
                  <li>United States</li>
                </ul>
                <h2>How to Contact Us</h2>
                <p>
                  It is our pleasure having you as a valued customer and we want
                  to hear from you if you have any concerns, complaints, or
                  questions. We also like getting positive feedback. Please
                  contact us by the below methods.
                </p>
                <p>
                  <strong>By Telephone : </strong>{" "}
                  <a href="tel:1 (888) 487-2145">1 (888) 487-2145</a>{" "}
                </p>
                <p>
                  <strong>By E-mail : </strong>{" "}
                  <a href="maillTo:advisor@cleanorigin.com">
                    advisor@cleanorigin.com
                  </a>
                </p>
                <p>
                  <strong>By Regular Mail : </strong>
                </p>
                <p>
                  LBVllc ( Little Bear Ventures ),
                  <br />
                  68 Southfield Avenue, Two Stamford Landing,
                  <br />
                  Suite 100,
                  <br />
                  Stamford, Connecticut 06902,
                  <br />
                  United States.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;
