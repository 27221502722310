import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";

function FlourescenceFilter() {
  const { stockFilter, isFilterReset } = useSelector(
    (state) => state.stockFilterReducer
  );

  const selectedFluroIntent = useSelector(
    (state) => state.stockFilterReducer.stockFilter.FluroIntent
  );
  useEffect(() => {
    isFilterReset &&
      setFlourescence((state) => {
        return { ...state, activevalue: "NON" };
      });
  }, [isFilterReset]);
  const dispatch = useDispatch();
  const masterData = useSelector(
    (state) => state.commonReducer.masterlist.fluroint
  );

  const [flourescence, setFlourescence] = useState({
    data: masterData.map((item) => item.MasterTypeValue_Code),
    activevalue: "NON",
  });

  const handleClick = (e) => {
    setFlourescence({ ...flourescence, activevalue: e.target.innerHTML });
    dispatch(
      addStockFilter({
        FluroIntent: e.target.innerHTML,
        loadmore: false,
        PageNum: 1,
      })
    );
    dispatch(resetProductPageNum());
    stockFilter.lodmore && dispatch(getProduct(stockFilter));
  };
  return (
    <div className="cmn-cirlce-value-secetion-wrapper">
      <span className="filter-sub-title">Flourescence:</span>
      <div className="circle-value">
        {flourescence.data.map((item) => {
          return (
            <span
              className={
                selectedFluroIntent === item ? "flourescence-active" : ""
              }
              onClick={handleClick}
              key={item}
            >
              {item}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default FlourescenceFilter;
