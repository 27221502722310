import React from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "./assets/css/bootstrap-min.css";
import "./assets/css/style.css";
function Layout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
