import React from "react";
import Layout from "../../Layout";

function Index() {
  return (
    <Layout>
      <section className="lab-grown-dimaond-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="cmn-diamond-with-title-wrapper text-center stock-information-title">
                <h1 className="cmn-section-titile cmn-color-title">
                  Terms & Conditions
                </h1>
                <div className="cmn-diamond-below-title mx-auto">
                  <img
                    src="assets/images/home/grey-small-diamod.png"
                    className="img-fluid"
                    alt="grey-small-diamod.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmn-content-wrapper">
                <h2>Terms & Conditions</h2>
                <h3>Last updated June 2, 2017</h3>
                <p>
                  Please review and read these Terms and Conditions (these
                  “Terms”) carefully as they represent the binding terms with
                  which you may engage with Parin Gems, its website and/or other
                  Internet presence, including any of its social media
                  platforms, and other electronic and telecommunication services
                  (collectively, the “Site”, and/or “Services”). BY USING THE
                  SITE, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
                </p>
                <p>
                  These Terms constitute an agreement between you and Parin Gems
                  (“Parin Gems,” “we”, “our”) and govern any purchases that you
                  make through and your use, and interaction with the Site. The
                  right to modify, change, amend or update these Terms at any
                  time is reserved by Parin Gems in its sole and exclusive
                  discretion. Modifications, changes, amendments or updates will
                  take immediate effect and shall be posted on Parin Gems's
                  Site. Any such update shall be noted by the “last updated”
                  date at the beginning of these Terms. Your continued use of
                  the Site and engagement with Parin Gems for use of the
                  Services shall be deemed your consent to any such change. As
                  such, we recommend that you regularly review these Terms as
                  frequently as you use the Services and Site. If you do not
                  agree to the Terms as indicated herein, stop using the Site
                  immediately.
                </p>

                <h2>Our Site's Information:</h2>
                <p>
                  We make every effort to ensure that our online inventory is as
                  up-to-date and accurate as possible. It is our primary mission
                  to ensure that you have the best shopping experience as
                  possible while using our Site. To do so, some of our products
                  appear larger in their photographs to help you see their
                  clarity and characteristics in greater detail. Please note
                  that because of this some objects will appear larger in their
                  photograph than their actual size and that depending on your
                  computer monitor or viewing screen some products' color and
                  luster may vary. We take great pride in our efforts to provide
                  you with as much information as possible about our products on
                  our Sites and wish to ensure that you make informed decisions
                  about your purchases. To help to accomplish that goal please
                  note that all Lab Created diamonds weights are approximate.
                  Refer to the following for size ranges appropriate to each
                  carat weight listing:
                </p>
                <p>
                  1/20 carat 0.04-0.06; 1/10 carat 0.085-0.11; 1/8 carat
                  0.115-.14; 1/6 carat 0.145-0.17; 1/5 carat 0.18-0.22; 1/4
                  carat 0.23-0.28; 1/3 0.29-0.36; 3/8 carat 0.37-0.44; 1/2 carat
                  0.45-0.57; 5/8 carat 0.58-0.68; 3/4 carat 0.69-0.82; 7/8 carat
                  0.83-0.94; 1 carat 0.95-1.11; 1 1/5 carats 1.18-1.22; 1 1/4
                  carats 1.23-1.28; 1 1/3 carats 1.29-1.36; 1 3/8 carats
                  1.37-1.44; 1 1/2 carats 1.45-1.57; 1 5/8 carats 1.58-1.68; 1
                  3/4 carats 1.69-1.82; 1 7/8 carats 1.83-1.94; 2 carats
                  1.95-2.11; 3 carats 2.95-3.17; 4 carats 3.95-4.17; 5 carats
                  4.95-5.17; 6 carats 5.95-6.17; 7 carats 6.95-7.17.
                </p>
                <p>
                  Additionally, some tolerances may be allowed for based on
                  measurement and manufacturing. Up .20mm for wedding bands and
                  .24mm for manufactured gemstones and pearls may be allowed.
                </p>
                <p>
                  Lab Created diamond jewelry, whether natural, laboratory-made
                  or imitation, that is set with several Lab Created diamonds,
                  will be provided with the minimum total carat weight (TW) for
                  the entire piece. Clarity of grades and color will be
                  identified as a “minimum” or “average” contingent upon the
                  number of Lab Created diamonds. If a minimum is what is
                  stated, then all Lab Created diamonds in that piece will be at
                  or above that minimum weight. If the average is stated then
                  the total aggregate quality of the piece is that identified
                  weight.
                </p>

                <h2>Your Account</h2>
                <p>
                  You are solely liable and responsible for keeping and
                  protecting your Parin Gems account username and password
                  confidential and limiting its access to only yourself. In
                  addition, you are solely liable for activities that take place
                  under your individual account and accessed with your password.
                  You may only use our Site with the direction and guidance of a
                  parent or guardian if you are under the age of 18. Finally,
                  take note that we reserve the right to terminate personal
                  accounts, deny service, or cancel orders at any time in our
                  sole discretion and for any reason.
                </p>

                <h2>Use of the Site</h2>
                <p>
                  By using the Site you are given an individual, non-exclusive,
                  non-sublicensable, and limited license to use and access our
                  Site, print, and electronically copy portions of our Site for
                  your personal non-commercial, and informational use only. This
                  license is governed by these Terms and prohibits: (a) any
                  commercial use of the Parin Gems Site or Materials; (b) use of
                  product listings, photographs, images, or descriptions in the
                  pursuit of commerce; (c) the public dissemination or posting
                  of Parin Gems Materials; (d) using the Site for alternative
                  purposes other than what is prescribed by the Terms; (e)
                  utilizing the Site for data mining purposes; (f) manipulating
                  the Site or its appearance, or creating alternative links or
                  other window-exiting programming or pop-up triggers; (g) use
                  of the Site in a way that inhibits or effects the daily
                  operation of the Site or Services; or (h) any other
                  non-intended use or purpose. Any unauthorized use of the Site
                  or Materials contained therein is strictly prohibited and will
                  prompt the immediate termination of the license granted
                  herein, unless otherwise authorized by Parin Gems in writing.
                  The aforementioned unauthorized use may additionally violate
                  copyright and trademark laws and communications regulations
                  and statutes and other applicable laws. Unless otherwise
                  stated, nothing in these Terms shall be interpreted as having
                  granted any license to intellectual property rights, whether
                  by implication, estoppel, or otherwise. This license is
                  revocable by Parin Gems at any time and for any reason.
                </p>

                <h2>Product Availability and Pricing</h2>
                <p>
                  If a product is temporarily unavailable, please contact us so
                  we can assist you by informing you when it will become
                  available next and to facilitate placing an order.
                  Occasionally, due to the volume of sales, an item will become
                  unavailable prior to our ability to update the Site. If such
                  an instance should occur, we will notify you as soon as
                  possible and discuss with you your options and possible
                  alternatives to proceed with a purchase or change your order
                  selection or give you a full or partial refund.
                </p>
                <p>
                  On occasion some data points, including prices, may be
                  displayed incorrectly due to a technical or scrivener error.
                  Though we make every reasonable attempt to prevent such errors
                  and confusion, mistakes happen. When such an instance arises
                  we will correct the error immediately upon its discovery.
                  However, we will not honor the erroneous price or listing. If
                  a product has been shipped and received by you, we will
                  contact you promptly and discuss your options to either return
                  the product or to charge you for the difference in price. If,
                  as a result of the error, the price is lower than what was
                  stated and you contact us, we will discuss your reimbursement
                  options. All our prices are final; we do not negotiate prices
                  on our products.
                </p>

                <h2>Returns and Lab Created diamond Certificates</h2>
                <p>
                  If you purchase a loose Lab Created diamond or any custom made
                  piece that contains a center gem stone from us, we will
                  deliver it to you with an accompanying Lab Created diamond
                  grading report or Lab Created diamond certificate. Lab Created
                  diamond certificates are detailed reports created by Lab
                  Created diamond grading experts at one of the Lab Created
                  diamond laboratories that specialize in these efforts. They
                  are expensive to replace and, as a result, you must return the
                  Lab Created diamond grading report with any return of any
                  custom made piece that contains a center gem stone with which
                  a Lab Created diamond certificate is included or a loose Lab
                  Created diamond. If no report is included with a returned Lab
                  Created diamond, you will be charged a replacement fee of
                  $150.
                </p>

                <h2>Passage of Title</h2>
                <p>
                  Parin Gems will pass the title for products purchased to the
                  buyer or the buyer's representative when the products are
                  delivered and received by the buyer or the buyer's
                  representative.
                </p>

                <h2>Cancellation of Products</h2>
                <p>
                  A customer's ordered product or good may be cancelled at any
                  time and for any reason by Parin Gems. Your only recourse in
                  such an event is a full refund of that which has been paid to
                  Parin Gems for such product.
                </p>

                <h2>Comparison Shopping</h2>
                <p>
                  We strive to provide pictures and details that will assist you
                  in making your purchase selection online. Unfortunately, we do
                  not allow comparison shopping at home. That is, you cannot buy
                  several products with the intent of keeping only one, as our
                  costs in shipping and restocking the items, including
                  re-verification of receipt of the proper stone is very high.
                  We would be happy to consult with you over the phone, via
                  Skype, FaceTime, or another video service to assist you in
                  making your choice.
                </p>

                <h2>Communications to Parin Gems and User Generated Content</h2>
                <p>
                  <strong>Submissions : </strong>By your submission of any:
                  question, comment, suggestion, idea, plan, note, drawing,
                  original or creative material or other information, regarding
                  the Site, Parin Gems, or our Services and products, you
                  acknowledge and agree that such submission is non-confidential
                  and will become the sole property of Parin Gems once
                  submitted. Thereafter, Parin Gems shall be the exclusive
                  rights holder, including all intellectual property rights
                  therein, and shall possess unlimited commercial and
                  non-commercial use and disclosure of that information, or
                  communication, without having to provide notice or
                  compensation to you. You hereby permit Parin Gems and its
                  affiliates, partners and sub-licensees to provide and disclose
                  the name you provided in accordance with the provided
                  communication or content, in their sole discretion. By
                  disclosing that communication or content, you signify and
                  warrant that (a) the content is accurate and not misleading;
                  (b) you possess ownership rights to the communication or
                  content submitted and you have the rights to post that
                  information on the Site and otherwise disseminate and disclose
                  that communication or content; and (c) the communication and
                  content submitted will not injure or violate the rights of any
                  person or entity.
                </p>

                <h3>Product Reviews and User Content : </h3>
                <ul>
                  <li>
                    The Site may include “Interactive Services” that allow users
                    to post, store, and share their own and user generated
                    content. Each user, poster, or account holder is completely
                    and individually responsible for his/her use of any
                    Interactive Services and does so at his/her own risk. By
                    your use of any Interactive Services, you agree not to
                    upload, transmit, distribute, post, create, or store, any
                    material that is or could be considered defamatory,
                    libelous, obscene, lewd, indecent or pornographic,
                    harassing, threatening, fraudulent or misleading, and
                    otherwise unlawful.
                  </li>
                  <li>
                    By posting or communicating your content you represent and
                    warrant that you are not violating any patent, trademark,
                    trade secret, copyright or other intellectual or proprietary
                    right of any person or entity. You also warrant that you
                    have the legal right or authority to do so.
                  </li>
                  <li>
                    Content that consists of instructions, enables, encourages
                    or otherwise assists the commission of a criminal offense,
                    violates the rights of others, or is in violation of,
                    national or international law, is strictly prohibited and
                    when reasonable necessary to prevent the risk of harm as
                    determined by Parin Gems may be reported to the appropriate
                    law enforcement authorities.
                  </li>
                  <li>
                    Viruses, spybots, adware, corrupted data or other harmful,
                    disruptive or destructive files are strictly prohibited.
                  </li>
                  <li>
                    Misrepresentation or impersonation of any other account
                    member, person, or entity is prohibited.
                  </li>
                  <li>
                    The unauthorized dissemination of sensitive personal
                    information (without limitation, addresses, phone numbers,
                    email addresses, Social Security numbers and credit card
                    numbers) from any other party is strictly prohibited.
                  </li>
                  <li>
                    Unwanted advertisements, promotions, marketing, and
                    promotions, political campaigning are prohibited.
                  </li>
                  <li>
                    Account member content posted, shared, stored, or disclosed
                    via the Site and/or Services that is deemed to inhibit any
                    other users experience with Parin Gems may be prohibited at
                    Parin Gems's sole discretion.
                  </li>
                </ul>
                <p>
                  Your use of Interactive Services is at your sole risk. Parin
                  Gems shall not be liable or responsible for any content that
                  any account holder posts, stores, shares, or uploads via
                  Interactive Services. Further, Parin Gems shall not be liable
                  or responsible for injury or damages that result from an
                  account holder's activities utilizing the Interactive Services
                  that constitute defamation, slander, libel, omissions,
                  falsehoods, obscenity, pornography or profanity. As a host of
                  Interactive Services, Parin Gems shall not be responsible or
                  liable for any representations, statements, manifestations, or
                  account holder generated content in appearing therein. Parin
                  Gems does not have any duty to edit, monitor, or screen
                  content or communications posted via the Interactive Services,
                  however Parin Gems reserves the right, and has sole
                  discretion, to do so for any reason and at any time with or
                  without notice. You are solely responsible for backing up any
                  stored content posted via the Interactive Services (if
                  desired). Any use of the Interactive Services that is
                  determined to violate these Terms shall result in an immediate
                  termination of the account holders access to and use of the
                  Site and Services without further notice
                </p>
                <p>
                  In order to preserve and maintain Parin Gems's integrity,
                  business reputation, and user experience, full cooperation
                  will be given to subpoenas and court orders, and legitimate
                  governmental requests. In so doing Parin Gems may disclose
                  account member personal information if necessary to comply
                  with the applicable order or deman
                </p>

                <h2>Third Party Content :</h2>
                <p>
                  Third Party Links: Links to third-party websites and services
                  may be provided on our Site. Parin Gems has no dominion,
                  ownership, or control over third-party websites and services
                  and therefore assumes no liability or responsibility for any
                  activities that occur on third-party websites. Furthermore,
                  unless otherwise expressly stated Parin Gems does not endorse
                  or condone any third-party or its content and does not warrant
                  any product or service as described or displayed on such
                  third-party website
                </p>
                <p>
                  Advertisements and Promotions: On occasion advertisements and
                  promotions from third parties may be run on our Site. Any
                  communication, interaction, or transactions with those
                  third-parties is solely between you and that third-party.
                  Parin Gems assumes no responsibility or liability for such
                  activities and does not warrant the product or services of
                  those third-parties. Finally, you are solely responsible for
                  any loss, injury, or damage that may arise from such
                  activities and interactions
                </p>

                <h2>Disclaimers and Notices</h2>
                <p>
                  Disclaimer of Manufactured Lab Created diamonds and Gems: For
                  many people shopping for a Lab Created diamond is a new and
                  exciting experience. We are committed to making that
                  experience the most straightforward and rewarding as possible.
                  Because of this commitment we strictly adhere to the Federal
                  Trade Commission's Guides for the Jewelry, Precious Metals and
                  Pewter Industries in 1996 (“Guides”). Under the Guides the way
                  that Lab Created diamonds are listed and described are
                  specifically defined. We use the Guides specific definitions
                  throughout our websites, social media platforms, and internet
                  presence. Below is a list of terms that we most commonly use.
                  For the complete list please reference the Guides.
                </p>
                <ul>
                  <li>
                    Mined diamond: A mined diamond is a natural mineral formed
                    in the lithospheric mantle consisting of pure carbon
                    crystallized in the isometric system. It is found in many
                    colors. Its hardness is 10; its specific gravity is
                    approximately 3.52; and it has a refractive index of 2.42.
                  </li>
                  <li>
                    “Laboratory-Grown diamond,” “Laboratory-Created diamond,” or
                    “Parin Gems-Created diamond”: A man-made and artificially
                    created crystalline form derived from pure carbon by its
                    exposure to extremely high pressure like in nature (HTHP)
                    though done so above ground and not in Earth's lithospheric
                    mantle or utilizing a diamond seed and applying chemical
                    vapor deposition technology or both. It will share the same
                    optical and chemical properties as a natural diamond.
                  </li>
                  <li>
                    “Imitation Lab Created diamond” or “Simulation Lab Created
                    diamond”: A manmade diamond like simulation that does not
                    share the same chemical or optical characteristics of a
                    natural diamond. It will be made from optically clear and
                    colorless cubic zirconium, plastics, or glass.
                  </li>
                  <li>
                    “Real,” “Genuine,” “Natural,” and “Precious”: terms used to
                    describe the quality and character of only diamonds that
                    were formed within the Earth's lithospheric mantle. The said
                    terms cannot be used to qualify laboratory-grown or
                    imitation diamonds.
                  </li>
                  <li>
                    “Flawless and “Perfect”: can only be used when qualifying
                    natural diamonds and cannot be used to describe
                    laboratory-grown or imitation diamonds. Flawless and perfect
                    are used to describe diamonds where under a corrected
                    magnifier at 10x power, operated by a skilled gemstone
                    grader, show no indications of blemishes.
                  </li>
                </ul>
                <p>
                  Intellectual Property Disclaimer: The Parin Gems Site and all
                  of its social media platforms and other content including,
                  without limitation, the “look and feel,” designs, logo, text,
                  graphics, color scheme, pictures, selection, sound files, and
                  the amalgamation thereof (together the "Site Materials") are
                  the sole proprietary property of Parin Gems and is protected
                  by US or international copy right, trademark or patent laws.
                </p>
                <p>
                  Proprietary products and services, such as but not limited to
                  the Site, Parin Gems products, the Parin Gems logos and
                  trademarks, and any other product or service name or slogan
                  contained in the Site are protected trademarks of Parin Gems
                  and may not be imitated, used, or copied in any form, unless
                  with prior written consent of Parin Gems. Metatags or any
                  other "hidden text that enables or utilizes ”Parin Gems" or
                  any slogan, name, or trade mark of Parin Gems may not be used
                  without our prior written permission. If there are other
                  non-Parin Gems names, trademarks, product names, or slogan
                  mentioned or listed on the Site they are the property of their
                  relative owners.
                </p>

                <h2>Indemnification</h2>
                <p>
                  By using the Site, you agree to hold harmless, defend and
                  indemnify, Parin Gems and its associates, employees,
                  third-parties, and contractors from and against any claims for
                  damages, costs, liabilities, expenses, attorney's fees, etc.
                  that arise or may arise from your use of the Site, our
                  Services, our Products, or any part of any of the foregoing.
                </p>

                <h2>Termination</h2>
                <p>
                  Parin Gems maintains and reserves the right and ability to
                  terminate or cancel your license to use the Site and to
                  prevent or prohibit your future access thereof in its sole and
                  absolute discretion without prior notice to you.
                </p>

                <h2>Severability</h2>
                <p>
                  Should any of provision of the Terms become invalid, void, or
                  unlawful, in whole or in part and thereby unenforceable at a
                  court of law or equity, those terms or provisions shall be
                  stricken and shall not affect the enforceability, validity,
                  and lawfulness of the remaining terms and provisions of these
                  Term
                </p>

                <h2>Applicable Law and Venue</h2>
                <p>
                  The Terms herein and your use of the Site and its products and
                  services shall be governed by and construed in accordance with
                  the laws of the State of Connecticut. It is also agreed that
                  any civil or criminal action at equity or law that is
                  resultant or regarding these Terms will be filed only in the
                  Federal and State courts located in Fairfield County, and that
                  you hereby irrevocably and unconditionally waive the personal
                  jurisdiction of any other state or federal court.
                </p>

                <h2>DMCA Policy</h2>
                <p>
                  Pursuant to the Digital Millennium Copyright Act (DMCA) and
                  applicable laws, Parin Gems has implemented a policy
                  prohibiting Parin Gems account holders or users of the Site
                  (account holders, or users) who are determined to be repeat
                  infringers of the DMCA. Parin Gems in its sole discretion may
                  restrict access to this Site and/or terminate the account
                  holders or Site users who violate any intellectual property
                  rights of others, whether there is continued infringement or
                  not
                </p>

                <h2>Questions & Contact Information</h2>
                <p>
                  Should any comments or questions arise concerning the Terms
                  please contact Parin Gems:
                </p>
                <p>
                  <strong>By Telephone : </strong>{" "}
                  <a href="tel:1 (888) 487-2145">1 (888) 487-2145</a>{" "}
                </p>
                <p>
                  <strong>By E-mail : </strong>{" "}
                  <a href="maillTo:advisor@cleanorigin.com">
                    advisor@cleanorigin.com
                  </a>
                </p>
                <p>
                  <strong>By Regular Mail : </strong>
                </p>
                <p>
                  LBVllc ( Little Bear Ventures ),
                  <br />
                  68 Southfield Avenue, Two Stamford Landing,
                  <br />
                  Suite 100,
                  <br />
                  Stamford, Connecticut 06902,
                  <br />
                  United States.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;
