import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import $, { isEmptyObject } from "jquery";
import { setViewProduct } from "../../../redux/reducers/CommonSlice";
import { Link } from "react-router-dom";
import {
  addToCart,
  addToCartLocal,
} from "../../../redux/reducers/ProductSlice";
import {
  addToWishlist,
  getMyWishList,
} from "../../../redux/reducers/WishListSlice";
import {
  addToCompare,
  getMyCompare,
} from "../../../redux/reducers/CompareSlice";
import { resetorderPlaced } from "../../../redux/reducers/CheckoutSlice";
import useNotify from "../../../hooks/useNotify";

function DiamondCard({ product, isItemAdded }) {
  const Notify = useNotify();
  const dispatch = useDispatch();
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  let compare = useSelector((state) => state.CompareReducer.compare.items);
  let { isItemAddedToWishlist, addItemWishError } = useSelector(
    (state) => state.WishlistReducer.wishlist
  );

  let { isItemAddedToCompare, addItemCompareError } = useSelector(
    (state) => state.CompareReducer.compare
  );
  const viewclass = useSelector(
    (state) => state.commonReducer.productChangeView
  );

  const btnMobileClass = useSelector(
    (state) => state.commonReducer.ListBtnChangeView
  );

  useEffect(() => {
    $(".large-view").on("click", function () {
      $(".cmn-diamonds-box-container").addClass("col-lg-12");

      $(".large-views").addClass("large-view-wrapper");
      $(".large-view-size-table-shapw-wrapper").addClass(
        "large-view-size-table-shapw-wrapper-alignment"
      );

      // $(".top-idlarge-view,.large-none").css({
      //   display: "none",
      // });
      $(".top-idlarge-view").addClass("top-id-none");

      $(".large-view-btn-alignment").css({
        display: "flex",
      });
    });
    $(".grid-view").on("click", function () {
      $(".cmn-diamonds-box-container").removeClass("col-lg-12");

      $(".large-view-size-table-shapw-wrapper").removeClass(
        "large-view-size-table-shapw-wrapper-alignment"
      );

      $(".large-views").removeClass("large-view-wrapper");
      // $(".top-idlarge-view ,.large-none").css({
      //   display: "block",
      // });
      $(".top-idlarge-view").removeClass("top-id-none");

      $(".large-view-btn-alignment").css({
        display: "none",
      });
    });
    $(".large-view-btn-alignment").css({
      display: "none",
    });
  }, []);
  return (
    <div
      className={`${viewclass} cmn-diamonds-box-container`}
      id={"prd_" + product.Lab_Report_No}
    >
      <div className="cmn-diamond-box-inner-wrapper">
        <div
          className={`report-id top-idlarge-view ${
            viewclass.indexOf("col-lg-12") !== -1 && "top-id-none"
          }`}
        >
          <span>Report#</span>
          <span>{product.Lab_Report_No}</span>
        </div>
        <div
          className={`large-views ${
            viewclass.indexOf("col-lg-12") !== -1 && "large-view-wrapper"
          }`}
        >
          <div className="image-overlay">
            {
              <Link
                to={`/product/${product.Stone_No}/${product.Weight}-Ct-${product.Shape}-ShapeDiamond-${product.Clarity}`}
                className=""
                onClick={() => dispatch(setViewProduct(product))}
              >
                <div className="produt-singel-box-image">
                  <img
                    src={
                      product.Stone_Img_url === ""
                        ? "assets/images/procuctdetails/no-image-available-icon-6.png"
                        : product.Stone_Img_url
                    }
                    className="img-fluid diamondimg"
                    alt="dimaond"
                  />
                </div>
              </Link>
            }

            {/* <a
                  className={`${product.Stone_Img_url && "over-lay-container"}`}
                > */}
            {/* <img
              src="assets/images/stock/view1.svg"
              alt="view1"
              className="img-fluid"
            /> */}
            {/*  </a> */}
          </div>

          <div
            className={`large-view-size-table-shapw-wrapper ${
              viewclass.indexOf("col-lg-12") !== -1 &&
              "large-view-size-table-shapw-wrapper-alignment"
            }`}
          >
            <div className="report-id" style={{ display: "none" }}>
              <span>Report#</span>
              <span>{product.Lab_Report_No}</span>
            </div>
            <div className="shap-rating cmn-child-div-box">
              <span>
                {product.Shape} | {product.Weight} Ct | {product.Color} |{" "}
                {product.Clarity}
              </span>
              <div id="full-stars-example-two"></div>
            </div>
            {/* <div className="carat-size cmn-child-div-box ">
              <span>Carat Size:</span>
              <span>{product.Weight}</span>
            </div> */}
            <div className="igi-logo-table cmn-child-div-box ">
              {/* <span>Table: {product.Table_Diameter_Per}</span> */}
              {/* <span className="certilogo">
                {product.Certificate_file_url && (
                  <a href={product.Certificate_file_url} target="_blank">
                    <img
                      src={`assets/images/stock/${product.Lab}.png`}
                      alt="logo_IGI"
                      className="img-fluid"
                    />
                  </a>
                )}
              </span> */}
            </div>
            <div className="price">
              {UserID ? <span> ${product.Cost_Amt}</span> : ""}
              <span className="certilogo">
                {product.Certificate_file_url && (
                  <a href={product.Certificate_file_url} target="_blank">
                    <img
                      src={`assets/images/stock/${product.Lab}.png`}
                      alt="logo_IGI"
                      className="img-fluid"
                    />
                  </a>
                )}
              </span>
            </div>
          </div>
          <div className={`cart-like-detail-btn ${btnMobileClass} `}>
            <a
              className="tooltips"
              onClick={() => {
                if (UserID) {
                  dispatch(resetorderPlaced());
                  dispatch(
                    addToCart({
                      CustomerID: UserID,
                      StockIDs: product.Stock_ID,
                    })
                  );
                } else {
                  dispatch(addToCartLocal(product));
                }
              }}
            >
              <img
                src="assets/images/stock/cart1.svg"
                className="img-fluid"
                alt="cart1"
              />
              {/* <span className="tooltip"></span> */}
              <span className="tooltiptext tooltips-bottom">Add To Cart</span>
            </a>
            <a
              className="tooltips"
              onClick={() => {
                if (UserID) {
                  dispatch(
                    addToWishlist({
                      CustomerID: UserID,
                      StockIDs: product.Stock_ID,
                    })
                  );
                  if (isItemAddedToWishlist) {
                    dispatch(getMyWishList({ UserID }));
                    //Notify("Stone added successfully to wishlist.", "success");
                  } else {
                    //Notify(addItemWishError, "error");
                  }
                } else {
                  Notify("Please login first!", "error");
                }
              }}
            >
              <img
                src="assets/images/stock/like-heart.svg"
                alt="like-heart"
                className="img-fluid"
              />
              <span className="tooltiptext tooltips-bottom">
                Add To Wishlist
              </span>
            </a>
            <a
              className="tooltips"
              onClick={() => {
                const itemExist = compare.find(
                  (item) => item.Stock_ID == product.Stock_ID
                );
                if (!isEmptyObject(itemExist)) {
                  Notify("Stone already exist to compare.", "error");
                  return;
                }
                if (UserID) {
                  dispatch(
                    addToCompare({
                      CustomerID: UserID,
                      StockIDs: product.Stock_ID,
                    })
                  );
                  if (isItemAddedToCompare) {
                    dispatch(getMyCompare({ UserID }));
                    Notify("Stone added successfully to compare.", "success");
                  } else {
                    Notify(addItemCompareError, "error");
                  }
                } else {
                  Notify("Please login first!", "error");
                }
              }}
            >
              <img
                src="assets/images/stock/compare-match.svg"
                alt="like-heart"
                className="img-fluid"
              />
              <span className="tooltiptext tooltips-bottom">
                Add To Compare
              </span>
            </a>
            <Link
              to={`/product/${product.Stone_No}/${product.Weight}-Ct-${product.Shape}-ShapeDiamond-${product.Clarity}`}
              className="details-btn tooltips"
              onClick={() => dispatch(setViewProduct(product))}
            >
              <img
                src="assets/images/stock/view-details.svg"
                alt="view-details"
                className="img-fluid"
              />
              <span className="tooltiptext tooltips-bottom">View Details</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiamondCard;
