import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="Forgot Password" />
        <ForgotPassword />
      </Layout>
    </>
  );
}

export default Index;
