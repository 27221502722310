import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FetchApi } from "../../helper/helperFunction";
import useNotify from "../../hooks/useNotify";
import { useDispatch, useSelector } from "react-redux";
import { Domain } from "../../constant";
import {
  getCountry,
  getBillState,
  getCityList,
  setIsAddressDeleted,
  setEditAddressMode,
  resetEditAddressMode,
} from "../../redux/reducers/CommonSlice";

const initialValues = {
  Name: "",
  CustomerName: "",
  Email: "",
  Address1: "",
  Address2: "",
  PhoneNo: "",
  CityID: "",
  CountryID: "",
  StateID: "",
  ZipCode: "",
};

const editAddressSchema = Yup.object().shape({
  Name: Yup.string().required("Required"),
  Email: Yup.string().email("Invalid email").required("Required"),
  Address1: Yup.string().required("Required"),
  Address2: Yup.string(),
  PhoneNo: Yup.string().required("Required"),
  CityID: Yup.string().required("Required"),
  CountryID: Yup.string().required("Required"),
  CustomerName: Yup.string().required("Required"),
  StateID: Yup.string().required("Required"),
  ZipCode: Yup.string(),
});

function AddressesCart({ detail }) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const Notify = useNotify();
  const country_list = useSelector((state) => state.commonReducer.country_list);
  const { state_list, city_list, isEditAddressMode } = useSelector(
    (state) => state.commonReducer
  );
  let { UserID } = useSelector((state) => state.userReducer.userLoginInfo);
  const [editMode, setEditMode] = useState(false);
  const [initialData, setInitialData] = useState(initialValues);
  const [addressCountryChange, setAddressCountryChange] = useState(0);
  const [addressStateChange, setAddressStateChange] = useState(0);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    return () => {
      setEditMode(false);
      setInitialData(initialValues);
    };
  }, []);
  useEffect(() => {
    if (editMode) {
      dispatch(getCountry());
      dispatch(getBillState(detail.CountryID));
      dispatch(
        getCityList({ CountryID: detail.CountryID, StateID: detail.StateID })
      );
    }
    setInitialData({ ...detail, Cust_ID: UserID });
  }, [editMode]);

  useEffect(() => {
    if (addressCountryChange) {
      dispatch(getBillState(addressCountryChange));
    }
  }, [addressCountryChange]);

  useEffect(() => {
    if (addressStateChange) {
      dispatch(
        getCityList({
          CountryID: addressCountryChange,
          StateID: addressStateChange,
        })
      );
    }
  }, [addressStateChange]);
  const saveInput = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      if (formRef.current.isValid) {
        setInitialData(formRef.current);
      }
    }
  };
  const removeAddress = async (value) => {
    try {
      setLoader(true);
      const response = await FetchApi(
        `${Domain}/mobile-api/remove-customer-address`,
        "POST",
        value
      );
      const res = await response.json();
      if (res.IsSuccess) {
        Notify("Address deleted successfully.", "success");
        dispatch(setIsAddressDeleted(true));
        setLoader(false);
      } else {
        Notify(res.Message, "error");
        setLoader(false);
      }
    } catch (error) {
      Notify(error, "error");
      setLoader(false);
    }
  };

  const handleEdit = () => {
    if (isEditAddressMode) {
      Notify("Please save previous one!", "error");
    } else {
      setEditMode(true);
      dispatch(setEditAddressMode());
    }
  };

  const handleCancle = () => {
    if (isEditAddressMode) {
      setEditMode(false);
      dispatch(resetEditAddressMode());
    }
  };
  return (
    <>
      <div className="single-address-contianer border-rights">
        <div className="name-with-edit-delete">
          <span>{detail.Name}</span>
          <span className="d-flex">
            {!editMode ? (
              <>
                <i className="fas fa-pen" onClick={handleEdit} title="Edit"></i>
                <i
                  className="fas fa-trash-alt"
                  onClick={() =>
                    removeAddress({ Id: detail.Id, Cust_ID: UserID })
                  }
                  title="Delete"
                ></i>
              </>
            ) : (
              <>
                <i
                  className="far fa-save"
                  title="Save"
                  type="submit"
                  form="form1"
                  onClick={() => {
                    saveInput();
                  }}
                ></i>
                <i
                  className="far fa-times"
                  title="Cancel"
                  type="submit"
                  form="form1"
                  onClick={handleCancle}
                ></i>
              </>
            )}
            {loader && <i className="fa fa-spinner fa-spin ml-2"> </i>}
          </span>
        </div>
        {editMode ? (
          <>
            <div className="address-details address-box2">
              <Formik
                initialValues={initialData}
                validationSchema={editAddressSchema}
                onSubmit={async (values, { resetForm }) => {
                  try {
                    setLoader(true);
                    const response = await FetchApi(
                      `${Domain}/mobile-api/edit-customer-address`,
                      "POST",
                      values
                    );
                    const res = await response.json();
                    if (res.IsSuccess) {
                      Notify("Address updated successfully.", "success");
                      resetForm();
                      dispatch(resetEditAddressMode());
                      dispatch(setIsAddressDeleted(true));
                      setLoader(false);
                    } else {
                      Notify(res.Message, "error");
                      setLoader(false);
                    }
                  } catch (error) {
                    Notify(error, "error");
                    setLoader(false);
                  }
                }}
                innerRef={formRef}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <ul className="border-bottom">
                      <li className="input-box">
                        <span>Name*&nbsp;:&nbsp;</span>
                        <span>
                          <Field
                            type="text"
                            placeholder="Enter your name"
                            name="Name"
                            autoComplete="off"
                            onChange={handleChange}
                            values={values.Name}
                          />
                          <div className="error">
                            <ErrorMessage name="Name" />
                          </div>
                        </span>
                      </li>
                      <li className="input-box">
                        <span>Email*&nbsp;:&nbsp;</span>
                        <span>
                          <Field
                            type="email"
                            placeholder="Enter your email"
                            name="Email"
                            autoComplete="off"
                            onChange={handleChange}
                            values={values.Email}
                          />
                          <div className="error">
                            <ErrorMessage name="Email" />
                          </div>
                        </span>
                      </li>
                      <li className="input-box">
                        <span>Phone*&nbsp;:&nbsp;</span>
                        <span>
                          <Field
                            type="phone"
                            placeholder="Enter your phone"
                            name="PhoneNo"
                            autoComplete="off"
                            onChange={handleChange}
                            values={values.PhoneNo}
                          />
                          <div className="error">
                            <ErrorMessage name="PhoneNo" />
                          </div>
                        </span>
                      </li>
                      <li className="input-box">
                        <span>Address1*&nbsp;:&nbsp;</span>
                        <span>
                          <Field
                            type="address"
                            placeholder="Enter your address"
                            name="Address1"
                            autoComplete="off"
                            onChange={handleChange}
                            values={values.Address1}
                          />
                          <div className="error">
                            <ErrorMessage name="Address1" />
                          </div>
                        </span>
                      </li>
                      <li className="input-box">
                        <span>Address2&nbsp;:&nbsp;</span>
                        <span>
                          <Field
                            type="address"
                            placeholder="Enter your address"
                            name="Address2"
                            autoComplete="off"
                            onChange={handleChange}
                            values={values.Address2}
                          />
                        </span>
                      </li>
                      <li className="input-box">
                        <span>Country*&nbsp;:&nbsp;</span>
                        <span>
                          <Field
                            as="select"
                            name="CountryID"
                            onChange={(e) => {
                              handleChange("CountryID")(e);
                              setAddressCountryChange(e.currentTarget.value);
                            }}
                            value={values.CountryID}
                          >
                            <option value=""> ---Select Country---</option>
                            {country_list.map((country) => {
                              return (
                                <option
                                  value={country.Value}
                                  key={country.Value}
                                >
                                  {country.Text}
                                </option>
                              );
                            })}
                          </Field>
                          <div className="error">
                            <ErrorMessage name="CountryID" />
                          </div>
                        </span>
                      </li>
                      <li className="input-box">
                        <span>State/Province*&nbsp;:&nbsp;</span>
                        <span>
                          <Field
                            as="select"
                            name="StateID"
                            onChange={(e) => {
                              handleChange("StateID")(e);
                              setAddressStateChange(e.currentTarget.value);
                            }}
                            value={values.StateID}
                          >
                            <option value=""> ---Select State---</option>
                            {state_list.map((statelist) => {
                              return (
                                <option
                                  value={statelist.Value}
                                  key={statelist.Value}
                                >
                                  {statelist.Text}
                                </option>
                              );
                            })}
                          </Field>
                          <div className="error">
                            <ErrorMessage name="StateID" />
                          </div>
                        </span>
                      </li>
                      <li className="input-box">
                        <span>City*&nbsp;:&nbsp;</span>
                        <span>
                          <Field
                            as="select"
                            name="CityID"
                            onChange={handleChange}
                            value={values.CityID}
                          >
                            <option value=""> ---Select City---</option>
                            {city_list.map((citylist) => {
                              return (
                                <option
                                  value={citylist.Value}
                                  key={citylist.Value}
                                >
                                  {citylist.Text}
                                </option>
                              );
                            })}
                          </Field>
                          <div className="error">
                            <ErrorMessage name="CityID" />
                          </div>
                        </span>
                      </li>
                      <li className="input-box">
                        <span>ZipCode&nbsp;:&nbsp;</span>
                        <span>
                          <Field
                            type="text"
                            placeholder="Enter your zipCode"
                            name="ZipCode"
                            autoComplete="off"
                            onChange={handleChange}
                            values={values.ZipCode}
                          />
                        </span>
                      </li>
                    </ul>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <>
            <div className="address-details">
              <ul className="border-bottom">
                <li>
                  <span>Name&nbsp;:&nbsp;</span>
                  <span>{detail.Name}</span>
                </li>
                <li>
                  <span>Email&nbsp;:&nbsp;</span>
                  <span>{detail.Email}</span>
                </li>
                <li>
                  <span>Phone&nbsp;:&nbsp;</span>
                  <span>{detail.PhoneNo}</span>
                </li>
                <li>
                  <span>Address1&nbsp;:&nbsp;</span>
                  <span className="address-margin">{detail.Address1}</span>
                </li>
                <li>
                  <span>Address2&nbsp;:&nbsp;</span>
                  <span className="address-margin">{detail.Address2}</span>
                </li>
                <li>
                  <span>Country&nbsp;:&nbsp;</span>
                  <span>{detail.CountryName}</span>
                </li>
                <li>
                  <span>State/Province&nbsp;:&nbsp;</span>
                  <span>{detail.StateName}</span>
                </li>
                <li>
                  <span>City&nbsp;:&nbsp;</span>
                  <span>{detail.CityName}</span>
                </li>
                <li>
                  <span>Zip/PostalCode&nbsp;:&nbsp;</span>
                  <span>{detail.ZipCode}</span>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddressesCart;
