import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

import Checkout from "./Checkout";

function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="Check Out" />
        <Checkout />
      </Layout>
    </>
  );
}

export default Index;
