import React from "react";
import Layout from "../../Layout";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import MyProfileTabs from "./MyProfileTabs";
import MyProfileContent from "./MyProfileContent";
import Addresses from "./Addresses";
import MyOrders from "./MyOrders";
import ChangePassword from "./ChangePassword";

function Index() {
  return (
    <>
      <Layout>
        <Breadcrumb pagetitle="MyProfile" />
        <section className="stock-container bg-cmn-property">
          <div className="container">
            <div className="cmn-diamond-with-title-wrapper">
              <div className="myaccount-tabs">
                <MyProfileTabs />
                <div
                  className="tab-content tab-description  cmn-shadow-bg"
                  id="v-pills-tabContent"
                >
                  <MyProfileContent />
                  {/* <Addresses /> */}
                  <MyOrders />
                  <ChangePassword />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default Index;
