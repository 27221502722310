import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStockFilter } from "../../../redux/reducers/StockFilterSlice";
import {
  getProduct,
  resetProductPageNum,
} from "../../../redux/reducers/ProductSlice";

function LabFilter() {
  const { stockFilter, isFilterReset } = useSelector(
    (state) => state.stockFilterReducer
  );

  const selectedLab = useSelector(
    (state) => state.stockFilterReducer.stockFilter.Lab
  );

  useEffect(() => {
    isFilterReset &&
      setLab((state) => {
        return { ...state, activevalue: "NONE" };
      });
  }, [isFilterReset]);

  const dispatch = useDispatch();
  const masterData = useSelector((state) => state.commonReducer.masterlist.lab);

  const [lab, setLab] = useState({
    data: masterData.map((item) => item.MasterTypeValue_Code),
    activevalue: "NONE",
  });

  const handleClick = (e) => {
    setLab({ ...lab, activevalue: e.target.innerHTML });
    dispatch(
      addStockFilter({ Lab: e.target.innerHTML, loadmore: false, PageNum: 1 })
    );
    dispatch(resetProductPageNum());
    stockFilter.lodmore && dispatch(getProduct(stockFilter));
  };

  return (
    <div className="cmn-cirlce-value-secetion-wrapper">
      <span className="filter-sub-title">Lab:</span>
      <div className="circle-value">
        {lab.data.map((item) => {
          return (
            <span
              className={selectedLab === item ? "lab-active" : ""}
              onClick={handleClick}
              key={item}
              style={item.length > 4 ? { fontSize: "10px" } : {}}
            >
              {item}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default LabFilter;
